import { API } from 'aws-amplify';

const API_NAME = "ProcessesAPI";
const BASE_PATH = "/process"

export default class ProcessService {
    async queryProcesses(queryStringParameters={}, data) {
        return API.post(API_NAME, `${BASE_PATH}/query`,{queryStringParameters:queryStringParameters, body: data})
            .then((response) => response)
            .catch((error) => {
                console.log(error.response);
            });
    }

    async createProcess(data) {
        return API.post( API_NAME, BASE_PATH, { body: data } )
    }

    async updateProcess(data, id) {
        return API.put( API_NAME, `${BASE_PATH}/${id}`, { body: data } )
    }

    async deleteProcess(id) {
        return API.del( API_NAME, `${BASE_PATH}/${id}`)
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }

    async getProcess(id) {
        return API.get( API_NAME, `${BASE_PATH}/${id}`)
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }
}