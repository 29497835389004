import { useAuthenticator } from "@aws-amplify/ui-react";
import { Icon } from "@iconify/react";
import getSymbolFromCurrency from "currency-symbol-map";
import { useFormik } from "formik";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { Editor } from "primereact/editor";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { FormatCurrency, capitalizeWord, objectHasKeys, tableHeaderTemplate } from "../../../Utils";
import dropdownDown from "../../../assets/icons/dropdown-down.svg";
import { INVOICE_STATUSES } from "../../../data/Invoice";
import { checkComponentPermissions } from "../../../data/Permissions";
import InvoiceService from "../../../services/InvoiceService/InvoiceService";
import TenantService from "../../../services/TenantService/TenantService";
import AddEditInvoiceClient from "./Dialogs/AddEditInvoiceClient";
import { calculateInvoiceGlobalValues } from "./Utils";

const emptyLine = {
    id: uuid(),
    title: "New Line",
    description: "",
    quantity: 1,
    price: 0,
    taxes: 10,
    total: 0,
};

const AddInvoice = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const globalTableMenuRef = useRef(null);
    const toast = useRef(null);
    const { user } = useAuthenticator((context) => [context.user]);

    const [loading, setLoading] = useState(false);

    const tenantService = new TenantService();
    const invoiceService = new InvoiceService();

    const [tenantInfo, setTenantInfo] = useState();

    const [showAddEditInvoiceNotes, setShowAddEditInvoiceNotes] = useState(false);
    const notesMenuRef = useRef(null);
    const [temporalNotes, setTemporalNotes] = useState("");

    const [invoiceGlobalValues, setInvoiceGlobalValues] = useState({ subtotal: 0, discount: 0, beforeTaxes: 0, taxes: 0, total: 0 });

    const [showDiscount, setShowDiscount] = useState(false);
    const [showShipping, setShowShipping] = useState(false);

    const [editClientDialog, setEditClientDialog] = useState(false);

    useEffect(() => {
        setLoading(true);
        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            let calls = [];
            calls.push(
                tenantService.getTenant(user.attributes["custom:tenant_id"]).then((data) => {
                    if (data) {
                        setTenantInfo(data);
                        formik.setFieldValue("source", {
                            name: data?.name,
                            phone: data?.phone,
                            picture: data?.logo_path,
                            tax_id: data?.tax_id,
                            currency: data?.currency,
                            address: data?.address,
                        });
                    }
                    return data;
                })
            );
            // calls.push(
            //     userService.queryUsers({ limit: 1 }).then((data) => {
            //         setTotalUsers(data?.total ? data?.total : 0);
            //         return data;
            //     })
            // );

            Promise.all(calls).then(() => {
                setLoading(false);
            });
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            shipping: 0,
            discount: 0,
            status: "draft",
            due_date: new Date().toISOString(),
            lines: [],
            client: undefined,
            notes: "",
            source: {},
        },
        validate: (data) => {
            let errors = {};

            if (!data.due_date) {
                errors.due_date = t("label.field_required");
            }
            if (!data.status) {
                errors.status = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            invoiceService
                .createInvoice(data)
                .then((data) => {
                    navigate("/invoices");
                })
                .catch((error) => {
                    setLoading(false);
                    console.log("Err: ", error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: t(error?.response?.data), life: 5000 });
                });
        },
    });

    const updateLine = (key, value, index, final) => {
        let newLines = formik.values.lines;

        newLines[index][key] = value;

        let grossCost = newLines[index]["quantity"] * newLines[index]["price"];

        if (newLines[index]["discount"]) {
            if (newLines[index]["discount"].amount > 0) {
                if (newLines[index]["discount"].type === "exact") {
                    grossCost -= newLines[index]["discount"]["amount"];
                } else if (newLines[index]["discount"].type === "percentage") {
                    grossCost -= grossCost * (newLines[index]["discount"]["amount"] / 100);
                }
            }
        }

        // newLines[index]["total"] = grossCost + (grossCost * newLines[index]["taxes"]) / 100;

        if (final) {
            formik.setFieldValue("lines", [...newLines]);
        }

        return [...newLines];
    };

    const lineTemplate = (type, item, key, index, disabled, placeholder) => {
        if (type === "text") {
            return (
                <InputText
                    className="min-w-full"
                    placeholder={placeholder}
                    disabled={disabled}
                    value={item[key]}
                    onChange={(e) => {
                        const updatedLines = updateLine(key, e.target.value, index, true);
                        setInvoiceGlobalValues(calculateInvoiceGlobalValues({ ...formik.values, lines: updatedLines }));
                    }}
                    onBlur={(e) => {
                        const updatedLines = updateLine(key, e.target.value, index, true);
                        setInvoiceGlobalValues(calculateInvoiceGlobalValues({ ...formik.values, lines: updatedLines }));
                    }}
                />
            );
        } else if (type === "number") {
            return (
                <div className="p-inputgroup custom-time-picker" disabled={disabled}>
                    <Button
                        icon="pi pi-minus"
                        className="p-button-text"
                        onClick={(e) => {
                            const updatedLines = updateLine(key, item[key] - 1, index, true);
                            setInvoiceGlobalValues(calculateInvoiceGlobalValues({ ...formik.values, lines: updatedLines }));
                        }}
                    />
                    <InputNumber
                        style={{ textAlign: "center" }}
                        value={item[key]}
                        onValueChange={(e) => {
                            const updatedLines = updateLine(key, e.value, index, true);
                            setInvoiceGlobalValues(calculateInvoiceGlobalValues({ ...formik.values, lines: updatedLines }));
                        }}
                        min={1}
                    />
                    <Button
                        icon="pi pi-plus"
                        className="p-button-text"
                        onClick={(e) => {
                            const updatedLines = updateLine(key, item[key] + 1, index, true);
                            setInvoiceGlobalValues(calculateInvoiceGlobalValues({ ...formik.values, lines: updatedLines }));
                        }}
                    />
                </div>
            );
        } else if (type === "currency") {
            return (
                <div className="p-inputgroup" disabled={disabled}>
                    <span className="p-inputgroup-addon">
                        <i className="pi">
                            <label className="mousee-text-small font-medium font-weight-bold">{getSymbolFromCurrency(tenantInfo?.currency)}</label>
                        </i>
                    </span>
                    <InputNumber
                        value={item[key] ? item[key] : 0}
                        min={0}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        locale={i18n.language.split("-")[0]}
                        onValueChange={(e) => {
                            const updatedLines = updateLine(key, e.value, index, true);
                            setInvoiceGlobalValues(calculateInvoiceGlobalValues({ ...formik.values, lines: updatedLines }));
                        }}
                    />
                </div>
            );
        } else if (type === "percent") {
            return (
                <div className="p-inputgroup" disabled={disabled}>
                    <span className="p-inputgroup-addon">
                        <i className="pi pi-percentage mousee-text-small font-xxx-small font-weight-regular"></i>
                    </span>
                    <InputNumber
                        value={item[key]}
                        max={100}
                        onValueChange={(e) => {
                            const updatedLines = updateLine(key, e.value, index, true);
                            setInvoiceGlobalValues(calculateInvoiceGlobalValues({ ...formik.values, lines: updatedLines }));
                        }}
                    />
                </div>
            );
        } /* else if (type === "html") {
            return (
                <span className="p-input-icon-right min-w-full max-w-full">
                    <i
                        className=""
                        onClick={(e) => {
                            if (e && e.stopPropagation) {
                                e.stopPropagation();
                            }
                            setActiveConcept(item);
                            setShowEditDescriptionDialog(true);
                        }}
                    >
                        <Icon icon="solar:pen-bold" className="pb-1" style={{ fontSize: "20px" }} />
                    </i>
                    <InputText
                        className="min-w-full"
                        value={item[key] ? extractContentFromHtml(item[key]) : "-"}
                        onClick={(e) => {
                            if (e && e.stopPropagation) {
                                e.stopPropagation();
                            }
                            // setActiveConcept(item);
                            // setShowEditDescriptionDialog(true);
                        }}
                    />
                </span>
            );
        } */ else if (type === "discount") {
            return (
                <div className="p-inputgroup" disabled={disabled}>
                    <span
                        className="p-inputgroup-addon"
                        onClick={(e) => {
                            e.stopPropagation();
                            // setEditedConceptIndex(index);
                            // discountMenuRef.current.toggle(e);
                        }}
                    >
                        {item && item.discount && item.discount.type === "percentage" ? (
                            <i className="pi pi-percentage mousee-text-small font-xxx-small font-weight-regular"></i>
                        ) : (
                            <i className="pi">
                                <label className="mousee-text-small font-medium font-weight-bold">{getSymbolFromCurrency(tenantInfo?.currency)}</label>
                            </i>
                        )}
                    </span>
                    <InputNumber
                        value={item[key] && item[key].amount ? item[key].amount : 0}
                        min={0}
                        minFractionDigits={0}
                        maxFractionDigits={0}
                        locale={i18n.language.split("-")[0]}
                        onValueChange={(e) => {
                            let newValue = {};
                            if (item && item.discount) {
                                newValue = {
                                    type: item?.discount?.type,
                                    amount: e.value,
                                };
                            } else {
                                newValue = {
                                    type: "exact",
                                    amount: e.value,
                                };
                            }

                            const updatedLines = updateLine(key, newValue, index, true);
                            setInvoiceGlobalValues(calculateInvoiceGlobalValues({ ...formik.values, lines: updatedLines }));
                        }}
                    />
                </div>
            );
        }

        return null;
    };

    const linesTableFooter = () => {
        return (
            <div className="grid">
                <div className="col-6 md:col">
                    {/* Subtotal */}
                    <div className="flex flex-column xl:pr-4">
                        <div className="flex align-items-center justify-content-left">
                            <label className="pl-2 mousee-text font-medium font-weight-semibold">{t("label.subtotal")}</label>
                        </div>
                        <div className="flex align-items-center justify-content-left">
                            <label className="pl-2 mousee-text font-medium font-weight-ultralight">{FormatCurrency(invoiceGlobalValues?.subtotal, 2, tenantInfo?.currency, i18n.language)}</label>
                        </div>
                    </div>
                </div>
                {showShipping ? (
                    <div className="col-6 md:col">
                        {/* Shipping */}
                        <div className="flex flex-column xl:pr-4">
                            <div className="flex align-items-center justify-content-left">
                                <label className="pl-2 mousee-text font-medium font-weight-semibold">{t("label.shipping")}</label>
                            </div>
                            <div className="flex align-items-center justify-content-left">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi">
                                            <label className="mousee-text-small font-medium font-weight-bold">{getSymbolFromCurrency(tenantInfo?.currency)}</label>
                                        </i>
                                    </span>
                                    <InputNumber
                                        id="shipping"
                                        name="shipping"
                                        value={formik.values.shipping}
                                        inputClassName="mousee-text font-small font-weight-light max-w-9rem"
                                        onValueChange={(e) => {
                                            formik.setFieldValue("shipping", e.value);
                                            setInvoiceGlobalValues(calculateInvoiceGlobalValues({ ...formik.values, shipping: e.value }));
                                        }}
                                        className="min-w-2rem"
                                        locale={i18n.language.split("-")[0]}
                                        min={0}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {showDiscount ? (
                    <div className="col-6 md:col">
                        {/* Discount */}
                        <div className="flex flex-column xl:pr-4">
                            <div className="flex align-items-center justify-content-left">
                                <label className="pl-2 mousee-text font-medium font-weight-semibold">{t("label.discount")}</label>
                            </div>
                            <div className="flex align-items-center justify-content-left">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi">
                                            <label className="mousee-text-small font-medium font-weight-bold">{getSymbolFromCurrency(tenantInfo?.currency)}</label>
                                        </i>
                                    </span>
                                    <InputNumber
                                        id="discount"
                                        name="discount"
                                        value={formik.values.discount}
                                        inputClassName="mousee-text font-small font-weight-light max-w-9rem"
                                        onValueChange={(e) => {
                                            formik.setFieldValue("discount", e.value);
                                            setInvoiceGlobalValues(calculateInvoiceGlobalValues({ ...formik.values, discount: e.value }));
                                        }}
                                        className="min-w-2rem"
                                        locale={i18n.language.split("-")[0]}
                                        min={0}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                <div className="col-6 md:col">
                    {/* Taxes */}
                    <div className="flex flex-column xl:pl-4 xl:pr-4">
                        <div className="flex align-items-center justify-content-left">
                            <label className="pl-2 mousee-text font-medium font-weight-semibold">{t("label.taxes")}</label>
                        </div>
                        <div className="flex align-items-center justify-content-left">
                            <label className="pl-2 mousee-text font-medium font-weight-ultralight">{FormatCurrency(invoiceGlobalValues?.taxes, 2, tenantInfo?.currency, i18n.language)}</label>
                        </div>
                    </div>
                </div>

                <div className="col-6 md:col">
                    {/* Total */}
                    <div className="flex flex-column xl:pl-4 xl:pr-4">
                        <div className="flex align-items-center justify-content-left">
                            <label className="pl-2 mousee-text font-medium font-weight-semibold">{t("label.total")}</label>
                        </div>
                        <div className="flex align-items-center justify-content-left">
                            <label className="pl-2 mousee-text font-medium font-weight-ultralight">{FormatCurrency(invoiceGlobalValues?.total, 2, tenantInfo?.currency, i18n.language)}</label>
                        </div>
                    </div>
                </div>

                {/* <div className="col-12 xl:col-2 md:col-6 min-h-full">
                    <div className="flex align-content-center flex-wrap min-h-full">
                        <div className="flex align-items-center justify-content-center min-w-full">
                            <Button icon={<Icon icon="solar:widget-bold-duotone" className="mr-2" style={{ fontSize: "20px" }} />} className="p-button-filled min-w-full" label={t("budgets.page.import_from_service")} />
                        </div>
                    </div>
                </div> */}
                <div className="col-12 xl:col-2 md:col-6 min-h-full">
                    <div className="flex align-content-center flex-wrap min-h-full">
                        <div className="flex align-items-center justify-content-center min-w-full">
                            <Button
                                icon="pi pi-plus-circle"
                                label={t("label.add_another_line")}
                                className="p-button-filled min-w-full"
                                onClick={() => {
                                    if (formik.values.lines && formik.values.lines?.length > 0) {
                                        if (formik.values.lines[formik.values.lines.length - 1].title !== "") {
                                            const newLines = formik.values.lines.concat([{ ...emptyLine, id: uuid() }]);
                                            formik.setFieldValue("lines", newLines);
                                            setInvoiceGlobalValues(calculateInvoiceGlobalValues({ ...formik.values, lines: newLines }));
                                        }
                                    } else {
                                        const newLines = [{ ...emptyLine, id: uuid() }];
                                        formik.setFieldValue("lines", newLines);
                                        setInvoiceGlobalValues(calculateInvoiceGlobalValues({ ...formik.values, lines: newLines }));
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    // const calculateInvoiceGlobalValues = (invoiceParam) => {
    //     let [subtotal, discount, taxes, total] = [0, 0, 0, 0];

    //     if (invoiceParam && invoiceParam?.lines && invoiceParam?.lines?.length > 0) {
    //         invoiceParam?.lines.forEach((line) => {
    //             subtotal += line?.quantity * line?.price;

    //             let grossCost = line?.quantity * line?.price;
    //             if (line?.discount && line?.discount?.type && line?.discount?.ammount) {
    //                 if (line?.discount?.type === "exact") {
    //                     discount += line?.discount?.ammount;
    //                     grossCost -= line?.discount?.ammount;
    //                 } else if (line?.discount?.type === "percentage") {
    //                     discount += grossCost * (line?.discount?.ammount / 100);
    //                     grossCost -= grossCost * (line?.discount?.ammount / 100);
    //                 }
    //             }

    //             if (line?.taxes) {
    //                 taxes += (grossCost * line?.taxes) / 100;
    //                 total += grossCost + (grossCost * line?.taxes) / 100;
    //             } else {
    //                 total += grossCost;
    //             }
    //         });
    //     }

    //     setInvoiceGlobalValues({ subtotal, discount, taxes, total });
    // };

    const calculateLineTotal = (lineParam) => {
        if (lineParam?.quantity && lineParam?.price) {
            let grossCost = lineParam?.quantity * lineParam?.price;

            if (lineParam?.discount && lineParam?.discount?.type && lineParam?.discount?.ammount) {
                if (lineParam?.discount?.type === "exact") {
                    grossCost -= lineParam?.discount?.ammount;
                } else if (lineParam?.discount?.type === "percentage") {
                    grossCost -= grossCost * (lineParam?.discount?.ammount / 100);
                }
            }
            if (lineParam?.taxes) {
                return grossCost + (grossCost * lineParam?.taxes) / 100;
            } else {
                return grossCost;
            }
        }

        return 0;
    };

    return checkComponentPermissions(
        <div className="grid page-layout">
            <Toast ref={toast} />
            <AddEditInvoiceClient
                show={editClientDialog}
                setShow={setEditClientDialog}
                setClientId={(newValue) => {
                    formik.setFieldValue("client_id", newValue);
                    formik.setTouched({}, false);
                }}
                setClient={(newValue) => {
                    formik.setFieldValue("client", newValue);
                    formik.setTouched({}, false);
                }}
            />

            <OverlayPanel unstyled ref={globalTableMenuRef} key="global-table-menu-overlay" id="global-table-menu-overlay" className="p-2 p-menu">
                <div className="flex flex-column">
                    {/* <div className="flex align-items-center justify-content-start p-2">
                        <div className="flex align-items-center">
                            <Checkbox inputId="description" name="description" onChange={() => setShowDescriptionColumn(!showDescriptionColumn)} checked={showDescriptionColumn} />
                            <label className="mousee-text font-x-small font-weight-regular ml-4">{t("label.description")}</label>
                        </div>
                    </div> */}

                    <div className="flex align-items-center justify-content-start p-2">
                        <div className="flex align-items-center">
                            <Checkbox inputId="shipping" name="shipping" onChange={() => setShowShipping(!showShipping)} checked={showShipping} />
                            <label className="mousee-text font-x-small font-weight-regular ml-3">{t("label.show_shipping")}</label>
                        </div>
                    </div>
                    <div className="flex align-items-center justify-content-start p-2">
                        <div className="flex align-items-center">
                            <Checkbox inputId="discount" name="discount" onChange={() => setShowDiscount(!showDiscount)} checked={showDiscount} />
                            <label className="mousee-text font-x-small font-weight-regular ml-3">{t("label.show_discount")}</label>
                        </div>
                    </div>
                </div>
            </OverlayPanel>
            <div className="col-12">
                <div className="grid">
                    <div className="col-12 p-0">
                        <div className="flex  justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <div className="grid p-0">
                                    <div className="col-12 p-0">
                                        <BreadCrumb
                                            home={{
                                                icon: <label className="mousee-text font-x-small font-weight-regular cursor-pointer">{capitalizeWord(t("label.invoices"))}</label>,
                                                command: () => {
                                                    navigate("/invoices");
                                                },
                                            }}
                                            separatorIcon={<div className="pl-2 pr-2">/</div>}
                                            model={[{ label: t("label.new_invoice"), disabled: true }]}
                                        />
                                    </div>
                                    <div className="col-12 px-0">
                                        <label className="mousee-text font-large font-weight-regular">{t("label.create_a_new_invoice")}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <Button label={t("label.save_changes")} loading={loading} disabled={objectHasKeys(formik.errors)} type="button" className="p-button" onClick={formik.handleSubmit} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pt-5">
                        <div className="project-header">
                            <div className="card grid">
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="col-12 xl:col">
                                            <div className="grid">
                                                <div className="col-12">
                                                    <div class="flex justify-content-between flex-wrap">
                                                        <div class="flex align-items-center justify-content-center">
                                                            <label className="mousee-text font-medium font-weight-bold">{t("pages.invoices.new_invoice.from")}</label>
                                                        </div>
                                                        {/* <div class="flex align-items-center justify-content-center">
                                                            <Icon icon="solar:pen-bold" className="" style={{ fontSize: "20px" }} />
                                                        </div> */}
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    {formik.values.source?.name ? <label className="block mousee-text font-x-small font-weight-semibold">{formik.values.source?.name}</label> : null}

                                                    {formik.values.source?.address?.line1 ? <label className="block mousee-text font-x-small font-weight-regular">{formik.values.source?.address?.line1}</label> : null}
                                                    {formik.values.source?.address?.line2 ? <label className="block mousee-text font-x-small font-weight-regular">{formik.values.source?.address?.line2}</label> : null}
                                                    {formik.values.source?.address?.city || formik.values.source?.address?.postal_code || formik.values.source?.address?.state ? (
                                                        <label className="block mousee-text font-x-small font-weight-regular">{`${formik.values.source?.address?.city ? `${formik.values.source?.address?.city}, ` : null}${
                                                            formik.values.source?.address?.postal_code ? `${formik.values.source?.address?.postal_code} - ` : null
                                                        }${formik.values.source?.address?.state ? formik.values.source?.address?.state : null}`}</label>
                                                    ) : null}

                                                    {formik.values.source?.address?.country ? <label className="block mousee-text font-x-small font-weight-regular">{formik.values.source?.address?.country}</label> : null}

                                                    {formik.values.source?.tax_id ? <label className="block mousee-text font-x-small font-weight-regular pt-2">{`${t("label.tax_id")}: ${formik.values.source?.tax_id}`}</label> : null}
                                                </div>
                                                {/* <div className="col-12">
                                                    {tenantInfo?.commercial_name ? (
                                                        <label className="block mousee-text font-x-small font-weight-semibold">{tenantInfo?.commercial_name}</label>
                                                    ) : tenantInfo?.social_reason ? (
                                                        <label className="block mousee-text font-x-small font-weight-semibold">{tenantInfo?.social_reason}</label>
                                                    ) : null}
                                                    {tenantInfo?.cif ? <label className="block mousee-text font-x-small font-weight-regular">{tenantInfo?.cif}</label> : null}
                                                    {tenantInfo?.address || tenantInfo?.city ? (
                                                        tenantInfo?.address && tenantInfo?.city ? (
                                                            <label className="block mousee-text font-x-small font-weight-regular">{`${tenantInfo?.address}, ${tenantInfo?.city}`}</label>
                                                        ) : tenantInfo?.address ? (
                                                            <label className="block mousee-text font-x-small font-weight-regular">{tenantInfo?.address}</label>
                                                        ) : tenantInfo?.city ? (
                                                            <label className="block mousee-text font-x-small font-weight-regular">{tenantInfo?.city}</label>
                                                        ) : null
                                                    ) : null}
                                                    {tenantInfo?.province || tenantInfo?.postal_code ? (
                                                        tenantInfo?.province && tenantInfo?.postal_code ? (
                                                            <label className="block mousee-text font-x-small font-weight-regular">{`${tenantInfo?.province}, ${tenantInfo?.postal_code}`}</label>
                                                        ) : tenantInfo?.province ? (
                                                            <label className="block mousee-text font-x-small font-weight-regular">{tenantInfo?.province}</label>
                                                        ) : tenantInfo?.postal_code ? (
                                                            <label className="block mousee-text font-x-small font-weight-regular">{tenantInfo?.postal_code}</label>
                                                        ) : null
                                                    ) : null}
                                                    {tenantInfo?.country ? <label className="block mousee-text font-x-small font-weight-regular">{tenantInfo?.country}</label> : null}
                                                </div> */}
                                            </div>
                                        </div>

                                        <Divider layout="vertical" className="hidden xl:flex border-300" />
                                        <Divider layout="horizontal" className="flex xl:hidden border-300" />

                                        <div className="col-12 xl:col">
                                            <div className="grid">
                                                <div className="col-12">
                                                    <div class="flex justify-content-between flex-wrap">
                                                        <div class="flex align-items-center justify-content-center">
                                                            <label className="mousee-text font-medium font-weight-bold">{`${t("label.bill_to")}:`}</label>
                                                        </div>
                                                        <div class="flex align-items-center justify-content-center">
                                                            <Button
                                                                icon={formik.values.client ? <Icon icon="solar:pen-bold" style={{ fontSize: "20px" }} /> : <i className="pi pi-plus-circle" style={{ fontSize: "18px" }}></i>}
                                                                className="p-button-text p-button-secondary"
                                                                onClick={() => setEditClientDialog(true)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {formik.values.client ? (
                                                    <div className="col-12">
                                                        {formik.values.client?.name ? <label className="block mousee-text font-x-small font-weight-semibold">{formik.values?.client?.name}</label> : null}

                                                        {formik.values.client?.address?.line1 ? <label className="block mousee-text font-x-small font-weight-regular">{formik.values.client?.address?.line1}</label> : null}
                                                        {formik.values.client?.address?.line2 ? <label className="block mousee-text font-x-small font-weight-regular">{formik.values.client?.address?.line2}</label> : null}
                                                        {formik.values.client?.address?.city || formik.values.client?.address?.postal_code || formik.values.client?.address?.state ? (
                                                            <label className="block mousee-text font-x-small font-weight-regular">{`${formik.values.client?.address?.city ? `${formik.values.client?.address?.city}, ` : null}${
                                                                formik.values.client?.address?.postal_code ? `${formik.values.client?.address?.postal_code} - ` : null
                                                            }${formik.values.client?.address?.state ? formik.values.client?.address?.state : null}`}</label>
                                                        ) : null}

                                                        {formik.values.client?.address?.country ? <label className="block mousee-text font-x-small font-weight-regular">{formik.values.client?.address?.country}</label> : null}

                                                        {formik.values.client?.tax_id ? <label className="block mousee-text font-x-small font-weight-regular pt-2">{`${t("label.tax_id")}: ${formik.values.client?.tax_id}`}</label> : null}
                                                    </div>
                                                ) : null}
                                                {/* {formik.values.client ? (
                                                    <div className="col-12">
                                                        {formik.values.client?.fiscal_data?.social_reason ? (
                                                            <label className="block mousee-text font-x-small font-weight-semibold">{formik.values.client?.fiscal_data?.social_reason}</label>
                                                        ) : formik.values.client?.fiscal_data?.commercial_name ? (
                                                            <label className="block mousee-text font-x-small font-weight-semibold">{formik.values.client?.fiscal_data?.commercial_name}</label>
                                                        ) : null}

                                                        {formik.values.client?.fiscal_data?.cif ? <label className="block mousee-text font-x-small font-weight-regular">{formik.values.client?.fiscal_data?.cif}</label> : null}

                                                        {formik.values.client?.fiscal_data?.address || formik.values.client?.fiscal_data?.city ? (
                                                            formik.values.client?.fiscal_data?.address && formik.values.client?.fiscal_data?.city ? (
                                                                <label className="block mousee-text font-x-small font-weight-regular">{`${formik.values.client?.fiscal_data?.address}, ${formik.values.client?.fiscal_data?.city}`}</label>
                                                            ) : formik.values.client?.fiscal_data?.address ? (
                                                                <label className="block mousee-text font-x-small font-weight-regular">{formik.values.client?.fiscal_data?.address}</label>
                                                            ) : formik.values.client?.fiscal_data?.city ? (
                                                                <label className="block mousee-text font-x-small font-weight-regular">{formik.values.client?.fiscal_data?.city}</label>
                                                            ) : null
                                                        ) : null}
                                                        {formik.values.client?.fiscal_data?.province || formik.values.client?.fiscal_data?.postal_code ? (
                                                            formik.values.client?.fiscal_data?.province && formik.values.client?.fiscal_data?.postal_code ? (
                                                                <label className="block mousee-text font-x-small font-weight-regular">{`${formik.values.client?.fiscal_data?.province}, ${formik.values.client?.fiscal_data?.postal_code}`}</label>
                                                            ) : formik.values.client?.fiscal_data?.province ? (
                                                                <label className="block mousee-text font-x-small font-weight-regular">{formik.values.client?.fiscal_data?.province}</label>
                                                            ) : formik.values.client?.fiscal_data?.postal_code ? (
                                                                <label className="block mousee-text font-x-small font-weight-regular">{formik.values.client?.fiscal_data?.postal_code}</label>
                                                            ) : null
                                                        ) : null}
                                                        {formik.values.client?.fiscal_data?.country ? <label className="block mousee-text font-x-small font-weight-regular">{formik.values.client?.fiscal_data?.country}</label> : null}
                                                    </div>
                                                ) : null} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 xl:col-3 md:col-6">
                                    <label className="pl-2 mousee-text font-x-small font-weight-semibold">{t("label.invoice_number")}*</label>
                                    <InputText name="invoice_number" disabled value="INV-**********" inputid="invoice_number" type="text" className="min-w-full" />
                                </div>
                                <div className="col-12 xl:col-3 md:col-6">
                                    <label className="pl-2 mousee-text font-x-small font-weight-semibold">{t("label.status")}*</label>
                                    <Dropdown
                                        dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                        dataKey="type"
                                        value={INVOICE_STATUSES?.filter((item) => item?.type === "draft")[0]}
                                        disabled
                                        optionLabel={(item) => {
                                            return t(item?.label);
                                        }}
                                        options={INVOICE_STATUSES}
                                        className="min-w-full"
                                    />
                                </div>
                                <div className="col-12 xl:col-3 md:col-6">
                                    <label className="pl-2 mousee-text font-x-small font-weight-semibold">{t("label.date")}*</label>
                                    <Calendar className="w-full" value={new Date()} disabled icon={<Icon icon="solar:calendar-mark-bold-duotone" className="" style={{ fontSize: "20px" }} />} locale={i18n.language.split("-")[0]} showIcon iconPos="left" />
                                </div>
                                <div className="col-12 xl:col-3 md:col-6">
                                    <label className="pl-2 mousee-text font-x-small font-weight-semibold">{t("label.due_date")}*</label>
                                    <Calendar
                                        minDate={new Date()}
                                        readOnlyInput
                                        className="w-full"
                                        value={formik.values.due_date ? new Date(formik.values.due_date) : undefined}
                                        onChange={(e) => {
                                            const newValue = e && e?.value ? e.value.toISOString() : undefined;
                                            formik.setFieldValue("due_date", newValue);
                                        }}
                                        icon={<Icon icon="solar:calendar-mark-bold-duotone" className="" style={{ fontSize: "20px" }} />}
                                        locale={i18n.language.split("-")[0]}
                                        showIcon
                                        iconPos="left"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="grid">
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-12">
                                        <label className="mousee-text font-medium font-weight-bold">{`${t("label.details")}:`}</label>
                                    </div>

                                    <div className="col-12">
                                        <div className="card p-0" style={{ overflow: "hidden" }}>
                                            <DataTable
                                                key="invoice-lines-table"
                                                selectionMode="single"
                                                dataKey="id"
                                                loading={loading}
                                                size="large"
                                                emptyMessage={t("label.no_items_to_display")}
                                                value={formik.values.lines ? formik.values.lines : []}
                                                responsiveLayout="scroll"
                                                stripedRows
                                                footer={linesTableFooter}
                                            >
                                                <Column header={tableHeaderTemplate(null, t("label.title"))} field="title" style={{ width: "30%", minWidth: "15rem" }} body={(item, options) => lineTemplate("text", item, "title", options.rowIndex, false, `${t("label.add_a_title")}...`)} />
                                                <Column
                                                    header={tableHeaderTemplate(null, t("label.description"))}
                                                    field="description"
                                                    style={{ width: "30%", minWidth: "15rem" }}
                                                    body={(item, options) => lineTemplate("text", item, "description", options.rowIndex, false, `${t("label.add_a_description")}...`)}
                                                />
                                                <Column header={tableHeaderTemplate(null, t("label.quantity"))} field="quantity" style={{ width: "10%", minWidth: "15rem" }} body={(item, options) => lineTemplate("number", item, "quantity", options.rowIndex, false)} />
                                                <Column header={tableHeaderTemplate(null, t("label.unit_price"))} field="price" style={{ width: "10%", minWidth: "15rem" }} body={(item, options) => lineTemplate("currency", item, "price", options.rowIndex)} />
                                                {/* <Column header={tableHeaderTemplate(null, t("label.discount"))} field="discount" style={{ width: "10%", minWidth: "10rem" }} body={(item, options) => lineTemplate("discount", item, "discount", options.rowIndex)} /> */}
                                                <Column header={tableHeaderTemplate(null, t("label.taxes"))} field="taxes" style={{ width: "10%", minWidth: "10rem" }} body={(item, options) => lineTemplate("percent", item, "taxes", options.rowIndex)} />
                                                <Column header={tableHeaderTemplate(null, t("label.total"))} field="total" style={{ width: "10%", minWidth: "10rem" }} body={(item) => FormatCurrency(calculateLineTotal(item), 2, tenantInfo?.currency, i18n.language)} />

                                                <Column
                                                    headerStyle={{ width: "5rem", textAlign: "center" }}
                                                    bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                                    header={() => {
                                                        return (
                                                            <Button
                                                                icon={"pi pi-ellipsis-v"}
                                                                className="p-button-text p-button-secondary"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    globalTableMenuRef?.current?.toggle(e);
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                    body={(item, options) => {
                                                        return (
                                                            <Button
                                                                icon={<Icon icon="solar:trash-bin-trash-bold-duotone" className="" style={{ fontSize: "20px" }} />}
                                                                className="p-button-text p-button-secondary"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    formik.setFieldValue(
                                                                        "lines",
                                                                        formik.values.lines.filter((_, index) => index !== options.rowIndex)
                                                                    );
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {!showAddEditInvoiceNotes ? (
                        formik.values.notes && formik.values.notes?.length > 0 && formik.values.notes !== "" ? (
                            <div className="col-12 project-header">
                                <div className="grid">
                                    <div className="col-12">
                                        <label className="mousee-text font-medium font-weight-bold">{`${t("label.notes")}:`}</label>
                                    </div>
                                    <div className="col-12">
                                        <div className="card">
                                            <div class="flex justify-content-between flex-wrap">
                                                <div class="flex align-items-center justify-content-center">
                                                    <div className="mousee-text font-x-small font-weight-regular" dangerouslySetInnerHTML={{ __html: formik.values.notes.replace(/(<? *script)/gi, "illegalscript") }}></div>
                                                </div>
                                                <div class="flex align-items-center justify-content-center">
                                                    <Button
                                                        icon={"pi pi-ellipsis-v"}
                                                        className="p-button-text p-button-secondary"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            notesMenuRef?.current?.toggle(e);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="col-12">
                                <Button className="p-button-filled" icon="pi pi-align-left" label={t("label.add_notes")} onClick={() => setShowAddEditInvoiceNotes(true)} />
                            </div>
                        )
                    ) : (
                        <div className="col-12 project-header">
                            <div className="card grid p-0">
                                <div className="col-12">
                                    <label className="pl-2 mousee-text font-small font-weight-semibold">{t("label.notes")}</label>
                                </div>

                                <div className="col-12 p-0 custom-task-editor">
                                    <Editor
                                        value={temporalNotes}
                                        className="w-12 border-transparent mousee-text text-x-small font-weight-regular"
                                        placeholder={t("label.add_description")}
                                        headerTemplate={
                                            <span className="ql-formats">
                                                <button className="ql-bold" aria-label="Bold"></button>
                                                <button className="ql-italic" aria-label="Italic"></button>
                                                <button className="ql-underline" aria-label="Underline"></button>

                                                <button className="ql-list" value={"ordered"} aria-label="List"></button>
                                                <button className="ql-list" value={"bullet"} aria-label="List"></button>

                                                <button className="ql-align"></button>
                                            </span>
                                        }
                                        onTextChange={(e) => {
                                            if (!e.htmlValue) {
                                                setTemporalNotes("");
                                            } else {
                                                setTemporalNotes(e.textValue);
                                            }
                                        }}
                                    />
                                    <Divider className="mt-0" />
                                    <div className="flex justify-content-start flex-wrap pb-3">
                                        <div className="flex align-items-center justify-content-center pl-3">
                                            <Button label={t("label.cancel")} className="p-button-outlined p-button-plain" onClick={() => setShowAddEditInvoiceNotes(false)} />
                                        </div>
                                        <div className="flex align-items-center justify-content-center pl-3">
                                            <Button
                                                label={t("label.save_changes")}
                                                className="p-button"
                                                onClick={() => {
                                                    formik.setFieldValue("notes", temporalNotes);
                                                    setShowAddEditInvoiceNotes(false);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* {!showAddEditBudgetDescription ? (
                        formik.values.description && (formik.values.description.title || formik.values.description.text) ? (
                            <div className="col-12 project-header">
                                <div className="card grid p-1">
                                    <div className="col-12 pl-4">
                                        <div className="flex justify-content-between flex-wrap">
                                            <div className="flex align-items-center justify-content-center">
                                                <label className="mousee-text font-large font-weight-semibold">{formik.values.description.title}</label>
                                            </div>
                                            <div className="flex align-items-center justify-content-center">
                                                <Button
                                                    icon={"pi pi-ellipsis-v"}
                                                    className="p-button-text p-button-secondary"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        descriptionMenuRef?.current?.toggle(e);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 pl-4 pr-4">
                                        <div className="mousee-text font-x-small font-weight-regular" dangerouslySetInnerHTML={{ __html: formik.values.description.text.replace(/(<? *script)/gi, "illegalscript") }}></div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="col-12">
                                <Button className="p-button-filled" icon="pi pi-align-left" label={t("label.add_notes")} onClick={() => setShowAddEditBudgetDescription(true)} />
                            </div>
                        )
                    ) : (
                        <div className="col-12 project-header">
                            <div className="card grid p-0">
                                <div className="col-12">
                                    <label className="pl-2 mousee-text font-small font-weight-semibold">{t("label.notes")}</label>
                                </div>

                                <div className="col-12 p-0 custom-task-editor">
                                    <Editor
                                        value={temporalDescriptionText}
                                        className="w-12 border-transparent mousee-text text-x-small font-weight-regular"
                                        placeholder={t("label.add_description")}
                                        headerTemplate={
                                            <span className="ql-formats">
                                                <button className="ql-bold" aria-label="Bold"></button>
                                                <button className="ql-italic" aria-label="Italic"></button>
                                                <button className="ql-underline" aria-label="Underline"></button>

                                                <button className="ql-list" value={"ordered"} aria-label="List"></button>
                                                <button className="ql-list" value={"bullet"} aria-label="List"></button>

                                                <button className="ql-align"></button>
                                            </span>
                                        }
                                        onTextChange={(e) => {
                                            setTemporalDescriptionText(e.htmlValue);
                                        }}
                                    />
                                    <Divider className="mt-0" />
                                    <div className="flex justify-content-start flex-wrap pl-3 pb-3">
                                        <div className="flex align-items-center justify-content-center">
                                            <Button
                                                label={t("label.save_changes")}
                                                className="p-button"
                                                onClick={() => {
                                                    // updateBudgetField("description", { title: temporalDescriptionTitle, text: temporalDescriptionText });

                                                    setShowAddEditBudgetDescription(false);
                                                }}
                                            />
                                        </div>
                                        <div className="flex align-items-center justify-content-center pl-3">
                                            <Button label={t("label.cancel")} className="p-button-outlined p-button-plain" onClick={() => setShowAddEditBudgetDescription(false)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )} */}
                </div>
            </div>
        </div>,
        ["add_invoices"],
        null,
        null
    );
};
export default AddInvoice;
