import { useAuthenticator } from "@aws-amplify/ui-react";
import { useFormik } from "formik";
import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ClientService from "../../../../services/ClientService/ClientService";
import { clientIndividualItemTemplate, clientItemTemplate } from "../../../People/Clients/Utils";
import { handleProfilesOnScroll } from "../../../People/Profiles/Utils";
// import "./AddParticipantDialog.scss";

const AddEditInvoiceClient = ({ show, setShow, setClientId, setClient }) => {
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    // const usersDropdownRef = useRef();

    // const profileService = new ProfileService();
    // const userService = new UserService();
    // const participantService = new ParticipantService();
    // const serviceService = new ServiceService();
    const clientService = new ClientService();

    const [loadingDropdown, setLoadingDropdown] = useState();
    const [loadingParticipant, setLoadingParticipant] = useState();

    const [tenantInfo, setTenantInfo] = useState();

    const { user } = useAuthenticator((context) => [context.user]);

    // Clients
    const [clients, setClients] = useState([]);
    const [queryClientsResponse, setQueryClientsResponse] = useState();
    const [tableFirst, setTableFirst] = useState(0);
    const [tableKeys, setTableKeys] = useState([0]);
    const [rowsNumber, setRowsNumber] = useState(10);
    const [activeIndex, setActiveIndex] = useState(0);
    const [clientFilterName, setClientFilterName] = useState(undefined);

    // // Users
    // const [users, setUsers] = useState([]);
    // const [queryUsersResponse, setQueryUsersResponse] = useState();
    // const [usersRowsNumber, setUsersRowsNumber] = useState(5);
    // const [userFilterName, setUserFilterName] = useState(undefined);

    // // Current Values
    // const [assignedProfiles, setAssignedProfiles] = useState({});
    // const [assignedUsers, setAssignedUsers] = useState({});

    // if (user && user.attributes && user.attributes["custom:tenant_id"]) {
    //     tenantService.getTenant(user.attributes["custom:tenant_id"]).then((data) => {
    //         setTenantInfo(data);
    //     });
    // }

    useEffect(() => {
        setLoading(true);
        clientService
            .queryClients({}, undefined)
            .then((data) => {
                setQueryClientsResponse(data);
                setClients(data?.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const formik = useFormik({
        initialValues: {
            text: "",
        },
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);
            let queryParameter = {
                limit: rowsNumber,
            };
            if (data.text.length > 0) {
                queryParameter = {
                    ...queryParameter,
                    name: data.text,
                };
            }
            clientService
                .queryClients(queryParameter, undefined)
                .then((data) => {
                    setTableFirst(0);
                    setTableKeys([0]);
                    setActiveIndex(0);
                    setQueryClientsResponse(data);
                    setClients(data?.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    });

    // const assignParticipant = (profileParam, participantsParam, assignedUsersParam) => {
    //     setLoadingParticipant(profileParam?.id);
    //     let newParticipant = {
    //         profile: {
    //             id: profileParam?.id,
    //             name: profileParam?.name,
    //             hour_cost: profileParam?.hour_cost,
    //             visibility: profileParam?.visibility,
    //         },
    //         estimate_time: {
    //             hours: 0,
    //             minutes: 0,
    //         },
    //         role: "viewer",
    //         client_id: task?.client_id,
    //         project_id: task?.project_id,
    //         process_id: task?.process_id,
    //         task_id: task?.id,
    //         overhead: tenantInfo?.overhead ? tenantInfo?.overhead : 0
    //     };
    //     if (assignedUsersParam[profileParam?.id]) {
    //         newParticipant = {
    //             ...newParticipant,
    //             user: {
    //                 id: assignedUsersParam[profileParam?.id]?.id,
    //                 name: assignedUsersParam[profileParam?.id]?.name,
    //                 surnames: assignedUsersParam[profileParam?.id]?.surnames,
    //                 avatar: assignedUsersParam[profileParam?.id]?.avatar,
    //                 hour_cost: assignedUsersParam[profileParam?.id]?.hour_cost,
    //                 email: assignedUsersParam[profileParam?.id]?.email,
    //             }
    //         }
    //     }

    //     participantService.createParticipant(task?.client_id, task?.project_id, task?.process_id, task?.id, newParticipant).then((data) => {
    //         if (data) {

    //             let assignedProfilesAux = {...assignedProfiles}
    //             assignedProfilesAux[profileParam?.id] = true
    //             setAssignedProfiles(assignedProfilesAux)

    //             let newParticipants = [];
    //             if (participantsParam && participantsParam?.length > 0) {
    //                 newParticipants = [...participants];
    //             }

    //             newParticipants.unshift(data);
    //             setParticipants(newParticipants);
    //             setLoadingParticipant(false);

    //             const serviceUpdateParams = {
    //                 update_params: {
    //                     participants: process?.participants ? process?.participants + 1 : 1,
    //                 },
    //             };

    //             serviceService.updateService(serviceUpdateParams, task?.client_id, task?.project_id, task?.process_id);
    //             updateTaskField("participants", task?.participants ? task?.participants + 1 : 1);
    //             setLoadingParticipant("")
    //         }
    //     })
    //     .catch(error => {
    //         console.log(error)
    //         setLoadingParticipant("")
    //     })

    // }

    const assignClient = (clientParam) => {
        setClientId(clientParam?.id);

        const clientData = {
            id: clientParam?.id,
            name: clientParam?.name,
            phone: clientParam?.phone,
            picture: clientParam?.picture,
            tax_id: clientParam?.tax_id,
            address: clientParam?.address,
            // fiscal_data: {
            //     address: clientParam?.fiscal_data?.address,
            //     cif: clientParam?.fiscal_data?.cif,
            //     city: clientParam?.fiscal_data?.city,
            //     country: clientParam?.fiscal_data?.country,
            //     postal_code: clientParam?.fiscal_data?.postal_code,
            //     province: clientParam?.fiscal_data?.province,
            //     social_reason: clientParam?.fiscal_data?.social_reason,
            // },
        };
        setClient(clientData);

        setShow(false);
    };

    return (
        <Dialog
            visible={show}
            onHide={() => setShow(false)}
            draggable={false}
            className="custom-dialog"
            closable={false}
            dismissableMask
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "24px 24px 0px" }}>
                        <label className="mousee-text font-medium font-weight-bold">{t("label.clients")}</label>
                    </div>
                    <div className="col-12" style={{ padding: "20px 24px" }}>
                        <form id="search-team-form" onSubmit={formik.handleSubmit} className="min-w-full">
                            <small className="pl-2 mousee-text-small font-weight-regular">{t("label.use_upper_and_lower_case")}</small>
                            <span className="p-input-icon-right min-w-full">
                                <i className="pi pi-search" />
                                <InputText
                                    value={formik.values.text}
                                    className="w-full xl:min-w-full"
                                    onChange={(e) => {
                                        formik.setFieldValue("text", e.target.value);
                                        if (e.target.value === "") {
                                            formik.handleSubmit();
                                        }
                                    }}
                                    placeholder={t("label.search_inputtext")}
                                />
                            </span>
                        </form>
                    </div>
                </div>
            }
            footer={<div></div>}
            onScrollCapture={(e) => handleProfilesOnScroll(e, clientService, rowsNumber, queryClientsResponse, setQueryClientsResponse, clients, setClients, clientFilterName, setClientFilterName, "")}
        >
            <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}>
                <div className="grid">
                    {clients?.map((item, index) => {
                        return (
                            <>
                                <div className="col-12">
                                    <div className="flex justify-content-between flex-wrap">
                                        <div className="flex align-items-center justify-content-start w-9 md:w-6">
                                            {/* <label className="mousee-text font-x-small font-weight-bold">{item?.name}</label> */}
                                            {item?.type === "individual" ? clientIndividualItemTemplate(item) : clientItemTemplate(item)}
                                        </div>
                                        <div className="flex align-items-center justify-content-center w-12 md:w-3 pt-2 md:pt-0">
                                            <Button icon={"pi pi-plus"} loading={loadingParticipant === item?.id} label={t("label.assign")} type="button" className="p-button-filled min-w-full" onClick={() => assignClient(item)} />
                                        </div>
                                    </div>
                                </div>
                                {index < clients?.length ? (
                                    <div className="col-12">
                                        <Divider className="border-300 mt-1 mb-1" />
                                    </div>
                                ) : null}
                            </>
                        );
                    })}

                    {!loading && (!clients || clients?.length === 0) ? (
                        <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>{" "}
                        </div>
                    ) : null}
                </div>
            </BlockUI>
        </Dialog>
    );
};
export default AddEditInvoiceClient;
