import { API } from 'aws-amplify';

const API_NAME = "ProjectsAPI";
const BASE_PATH = "/project"

export default class ProjectService {
    // async getProjects(queryStringParameters={}) {
    //     return API.get(API_NAME, `${BASE_PATH}`,{queryStringParameters:queryStringParameters})
    //         .then((response) => response)
    //         .catch((error) => {
    //             console.log(error.response);
    //         });
    // }

    async queryProjects(queryStringParameters={}, data) {
        return API.post(API_NAME, `${BASE_PATH}/query`,{queryStringParameters:queryStringParameters, body: data})
            .then((response) => response)
            .catch((error) => {
                console.log(error.response);
            });
    }

    async createProject(client_id, data) {
        return API.post( API_NAME, BASE_PATH, { queryStringParameters: {client_id: client_id}, body: data } )
        // .then((response) => response)
        // .catch((error) => {
        //     console.log(error.response);
        // });
    }

    // async createProjectFromBudget(data, client_id) {
    //     return API.post( API_NAME, `${BASE_PATH}/${client_id}`, { body: data } )
    //     .then((response) => response)
    //     .catch((error) => {
    //         console.log(error.response);
    //     });
    // }

    async updateProject(data, client_id, project_id) {
        return API.put( API_NAME, `${BASE_PATH}/${project_id}`, { queryStringParameters: {client_id: client_id}, body: data } )
        // .then((response) => response)
        // .catch((error) => {
        //     console.log(error.response);
        // });
    }

    async deleteProject(client_id, project_id) {
        return API.del( API_NAME, `${BASE_PATH}/${project_id}`, { queryStringParameters: {client_id: client_id} })
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }

    async getProject(client_id, project_id) {
        return API.get( API_NAME, `${BASE_PATH}/${project_id}`, { queryStringParameters: {client_id: client_id} })
    }    
}