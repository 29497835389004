import { useAuthenticator } from "@aws-amplify/ui-react";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormatCurrency, formatDateV2, isDesktop, objectHasKeys, tableHeaderTemplate } from "../../../Utils";
import dropdownDown from "../../../assets/icons/dropdown-down.svg";
import CustomTablePaginator from "../../../components/CustomTablePaginator";
import { checkComponentPermissions, checkPermissions } from "../../../data/Permissions";
import { getAdditionalTopBarComponentsV2 } from "../../../data/TabsData";
import { getUserStatusChip } from "../../../data/User";
import ProfileService from "../../../services/ProfileService/ProfileService";
import UserService from "../../../services/UserService/UserService";
import Forbidden from "../../Auth/Forbidden";
import { filterProfiles, handleProfilesOnScroll } from "../Profiles/Utils";
import AddUserDialog from "./AddUserDialog";
import EditUserDialog from "./EditUserDialog";
import ShowProfilesOverlay from "./ShowProfilesOverlay";
import { userItemTemplate } from "./Utils";

import { Icon } from "@iconify/react";
import { Menu } from "primereact/menu";
import { useTranslation } from "react-i18next";
import TenantService from "../../../services/TenantService/TenantService";

const Users = (props) => {
    const { t, i18n } = useTranslation();
    const { user, signOut } = useAuthenticator((context) => [context.user]);

    const [showNewUserDialog, setShowNewUserDialog] = useState(false);
    const [showEditUserDialog, setShowEditUserDialog] = useState(false);
    const userService = new UserService();
    const profileService = new ProfileService();
    const [loading, setLoading] = useState(false);
    const [sendingInvitation, setSendingInvitation] = useState(undefined);
    const navigate = useNavigate();
    const toast = useRef(null);
    // const [users, setUsers] = useState([])
    const [editedUser, setEditedUser] = useState();
    const [activeUser, setActiveUser] = useState(null);
    const menuRef = useRef(null);
    const [isAdmin, setIsAdmin] = useState(false);

    const [profiles, setProfiles] = useState([]);
    const [queryProfilesResponse, setQueryProfilesResponse] = useState();
    const [profilesRowsNumber, setProfilesRowsNumber] = useState(5);
    const [profileFilterName, setProfileFilterName] = useState(undefined);

    const [users, setUsers] = useState([]);
    const [queryUsersResponse, setQueryUsersResponse] = useState();
    const [usersTableFirst, setUsersTableFirst] = useState(0);
    const [usersTableKeys, setUsersTableKeys] = useState([0]);
    const [usersRowsNumber, setUsersRowsNumber] = useState(10);
    const [usersActiveIndex, setUsersActiveIndex] = useState(0);

    const [profileVisibility, setProfileVisibility] = useState("public");

    // const showUserProfilesRef = useRef(null);
    const showUserProfilesRef = useRef([]);
    const [showUserProfilesOverlay, setShowUserProfilesOverlay] = useState(false);

    const tenantService = new TenantService();
    const [tenantInfo, setTenantInfo] = useState();

    useEffect(() => {
        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            tenantService.getTenant(user.attributes["custom:tenant_id"]).then((data) => {
                if (data) {
                    setTenantInfo(data);
                }
            });
        }
    }, []);

    useEffect(() => {
        showUserProfilesRef.current = showUserProfilesRef.current.slice(0, users.length);
    }, [users]);

    useEffect(() => {
        props.setTopbarAdditionalComponents(getAdditionalTopBarComponentsV2("team", navigate));
    }, []);

    useEffect(() => {
        props.setOption("people");
    }, []);

    useEffect(() => {
        formik.resetForm();
        setLoading(true);

        userService.queryUsers({ limit: usersRowsNumber }).then((data) => {
            setQueryUsersResponse(data);
            setUsers(data?.data);
            setLoading(false);
        });

        refreshProfiles(profileVisibility, undefined);
    }, []);

    const formik = useFormik({
        initialValues: {
            profile: null,
            status: null,
            text: "",
        },
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);
            let queryParameter = {
                limit: usersRowsNumber,
            };
            if (data.text.length > 0) {
                queryParameter = {
                    ...queryParameter,
                    name: data.text,
                };
            }
            userService.queryUsers(queryParameter).then((data) => {
                setUsersTableFirst(0);
                setUsersTableKeys([0]);
                setUsersActiveIndex(0);
                setQueryUsersResponse(data);
                setUsers(data?.data);
                setLoading(false);
            });
        },
    });

    const filterByParams = (e, type) => {
        setLoading(true);

        formik.setFieldValue(type, e.value);

        let queryParameters = {};

        let flag = false;

        if (type === "profile") {
            if (e.value) {
                flag = true;

                userService.queryUsers({ limit: usersRowsNumber, profile_id: `${e.value.visibility}#${e.value.id}` }).then((data) => {
                    setUsersTableFirst(0);
                    setUsersTableKeys([0]);
                    setUsersActiveIndex(0);
                    setQueryUsersResponse(data);
                    setUsers(data?.data);
                    setLoading(false);
                });
            }
        }

        if (!flag) {
            userService.queryUsers({ limit: usersRowsNumber }).then((data) => {
                setQueryUsersResponse(data);
                setUsers(data?.data);
                setLoading(false);
            });
        }
    };

    const deleteUser = (item) => {
        if (item) {
            if (item?.status !== "inactive" && item?.status !== "draft") {
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: t("users.page.to_delete_a_user_the_user_status_must_be_inactive"), life: 5000 });
            } else {
                const userFullname = `${item?.name} ${item?.surnames}`.trim();
                confirmDialog({
                    message: <span>{t("users.page.are_you_sure_you_want_to_delete_the_user", { username: userFullname })}</span>,
                    header: t("label.delete_user"),
                    draggable: false,
                    acceptLabel: t("label.delete_user"),
                    icon: "pi pi-info-circle",
                    acceptClassName: "p-button-danger",
                    accept: () => {
                        setLoading(true);
                        userService
                            .deleteUser({}, item.id)
                            .then((response) => {
                                profileService.queryProfiles({ user_id: item.id, limit: 50 }, undefined, "private").then((data) => {
                                    if (data) {
                                        const profilesToRemove = {
                                            remove: data.data.map((newProfile) => newProfile.id),
                                        };
                                        profileService.asignUser(profilesToRemove, item.id);
                                        userService.asignProfiles(profilesToRemove, item.id);
                                    }
                                });

                                const newUsers = users.filter((newUser) => newUser.id !== item.id);
                                setUsers(newUsers);
                                setLoading(false);
                            })
                            .catch((error) => {
                                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data, life: 5000 });
                                setLoading(false);
                            });
                    },
                    contentClassName: "pt-3",
                });
            }
        }
    };

    const onTablePageChange = (key) => {
        if (key === usersTableKeys.length) {
            setLoading(true);

            let queryParameters = {
                limit: usersRowsNumber,
            };

            if (formik.values.text) {
                queryParameters = {
                    ...queryParameters,
                    name: formik.values.text,
                };
            }

            userService.queryUsers(queryParameters, queryUsersResponse.last_key).then((data) => {
                setQueryUsersResponse(data);

                if (data.data && data.data.length > 0) {
                    const newUsers = [...users, ...data.data];
                    setUsers(newUsers);

                    setUsersTableKeys(usersTableKeys.concat([usersTableKeys.length]));
                    setUsersActiveIndex(usersTableKeys.length);

                    if (usersTableKeys.length > 0) {
                        setUsersTableFirst(usersTableKeys.length * usersRowsNumber);
                    } else {
                        setUsersTableFirst(0);
                    }
                }

                setLoading(false);
            });
        } else {
            const newIndex = usersTableKeys.findIndex((item) => item === key);
            setUsersActiveIndex(newIndex);

            if (newIndex > 0) {
                setUsersTableFirst(newIndex * usersRowsNumber);
            } else {
                setUsersTableFirst(0);
            }
        }
    };

    const emptyPage = () => {
        return (
            <div className="flex align-items-center justify-content-center overflow-hidden min-w-full">
                <img src={`/empty_images/${i18n.language}/users_empty.svg`} alt="" width={isDesktop() ? 550 : 400} className="cursor-pointer" onClick={() => setShowNewUserDialog(true)} />
            </div>
        );
    };

    const refreshProfiles = (visibility, name) => {
        let queryParameters = {
            limit: profilesRowsNumber,
        };

        if (name && name.length > 0) {
            queryParameters = {
                ...queryParameters,
                name: name,
            };
        }

        let lastKey = undefined;

        if (visibility === profileVisibility && queryProfilesResponse && queryProfilesResponse?.last_key && objectHasKeys(queryProfilesResponse?.last_key)) {
            lastKey = queryProfilesResponse?.last_key;
        }

        profileService.queryProfiles(queryParameters, lastKey, "private").then((data) => {
            setQueryProfilesResponse(data);
            setProfiles(data?.data);
        });
    };

    const sendInvitation = (userId) => {
        setSendingInvitation(userId);

        const updateParams = {
            status: "active",
        };

        userService.updateUser({}, { update_params: updateParams }, userId).then((data) => {
            if (data) {
                let newUsers = [...users];
                const currentUserIndex = newUsers.findIndex((item) => item.id === data.id);
                newUsers[currentUserIndex] = data;
                setUsers(newUsers);
                setSendingInvitation(undefined);
            }
        });
    };

    const getMenuItems = (item) => {
        let items = [
            {
                label: t("label.edit"),
                icon: <Icon icon="solar:pen-bold" className="mr-3" style={{ fontSize: "20px" }} />,
                // command: () => setShowEditUserDialog(true),
                command: () => navigate(`/people/${activeUser?.id}`),
            },
            {
                label: t("label.delete"),
                icon: <Icon icon="solar:trash-bin-trash-bold-duotone" className="mr-3" style={{ fontSize: "20px" }} />,
                command: () => deleteUser(item),
            },
        ];

        return items;
    };

    const usersTableHeader = (
        <div className="grid">
            <div className="col-12 md:col-4">
                <div className="flex align-content-end flex-wrap w-full h-full">
                    <div className="flex align-items-end justify-content-center w-full h-full">
                        <Dropdown
                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                            id="search-user-profiles-dowpdown"
                            name="search-user-profiles-dowpdown"
                            dataKey="id"
                            value={formik.values.profile}
                            options={profiles}
                            optionLabel="name"
                            onChange={(e) => filterByParams(e, "profile")}
                            placeholder={t("label.select_skill_dropdown")}
                            filter
                            className="min-w-full"
                            showClear
                            emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                            onScrollCapture={(e) => handleProfilesOnScroll(e, profileService, profilesRowsNumber, queryProfilesResponse, setQueryProfilesResponse, profiles, setProfiles, profileFilterName, setProfileFilterName, profileVisibility)}
                            filterTemplate={(options) => (
                                <div className="flex flex-column min-w-full">
                                    <div className="flex align-items-center justify-content-center">
                                        <span className="p-input-icon-right min-w-full">
                                            <i className="pi pi-search" />
                                            <InputText
                                                autoFocus
                                                className="min-w-full"
                                                value={profileFilterName}
                                                ref={null}
                                                onChange={(e) => filterProfiles(e, options, setProfileFilterName, queryProfilesResponse, setQueryProfilesResponse, profileService, profilesRowsNumber, profiles, setProfiles, profileVisibility)}
                                            />
                                        </span>
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12 md:col-8">
                <form id="search-team-form" onSubmit={formik.handleSubmit} className="min-w-full">
                    <small className="pl-2 mousee-text-small font-weight-regular">{t("label.use_upper_and_lower_case")}</small>
                    <span className="p-input-icon-right min-w-full">
                        <i className="pi pi-search" />
                        <InputText
                            value={formik.values.text}
                            className="w-full xl:min-w-full"
                            onChange={(e) => {
                                formik.setFieldValue("text", e.target.value);
                                if (e.target.value === "") {
                                    formik.handleSubmit();
                                }
                            }}
                            placeholder={t("label.search_user")}
                        />
                    </span>
                </form>
            </div>
        </div>
    );

    return checkComponentPermissions(
        <div className="grid page-layout">
            <Toast ref={toast} />
            <ConfirmDialog />
            <Menu model={getMenuItems(activeUser)} popup ref={menuRef} />
            {showNewUserDialog ? <AddUserDialog reload={setLoading} show={showNewUserDialog} onHide={setShowNewUserDialog} users={users} setUsers={setUsers} /> : null}
            {showEditUserDialog ? <EditUserDialog show={showEditUserDialog} onHide={setShowEditUserDialog} user={activeUser} setUser={setActiveUser} users={users} setUsers={setUsers} /> : null}
            {formik.values.text === "" && !formik.values.profile && !loading && (!users || (users && users.length === 0)) ? (
                emptyPage()
            ) : (
                <>
                    {/* <div className="xl:col-offset-1"></div> */}
                    <div className="col-12 xl:col-12">
                        <div className="grid">
                            <div className="col-12 p-0">
                                <div className="flex  justify-content-between flex-wrap">
                                    <div className="flex align-items-center justify-content-center">
                                        <div className="grid p-0">
                                            <div className="col-12 p-0">
                                                <label className="mousee-text font-large font-weight-semibold">{t("label.users")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex align-items-center justify-content-center">
                                        <div className="flex flex-row flex-wrap">
                                            {checkComponentPermissions(
                                                <div className="flex align-items-center justify-content-center">
                                                    {/* <Button icon={"pi pi-plus"} label={t("label.new_user")} type="button" className="p-button min-w-full" onClick={() => setShowNewUserDialog(true)} /> */}
                                                    <Button icon={"pi pi-plus"} label={t("label.new_user")} type="button" className="p-button min-w-full"  onClick={() => navigate("/people/new")} />
                                                </div>,
                                                ["add_users"],
                                                null,
                                                null
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 p-0 pt-5">
                                <div className="card p-0" style={{ overflow: "hidden" }}>
                                    <DataTable
                                        key="team-users-table"
                                        selectionMode="single"
                                        dataKey="id"
                                        emptyMessage={t("label.no_items_to_display")}
                                        loading={loading}
                                        value={users.slice(usersActiveIndex * usersRowsNumber, usersActiveIndex * usersRowsNumber + usersRowsNumber)}
                                        responsiveLayout="scroll"
                                        stripedRows
                                        onRowClick={(e) => {
                                            if (checkPermissions(["edit_users"])) {
                                                setActiveUser(e.data);
                                                // setShowEditUserDialog(true);
                                                navigate(`/people/${e.data?.id}`)
                                            }
                                        }}
                                        sortIcon={(item) => {
                                            if (item?.sorted) {
                                                if (item?.sortOrder === 1) {
                                                    return <Icon icon="solar:sort-from-bottom-to-top-bold-duotone" style={{ fontSize: "20px" }} />;
                                                } else if (item?.sortOrder === -1) {
                                                    return <Icon icon="solar:sort-from-top-to-bottom-bold-duotone" style={{ fontSize: "20px" }} />;
                                                }
                                            }
                                        }}
                                        header={usersTableHeader}
                                        first={usersTableFirst}
                                        footer={<CustomTablePaginator activeIndex={usersActiveIndex} onPageChange={onTablePageChange} first={usersTableFirst} rowsNumber={usersRowsNumber} keys={usersTableKeys} lastKey={queryUsersResponse?.last_key} />}
                                    >
                                        <Column sortable field="name" header={tableHeaderTemplate(null, t("label.name"))} style={{ width: "40%", minWidth: "20rem" }} body={(item) => userItemTemplate(item)} />

                                        <Column
                                            header={tableHeaderTemplate(null, t("label.skills"))}
                                            key={(item) => item.id}
                                            columnKey={(item) => item.id}
                                            body={(item, props) => {
                                                return (
                                                    <div key={users[props.rowIndex + usersActiveIndex * usersRowsNumber].id} id={users[props.rowIndex + usersActiveIndex * usersRowsNumber].id}>
                                                        <Button
                                                            key={users[props.rowIndex + usersActiveIndex * usersRowsNumber].id}
                                                            icon={<Icon icon="solar:eye-bold-duotone" data-pr-tooltip={t("users.page.show_skills")} style={{ fontSize: "24px" }} />}
                                                            className="p-button-text p-button-secondary"
                                                            tooltip={t("users.page.show_skills")}
                                                            tooltipOptions={{ position: "bottom" }}
                                                            onClick={(e) => {
                                                                e?.stopPropagation();
                                                                showUserProfilesRef?.current[props.rowIndex + usersActiveIndex * usersRowsNumber]?.toggle(e);
                                                            }}
                                                        />
                                                        <ShowProfilesOverlay
                                                            visible={showUserProfilesOverlay}
                                                            onHide={setShowUserProfilesOverlay}
                                                            key={users[props.rowIndex + usersActiveIndex * usersRowsNumber].id}
                                                            customRef={(el) => (showUserProfilesRef.current[props.rowIndex + usersActiveIndex * usersRowsNumber] = el)}
                                                            userId={users[props.rowIndex + usersActiveIndex * usersRowsNumber].id}
                                                        />
                                                    </div>
                                                );
                                            }}
                                            style={{ width: "15%", minWidth: "7rem" }}
                                        />

                                        {/* <Column header={tableHeaderTemplate(null, t("label.type"))} body={(item) => t(getUserTypeLabel(item.type))} style={{ width: "15%", minWidth: "12rem" }} /> */}

                                        <Column header={tableHeaderTemplate(null, t("label.freelance"))} body={(item) => (item.external ? `Sí` : "No")} style={{ width: "5%", minWidth: "10rem" }} />

                                        {checkComponentPermissions(
                                            <Column sortable field="hour_cost" header={tableHeaderTemplate(null, t("label.hour_cost"))} body={(item) => FormatCurrency(item?.hour_cost, 2, tenantInfo?.currency, i18n.language)} style={{ width: "10%", minWidth: "10rem" }} />,
                                            ["view_hour_cost"],
                                            null,
                                            null
                                        )}

                                        <Column
                                            header={tableHeaderTemplate(null, t("label.status"))}
                                            field="status"
                                            sortable
                                            style={{ width: "15%", minWidth: "6rem" }}
                                            body={(item) => {
                                                if (item?.status === "draft") {
                                                    // return <Button type="button" icon="pi pi-send" className="p-button-filled p-button-secondary" loading={sendingInvitation === item.id} label={t("label.send_invitation")} onClick={(e) => sendInvitation(item.id)} />;

                                                    return (
                                                        <Button
                                                            icon={<Icon icon="iconamoon:send-fill" data-pr-tooltip={t("label.send_invitation")} style={{ fontSize: "24px", color: "var(--secondary-color-extra-light)" }} />}
                                                            className="p-button-text p-button-secondary"
                                                            tooltip={t("label.send_invitation")}
                                                            tooltipOptions={{ position: "bottom" }}
                                                            onClick={(e) => {
                                                                e?.stopPropagation();
                                                                sendInvitation(item.id);
                                                            }}
                                                        />
                                                    );
                                                } else {
                                                    // <Chip style={{ borderRadius: "var(--border-radius-small)", backgroundColor: item.status === "active" ? "#8ADCBD" : "#FAD797" }} label={t(getUserStatusLabel(item.status))} />;
                                                    return getUserStatusChip(item?.status, t);
                                                }
                                            }}
                                        />

                                        <Column sortable field="created_at" header={tableHeaderTemplate(null, t("label.creation_date"))} body={(item) => (item.created_at ? formatDateV2(item?.created_at, true, i18n.language) : "N/A")} style={{ width: "15%", minWidth: "14rem" }} />
                                        <Column sortable field="updated_at" header={tableHeaderTemplate(null, t("label.update_date"))} body={(item) => (item.updated_at ? formatDateV2(item?.updated_at, true, i18n.language) : "N/A")} style={{ width: "15%", minWidth: "14rem" }} />

                                        {checkComponentPermissions(
                                            checkPermissions("delete_users") && checkPermissions("edit_users") ? (
                                                <Column
                                                    headerStyle={{ width: "5rem", textAlign: "center" }}
                                                    bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                                    body={(item, options) => {
                                                        return (
                                                            <Button
                                                                icon={"pi pi-ellipsis-v"}
                                                                className="p-button-text p-button-secondary"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setActiveUser(item);
                                                                    menuRef.current.toggle(e);
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                            ) : checkPermissions("delete_users") ? (
                                                <Column
                                                    headerStyle={{ width: "5rem", textAlign: "center" }}
                                                    bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                                    body={(item, options) => {
                                                        return (
                                                            <Button
                                                                icon={<Icon icon="solar:trash-bin-trash-bold-duotone" className="" style={{ fontSize: "20px" }} />}
                                                                className="p-button-text p-button-secondary"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    deleteUser(item);
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                            ) : checkPermissions("edit_users") ? (
                                                <Column
                                                    headerStyle={{ width: "5rem", textAlign: "center" }}
                                                    bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                                    body={(item, options) => {
                                                        return (
                                                            <Button
                                                                icon={<Icon icon="solar:pen-bold" className="" style={{ fontSize: "20px" }} />}
                                                                className="p-button-text p-button-secondary"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setActiveUser(item);
                                                                    // setShowEditUserDialog(true);
                                                                    navigate(`/people/${item?.id}`)
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                            ) : null,
                                            ["delete_users", "edit_users"],
                                            null,
                                            null
                                        )}
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="xl:col-offset-1"></div> */}
                </>
            )}
        </div>,
        ["view_users"],
        null,
        <Forbidden />
    );
};
export default Users;
