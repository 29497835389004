import { Avatar } from "primereact/avatar";

export const calculateInvoiceAmount = (invoice) => {
    let [total] = [0, 0, 0, 0];

    if (invoice && invoice?.lines && invoice?.lines?.length > 0) {

        let shippingPerLine = invoice?.shipping ? (invoice?.shipping/invoice?.lines?.length) : 0;
        let discountPerLine = invoice?.discount ? (invoice?.discount/invoice?.lines?.length) : 0;

        invoice?.lines.forEach((line) => {
            // let grossCost = line?.quantity * line?.price;
            let grossCost = ((line?.quantity * line?.price) + shippingPerLine) - discountPerLine;
            

            // if (line?.discount && line?.discount?.type && line?.discount?.ammount) {
            //     if (line?.discount?.type === "exact") {
            //         grossCost -= line?.discount?.ammount;
            //     } else if (line?.discount?.type === "percentage") {
            //         grossCost -= grossCost * (line?.discount?.ammount / 100);
            //     }
            // }

            if (line?.taxes) {
                total += grossCost + (grossCost * line?.taxes) / 100;
            } else {
                total += grossCost;
            }
        });
    }

    return total;
};

export const calculateInvoiceGlobalValues = (invoiceParam) => {
    let [subtotal, discount, beforeTaxes, taxes, total] = [0, 0, 0, 0, 0];

    if (invoiceParam && invoiceParam?.lines && invoiceParam?.lines?.length > 0) {
        discount = invoiceParam?.discount

        let shippingPerLine = invoiceParam?.shipping ? (invoiceParam?.shipping/invoiceParam?.lines?.length) : 0;
        let discountPerLine = invoiceParam?.discount ? (invoiceParam?.discount/invoiceParam?.lines?.length) : 0;

        // if (invoiceParam?.shipping) {
        //     subtotal += invoiceParam?.shipping
        //     total += invoiceParam?.shipping
        // }
        // if (invoiceParam?.discount) {
        //     subtotal -= invoiceParam?.discount
        //     total -= invoiceParam?.discount
        //     discount = invoiceParam?.discount
        // }
        invoiceParam?.lines.forEach((line) => {
            subtotal += line?.quantity * line?.price

            let grossCost = ((line?.quantity * line?.price) + shippingPerLine) - discountPerLine;
            // if (line?.discount && line?.discount?.type && line?.discount?.ammount) {
            //     if (line?.discount?.type === "exact") {
            //         discount += line?.discount?.ammount;
            //         grossCost -= line?.discount?.ammount;
            //     } else if (line?.discount?.type === "percentage") {
            //         discount += grossCost * (line?.discount?.ammount / 100);
            //         grossCost -= grossCost * (line?.discount?.ammount / 100);
            //     }
            // }

            beforeTaxes += grossCost;
            if (line?.taxes) {
                taxes += (grossCost * line?.taxes) / 100;
                total += grossCost + (grossCost * line?.taxes) / 100;
            } else {
                total += grossCost;
            }
        });


    }

    return { subtotal, discount, beforeTaxes, taxes, total };
};

export const invoiceClientItemTemplate = (invoice, avatarSize) => {
    return (
        <div className="flex align-items-center gap-2">
            <Avatar
                image={invoice?.client?.picture && invoice?.client?.picture}
                size={avatarSize ? avatarSize : "xlarge"}
                shape="circle"
            />
            <div class="flex flex-column">
                <div class="flex align-items-center justify-content-left">
                    <label className='pl-3 mousee-text font-small font-weight-regular'>{invoice?.client?.name}</label>
                </div>
                <div class="flex align-items-center justify-content-left">
                    <label className='pl-3 mousee-text-small font-x-small font-weight-regular'>{invoice?.id}</label>
                </div>
            </div>
            
            

        </div>
    );
};

export const calculateInvoiceLineTotal = (lineParam) => {
    if (lineParam?.quantity && lineParam?.price) {
        let grossCost = lineParam?.quantity * lineParam?.price;

        if (lineParam?.discount && lineParam?.discount?.type && lineParam?.discount?.ammount) {
            if (lineParam?.discount?.type === "exact") {
                grossCost -= lineParam?.discount?.ammount;
            } else if (lineParam?.discount?.type === "percentage") {
                grossCost -= grossCost * (lineParam?.discount?.ammount / 100);
            }
        }
        if (lineParam?.taxes) {
            return grossCost + (grossCost * lineParam?.taxes) / 100;
        } else {
            return grossCost;
        }
    }

    return 0;
};