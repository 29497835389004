import { Button } from "primereact/button";
import React from "react";
import { useTranslation } from "react-i18next";
import { capitalizeWord } from "../../Utils";
import Lozenge from "../Lozenge/Lozenge";

export const PaymentMethodCard = ({ paymentMethod, defaultPaymentMethodId, activePaymentMethod, setActivePaymentMethod, menuRef }) => {
    const { t } = useTranslation();

    const getImportantInfo = (paymentMethod, defaultPaymentMethodId) => {
        if (paymentMethod?.type === "card" && paymentMethod?.card) {
            return getCardInfo(paymentMethod, defaultPaymentMethodId);
        } else if (paymentMethod?.type === "sepa_debit" && paymentMethod?.sepa_debit) {
            return getSepaDirectInfo(paymentMethod, defaultPaymentMethodId);
        }

        return;
    };

    const getSepaDirectInfo = (paymentMethod, defaultPaymentMethodId) => {
        return (
            <div className="h-6rem min-w-full">
                <div className="col-12 pb-3">
                    <div className="flex justify-content-between flex-wrap">
                        <div className="flex align-items-center justify-content-center">
                            <div class="flex flex-row flex-wrap">
                                <div class="flex align-items-center justify-content-left mr-3">
                                    <label className="mousee-text font-x-small font-weight-bold">{`${t("payment_preferences.page.sepa_debit")} ••••${paymentMethod?.sepa_debit?.last4}`}</label>
                                </div>
                                <div class="flex align-items-center justify-content-center">
                                    {paymentMethod?.id && defaultPaymentMethodId && paymentMethod?.id === defaultPaymentMethodId ? (
                                        <div className="col-12 p-1">
                                            {/* <Chip label={t("label.default")} /> */}
                                        <Lozenge text={t("label.default")} className="p-lozenge-info"/>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="flex align-items-center justify-content-center">
                            <Button
                                icon={"pi pi-ellipsis-v"}
                                className="p-button-text p-button-secondary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setActivePaymentMethod(paymentMethod);
                                    menuRef?.current?.toggle(e);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 p-1">
                    <label className="mousee-text font-x-small font-weight-regular">{paymentMethod?.billing_details?.name}</label>
                </div>
            </div>
        );
    };

    const getCardInfo = (paymentMethod, defaultPaymentMethodId) => {
        const cardBrand = capitalizeWord(paymentMethod?.card?.brand);
        return (
            <div className="h-6rem min-w-full">
                <div className="col-12 pb-2">
                    <div className="flex justify-content-between flex-wrap">
                        <div className="flex align-items-center justify-content-center">
                            <div class="flex align-items-center justify-content-left mr-3">
                                <label className="mousee-text font-x-small font-weight-bold">{`${cardBrand} ••••${paymentMethod?.card?.last4}`}</label>
                            </div>
                            <div class="flex align-items-center justify-content-center">
                                {paymentMethod?.id && defaultPaymentMethodId && paymentMethod?.id === defaultPaymentMethodId ? (
                                    <div className="col-12 p-1">
                                        {/* <Chip label={t("label.default")} /> */}
                                        <Lozenge text={t("label.default")} className="p-lozenge-info"/>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="flex align-items-center justify-content-center">
                            {/* <RadioButton 
                                onChange={() => setActivePaymentMethod(paymentMethod)}
                                checked={paymentMethod?.id === activePaymentMethod?.id}
                            /> */}

                            <Button
                                icon={"pi pi-ellipsis-v"}
                                className="p-button-text p-button-secondary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setActivePaymentMethod(paymentMethod);
                                    menuRef?.current?.toggle(e);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 p-0 pl-1 ">
                    <label className="mousee-text font-x-small font-weight-regular">{paymentMethod?.billing_details?.name}</label>
                </div>

                <div className="col-12 p-0 pl-1">
                    <label className="mousee-text font-x-small font-weight-regular">{`${t("payment_preferences.page.expires_on")} ${paymentMethod?.card?.exp_month}/${paymentMethod?.card?.exp_year}`}</label>
                </div>
                {/* {paymentMethod?.id && defaultPaymentMethodId && paymentMethod?.id === defaultPaymentMethodId ? (
                    <div className="col-12 ">
                        <Chip label={t("label.default")} />
                    </div>
                ) : null} */}
            </div>
        );
    };

    return (
        // <div className="col-12 xl:col-3 md:col-6 p-4">
        <div className="col-12 lg:col-6 p-4">
            <div className="border-solid p-4 border-1" style={{ borderRadius: "var(--border-radius)", borderColor: "var(--surface-300)" }}>
                <div className="grid">{getImportantInfo(paymentMethod, defaultPaymentMethodId)}</div>
            </div>
        </div>
    );
};
