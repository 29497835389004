import { useAuthenticator } from "@aws-amplify/ui-react";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { Editor } from "primereact/editor";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { FormatCurrency, blockBackgroundScroll, calculateTime, parseTime, tableHeaderTemplate } from "../../../../../Utils";
import dropdownDown from "../../../../../assets/icons/dropdown-down.svg";
import maximizeIcon from "../../../../../assets/icons/maximize-icon.svg";
import minimizeIcon from "../../../../../assets/icons/minimize-icon.svg";


import getSymbolFromCurrency from "currency-symbol-map";
import { InputSwitch } from "primereact/inputswitch";
import { MultiSelect } from "primereact/multiselect";
import { useTranslation } from "react-i18next";
import TenantService from "../../../../../services/TenantService/TenantService";
import UserService from "../../../../../services/UserService/UserService";
import { filterUsers, handleUsersOnScroll } from "../../../../People/Users/Utils";
import { selectedTaskParticipantUserTemplate, sumTwoHHMM, taskParticipantUserOptionTemplate } from "../../../../Task/Utils";
import { calculateParticipantOverheadCost, calculateParticipantTotalCost, formatCostLabel, formatMarginLabel, groupByUserItemTemplate } from "../Utils";

const EditProcessDialog = ({ concept, show, onHide, concepts, setConcepts }) => {
    const [loading, setLoading] = useState(false);
    const [loadingDropdown, setLoadingDropdown] = useState(false);
    const [tenant, setTenant] = useState();

    const tenantService = new TenantService();
    const userService = new UserService();

    const { t, i18n } = useTranslation();

    const [users, setUsers] = useState([]);
    const [queryUsersResponse, setQueryUsersResponse] = useState();
    const [usersRowsNumber, setUsersRowsNumber] = useState(5);
    const [userFilterName, setUserFilterName] = useState(undefined);

    const { user } = useAuthenticator((context) => [context.user]);

    const [tableData, setTableData] = useState([]);

    const [cost, setCost] = useState(0);
    const [overhead, setOverhead] = useState(0);
    const [recommendedSellPrice, setRecommendedSellPrice] = useState(0);
    const [recommendedSellPriceMargin, setRecommendedSellPriceMargin] = useState(0);
    const [finalSellPrice, setFinalSellPrice] = useState(0);
    const [finalSellPriceMargin, setFinalSellPriceMargin] = useState(0);

    const [groupByUsers, setGroupByUsers] = useState(false);

    // Reset All values
    const resetValues = () => {
        setCost(0);
        setOverhead(0);
        setRecommendedSellPrice(0);
        setRecommendedSellPriceMargin(0);
        setFinalSellPrice(0);
        setFinalSellPriceMargin(0);
        setTableData([]);
    };

    // Retrieve list of processes
    useEffect(() => {
        formik.setValues({ ...concept });
        onProcessChange({ value: concept?.process });
    }, []);

    // Retrieve Tenant Info
    useEffect(() => {
        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            setLoading(true);
            tenantService.getTenant(user.attributes["custom:tenant_id"]).then((data) => {
                setTenant(data);
                setLoading(false);
            });
        }
    }, []);

    const formik = useFormik({
        initialValues: {},
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            const newData = {
                id: data.id,
                name: data?.name,
                description: data?.description,
                tags: data?.tags,
                price: finalSellPrice,
                quantity: data.quantity,
                taxes: data.taxes,
                total: finalSellPrice + finalSellPrice * (data.taxes / 100),
                type: data.type,
                process: getProcessFromTableData(tableData),
            };

            const conceptIndex = concepts.findIndex((item) => item.id === data.id);

            concepts[conceptIndex] = newData;

            setConcepts(concepts);
            blockBackgroundScroll(false);
            onHide(false);
            formik.resetForm();
        },
    });

    // Get data from TableDate to Process
    const getProcessFromTableData = (tableData) => {
        let process = {
            process_id: formik.values.process.id,
            name: formik.values.process.name,
        };
        let newTasks = formik.values.process.tasks;

        if (newTasks) {
            for (let i = 0; i < tableData.length; i++) {
                const row = tableData[i];

                // Search participant task index
                const currentTaskIndex = newTasks.findIndex((item) => item.id === row.task_id);

                // Search participant index
                const currentParticipantIndex = newTasks[currentTaskIndex].participants.findIndex((item) => item.id === row.id);

                newTasks[currentTaskIndex].participants[currentParticipantIndex] = {
                    ...newTasks[currentTaskIndex].participants[currentParticipantIndex],
                    time: row.time,
                    margin: row.margin,
                    overhead: row.overhead,
                };

                if (row.user) {
                    newTasks[currentTaskIndex].participants[currentParticipantIndex]["user"] = {
                        id: row?.user?.id,
                        name: row?.user?.name,
                        surnames: row?.user?.surnames,
                        avatar: row?.user?.avatar,
                        hour_cost: row?.user?.hour_cost,
                        email: row?.user?.email,
                        external: row?.user?.external ? row?.user?.external : false,
                    };
                }
            }

            process = {
                ...process,
                tasks: [...newTasks],
            };
        }

        return process;
    };

    // When process changes
    const onProcessChange = (e) => {
        formik.setFieldValue("process", e.value);
        if (!e.value) {
            resetValues();
        } else {
            composeTableData(e.value);
        }
    };

    const onTableDataChange = (tableData) => {
        let newTableData = [];
        if (tableData) {
            newTableData = [...tableData];
        }

        for (let i = 0; i < newTableData.length; i++) {
            if (newTableData[i]?.user) {
                const rowTotal = calculateParticipantTotalCost(newTableData[i].time, newTableData[i]?.user?.hour_cost, newTableData[i].overhead, newTableData[i].margin);
                newTableData[i].total = rowTotal;
            } else if (newTableData[i]?.profile) {
                const rowTotal = calculateParticipantTotalCost(newTableData[i].time, newTableData[i]?.profile?.hour_cost, newTableData[i].overhead, newTableData[i].margin);
                newTableData[i].total = rowTotal;
            }
        }

        setTableData([...newTableData]);

        let cost = 0;
        let overhead = 0;
        let margins = [];

        for (let i = 0; i < tableData.length; i++) {
            const row = tableData[i];

            let hourCost = 0;

            // Calculate cost
            if (row.user && row?.user?.hour_cost) {
                hourCost = row?.user?.hour_cost;
            } else {
                hourCost = row?.profile?.hour_cost;
            }

            const participantCost = calculateParticipantTotalCost(row.time, hourCost, undefined, undefined);

            cost += participantCost;
            overhead += calculateParticipantOverheadCost(row.time, hourCost, row.overhead);

            margins.push(row.margin);
        }
        setCost(cost);
        setOverhead(overhead);

        // Median Margin
        let margin = 0;
        margins.forEach((rowMargin) => {
            margin += rowMargin;
        });
        margin = margin / margins.length;

        let recommendedSellPrice = 0;

        recommendedSellPrice = (cost + overhead) / (1 - margin / 100);

        setRecommendedSellPrice(recommendedSellPrice);

        const benefit = recommendedSellPrice - (cost + overhead);
        if (recommendedSellPrice) {
            setRecommendedSellPriceMargin((benefit / recommendedSellPrice) * 100);
        } else {
            setRecommendedSellPriceMargin(0);
        }

        let finalSellPrice = 0;
        if (concept?.price) {
            finalSellPrice = parseFloat((concept?.price).toFixed(2));
        }

        setFinalSellPrice(finalSellPrice);

        const finalBenefit = finalSellPrice - (cost + overhead);

        if (finalSellPrice) {
            setFinalSellPriceMargin((finalBenefit / finalSellPrice) * 100);
        } else {
            setFinalSellPriceMargin(0);
        }
    };

    const updateRowValue = (key, value, index) => {
        let newTableData = tableData;

        newTableData[index][key] = value;

        if (key === "user") {
            const operation = (value, index) => {
                let newHourCost = 0;
                newTableData[index]["user"] = value;
                const margin = newTableData[index]["margin"];

                if (value) {
                    newHourCost = value.hour_cost;

                    // Set Overhead to Zero in case the user is external (freelance)
                    if (value.external) {
                        newTableData[index]["overhead"] = 0;
                    }
                } else {
                    newHourCost = newTableData[index]["profile"]["hour_cost"];
                    newTableData[index]["overhead"] = tenant.overhead;
                }

                let overhead = newTableData[index]["overhead"];

                newTableData[index]["total"] = calculateParticipantTotalCost(newTableData[index]["time"], newHourCost, overhead, margin);
            };
            if (value) {
                const userDisplayName = `${value.name} ${value.surnames ? value.surnames : ""}`.trim();
                confirmDialog({
                    tagKey: "assignAllDialog",
                    message: (
                        <div>
                            <span>{t("label.you_have_assigned_username_as_skill_name", { username: userDisplayName, skill_name: newTableData[index]["profile"]["name"] })}</span>
                            <br />
                            <span>{t("label.do_you_want_to_assign_it_to_the_rest_of_the_skill_name_tasks", { skill_name: newTableData[index]["profile"]["name"] })}</span>
                        </div>
                    ),
                    header: t("label.team_assignment"),
                    draggable: false,
                    icon: "pi pi-info-circle",
                    contentClassName: "pt-3",
                    rejectLabel: t("label.no_just_this_one"),
                    acceptLabel: t("label.yes_assign_to_all"),
                    accept: () => {
                        for (let i = 0; i < newTableData.length; i++) {
                            const participant = newTableData[i];
                            if (participant.profile.id === newTableData[index]["profile"]["id"]) {
                                operation(value, i);
                            }
                        }
                        onTableDataChange(newTableData);
                    },
                    reject: () => {
                        operation(value, index);
                    },
                });
            } else {
                operation(value, index);
            }
        } else if (key === "time") {
            const hourCost = newTableData[index].user ? newTableData[index].user.hour_cost : newTableData[index].profile.hour_cost;
            const time = value;
            const margin = newTableData[index]["margin"];
            const overhead = newTableData[index]["overhead"];

            newTableData[index]["total"] = calculateParticipantTotalCost(time, hourCost, overhead, margin);
        } else if (key === "margin") {
            const operation = (value, index) => {
                newTableData[index]["margin"] = value;

                const hourCost = newTableData[index].user ? newTableData[index].user.hour_cost : newTableData[index].profile.hour_cost;
                const time = newTableData[index]["time"];
                const overhead = newTableData[index]["overhead"];

                newTableData[index]["total"] = calculateParticipantTotalCost(time, hourCost, overhead, value);
            };

            if (value) {
                confirmDialog({
                    tagKey: "assignAllDialog",
                    message: (
                        <div>
                            <span>{t("label.you_have_assigned_a_percentage_margin_to_task_title", { margin_percentage: `${value}%`, task_title: newTableData[index]["task_title"] })}</span>
                            <br />
                            <span>{t("label.do_you_want_to_assign_it_to_all_tasks")}</span>
                        </div>
                    ),
                    header: t("label.margin_assignment"),
                    draggable: false,
                    icon: "pi pi-info-circle",
                    contentClassName: "pt-3",
                    rejectLabel: t("label.no_just_this_one"),
                    acceptLabel: t("label.yes_assign_to_all"),
                    accept: () => {
                        for (let i = 0; i < newTableData.length; i++) {
                            operation(value, i);
                        }
                        onTableDataChange(newTableData);
                    },
                    reject: () => {
                        operation(value, index);
                    },
                });
            } else {
                operation(value, index);
            }
        } else if (key === "overhead") {
            const operation = (value, index) => {
                newTableData[index]["overhead"] = value;
                const hourCost = newTableData[index].user ? newTableData[index].user.hour_cost : newTableData[index].profile.hour_cost;
                const time = newTableData[index]["time"];
                const margin = newTableData[index]["margin"];

                newTableData[index]["total"] = calculateParticipantTotalCost(time, hourCost, value, margin);
            };

            if (value) {
                confirmDialog({
                    tagKey: "assignAllDialog",
                    message: (
                        <div>
                            <span>{t("label.you_have_assigned_a_percentage_overhead_to_task_title", { overhead_percentage: `${value}%`, task_title: newTableData[index]["task_title"] })}</span>
                            <br />
                            <span>{t("label.do_you_want_to_assign_it_to_all_tasks")}</span>
                        </div>
                    ),
                    header: t("label.overhead_assignment"),
                    draggable: false,
                    icon: "pi pi-info-circle",
                    contentClassName: "pt-3",
                    rejectLabel: t("label.no_just_this_one"),
                    acceptLabel: t("label.yes_assign_to_all"),
                    accept: () => {
                        for (let i = 0; i < newTableData.length; i++) {
                            const participant = newTableData[i];

                            if (!participant.user || (participant.user && !participant.user.external)) {
                                operation(value, i);
                            }
                        }
                        onTableDataChange(newTableData);
                    },
                    reject: () => {
                        operation(value, index);
                    },
                });
            } else {
                operation(value, index);
            }
        }

        onTableDataChange(newTableData);
    };

    // Compose table data based on selected process
    const composeTableData = (process) => {
        let composedTableData = [];
        if (process && process.tasks) {
            for (let i = 0; i < process.tasks.length; i++) {
                const task = process.tasks[i];

                if (task.participants) {
                    for (let j = 0; j < task.participants.length; j++) {
                        const participant = task.participants[j];

                        // Total estimate cost
                        let hourCost = 0;
                        const participantMargin = participant.margin ? participant.margin : 0;

                        if (participant.user) {
                            hourCost = participant.user.hour_cost;
                        } else {
                            hourCost = participant.profile.hour_cost;
                        }

                        let overhead = 0;

                        if (participant.overhead) {
                            overhead = participant.overhead;
                        } else if (tenant && tenant.overhead) {
                            overhead = tenant.overhead;
                        }

                        const participantTotal = calculateParticipantTotalCost(participant.time, hourCost, overhead, participantMargin);

                        let participantAux = {
                            ...participant,
                            task_id: task.id,
                            task_title: task.title,
                            margin: participantMargin,
                            overhead: overhead,
                            total: participantTotal,
                        };

                        composedTableData.push(participantAux);
                    }
                }
            }
        }

        onTableDataChange(composedTableData);
    };

    const timeBodyTemplate = (item, index) => {
        return (
            <div className="p-inputgroup custom-time-picker">
                <Button
                    icon="pi pi-minus"
                    className="p-button-text"
                    onClick={(e) => {
                        updateRowValue("time", calculateTime(item.time, 15, "subtract"), index);
                    }}
                />
                <InputMask
                    value={item.time ? parseTime(item.time) : "00:00"}
                    placeholder="00:00"
                    mask="99:99"
                    className="max-w-9rem"
                    key={`time-input-mask-${item.id}`}
                    onChange={(e) => {
                        if (!e.value.includes("_")) {
                            updateRowValue("time", calculateTime(item.time, e.value, "", "manual"), index);
                        }
                    }}
                />

                <Button
                    icon="pi pi-plus"
                    className="p-button-text"
                    onClick={(e) => {
                        updateRowValue("time", calculateTime(item.time, 15, "add"), index);
                    }}
                />
            </div>
        );
    };

    const percentageOverheadBodyTemplate = (item, index, type) => {
        return (
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon">
                    <i className="pi pi-percentage mousee-text-small font-xxx-small font-weight-regular"></i>
                </span>
                <InputNumber
                    disabled={item.user && item.user.external}
                    value={item.overhead}
                    max={100}
                    onValueChange={(e) => {
                        if (type === "tableData") {
                            updateRowValue("overhead", e.value, index);
                        } else if (type === "userTableData") {
                            if (item?.type === "user") {
                                updateGroupedByProfileUser(item["user_id"], "overhead", e.value, item);
                            } else if (item?.type === "profile") {
                                updateGroupedByProfileUser(item["profile_id"], "overhead", e.value, item);
                            }
                        }
                    }}
                />
            </div>
        );
    };

    const percentageMarginBodyTemplate = (key, item, index, type) => {
        return (
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon">
                    <i className="pi pi-percentage mousee-text-small font-xxx-small font-weight-regular"></i>
                </span>
                <InputNumber
                    value={item[key]}
                    max={100}
                    onValueChange={(e) => {
                        if (type === "tableData") {
                            updateRowValue(key, e.value, index);
                        } else if (type === "userTableData") {
                            if (item?.type === "user") {
                                updateGroupedByProfileUser(item["user_id"], key, e.value, item);
                            } else if (item?.type === "profile") {
                                updateGroupedByProfileUser(item["profile_id"], key, e.value, item);
                            }
                        }
                    }}
                />
            </div>
        );
    };

    const userBodyTemplate = (item, index) => {
        return (
            <Dropdown
                dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                id="users-dowpdown"
                name="users-dowpdown"
                dataKey="id"
                value={item.user}
                showClear
                options={item.user ? [item.user] : users}
                optionLabel={(item) => {
                    return item.name + " " + item.surnames;
                }}
                onChange={(e) => {
                    updateRowValue("user", e.value, index);
                    setUsers([]);
                }}
                placeholder={t("label.dropdown_select_user")}
                className="min-w-full"
                emptyMessage={
                    loadingDropdown ? (
                        <div className="flex align-items-center">
                            <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
                            <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading_users")}</label>
                        </div>
                    ) : (
                        <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>
                    )
                }
                onShow={() => {
                    setLoadingDropdown(true);
                    setUsers([]);

                    userService.queryUsers({ profile_id: `${item?.profile?.visibility}#${item?.profile?.id}`, limit: usersRowsNumber }).then((data) => {
                        setQueryUsersResponse(data);
                        setUsers(data?.data);
                        setLoadingDropdown(false);
                    });
                }}
                valueTemplate={selectedTaskParticipantUserTemplate}
                itemTemplate={taskParticipantUserOptionTemplate}
                onScrollCapture={(e) => handleUsersOnScroll(e, userService, usersRowsNumber, queryUsersResponse, setQueryUsersResponse, users, setUsers, userFilterName, setUserFilterName, undefined)}
                filterTemplate={(options) => (
                    <span className="p-input-icon-right min-w-full">
                        <i className="pi pi-search" />
                        <InputText autoFocus className="min-w-full" value={userFilterName} ref={null} onChange={(e) => filterUsers(e, options, setUserFilterName, queryUsersResponse, setQueryUsersResponse, userService, usersRowsNumber, users, setUsers, undefined)} />
                    </span>
                )}
            />
        );
    };

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error pl-2">{formik.errors[name]}</small> : null;
    };

    const updateFinalPrice = (finalPrice) => {
        if (finalPrice) {
            finalPrice = parseFloat(finalPrice.toFixed(2));
        }
        setFinalSellPrice(finalPrice);

        const finalBenefit = finalPrice - (cost + overhead);
        if (finalPrice) {
            setFinalSellPriceMargin((finalBenefit / finalPrice) * 100);
        } else {
            setFinalSellPriceMargin(0);
        }
    };

    const getGroupByUserData = (tableData) => {
        let usersProfilesData = [];

        if (tableData) {
            tableData.forEach((row) => {
                let userProfileIndex = undefined;
                let isUser = false;
                if (row.user) {
                    userProfileIndex = usersProfilesData.findIndex((item) => item?.user_id === row?.user?.id);
                    isUser = true;
                } else if (row.profile) {
                    userProfileIndex = usersProfilesData.findIndex((item) => item?.profile_id === row?.profile?.id);
                }

                if (userProfileIndex !== undefined) {
                    if (userProfileIndex < 0) {
                        if (isUser) {
                            usersProfilesData.push({
                                user_id: row?.user?.id,
                                user_name: `${row?.user?.name} ${row?.user?.surnames ? row?.user?.surnames : ""}`.trim(),
                                user_avatar: row?.user?.avatar,
                                hour_cost: row?.user?.hour_cost,
                                time: row?.time,
                                margin: row?.margin,
                                overhead: row?.overhead,
                                total_cost: calculateParticipantTotalCost(row?.time, row?.user?.hour_cost, row?.overhead, row?.margin),
                                tasks: [row?.task_id],
                                type: "user",
                            });
                        } else {
                            usersProfilesData.push({
                                profile_id: row?.profile?.id,
                                profile_name: row?.profile?.name?.trim(),
                                hour_cost: row?.profile?.hour_cost,
                                time: row?.time,
                                margin: row?.margin,
                                overhead: row?.overhead,
                                total_cost: calculateParticipantTotalCost(row?.time, row?.profile?.hour_cost, row?.overhead, row?.margin),
                                tasks: [row?.task_id],
                                type: "profile",
                            });
                        }
                    } else {
                        const newTime = sumTwoHHMM(usersProfilesData[userProfileIndex]["time"], row.time);
                        usersProfilesData[userProfileIndex]["time"] = newTime;

                        const totalCost = calculateParticipantTotalCost(newTime, usersProfilesData[userProfileIndex]["hour_cost"], usersProfilesData[userProfileIndex]["overhead"], usersProfilesData[userProfileIndex]["margin"]);

                        usersProfilesData[userProfileIndex]["total_cost"] = totalCost;

                        if (!usersProfilesData[userProfileIndex]["tasks"].includes(row?.task_id)) {
                            usersProfilesData[userProfileIndex]["tasks"] = [...usersProfilesData[userProfileIndex]["tasks"], row?.task_id];
                        }
                    }
                }
            });
        }

        return usersProfilesData;
    };

    const updateGroupedByProfileUser = (profileUserId, key, value, rowData) => {
        let newTableData = tableData;
        let flag = false;

        if (key === "margin") {
            flag = true;

            for (let i = 0; i < newTableData.length; i++) {
                if (rowData?.type === "user") {
                    if (newTableData[i]?.user && newTableData[i]?.user?.id === profileUserId) {
                        newTableData[i].margin = value;
                        const participantTotal = calculateParticipantTotalCost(newTableData[i].time, newTableData[i]?.user?.hour_cost, newTableData[i].overhead, value);
                        newTableData[i].total = participantTotal;
                    }
                } else if (rowData?.type === "profile") {
                    if (newTableData[i]?.profile && newTableData[i]?.profile?.id === profileUserId) {
                        newTableData[i].margin = value;
                        const participantTotal = calculateParticipantTotalCost(newTableData[i].time, newTableData[i]?.profile?.hour_cost, newTableData[i].overhead, value);
                        newTableData[i].total = participantTotal;
                    }
                }
            }
        } else if (key === "overhead") {
            flag = true;

            for (let i = 0; i < newTableData.length; i++) {
                if (rowData?.type === "user") {
                    if (newTableData[i]?.user && newTableData[i]?.user?.id === profileUserId) {
                        newTableData[i].overhead = value;
                        const participantTotal = calculateParticipantTotalCost(newTableData[i].time, newTableData[i]?.user?.hour_cost, value, newTableData[i].margin);
                        newTableData[i].total = participantTotal;
                    }
                } else if (rowData?.type === "profile") {
                    if (newTableData[i]?.profile && newTableData[i]?.profile?.id === profileUserId) {
                        newTableData[i].overhead = value;
                        const participantTotal = calculateParticipantTotalCost(newTableData[i].time, newTableData[i]?.profile?.hour_cost, value, newTableData[i].margin);
                        newTableData[i].total = participantTotal;
                    }
                }
            }
        } else if (key === "total_cost") {
            flag = true;

            const oldTotalCost = rowData.total_cost;
            const newTotalCost = value;
            const oldHourCost = rowData.hour_cost;

            const newHourCost = (newTotalCost * oldHourCost) / oldTotalCost;

            for (let i = 0; i < newTableData.length; i++) {
                if (rowData?.type === "user") {
                    if (newTableData[i]?.user && newTableData[i]?.user?.id === profileUserId) {
                        newTableData[i].user.hour_cost = newHourCost;
                        const participantTotal = calculateParticipantTotalCost(newTableData[i].time, newHourCost, newTableData[i].overhead, newTableData[i].margin);
                        newTableData[i].total = participantTotal;
                    }
                } else if (rowData?.type === "profile") {
                    if (newTableData[i]?.profile && newTableData[i]?.profile?.id === profileUserId) {
                        newTableData[i].profile.hour_cost = newHourCost;
                        const participantTotal = calculateParticipantTotalCost(newTableData[i].time, newHourCost, newTableData[i].overhead, newTableData[i].margin);
                        newTableData[i].total = participantTotal;
                    }
                }
            }
        }

        if (flag) {
            onTableDataChange(newTableData);
        }
    };

    const tableView = (groupByUsers, tableData) => {
        if (groupByUsers) {
            const groupByUserData = getGroupByUserData(tableData);
            return (
                <DataTable key="budget-concepts-table" selectionMode="single" dataKey="user_id" loading={loading} size="large" value={groupByUserData} responsiveLayout="scroll" stripedRows emptyMessage={t("label.no_items_to_display")}>
                    <Column
                        header={tableHeaderTemplate(null, t("label.name"))}
                        style={{ width: "35%", minWidth: "20rem" }}
                        body={(item) => {
                            if (item?.type === "user") {
                                return groupByUserItemTemplate(item);
                            } else if (item?.type === "profile") {
                                return (
                                    <div className="flex align-items-center gap-2">
                                        <span className="pl-3">{item?.profile_name}</span>
                                    </div>
                                );
                            }
                        }}
                    />

                    <Column header={tableHeaderTemplate(null, t("label.tasks"))} body={(item) => item?.tasks?.length} style={{ width: "10%", minWidth: "8rem" }} />

                    <Column style={{ width: "10%", minWidth: "13rem" }} header={tableHeaderTemplate(null, t("label.time"))} body={(item, options) => `${parseTime(item.time)} hrs`} />

                    <Column header={tableHeaderTemplate(null, t("label.hour_cost"))} body={(item) => `${FormatCurrency(item?.hour_cost, 2, tenant?.currency, i18n.language)}/h`} style={{ width: "8%" }} />

                    <Column header={tableHeaderTemplate(null, t("label.overhead"))} body={(item, options) => percentageOverheadBodyTemplate(item, options.rowIndex, "userTableData")} style={{ width: "8%", minWidth: "9rem" }} />

                    <Column header={tableHeaderTemplate(null, t("label.margin"))} body={(item, options) => percentageMarginBodyTemplate("margin", item, options.rowIndex, "userTableData")} style={{ width: "8%", minWidth: "9rem" }} />

                    <Column
                        header={tableHeaderTemplate(null, t("label.total_estimated"))}
                        body={(item) => {
                            return (
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi">
                                            <label className="mousee-text-small font-medium font-weight-bold">{getSymbolFromCurrency(tenant?.currency)}</label>
                                        </i>
                                    </span>
                                    <InputNumber
                                        value={item?.total_cost}
                                        min={1}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        locale={i18n.language.split("-")[0]}
                                        onValueChange={(e) => {
                                            if (item?.type === "user") {
                                                updateGroupedByProfileUser(item["user_id"], "total_cost", e.value, item);
                                            } else if (item?.type === "profile") {
                                                updateGroupedByProfileUser(item["profile_id"], "total_cost", e.value, item);
                                            }
                                        }}
                                    />
                                </div>
                            );
                        }}
                        style={{ width: "10%", minWidth: "10rem" }}
                    />
                </DataTable>
            );
        } else {
            return (
                <DataTable key="budget-concepts-table" selectionMode="single" dataKey="id" loading={loading} size="large" value={tableData} responsiveLayout="scroll" stripedRows onValueChange={(e) => console.log(e)} emptyMessage={t("label.no_items_to_display")}>
                    <Column field="profile.name" header={tableHeaderTemplate(null, t("label.skill"))} style={{ width: "20%", minWidth: "20rem" }} />

                    <Column field="task_title" header={tableHeaderTemplate(null, t("label.task"))} style={{ width: "25%", minWidth: "15rem" }} />

                    <Column header={tableHeaderTemplate(null, t("label.user"))} body={(item, options) => userBodyTemplate(item, options.rowIndex)} style={{ width: "15%" }} />

                    <Column style={{ width: "10%", minWidth: "13rem" }} header={tableHeaderTemplate(null, t("label.time"))} body={(item, options) => timeBodyTemplate(item, options.rowIndex)} />

                    <Column header={tableHeaderTemplate(null, t("label.hour_cost"))} body={(item) => `${FormatCurrency(item?.user ? item?.user?.hour_cost : item?.profile?.hour_cost, 2, tenant?.currency, i18n.language)}/h`} style={{ width: "8%", minWidth: "10rem" }} />

                    <Column header={tableHeaderTemplate(null, t("label.overhead"))} body={(item, options) => percentageOverheadBodyTemplate(item, options.rowIndex, "tableData")} style={{ width: "8%", minWidth: "9rem" }} />

                    <Column header={tableHeaderTemplate(null, t("label.margin"))} body={(item, options) => percentageMarginBodyTemplate("margin", item, options.rowIndex, "tableData")} style={{ width: "8%", minWidth: "9rem" }} />

                    <Column header={tableHeaderTemplate(null, t("label.total_estimated"))} body={(item) => FormatCurrency(item.total, 2, tenant?.currency, i18n.language)} style={{ width: "10%", minWidth: "10rem" }} />
                </DataTable>
            );
        }
    };

    return (
        <Dialog
            key={"edit-process-dialog"}
            visible={show}
            onShow={() => blockBackgroundScroll(true)}
            onHide={() => {
                blockBackgroundScroll(false);
                onHide(false);
                formik.resetForm();
            }}
            draggable={false}
            style={{ height: "90%", width: "90%" }}
            maximizable
            maximizeIcon={<img alt={"dialog-maximize-icon"} src={maximizeIcon} className="" style={{ width: "1.5rem" }}></img>}
            minimizeIcon={<img alt={"dialog-minimize-icon"} src={minimizeIcon} className="" style={{ width: "1.5rem" }}></img>}
            header={
                <div>
                    <span className="mousee-text font-medium font-weight-bold">{t("label.edit_concept")}</span>
                </div>
            }
            footer={
                <div className="flex justify-content-between flex-wrap">
                    <div className="flex align-items-center justify-content-center">
                        <div className="flex justify-content-between flex-wrap">
                            {/* Precio venta */}
                            <div className="flex align-items-start justify-content-center pl-4 pr-4">
                                <div className="flex flex-column">
                                    <div className="flex align-items-center justify-content-left">
                                        <label className="mousee-text font-medium font-weight-semibold">{t("budgets.page.selling_price")}</label>
                                    </div>
                                    <div className="flex align-items-center justify-content-left">
                                        <small className="mousee-text-small font-x-small font-weight-regular">{t("label.recommended")}</small>
                                    </div>
                                    <div className="flex align-items-center justify-content-left pt-1">
                                        <label className="mousee-text font-medium font-weight-regular">{FormatCurrency(recommendedSellPrice, 2, tenant?.currency, i18n.language)}</label>
                                    </div>
                                </div>
                            </div>

                            {/* Costes */}
                            <div className="flex align-items-start justify-content-center">
                                <div className="flex flex-column">
                                    <div className="flex align-items-center justify-content-left">
                                        <label className="pl-2 mousee-text font-medium font-weight-light">{t("label.costs")}</label>
                                    </div>
                                    <div className="flex align-items-center justify-content-left">
                                        <label className="pl-2 mousee-text font-medium font-weight-light">({FormatCurrency(cost, 2, tenant?.currency, i18n.language)})</label>
                                    </div>
                                </div>
                            </div>

                            {/* Overhead */}
                            <div className="flex align-items-start justify-content-center pl-4">
                                <div className="flex flex-column">
                                    <div className="flex align-items-center justify-content-left">
                                        <label className="pl-2 mousee-text font-medium font-weight-light">{t("label.overhead")}</label>
                                    </div>
                                    <div className="flex align-items-center justify-content-left">
                                        <label className="pl-2 mousee-text font-medium font-weight-light">({FormatCurrency(overhead, 2, tenant?.currency, i18n.language)})</label>
                                    </div>
                                </div>
                            </div>

                            {/* Beneficio */}
                            <div className="flex align-items-start justify-content-center pl-4">
                                <div className="flex flex-column">
                                    <div className="flex align-items-center justify-content-left">
                                        <label className="pl-2 mousee-text font-medium font-weight-light">{t("label.profit")}</label>
                                    </div>
                                    <div className="flex align-items-center justify-content-left pl-2">{formatCostLabel(recommendedSellPrice - (cost + overhead), tenant?.currency, i18n.language)}</div>
                                </div>
                            </div>

                            {/* Margen */}
                            <div className="flex align-items-start justify-content-center pl-4">
                                <div className="flex flex-column">
                                    <div className="flex align-items-center justify-content-left">
                                        <label className="pl-2 mousee-text font-medium font-weight-light">{t("label.margin")}</label>
                                    </div>
                                    <div className="flex align-items-center justify-content-left pl-2">{formatMarginLabel(recommendedSellPriceMargin)}</div>
                                </div>
                            </div>

                            <Divider className="border-300" layout="vertical" />

                            <div className="flex align-items-start justify-content-center pl-4">
                                <div className="flex flex-column">
                                    <div className="flex align-items-center justify-content-left">
                                        <label className="mousee-text font-medium font-weight-semibold">{t("budgets.page.selling_price")}</label>
                                    </div>
                                    <div className="flex align-items-center justify-content-left">
                                        <small className="mousee-text-small font-x-small font-weight-regular">{t("label.final")}</small>
                                    </div>
                                    <div className="flex align-items-center justify-content-left">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">
                                                <i className="pi">
                                                    <label className="mousee-text-small font-medium font-weight-bold">{getSymbolFromCurrency(tenant?.currency)}</label>
                                                </i>
                                            </span>
                                            <InputNumber
                                                id="final_total"
                                                name="final_total"
                                                value={finalSellPrice}
                                                inputClassName="mousee-text font-small font-weight-light max-w-9rem"
                                                onValueChange={(e) => {
                                                    updateFinalPrice(e.value);
                                                }}
                                                className="min-w-2rem"
                                                locale={i18n.language.split("-")[0]}
                                                min={0}
                                                minFractionDigits={2}
                                                maxFractionDigits={2}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex align-items-start justify-content-center pl-4">
                                <div className="flex flex-column">
                                    <div className="flex align-items-center justify-content-left">
                                        <label className="mousee-text font-medium font-weight-semibold">{t("label.profit")}</label>
                                    </div>
                                    <div className="flex align-items-center justify-content-left">
                                        <small className="mousee-text-small font-x-small font-weight-regular">{t("label.final")}</small>
                                    </div>
                                    <div className="flex align-items-center justify-content-left pt-3">{formatCostLabel(finalSellPrice - (cost + overhead), tenant?.currency, i18n.language)}</div>
                                </div>
                            </div>

                            <div className="flex align-items-start justify-content-center pl-4">
                                <div className="flex flex-column">
                                    <div className="flex align-items-center justify-content-left">
                                        <label className="mousee-text font-medium font-weight-semibold">{t("label.margin")}</label>
                                    </div>
                                    <div className="flex align-items-center justify-content-left">
                                        <small className="mousee-text-small font-x-small font-weight-regular">{t("label.final")}</small>
                                    </div>
                                    <div className="flex align-items-center justify-content-left pt-3">{formatMarginLabel(finalSellPriceMargin)}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex align-items-end justify-content-center">
                        <Button form="import-process-data-form" type="button" label={t("label.save_changes")} className="p-button" onClick={formik.handleSubmit} />
                    </div>
                </div>
            }
            contentClassName="p-4"
            contentStyle={{ background: "var(--miflow-no-white-background)" }}
        >
            <ConfirmDialog tagKey="assignAllDialog" key={"assignAllDialog"} />
            <div className="grid">
                <div className="col-12">
                    <div className="card" style={{ borderRadius: "var(--border-radius-large)" }}>
                        <div className="grid">
                            <div className="col-12 md:col-8">
                                <label className="pl-2 mousee-text font-x-small font-weight-semibold">{t("label.name")}*</label>
                                <InputText className="min-w-full" value={formik.values.name} onChange={(e) => formik.setFieldValue("name", e.target.value)} />
                            </div>
                            <div className="col-12 md:col-4">
                                <label className="pl-2 mousee-text font-x-small font-weight-semibold">{t("label.verticals")}</label>
                                <MultiSelect
                                    value={tenant?.verticals && formik.values.tags ? formik.values.tags : []}
                                    onChange={(e) => {
                                        formik.setFieldValue("tags", e.value);
                                    }}
                                    options={tenant?.verticals ? tenant?.verticals : []}
                                    display="chip"
                                    closeIcon={<></>}
                                    placeholder={t("label.select_verticals_dropdown")}
                                    className="min-w-full max-w-full"
                                    showSelectAll={false}
                                />
                            </div>
                        </div>

                        <div className="pt-2">
                            <label className="pl-2 mousee-text font-x-small font-weight-semibold">{t("label.description")}</label>
                            <Editor
                                value={formik.values.description}
                                className="min-h-full"
                                headerTemplate={
                                    <span className="ql-formats">
                                        <button className="ql-bold" aria-label="Bold"></button>
                                        <button className="ql-italic" aria-label="Italic"></button>
                                        <button className="ql-underline" aria-label="Underline"></button>

                                        <button className="ql-list" value={"ordered"} aria-label="List"></button>
                                        <button className="ql-list" value={"bullet"} aria-label="List"></button>

                                        <button className="ql-align"></button>
                                    </span>
                                }
                                onTextChange={(e) => {
                                    formik.setFieldValue("description", e.htmlValue);
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="flex align-items-center pl-2">
                        <InputSwitch checked={groupByUsers} onChange={(e) => setGroupByUsers(e.value)} />
                        <label className="ml-2 mousee-text font-x-small font-weight-light">{t("budgets.page.group_by_skill_user")}</label>
                    </div>
                </div>

                <div className="col-12" style={{ background: "var(--miflow-no-white-background)" }}>
                    {formik.values.process ? (
                        <div className="card p-0" style={{ overflow: "hidden", borderRadius: "var(--border-radius-large)" }}>
                            {tableView(groupByUsers, tableData)}
                        </div>
                    ) : (
                        <span className="mousee-text font-x-small font-weight-regular">
                            <i>{t("budgets.page.select_a_service")}</i>
                        </span>
                    )}
                </div>
            </div>
        </Dialog>
    );
};
export default EditProcessDialog;
