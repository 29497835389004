import { useFormik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./UserAbsences.scss";

import { Icon } from "@iconify/react";
import moment from "moment";
import "moment/locale/es";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { objectHasKeys, tableHeaderTemplate } from "../../../../Utils";
import dropdownDown from "../../../../assets/icons/dropdown-down.svg";
import { checkComponentPermissions, checkPermissions } from "../../../../data/Permissions";
import HourService from "../../../../services/HourService/HourService";
import UserService from "../../../../services/UserService/UserService";
import { containsHour, getAbsenceById, getNotAllowedSpecificDates, getNotAllowedWeekDays, userItemTemplate } from "../Utils";
import AddAuthorizerDialog from "./Dialogs/AddAuthorizerDialog";
import AddChangeAbsenceDialog from "./Dialogs/AddChangeAbsenceDialog";

const localizer = momentLocalizer(moment);

const UserAbsences = ({ currentUser, tenantInfo, hours, setHours, queryHoursResponse, setQueryHoursResponse, absences, setAbsences, queryAbsencesResponse, setQueryAbsencesResponse, updateUserField }) => {
    const { t, i18n } = useTranslation();
    const toast = useRef(null);

    const hourService = new HourService();
    const userService = new UserService();

    const [currentAbsenceConfig, setCurrentAbsenceConfig] = useState();

    if (i18n.language.startsWith("es")) {
        moment.locale("es");
        moment.updateLocale("es", {
            months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
            weekdays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
            weekdaysShort: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
        });
    } else {
        moment.locale("en");
        moment.updateLocale("en", {});
    }

    const [currentHour, setCurrentHour] = useState();

    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [dialog, setDialog] = useState();

    const [selectedDate, setSelectedDate] = useState();

    const [retrievedMonths, setRetrievedMonths] = useState([]);

    const [authorizers, setAuthorizers] = useState([]);
    const [loadingAuthorizers, setLoadingAuthorizers] = useState(false);

    const [currentYearMonth, setCurrentYearMonth] = useState();

    useEffect(() => {
        setCurrentAbsenceConfig(parseUserMapToList(currentUser?.absences_config?.years_config));
    }, []);

    useEffect(() => {
        if (!hours || hours?.length === 0) {
            retrieveHours(new Date().getFullYear(), new Date().getMonth());
        }
    }, []);

    useEffect(() => {
        if (!authorizers || authorizers?.length === 0) {
            if (currentUser?.absences_config?.authorizers && currentUser?.absences_config?.authorizers?.length > 0) {
                setLoadingAuthorizers(true);

                let calls = [];
                currentUser?.absences_config?.authorizers.forEach((userId) => {
                    calls.push(userService.getUser({}, userId));
                });

                Promise.all(calls).then((usersData) => {
                    let parsedData = [];
                    for (let i = 0; i < usersData?.length; i++) {
                        const userData = usersData[i];
                        if (userData) {
                            parsedData.push(userData);
                        } else {
                            let authorizersAux = currentUser?.absences_config?.authorizers?.slice(i).contact(currentUser?.absences_config?.authorizers?.slice(i + 1));
                            updateUserField("absences_config.authorizers", authorizersAux);
                        }
                    }

                    setAuthorizers(parsedData);
                    setLoadingAuthorizers(false);
                });
            }
        }
    }, []);

    const retrieveHours = (yearParam, monthParam) => {
        setCurrentYearMonth(`${yearParam}#${monthParam}`);
        if (!retrievedMonths.includes(`${yearParam}#${monthParam}`)) {
            setRetrievedMonths([...retrievedMonths, `${yearParam}#${monthParam}`]);
            setLoading(true);

            let fromParam = new Date(yearParam, monthParam).toISOString();
            // Get Last Day of the Month
            var untilParamDate = new Date(yearParam, monthParam + 1, 0);
            // Add a day
            untilParamDate.setDate(untilParamDate.getDate() + 1);

            let untilParam = untilParamDate.toISOString();

            hourService
                .queryHours({ query_type: "absence", limit: 100, user_id: currentUser?.id, from: fromParam, until: untilParam })
                .then((data) => {
                    setQueryHoursResponse(data);
                    let newHours = [];
                    if (hours && hours?.length > 0) {
                        newHours = [...hours];
                    }

                    if (data?.data) {
                        const hoursAux = data?.data?.filter((item) => !containsHour(newHours, item?.id));
                        newHours = [...newHours, ...hoursAux];
                    }

                    setHours(newHours);

                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data });
                });
        }
    };

    const formik = useFormik({
        initialValues: {},
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);
        },
    });

    const event = ({ event }) => {
        return (
            <div className="p-1">
                <label className="mousee-text font-xx-small font-weight-semibold">{event?.name}</label>
                {event?.description ? (
                    <>
                        <br /> <small className="mousee-text font-xxx-small font-weight-regular">{event?.description}</small>{" "}
                    </>
                ) : null}
            </div>
        );
    };

    const getEventsFromHours = (hoursParam) => {
        let events = [];

        hoursParam.forEach((hourItem, index) => {
            const event = {
                id: index,
                name: getAbsenceById(hourItem?.absence_type, absences) ? getAbsenceById(hourItem?.absence_type, absences)?.name : t("label.undefined"),
                // description: hourItem?.observations,
                allDay: hourItem?.timming === "all_day" ? true : false,
                start: new Date(hourItem?.date),
                end: hourItem?.end_date ? new Date(hourItem?.end_date) : new Date(hourItem?.date),
                hour_id: hourItem?.id,
            };

            events.push(event);
        });

        return events;
    };

    const toolbar = (toolbarProps) => {
        return (
            <div className="pb-3">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex align-items-center justify-content-center">
                        <Button className="p-button-filled" disabled={toolbarProps?.date?.getMonth() === new Date().getMonth()} label={t("label.current_month")} onClick={() => toolbarProps?.onNavigate("TODAY")} />
                    </div>
                    <div class="flex align-items-center justify-content-center">
                        <div class="flex flex-row flex-wrap">
                            <div class="flex align-items-center justify-content-center mr-3">
                                <i
                                    className={"pi pi-angle-left cursor-pointer"}
                                    onClick={() => {
                                        toolbarProps?.onNavigate("PREV");

                                        let prevDate = toolbarProps?.date;
                                        prevDate.setMonth(prevDate.getMonth() - 1);

                                        retrieveHours(prevDate?.getFullYear(), prevDate?.getMonth());
                                    }}
                                />
                            </div>
                            <div class="flex align-items-center justify-content-center" style={{ minWidth: "10rem" }}>
                                <label className="mousee-text font-small font-weight-bold">{toolbarProps?.label}</label>
                            </div>
                            <div class="flex align-items-center justify-content-center ml-3">
                                <i
                                    className={"pi pi-angle-right cursor-pointer"}
                                    onClick={() => {
                                        toolbarProps?.onNavigate("NEXT");
                                        let prevDate = toolbarProps?.date;
                                        prevDate.setMonth(prevDate.getMonth() + 1);

                                        retrieveHours(prevDate?.getFullYear(), prevDate?.getMonth());
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div class="flex align-items-center justify-content-center">
                        <Button
                            icon="pi pi-plus"
                            className="p-button"
                            label={t("label.request_absence")}
                            onClick={() => {
                                setSelectedDate(undefined);
                                setDialog("new_edit_absence");
                                setShowDialog(true);
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const getDialog = (dialogParam) => {
        if (dialogParam === "new_edit_absence") {
            return (
                <AddChangeAbsenceDialog
                    currentUser={currentUser}
                    tenantInfo={tenantInfo}
                    show={showDialog}
                    setShow={(value) => {
                        setShowDialog(value);
                        setCurrentHour(undefined);
                    }}
                    initDate={selectedDate}
                    absences={absences}
                    setAbsences={setAbsences}
                    queryAbsencesResponse={queryAbsencesResponse}
                    setQueryAbsencesResponse={setQueryAbsencesResponse}
                    currentHour={currentHour}
                    setCurrentHour={setCurrentHour}
                    hours={hours}
                    setHours={setHours}
                    authorizers={authorizers}
                />
            );
        } else if (dialogParam === "add_authorizer") {
            return <AddAuthorizerDialog show={showDialog} setShow={setShowDialog} currentUser={currentUser} updateUserField={updateUserField} authorizers={authorizers} setAuthorizers={setAuthorizers} />;
        }
    };

    const handleSlotSelection = ({ start, end, action }) => {
        const notAllowedDays = getNotAllowedWeekDays(tenantInfo);

        if (!notAllowedDays.includes(start.getDay())) {
            const notAllowedSpecificDates = getNotAllowedSpecificDates(tenantInfo);
            let flag = true;

            for (let i = 0; i < notAllowedSpecificDates?.length && flag; i++) {
                const element = notAllowedSpecificDates[i];
                flag = !(moment(start).dayOfYear() === moment(element, moment.ISO_8601).dayOfYear());
            }

            if (flag) {
                setSelectedDate(start?.toISOString());
                setDialog("new_edit_absence");
                setShowDialog(true);
            }
        }
    };

    const eventStyleGetter = (event, start, end, isSelected) => {
        let style = {
            backgroundColor: "#dee2e6",
            // color: "var(--secondary-color)",
            borderRadius: "var(--border-radius)",
            padding: 0,
            textAlign: "center",
        };

        if (event?.hour_id) {
            const filteredItems = hours?.filter((item) => item?.id === event?.hour_id);

            if (filteredItems && filteredItems?.length > 0) {
                const currentItem = filteredItems[0];

                if (currentItem?.approved) {
                    style = {
                        ...style,
                        backgroundColor: getAbsenceById(currentItem?.absence_type, absences)?.color,
                        // opacity: 0.5
                    };
                }
            }
        }

        return {
            style: style,
        };
    };

    const getNotAllowedDaysStyle = (date, tenantInfoParam) => {
        let notAllowedWeekDays = getNotAllowedWeekDays(tenantInfoParam);

        let parsedNotAllowedWeekDays = {};
        notAllowedWeekDays.forEach((notAllowedWeekDay) => {
            parsedNotAllowedWeekDays = {
                ...parsedNotAllowedWeekDays,
                ...(moment(date).day() === notAllowedWeekDay && {
                    className: "rbc-off-range-bg",
                }),
            };
        });
        const notAllowedSpecificDates = getNotAllowedSpecificDates(tenantInfoParam);
        notAllowedSpecificDates.forEach((notAllowedSpecificDay) => {
            parsedNotAllowedWeekDays = {
                ...parsedNotAllowedWeekDays,
                ...(moment(date).dayOfYear() === moment(notAllowedSpecificDay, moment.ISO_8601).dayOfYear() && {
                    className: "rbc-off-range-bg",
                }),
            };
        });

        return parsedNotAllowedWeekDays;
    };

    const dayPropGetter = useCallback((date) => getNotAllowedDaysStyle(date, tenantInfo), []);

    const onEventSelect = (event) => {
        if (event?.hour_id) {
            const filteredItems = hours?.filter((item) => item?.id === event?.hour_id);

            if (filteredItems && filteredItems?.length > 0) {
                const currentItem = filteredItems[0];
                setCurrentHour(currentItem);
            }
        }

        setDialog("new_edit_absence");
        setShowDialog(true);
    };

    const isCurrentYearMonth = (dateParam, currentYearMonthParam) => {
        let result = false;

        if (dateParam && currentYearMonthParam) {
            const currentYear = currentYearMonthParam?.split("#")[0];
            const currentMonth = currentYearMonthParam?.split("#")[1];

            result = new Date(dateParam).getFullYear() === parseInt(currentYear) && new Date(dateParam).getMonth() === parseInt(currentMonth);
        }

        return result;
    };

    const getAbsencesTypeCount = (hours, absence, currentYearMonthParam) => {
        let count = 0;
        if (hours && hours?.length > 0 && absence && currentYearMonthParam) {
            for (let i = 0; i < hours?.length; i++) {
                const item = hours[i];

                if (item?.absence_type === absence?.id && item?.date && isCurrentYearMonth(item?.date, currentYearMonthParam)) {
                    count++;
                }
            }
        }

        return count;
    };

    function compare(a, b) {
        if (parseInt(a.year) < parseInt(b.year)) {
            return 1;
        }
        if (parseInt(a.year) > parseInt(b.year)) {
            return -1;
        }
        return 0;
    }

    const parseUserMapToList = (yearsConfigParam) => {
        let items = [];

        if (yearsConfigParam && objectHasKeys(yearsConfigParam)) {
            for (const key in yearsConfigParam) {
                items.push({
                    year: key,
                    used: yearsConfigParam[key]?.used ? yearsConfigParam[key]?.used : 0,
                    total: yearsConfigParam[key]?.total ? yearsConfigParam[key]?.total : 0,
                });
            }
        }

        return items.sort(compare);
    };

    const parseUserListToMap = (yearsConfigParamList) => {
        let yearsConfig = {};

        if (yearsConfigParamList && yearsConfigParamList?.length) {
            for (let i = 0; i < yearsConfigParamList?.length; i++) {
                const element = yearsConfigParamList[i];
                if (element?.year) {
                    yearsConfig[element?.year] = {
                        used: element?.used ? element?.used : 0,
                        total: element?.total ? element?.total : 0,
                    };
                }
            }
        }

        return yearsConfig;
    };

    const getYearOptions = () => {
        let items = [];

        for (let i = 2000; i <= new Date().getFullYear(); i++) {
            items.push({
                id: `${i}`,
                label: `${i}`,
            });
        }

        return items;
    };

    const cellEditor = (options) => {
        if (options.field === "year") {
            return yearEditor(options);
        } else {
            return integerEditor(options);
        }
    };

    const integerEditor = (options) => {
        return <InputNumber minFractionDigits={1} maxFractionDigits={1} min={0} inputStyle={{ maxWidth: "5rem" }} inputId={options?.field} value={options.value} onValueChange={(e) => options.editorCallback(e.value)} />;
    };

    const yearEditor = (options) => {
        return <Dropdown dataKey="id" dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>} value={{ id: `${options.value}`, label: `${options.value}` }} onChange={(e) => options.editorCallback(e.value?.label)} options={getYearOptions()} className="w-full" />;
    };

    const onCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;

        rowData[field] = newValue;

        let currentAbsenceConfigAux = [...currentAbsenceConfig]?.sort(compare);
        currentAbsenceConfigAux[e.rowIndex] = rowData;

        setCurrentAbsenceConfig(currentAbsenceConfigAux);
        updateUserField("absences_config.years_config", parseUserListToMap(currentAbsenceConfigAux));
    };

    return (
        <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}>
            <div className="grid">
                <Toast ref={toast} />

                {showDialog ? getDialog(dialog) : null}

                <div className="col-12">
                    <div className=" card">
                        <div className="grid overflow-auto">
                            <div className="col-12" style={{ minWidth: "58rem" }}>
                                <Calendar
                                    localizer={localizer}
                                    events={getEventsFromHours(hours)}
                                    views={["month"]}
                                    startAccessor="start"
                                    endAccessor="end"
                                    style={{ height: 650, borderRadius: "var(--border-radius)" }}
                                    onSelectSlot={handleSlotSelection}
                                    eventPropGetter={eventStyleGetter}
                                    onSelectEvent={(event) => onEventSelect(event)}
                                    selectable
                                    components={{
                                        event: event,
                                        toolbar: toolbar,
                                    }}
                                    dayPropGetter={dayPropGetter}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 xl:col-4">
                    <div className="grid card xl:mr-1 min-h-full">
                        <div className="col-12">
                            <div className="flex justify-content-between flex-wrap">
                                <div className="flex align-items-center justify-content-center pl-2">
                                    <label className="mousee-text font-medium font-weight-bold">{t("label.absences_authorization")}</label>
                                </div>
                                {checkComponentPermissions(
                                    <div className="flex align-items-center justify-content-center pr-2">
                                        <Button
                                            className="p-button-filled"
                                            icon="pi pi-plus"
                                            onClick={() => {
                                                setDialog("add_authorizer");
                                                setShowDialog(true);
                                            }}
                                        />
                                    </div>,
                                    ["edit_users"],
                                    null,
                                    null
                                )}
                            </div>
                        </div>

                        <div className="col-12">
                            {loadingAuthorizers ? (
                                <label className="mousee-text-small font-x-small font-weight-regular font-italic">{t("label.loading")}</label>
                            ) : authorizers?.length > 0 ? (
                                <div className="grid pt-3 align-content-start">
                                    {authorizers?.map((item, index) => {
                                        return (
                                            <>
                                                <div className="col-12">
                                                    <div className="flex justify-content-between flex-wrap">
                                                        <div className="flex align-items-center justify-content-start">{userItemTemplate(item, "large")}</div>
                                                        <div className="flex align-items-center justify-content-center">
                                                            <Button icon={<Icon icon="solar:trash-bin-trash-bold-duotone" className="" style={{ fontSize: "20px" }} />} className="p-button-text p-button-secondary" /**onClick={() => unassignRole(item)} */ />
                                                        </div>
                                                    </div>
                                                </div>
                                                {index !== authorizers?.length - 1 ? <Divider className="border-300 mt-1 mb-1" /> : null}
                                            </>
                                        );
                                    })}
                                </div>
                            ) : (
                                <label className="mousee-text-small font-x-small font-weight-regular font-italic">{t("label.there_are_no_authorizers_assigned_to_this_user")}</label>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-12 xl:col-3">
                    <div className="grid card xl:mr-1 min-h-full">
                        <div className="col-12">
                            <div className="flex justify-content-between flex-wrap">
                                <div className="flex align-items-center justify-content-center pl-2">
                                    <label className="mousee-text font-medium font-weight-bold">{t("label.current_month")}</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="grid">
                                <div className="col-12">
                                    <div class="flex justify-content-between flex-wrap">
                                        <div class="flex align-items-center justify-content-start w-11">
                                            <div class="flex flex-row flex-wrap w-12">
                                                <div class="flex align-items-center justify-content-start w-11">
                                                    <label className="mousee-text font-x-small font-weight-regular">{t("label.pending_approval")}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex align-items-center justify-content-end w-1">
                                            <label className="mousee-text font-x-small font-weight-regular">{hours && hours?.length > 0 ? hours?.filter((item) => isCurrentYearMonth(item?.date, currentYearMonth) && !item?.approved)?.length : 0}</label>
                                        </div>
                                    </div>
                                </div>
                                {absences && absences?.length > 0 ? (
                                    <>
                                        {/* <div className="col-12">
											<Divider className='border-300'/>
										</div> */}
                                        {absences?.map((item, index) => {
                                            return (
                                                <div className="col-12">
                                                    <div class="flex justify-content-between flex-wrap">
                                                        <div class="flex align-items-center justify-content-start w-11">
                                                            <div class="flex flex-row flex-wrap w-12">
                                                                <div class="flex align-items-center justify-content-start w-1">
                                                                    <Checkbox
                                                                        inputId={`color-item-${index}`}
                                                                        name={`color-item-${index}`}
                                                                        checked={false}
                                                                        className={`absence-checkbox ${item?.color?.replace("#", "").match(/^\d/) ? `a${item?.color?.replace("#", "")}` : item?.color?.replace("#", "")}`}
                                                                    />
                                                                </div>
                                                                <div class="flex align-items-center justify-content-start pl-2 w-11">
                                                                    <label className="mousee-text font-x-small font-weight-regular">{item?.name}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="flex align-items-center justify-content-end w-1">
                                                            <label className="mousee-text font-x-small font-weight-regular">
                                                                {getAbsencesTypeCount(hours, item, currentYearMonth) -
                                                                    (hours && hours?.length > 0 ? hours?.filter((hourItem) => isCurrentYearMonth(hourItem?.date, currentYearMonth) && !hourItem?.approved && hourItem?.absence_type === item?.id)?.length : 0)}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 xl:col-5">
                    <div className="grid card min-h-full">
                        <div className="col-12">
                            <div className="flex justify-content-between flex-wrap">
                                <div className="flex align-items-center justify-content-center pl-2">
                                    <label className="mousee-text font-medium font-weight-bold">{t("label.summary")}</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <DataTable
                                key="user-absences-configuration-table"
                                dataKey="year"
                                emptyMessage={t("label.no_items_to_display")}
                                size="small"
                                value={currentAbsenceConfig}
                                responsiveLayout="scroll"
                                stripedRows
                                rows={5}
                                // sortField="year"
                                // sortOrder={-1}
                                editor={(options) => cellEditor(options)}
                                editMode="cell"
                                footer={
                                    <div className="grid">
                                        <div className="col-12">
                                            <div class="flex justify-content-end flex-wrap">
                                                <div class="flex align-items-center justify-content-center">
                                                    <Button
                                                        icon="pi pi-plus-circle"
                                                        label={t("label.add_another_line")}
                                                        className="p-button-filled min-w-full"
                                                        onClick={() => {
                                                            if (currentAbsenceConfig && currentAbsenceConfig?.length > 0) {
                                                                let minorNumber = new Date().getFullYear();

                                                                for (let i = 0; i < currentAbsenceConfig?.length; i++) {
                                                                    const element = currentAbsenceConfig[i];

                                                                    if (parseInt(element?.year) < minorNumber) {
                                                                        minorNumber = parseInt(element?.year);
                                                                    }
                                                                }

                                                                const newAbsenceConfig = [...currentAbsenceConfig, { year: minorNumber - 1, available: 0, used: 0, total: 0 }];
                                                                setCurrentAbsenceConfig(newAbsenceConfig);
                                                                updateUserField("absences_config.years_config", parseUserListToMap(newAbsenceConfig));
                                                            } else {
                                                                const newAbsenceConfig = [{ year: new Date().getFullYear(), available: 0, used: 0, total: 0 }];
                                                                setCurrentAbsenceConfig(newAbsenceConfig);
                                                                updateUserField("absences_config.years_config", parseUserListToMap(newAbsenceConfig));
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            >
                                <Column header={tableHeaderTemplate(null, t("label.year"))} field="year" style={{ width: "40%" }} editor={(options) => (checkPermissions(["edit_users"]) ? cellEditor(options) : null)} onCellEditComplete={checkPermissions(["edit_users"]) ? onCellEditComplete : null} />

                                <Column header={tableHeaderTemplate(null, t("label.available"))} style={{ width: "20%" }} body={(item) => item?.total - item?.used} />
                                <Column header={tableHeaderTemplate(null, t("label.used"))} field="used" style={{ width: "20%" }} editor={(options) => (checkPermissions(["edit_users"]) ? cellEditor(options) : null)} onCellEditComplete={checkPermissions(["edit_users"]) ? onCellEditComplete : null} />
                                <Column
                                    header={tableHeaderTemplate(null, t("label.total"))}
                                    field="total"
                                    style={{ width: "20%" }}
                                    editor={(options) => (checkPermissions(["edit_users"]) ? cellEditor(options) : null)}
                                    onCellEditComplete={checkPermissions(["edit_users"]) ? onCellEditComplete : null}
                                />
                                {checkComponentPermissions(
                                    <Column
                                        headerStyle={{ width: "5rem", textAlign: "center" }}
                                        bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                        body={(item, options) => {
                                            return (
                                                <Button
                                                    icon={<Icon icon="solar:trash-bin-trash-bold-duotone" className="" style={{ fontSize: "20px" }} />}
                                                    className="p-button-text p-button-secondary"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        const newAbsenceConfig = currentAbsenceConfig?.filter((itemAux) => itemAux?.year !== item?.year);
                                                        setCurrentAbsenceConfig(newAbsenceConfig);
                                                        updateUserField("absences_config.years_config", parseUserListToMap(newAbsenceConfig));
                                                    }}
                                                />
                                            );
                                        }}
                                    />,
                                    ["edit_users"],
                                    null,
                                    null
                                )}
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </BlockUI>
    );
};
export default UserAbsences;
