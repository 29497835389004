import { API } from 'aws-amplify';

const API_NAME = "PaymentsAPI";
const BASE_PATH = "/billing"

export default class BillingService {
    async queryInvoices() {
        return API.get( API_NAME, `${BASE_PATH}/invoice/query`)
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }

    async getUpcomingInvoice() {
        return API.get( API_NAME, `${BASE_PATH}/invoice/upcoming`)
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }
}