import { API } from 'aws-amplify';

const API_NAME = "PaymentsAPI";
const BASE_PATH = "/payment"

export default class PaymentService {

    async getSetupIntent() {
        return API.get( API_NAME, `${BASE_PATH}/payment_method/setup_intent`)
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }

    async queryPaymentMethods() {
        return API.get( API_NAME, `${BASE_PATH}/payment_method/query`)
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }

    async attachPaymentMethod(paymentMethodId, markAsDefault) {
        return API.post( API_NAME, `${BASE_PATH}/payment_method/attach/${paymentMethodId}`,{queryStringParameters: {default: `${markAsDefault}`}})
    }

    async deletePaymentMethod(paymentMethodId) {
        return API.del( API_NAME, `${BASE_PATH}/payment_method/delete/${paymentMethodId}`)
    }

    async updatePaymentMethod(paymentMethodId, data) {
        return API.put( API_NAME, `${BASE_PATH}/payment_method/update/${paymentMethodId}`, {body: data})
    }

    async setDefaultPaymentMethod(paymentMethodId) {
        return API.post( API_NAME, `${BASE_PATH}/payment_method/set_default/${paymentMethodId}`)
    }

    // async queryPayments(queryStringParameters={}, data) {
    //     return API.post(API_NAME, `${BASE_PATH}/query`,{queryStringParameters:queryStringParameters, body: data})
    //         .then((response) => response)
    //         .catch((error) => {
    //             console.log(error.response);
    //         });
    // }

    // async createPayment(client_id, project_id, process_id, task_id, data) {
    //     return API.post( API_NAME, BASE_PATH, { queryStringParameters: {client_id: client_id, project_id: project_id, process_id: process_id, task_id: task_id}, body: data } )
    //     .then((response) => response)
    //     .catch((error) => {
    //         console.log(error.response);
    //     });
    // }

    // async updatePayment(data, client_id, project_id, process_id, task_id, participant_id) {
    //     return API.put( API_NAME, `${BASE_PATH}/${participant_id}`, { queryStringParameters: {client_id: client_id, project_id: project_id, process_id: process_id, task_id: task_id}, body: data } )
    //     .then((response) => response)
    //     .catch((error) => {
    //         console.log(error.response);
    //     });
    // }

    // async deletePayment(client_id, project_id, process_id, task_id, participant_id) {
    //     return API.del( API_NAME, `${BASE_PATH}/${participant_id}`, { queryStringParameters: {client_id: client_id, project_id: project_id, process_id: process_id, task_id: task_id}})
    //     .then((response) => response)
    //     .catch((error) => {
    //         console.log(error.response);
    //     });
    // }

    // async emptyPayments(client_id, project_id, process_id, task_id) {
    //     return API.del( API_NAME, `${BASE_PATH}/empty`, { queryStringParameters: {client_id: client_id, project_id: project_id, process_id: process_id, task_id: task_id}})
    //     .then((response) => response)
    //     .catch((error) => {
    //         console.log(error.response);
    //     });
    // }
}