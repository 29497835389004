import { API } from "aws-amplify";
const API_NAME = "FilesAPI";
const BASE_PATH = "/file";

export default class FileService {
    async generate() {
        return API.get(API_NAME, `${BASE_PATH}/generate`)
            .then((response) => response)
            .catch((error) => {
                console.log(error.response);
            });
    }

    async queryFiles(queryStringParameters = {}, data) {
        return API.post(API_NAME, `${BASE_PATH}/query`, { queryStringParameters: queryStringParameters, body: data });
    }

    async createFile(client_id, project_id, process_id, task_id, data) {
        return API.post(API_NAME, BASE_PATH, { queryStringParameters: { client_id: client_id, project_id: project_id, process_id: process_id, task_id: task_id }, body: data });
    }

    async deleteFile(client_id, project_id, process_id, task_id, file_id) {
        return API.del(API_NAME, `${BASE_PATH}/${file_id}`, { queryStringParameters: { client_id: client_id, project_id: project_id, process_id: process_id, task_id: task_id } });
    }

    async emptyFiles(client_id, project_id, process_id, task_id) {
        return API.del(API_NAME, `${BASE_PATH}/empty`, { queryStringParameters: { client_id: client_id, project_id: project_id, process_id: process_id, task_id: task_id } });
    }

    async uploadFile(body, headers, url) {
        return fetch(url, {
            method: "PUT",
            body: body,
            // 👇 Set headers manually for single file upload
            headers: headers,
        });
    }
}
