import classNames from "classnames";
import { Badge } from "primereact/badge";
import { Ripple } from "primereact/ripple";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import logoEstimatesHovered from "../assets/icons/menu-budget-hovered.svg";
import logoEstimatesSelected from "../assets/icons/menu-budget-selected.svg";
import logoEstimates from "../assets/icons/menu-budget.svg";
// import logoChatHovered from '../assets/icons/menu-chat-hovered.svg';
// import logoChatSelected from '../assets/icons/menu-chat-selected.svg';
// import logoChat from '../assets/icons/menu-chat.svg';
import logoMetricsHovered from "../assets/icons/menu-metrics-hovered.svg";
import logoMetricsSelected from "../assets/icons/menu-metrics-selected.svg";
import logoMetrics from "../assets/icons/menu-metrics.svg";
import logoPanelHovered from "../assets/icons/menu-panel-hovered.svg";
import logoPanelSelected from "../assets/icons/menu-panel-selected.svg";
import logoPanel from "../assets/icons/menu-panel.svg";
import logoProductsHovered from "../assets/icons/menu-products-hovered.svg";
import logoProductsSelected from "../assets/icons/menu-products-selected.svg";
import logoProducts from "../assets/icons/menu-products.svg";
import logoTasksHovered from "../assets/icons/menu-tasks-hovered.svg";
import logoTasksSelected from "../assets/icons/menu-tasks-selected.svg";
import logoTasks from "../assets/icons/menu-tasks.svg";

import logoPeopleHovered from "../assets/icons/menu-team-hovered.svg";
import logoPeopleSelected from "../assets/icons/menu-team-selected.svg";
import logoPeople from "../assets/icons/menu-team.svg";


import { useTranslation } from "react-i18next";
import { capitalizeWord, listContainsStringWith } from "../Utils";
import logoClientsHovered from "../assets/icons/menu-clients-hovered.svg";
import logoClientsSelected from "../assets/icons/menu-clients-selected.svg";
import logoClients from "../assets/icons/menu-clients.svg";


import { checkComponentPermissions } from "../data/Permissions";

const AppSubmenu = (props) => {
    const { t } = useTranslation();

    const [activeIndex, setActiveIndex] = useState(null);

    const onMenuItemClick = (event, item, index) => {
        //avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        //execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
        }

        if (index === activeIndex) setActiveIndex(null);
        else setActiveIndex(index);

        if (props.onMenuItemClick) {
            props.onMenuItemClick({
                originalEvent: event,
                item: item,
            });
        }
    };

    const onKeyDown = (event) => {
        if (event.code === "Enter" || event.code === "Space") {
            event.preventDefault();
            event.target.click();
        }
    };

    const renderLinkContent = (item) => {
        let submenuIcon = item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>;
        let badge = item.badge && <Badge value={item.badge} />;

        return (
            <React.Fragment>
                <img src={item.icon} className="mr-2" alt=""></img>
                {/* {item?.icon} */}
                {item?.show_point ? <span className="sub-icon">{" · "}</span> : null}
                <span>{capitalizeWord(t(item.label))}</span>
                {submenuIcon}
                {badge}
                <Ripple />
            </React.Fragment>
        );
    };

    const renderLink = (item, i) => {
        let content = renderLinkContent(item);

        if (item.to) {
            return (
                <NavLink aria-label={item.label} onKeyDown={onKeyDown} role="menuitem" className={`p-ripple ${item?.options && !props?.staticMenuMedium ? (listContainsStringWith(item?.options, window.location.pathname) ? "router-link-active router-link-exact-active-root" : "") : (item.to && window.location.pathname.startsWith(item.to) ? "router-link-active router-link-exact-active" : "")}`} to={item.to} onClick={(e) => onMenuItemClick(e, item, i)} exact target={item.target}>
                    {content}
                </NavLink>
            );
        } else {
            return (
                <a tabIndex="0" aria-label={item.label} onKeyDown={onKeyDown} role="menuitem" href={item.url} className={`p-ripple ${listContainsStringWith(item?.options, window.location.pathname) ? "router-link-active router-link-exact-active-root" : ""}`} onClick={(e) => onMenuItemClick(e, item, i)} target={item.target}>
                    {content}
                </a>
            );
        }
    };

    let items =
        props.items &&
        props.items.map((item, i) => {
            let active = activeIndex === i;
            let styleClass = classNames(item.badgeStyleClass, { "layout-menuitem-category": props.root, "active-menuitem": active && !item.to }, !item?.always ? "layout-menuitem-child" : "");

            if (props.root) {
                return checkComponentPermissions( 
                    <li className={styleClass} key={i} role="none">
                        {props.root === true && (
                            <React.Fragment>
                                <div className="layout-menuitem-root-text" aria-label={item.label}>
                                    {t(item.label)}
                                </div>
                                <AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClick} />
                            </React.Fragment>
                        )}
                    </li>,
                    item.permissions,
                    item.userTypes,
                    null
                );
            } else {
                return checkComponentPermissions(
                    <li className={styleClass} key={i} role="none">
                        {renderLink(item, i)}
                        <CSSTransition classNames="layout-submenu-wrapper" timeout={{ enter: 1000, exit: 450 }} in={active} unmountOnExit>
                            <AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClick} />
                        </CSSTransition>
                    </li>,
                    item.permissions,
                    item.userTypes,
                    null
                );
            }
        });

    return items ? (
        <ul className={props.className} role="menu">
            {items}
        </ul>
    ) : null;
};

export const AppMenuV2 = (props) => {
    const menuModel = [
        {
            label: "label.overview",
            permissions: ["view_panel"],
            userTypes: ["employee", "client"],
            always: true,
            items: [
                {
                    icon: logoPanel,
                    hoverIcon: logoPanelHovered,
                    selectedIcon: logoPanelSelected,
                    // icon: <Icon icon="solar:copy-bold-duotone" color="inherit" flip="vertical" rotate={180} className="mousee-text font-x-large" />,
                    label: "label.dashboard",
                    to: "/home",
                    option: "panel",
                    permissions: ["view_panel"],
                    userTypes: ["employee", "client"],
                    always: true,
                    options: ["/home"],
                },
            ],
        },
        {
            label: "label.management",
            permissions: ["view_users", "view_profiles", "view_users", "view_clients", "view_services", "view_budgets", "view_projects", "view_metrics"],
            always: true,
            items: [
                {
                    label: "label.team",
                    icon: logoPeople,
                    hoverIcon: logoPeopleHovered,
                    selectedIcon: logoPeopleSelected,
                    permissions: ["view_users", "view_profiles"],
                    always: true,
                    to: props?.staticMenuMedium ? "/people" : undefined,
                    options: ["/people", "/skills", "/capacity"],
                    // icon: <Icon icon="solar:users-group-rounded-bold-duotone" color="inherit" className="mousee-text font-x-large" />,
                    items: [
                        {
                            label: "label.users",
                            to: "/people",
                            option: "people",
                            permissions: ["view_users"],
                            show_point: true,
                        },
                        {
                            label: "label.skills",
                            to: "/skills",
                            option: "people",
                            permissions: ["view_profiles"],
                            show_point: true,
                        },
                        {
                            label: "label.capacity",
                            to: "/capacity",
                            option: "people",
                            permissions: ["view_users"],
                            show_point: true,
                        },
                    ],
                },
                {
                    icon: logoClients,
                    hoverIcon: logoClientsHovered,
                    selectedIcon: logoClientsSelected,
                    // icon: <Icon icon="solar:mask-happly-bold-duotone" color="inherit" className="mousee-text font-x-large" />,
                    label: "label.clients",
                    to: "/clients",
                    option: "clients",
                    permissions: ["view_clients"],
                    options: ["/clients"],
                    always: true,
                },
                {
                    icon: logoProducts,
                    hoverIcon: logoProductsHovered,
                    selectedIcon: logoProductsSelected,
                    // icon: <Icon icon="solar:widget-bold-duotone" color="inherit" className="mousee-text font-x-large" />,
                    label: "label.services",
                    to: "/processes",
                    option: "products",
                    permissions: ["view_services"],
                    options: ["/processes"],
                    always: true,
                },
                {
                    label: "label.billing",
                    icon: logoEstimates,
                    hoverIcon: logoEstimatesHovered,
                    selectedIcon: logoEstimatesSelected,
                    permissions: ["view_budgets", "view_invoices"],
                    always: true,
                    to: props?.staticMenuMedium ? "/budgets" : undefined,
                    options: ["/budgets", "/invoices"],
                    items: [
                        {
                            label: "label.budgets",
                            to: "/budgets",
                            option: "budgets",
                            permissions: ["view_budgets"],
                            userTypes: ["client"],
                            show_point: true,
                        },
                        {
                            label: "label.invoices",
                            to: "/invoices",
                            option: "invoice",
                            permissions: ["view_invoices"],
                            // userTypes: ["client"],
                            show_point: true,
                        }
                    ],
                },
                // {
                //     icon: logoEstimates,
                //     hoverIcon: logoEstimatesHovered,
                //     selectedIcon: logoEstimatesSelected,
                //     // icon: <Icon icon="solar:calculator-minimalistic-bold-duotone" color="inherit" className="mousee-text font-x-large" />,
                //     label: "label.budgets",
                //     to: "/budgets",
                //     option: "estimates",
                //     permissions: ["view_budgets"],
                //     options: ["/budgets"],
                //     userTypes: ["client"],
                //     always: true,
                // },
                {
                    icon: logoTasks,
                    hoverIcon: logoTasksHovered,
                    selectedIcon: logoTasksSelected,
                    // icon: <Icon icon="solar:clipboard-list-bold-duotone" color="inherit" className="mousee-text font-x-large" />,
                    label: "label.projects",
                    to: "/projects",
                    option: "projects",
                    permissions: ["view_projects"],
                    options: ["/projects"],
                    userTypes: ["client"],
                    always: true,
                },
                {
                    icon: logoMetrics,
                    hoverIcon: logoMetricsHovered,
                    selectedIcon: logoMetricsSelected,
                    // icon: <Icon icon="solar:chart-2-bold-duotone" color="inherit" className="mousee-text font-x-large" />,
                    label: "label.metrics",
                    to: "/metrics",
                    option: "metrics",
                    permissions: ["view_metrics"],
                    options: ["/metrics"],
                    always: true,
                },
            ],
        },
        // {
        //     label: "label.organization",
        //     permissions: ["view_tenant", "view_permissions", "view_billing", "view_payment_method", "view_tenant"],
        //     items: [
        //         {
        //             label: "tenant.page.topbar.menu.item.company.profile",
        //             to: "/tenant",
        //             option: "tenant_configuration",
        //             permissions: ["view_tenant"],
        //             show_point: true,
        //         },
        //         {
        //             label: "tenant.page.topbar.menu.item.permissions",
        //             to: "/permissions",
        //             option: "tenant_configuration",
        //             permissions: ["view_permissions"],
        //             show_point: true,
        //         },
        //         {
        //             label: "tenant.page.topbar.menu.item.billing",
        //             to: "/billing",
        //             option: "tenant_configuration",
        //             permissions: ["view_billing"],
        //             show_point: true,
        //         },
        //         {
        //             label: "tenant.page.topbar.menu.item.payment.preferences",
        //             to: "/payment_preferences",
        //             option: "tenant_configuration",
        //             permissions: ["view_payment_method"],
        //             show_point: true,
        //         },
        //         {
        //             label: "tenant.page.topbar.menu.item.overhead",
        //             to: "/overhead",
        //             option: "tenant_configuration",
        //             permissions: ["view_tenant"],
        //             show_point: true,
        //         },
        //     ],
        // },
    ];

    return (
        <div className="layout-menu-container">
            <img
                src={`${props?.staticMenuMedium ? "/avatar.svg" : "/logo.svg"}`}
                alt="logo"
                style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "80%",
                }}
            />
            <AppSubmenu staticMenuMediumitems={props?.staticMenuMedium} items={menuModel} className="layout-menu" onMenuItemClick={props.onMenuItemClick} root={true} role="menu" />
        </div>
    );
};
