import { useFormik } from "formik";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    addDaysToDate,
    datesAreOnSameDay,
    getDayName,
    getFirstLastDayOfTheMonth,
    getMonthsBetweenTwoDates,
    getWeekDayDatesBetweenTwoDates,
    getWeekDaysInMonth,
    getWeekFirstLastDayObject,
    isDesktop,
    objectHasKeys,
    parseDateToMonthDay,
    parseDateToMonthName,
    parseDateToYearName,
    parseTimeV2,
    tableHeaderTemplate,
    toHoursAndMinutes,
    toMinutes,
} from "../../../Utils";
import dropdownDown from "../../../assets/icons/dropdown-down.svg";
import CustomTablePaginator from "../../../components/CustomTablePaginator";
import { checkComponentPermissions } from "../../../data/Permissions";
import { getAdditionalTopBarComponentsV2 } from "../../../data/TabsData";
import UserService from "../../../services/UserService/UserService";
import Forbidden from "../../Auth/Forbidden";
import { userItemTemplate } from "../Users/Utils";

import { Calendar } from "primereact/calendar";

import { CAPACITY_TIME_FRAME_VALUES, getCapacityTimeFrameObject } from "../../../data/Capacity";

import { InputText } from "primereact/inputtext";
import { ProgressBar } from "primereact/progressbar";
import { Skeleton } from "primereact/skeleton";
import { useTranslation } from "react-i18next";
import ClientService from "../../../services/ClientService/ClientService";
import HourService from "../../../services/HourService/HourService";
import ProjectService from "../../../services/ProjectService/ProjectService";
import ServiceService from "../../../services/ServiceService/ServiceService";
import { sumTwoHHMM } from "../../Task/Utils";
import "./Capacity.css";

const Capacity = (props) => {
    const userService = new UserService();
    const hourService = new HourService();
    const clientService = new ClientService();
    const serviceService = new ServiceService();
    const projectService = new ProjectService();

    const [loading, setLoading] = useState(false);
    const [loadingSublist, setLoadingSublist] = useState(false);
    const [activeUserId, setActiveUserId] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const navigate = useNavigate();
    const toast = useRef(null);
    const { t, i18n } = useTranslation();

    const [expandedRows, setExpandedRows] = useState({});

    const [timeFrameOption, setTimeFrameOption] = useState("weeks");
    const [timeFrame, setTimeFrame] = useState();

    const [dynamicColumns, setDynamicColumns] = useState([]);

    const [users, setUsers] = useState([]);
    const [queryUsersResponse, setQueryUsersResponse] = useState();
    const [usersTableFirst, setUsersTableFirst] = useState(0);
    const [usersTableKeys, setUsersTableKeys] = useState([0]);
    const [usersRowsNumber, setUsersRowsNumber] = useState(10);
    const [usersActiveIndex, setUsersActiveIndex] = useState(0);

    const [loadingUsersData, setLoadingUsersData] = useState({});
    const [usersData, setUsersData] = useState({});
    const [usersTooltipData, setUsersTooltipData] = useState({});
    const [usersTooltipDataLoading, setUsersTooltipDataLoading] = useState({});

    const [usersProgressBarTooltipData, setUsersProgressBarTooltipData] = useState({});
    const [usersProgressBarTooltipDataLoading, setUsersProgressBarTooltipDataLoading] = useState({});
    const [usersProgressBarTooltipDataServices, setUsersProgressBarTooltipDataServices] = useState({});
    const [usersProgressBarTooltipDataProjects, setUsersProgressBarTooltipDataProjects] = useState({});

    const [usersTooltipDataServices, setUsersTooltipDataServices] = useState({});
    const [usersTooltipDataProjects, setUsersTooltipDataProjects] = useState({});

    useEffect(() => {
        props.setTopbarAdditionalComponents(getAdditionalTopBarComponentsV2("capacity", navigate));
    }, []);

    useEffect(() => {
        props.setOption("people");
    }, []);

    useEffect(() => {
        formik.resetForm();
        setLoadingUsers(true);

        userService.queryUsers({ limit: usersRowsNumber }).then((data) => {
            setQueryUsersResponse(data);
            setUsers(data?.data);
            setLoadingUsers(false);
        });
    }, []);

    const putUsersLoading = async (usersParam, loadingValue) => {
        usersParam = usersParam ? usersParam : users;

        let loadingUsersDataAux = {};
        setLoadingUsersData({});
        setUsersTooltipData({});
        setUsersProgressBarTooltipData({});
        setUsersProgressBarTooltipDataLoading({});
        setUsersTooltipDataServices({});
        setUsersTooltipDataProjects({});
        setUsersProgressBarTooltipDataServices({});
        setUsersProgressBarTooltipDataProjects({});

        for (let i = 0; i < usersParam.length; i++) {
            const userItem = usersParam[i];
            loadingUsersDataAux[userItem?.id] = loadingValue;
        }
        setLoadingUsersData(loadingUsersDataAux);
    };

    // declare the data fetching function
    const fetchUsersData = async (timeFrameParam, timeFrameOptionParam) => {
        timeFrameParam = timeFrameParam ? timeFrameParam : timeFrame;
        timeFrameOptionParam = timeFrameOptionParam ? timeFrameOptionParam : timeFrameOption;

        putUsersLoading(null, true);
        if (users && users.length > 0 && timeFrameParam) {
            for (let i = 0; i < users.length; i++) {
                const userData = users[i];

                let queryParameters = {
                    user_id: userData?.id,
                    query_type: "user",
                    limit: 200,
                };

                let fromDateStr = undefined;
                let untilDateStr = undefined;

                if (timeFrameOptionParam === "weeks") {
                    fromDateStr = `${timeFrameParam[0]?.toISOString()?.split("T")[0]}T00:00:00.000Z`;
                    untilDateStr = `${timeFrameParam[1]?.toISOString()?.split("T")[0]}T00:00:00.000Z`;
                } else if (timeFrameOptionParam === "months") {
                    if (!timeFrameParam[1]) {
                        const { firstDay, lastDay } = getFirstLastDayOfTheMonth(timeFrameParam[0]);
                        fromDateStr = firstDay.toISOString();
                        untilDateStr = lastDay.toISOString();
                    } else {
                        const { firstDay } = getFirstLastDayOfTheMonth(timeFrameParam[0]);
                        const { lastDay } = getFirstLastDayOfTheMonth(timeFrameParam[1]);
                        fromDateStr = firstDay.toISOString();
                        untilDateStr = lastDay.toISOString();
                    }
                }

                queryParameters = {
                    ...queryParameters,
                    from: fromDateStr,
                    until: untilDateStr,
                };

                const data = await hourService.queryHours(queryParameters);
                let newUsersData = usersData;
                newUsersData[userData?.id] = data;
                setUsersData(newUsersData);
            }
        }
        putUsersLoading(null, false);
    };

    useEffect(() => {
        fetchUsersData().then(() => {
            onTimeFrameChange([new Date()]);
        });
    }, [users]);

    useEffect(() => {
        onTimeFrameChange([new Date()]);
    }, []);

    const formik = useFormik({
        initialValues: {
            profile: null,
            status: null,
            text: "",
        },
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);
            let queryParameter = {
                limit: usersRowsNumber,
            };
            if (data.text.length > 0) {
                queryParameter = {
                    ...queryParameter,
                    name: data.text,
                };
            }
            userService.queryUsers(queryParameter).then((data) => {
                setUsersTableFirst(0);
                setUsersTableKeys([0]);
                setUsersActiveIndex(0);
                setQueryUsersResponse(data);
                setUsers(data?.data);
                setLoading(false);
            });
        },
    });

    const filterByParams = (e, type) => {
        setLoading(true);

        formik.setFieldValue(type, e.value);

        let queryParameters = {};

        let flag = false;

        if (type === "profile") {
            if (e.value) {
                flag = true;

                userService.queryUsers({ limit: usersRowsNumber, profile_id: `${e.value.visibility}#${e.value.id}` }).then((data) => {
                    setUsersTableFirst(0);
                    setUsersTableKeys([0]);
                    setUsersActiveIndex(0);
                    setQueryUsersResponse(data);
                    setUsers(data?.data);
                    setLoading(false);
                });
            }
        }

        if (!flag) {
            userService.queryUsers({ limit: usersRowsNumber }).then((data) => {
                setQueryUsersResponse(data);
                setUsers(data?.data);
                setLoading(false);
            });
        }
    };

    const onTablePageChange = (key) => {
        if (key === usersTableKeys.length) {
            setLoadingUsers(true);

            let queryParameters = {
                limit: usersRowsNumber,
            };

            if (formik.values.text) {
                queryParameters = {
                    ...queryParameters,
                    name: formik.values.text,
                };
            }

            userService.queryUsers(queryParameters, queryUsersResponse.last_key).then((data) => {
                setQueryUsersResponse(data);

                if (data.data && data.data.length > 0) {
                    let loadingUsersDataAux = { ...loadingUsersData };

                    data?.data.forEach((userItem) => {
                        loadingUsersDataAux[userItem?.id] = true;
                    });
                    setLoadingUsersData(loadingUsersDataAux);

                    const newUsers = [...users, ...data.data];
                    setUsers(newUsers);

                    setUsersTableKeys(usersTableKeys.concat([usersTableKeys.length]));
                    setUsersActiveIndex(usersTableKeys.length);

                    if (usersTableKeys.length > 0) {
                        setUsersTableFirst(usersTableKeys.length * usersRowsNumber);
                    } else {
                        setUsersTableFirst(0);
                    }
                }

                setLoadingUsers(false);
            });
        } else {
            const newIndex = usersTableKeys.findIndex((item) => item === key);
            setUsersActiveIndex(newIndex);

            if (newIndex > 0) {
                setUsersTableFirst(newIndex * usersRowsNumber);
            } else {
                setUsersTableFirst(0);
            }
        }
    };

    const onTimeFrameChange = (newValue, timeFrameOptionParam) => {
        timeFrameOptionParam = timeFrameOptionParam ? timeFrameOptionParam : timeFrameOption;
        if (timeFrameOptionParam === "weeks") {
            const firstLastDayList = getWeekFirstLastDayObject(newValue[0]);
            const datesBetween = getWeekDayDatesBetweenTwoDates(firstLastDayList[0], firstLastDayList[1]);

            const firstLastWeekDayList = [datesBetween[0], datesBetween[datesBetween?.length - 1]];

            setTimeFrame(firstLastWeekDayList);
            setDynamicColumns(getColumns(firstLastWeekDayList, timeFrameOptionParam));
            fetchUsersData(firstLastWeekDayList, timeFrameOptionParam);
        } else if (timeFrameOptionParam === "months") {
            if (!newValue[1]) {
                setTimeFrame([newValue[0], null]);
                setDynamicColumns(getColumns([newValue[0], null], timeFrameOptionParam));
                fetchUsersData([newValue[0], null], timeFrameOptionParam);
            } else {
                if (newValue[1] !== timeFrame[0]) {
                    setTimeFrame([newValue[1], null]);
                    setDynamicColumns(getColumns([newValue[1], null], timeFrameOptionParam));
                    fetchUsersData([newValue[1], null], timeFrameOptionParam);
                } else {
                    setTimeFrame([newValue[0], null]);
                    setDynamicColumns(getColumns([newValue[0], null], timeFrameOptionParam));
                    fetchUsersData([newValue[0], null], timeFrameOptionParam);
                }
            }
        }
    };

    const getColumns = (timeFrameParam, timeFrameOptionParam) => {
        let columns = [];
        if (timeFrameOptionParam === "weeks") {
            if (timeFrameParam && timeFrameParam.length === 2) {
                const datesList = getWeekDayDatesBetweenTwoDates(new Date(timeFrameParam[0]), new Date(timeFrameParam[1]));
                columns = datesList?.map((item) => getDayName(new Date(item), i18n.language));
            }
        } else if (timeFrameOptionParam === "months") {
            if (!timeFrameParam[1] || (timeFrameParam[1] && timeFrameParam[0].getMonth() === timeFrameParam[1].getMonth())) {
                const daysInMonth = getWeekDaysInMonth(timeFrameParam[0].getMonth(), timeFrameParam[0].getFullYear());
                columns = daysInMonth?.map((item) => parseDateToMonthDay(item, i18n.language));
            } else {
                let monthsList = getMonthsBetweenTwoDates(new Date(timeFrameParam[0]).toISOString(), new Date(timeFrameParam[1]).toISOString());
                columns = monthsList.map((item) => parseDateToMonthName(new Date(item.split("-")[0], Number(item.split("-")[1] - 1), item.split("-")[2]), i18n.language));
            }
        }

        return columns;
    };

    const onTimeFrameButtonClick = (type) => {
        if (type === "next") {
            if (timeFrameOption === "weeks") {
                let nextDateDay = addDaysToDate(new Date(timeFrame[1]), 2);
                onTimeFrameChange([nextDateDay]);
            } else if (timeFrameOption === "months") {
                let nextDate = undefined;
                let currentMonth = undefined;
                let currentDate = undefined;
                if (!timeFrame[1]) {
                    currentDate = timeFrame[0];
                } else {
                    currentDate = timeFrame[1];
                }

                currentMonth = currentDate.getMonth();

                if (currentMonth + 1 > 11) {
                    currentMonth = 0;
                    let currentYear = currentDate.getFullYear();
                    const nextYear = currentYear + 1;

                    nextDate = new Date(nextYear, currentMonth, "01");
                } else {
                    currentMonth += 1;
                    let currentYear = currentDate.getFullYear();

                    nextDate = new Date(currentYear, currentMonth, "01");
                }

                onTimeFrameChange([nextDate]);
            } else if (timeFrameOption === "years") {
            }
        } else if (type === "previous") {
            if (timeFrameOption === "weeks") {
                let previousDateDay = addDaysToDate(new Date(timeFrame[0]), -2);
                onTimeFrameChange([previousDateDay]);
            } else if (timeFrameOption === "months") {
                let nextDate = undefined;
                let currentMonth = undefined;

                currentMonth = timeFrame[0].getMonth();

                if (currentMonth - 1 < 0) {
                    currentMonth = 11;
                    let currentYear = timeFrame[0].getFullYear();
                    const previousYear = currentYear - 1;

                    nextDate = new Date(previousYear, currentMonth, "01");
                } else {
                    currentMonth -= 1;
                    let currentYear = timeFrame[0].getFullYear();

                    nextDate = new Date(currentYear, currentMonth, "01");
                }

                onTimeFrameChange([nextDate]);
            } else if (timeFrameOption === "years") {
            }
        }
    };

    const onTimeFrameOptionChange = (newValue) => {
        setTimeFrameOption(newValue);
        if (newValue === "weeks") {
            onTimeFrameChange([new Date()], newValue);
        } else if (newValue === "months") {
            onTimeFrameChange([new Date(timeFrame[0]), null], newValue);
        }
    };

    const getEspecificDateTime = (hoursList, specificDate, timeFrameParam) => {
        let time = undefined;

        if (timeFrameOption === "weeks") {
            const datesList = getWeekDayDatesBetweenTwoDates(new Date(timeFrameParam[0]), new Date(timeFrameParam[1]));
            const datesListFiltered = datesList?.filter((item) => getDayName(item, i18n.language) === specificDate);

            if (datesListFiltered && datesListFiltered.length > 0) {
                const parsedSpecificDate = datesListFiltered[0];

                const hourListFiltered = hoursList?.filter((item) => datesAreOnSameDay(new Date(item?.date), parsedSpecificDate));

                time = getTotalSumHoursList(hourListFiltered);
            }
        } else if (timeFrameOption === "months") {
            if (!timeFrameParam[1]) {
                const datesList = getWeekDaysInMonth(timeFrameParam[0].getMonth(), timeFrameParam[0].getFullYear());
                const datesListFiltered = datesList?.filter((item) => parseDateToMonthDay(item, i18n.language) === specificDate);

                if (datesListFiltered && datesListFiltered.length > 0) {
                    const parsedSpecificDate = datesListFiltered[0];

                    const hourListFiltered = hoursList?.filter((item) => datesAreOnSameDay(new Date(item?.date), parsedSpecificDate));

                    time = getTotalSumHoursList(hourListFiltered);
                }
            } else {
                if (timeFrameParam[0].getMonth() === timeFrameParam[1].getMonth()) {
                    const datesList = getWeekDaysInMonth(timeFrameParam[0].getMonth(), timeFrameParam[0].getFullYear());
                    const datesListFiltered = datesList?.filter((item) => parseDateToMonthDay(item, i18n.language) === specificDate);

                    if (datesListFiltered && datesListFiltered.length > 0) {
                        const parsedSpecificDate = datesListFiltered[0];

                        const hourListFiltered = hoursList?.filter((item) => datesAreOnSameDay(new Date(item?.date), parsedSpecificDate));

                        time = getTotalSumHoursList(hourListFiltered);
                    }
                } else {
                    const hourListFiltered = hoursList?.filter((item) => new Date(item?.date).toLocaleString(i18n.language, { month: "long" }) === specificDate);

                    time = getTotalSumHoursList(hourListFiltered);
                }
            }
        }

        return time;
    };

    const getEspecificDateTimeHours = (hoursList, specificDate, timeFrameParam) => {
        let time = undefined;

        if (timeFrameOption === "weeks") {
            const datesList = getWeekDayDatesBetweenTwoDates(new Date(timeFrameParam[0]), new Date(timeFrameParam[1]));
            const datesListFiltered = datesList?.filter((item) => getDayName(item, i18n.language) === specificDate);

            if (datesListFiltered && datesListFiltered.length > 0) {
                const parsedSpecificDate = datesListFiltered[0];

                const hourListFiltered = hoursList?.filter((item) => datesAreOnSameDay(new Date(item?.date), parsedSpecificDate));

                return hourListFiltered;
            }
        } else if (timeFrameOption === "months") {
            if (!timeFrameParam[1]) {
                const datesList = getWeekDaysInMonth(timeFrameParam[0].getMonth(), timeFrameParam[0].getFullYear());
                const datesListFiltered = datesList?.filter((item) => parseDateToMonthDay(item, i18n.language) === specificDate);

                if (datesListFiltered && datesListFiltered.length > 0) {
                    const parsedSpecificDate = datesListFiltered[0];

                    const hourListFiltered = hoursList?.filter((item) => datesAreOnSameDay(new Date(item?.date), parsedSpecificDate));

                    return hourListFiltered;
                }
            } else {
                if (timeFrameParam[0].getMonth() === timeFrameParam[1].getMonth()) {
                    const datesList = getWeekDaysInMonth(timeFrameParam[0].getMonth(), timeFrameParam[0].getFullYear());
                    const datesListFiltered = datesList?.filter((item) => parseDateToMonthDay(item, i18n.language) === specificDate);

                    if (datesListFiltered && datesListFiltered.length > 0) {
                        const parsedSpecificDate = datesListFiltered[0];

                        const hourListFiltered = hoursList?.filter((item) => datesAreOnSameDay(new Date(item?.date), parsedSpecificDate));

                        return hourListFiltered;
                    }
                } else {
                    const hourListFiltered = hoursList?.filter((item) => new Date(item?.date).toLocaleString(i18n.language, { month: "long" }) === specificDate);

                    return hourListFiltered;
                }
            }
        }

        return time;
    };

    const getTotalSumHoursList = (hoursList) => {
        let time = { hours: 0, minutes: 0 };

        hoursList.forEach((hourList) => {
            time = sumTwoHHMM(hourList?.time, time);
        });

        return time;
    };

    const getTotalIncurredTimeProgressBar = (incurredTime, totalTime, timeFrameOptionParam, timeFrameParam) => {
        timeFrameOptionParam = timeFrameOptionParam ? timeFrameOptionParam : timeFrameOption;
        timeFrameParam = timeFrameParam ? timeFrameParam : timeFrame;

        if (!totalTime) {
            if (timeFrameOptionParam === "weeks") {
                totalTime = { hours: 40, minutes: 0 };
            } else if (timeFrameOptionParam === "months") {
                if (!timeFrameParam[1]) {
                    totalTime = { hours: 160, minutes: 0 };
                } else {
                    if (timeFrameParam[0].getMonth() === timeFrameParam[1].getMonth()) {
                        totalTime = { hours: 160, minutes: 0 };
                    } else {
                        let monthsList = getMonthsBetweenTwoDates(new Date(timeFrameParam[0]).toISOString(), new Date(timeFrameParam[1]).toISOString());
                        if (monthsList.length > 0) {
                            totalTime = { hours: monthsList.length * 160, minutes: 0 };
                        } else {
                            totalTime = { hours: 160, minutes: 0 };
                        }
                    }
                }
            }
        }

        const incurredTimeMinutes = toMinutes(incurredTime);
        const totalTimeMinutes = toMinutes(totalTime);

        return (incurredTimeMinutes * 100) / totalTimeMinutes;
    };

    const calculateTotalCapacity = (usersList, timeFrameOptionParam, timeFrameParam) => {
        let time = { hours: 0, minutes: 0 };

        timeFrameOptionParam = timeFrameOptionParam ? timeFrameOptionParam : timeFrameOption;
        timeFrameParam = timeFrameParam ? timeFrameParam : timeFrame;

        if (timeFrameOptionParam === "weeks") {
            usersList.forEach((usersListItem) => {
                let timePerWeek = { hours: 40, minutes: 0 };

                if (usersListItem?.time_per_week) {
                    timePerWeek = usersListItem?.time_per_week;
                }

                time = sumTwoHHMM(timePerWeek, time);
            });
        } else if (timeFrameOptionParam === "months") {
            if (!timeFrameParam[1]) {
                if (usersList?.length > 0) {
                    usersList.forEach((usersListItem) => {
                        let timePerWeek = { hours: 40, minutes: 0 };

                        if (usersListItem?.time_per_week) {
                            timePerWeek = usersListItem?.time_per_week;
                        }

                        const timePerDay = toMinutes(timePerWeek) / 5;
                        const weekDaysInMonth = getWeekDaysInMonth(timeFrameParam[0].getMonth(), timeFrameParam[0].getFullYear());

                        time = sumTwoHHMM(toHoursAndMinutes(timePerDay * weekDaysInMonth?.length), time);
                    });
                } else {
                    time = sumTwoHHMM({ hours: 160, minutes: 0 });
                }
            } else {
                if (timeFrameParam[0].getMonth() === timeFrameParam[1].getMonth()) {
                    usersList.forEach((usersListItem) => {
                        let timePerWeek = { hours: 40, minutes: 0 };

                        if (usersListItem?.time_per_week) {
                            timePerWeek = usersListItem?.time_per_week;
                        }

                        const timePerDay = toMinutes(timePerWeek) / 5;
                        const weekDaysInMonth = getWeekDaysInMonth(timeFrameParam[0].getMonth(), timeFrameParam[0].getFullYear());

                        time = sumTwoHHMM(toHoursAndMinutes(timePerDay * weekDaysInMonth?.length), time);
                    });
                } else {
                    const monthsBetween = getMonthsBetweenTwoDates(timeFrameParam[0].toISOString().split("T")[0], timeFrameParam[1].toISOString().split("T")[0]);

                    let monthsWeekDays = [];

                    monthsBetween.forEach((monthBetween) => {
                        monthsWeekDays = [...monthsWeekDays, ...getWeekDaysInMonth(parseInt(monthBetween.split("-")[1]) - 1, monthBetween.split("-")[0])];
                    });

                    usersList.forEach((usersListItem) => {
                        let timePerWeek = { hours: 40, minutes: 0 };

                        if (usersListItem?.time_per_week) {
                            timePerWeek = usersListItem?.time_per_week;
                        }

                        const timePerDay = toHoursAndMinutes(toMinutes(timePerWeek) / 5);
                        const timePerMonth = toHoursAndMinutes(toMinutes(timePerDay) * monthsWeekDays?.length);

                        time = sumTwoHHMM(timePerMonth, time);
                    });
                }
            }
        }

        return time;
    };

    const calculateTotalIncurredTime = (usersDataParam) => {
        let time = { hours: 0, minutes: 0 };

        if (usersDataParam && objectHasKeys(usersDataParam)) {
            for (const [key, value] of Object.entries(usersDataParam)) {
                if (value?.data && value?.data?.length > 0) {
                    for (let i = 0; i < value?.data?.length; i++) {
                        const dataElement = value?.data[i];
                        time = sumTwoHHMM(dataElement?.time, time);
                    }
                }
            }
        }

        return time;
    };

    const someUsersDataLoading = (usersDataLoadingParam) => {
        for (const [key, value] of Object.entries(usersDataLoadingParam)) {
            if (value === true) {
                return value;
            }
        }
        return false;
    };

    const fetchUserTooltipData = (rowColumnId, columnValue, timeFrameParam, hoursList, userId) => {
        const specificHoursList = getEspecificDateTimeHours(hoursList, columnValue, timeFrameParam);

        let usersTooltipDataLoadingAux = { ...usersTooltipDataLoading };
        usersTooltipDataLoadingAux[rowColumnId] = true;
        setUsersTooltipDataLoading(usersTooltipDataLoadingAux);

        if (specificHoursList && specificHoursList?.length > 0) {
            let retrieveClientsCalls = [];
            let retrieveServicesCalls = [];
            let retrieveProjectsCalls = [];

            const uniqueProjectsIds = Array.from(new Set(specificHoursList.map((item) => `${item?.client_id}#${item?.project_id}`)));

            uniqueProjectsIds.forEach((uniqueProjectsId) => {
                const splittedProjectId = uniqueProjectsId?.split("#");
                retrieveProjectsCalls.push(projectService.getProject(splittedProjectId[0], splittedProjectId[1]));
            });

            Promise.all(retrieveProjectsCalls).then((data) => {
                let usersTooltipDataProjectsAux = { ...usersTooltipDataProjects };
                if (data && data?.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        const dataElement = data[i];

                        const projectId = dataElement?.id?.split("#")[1];

                        const specificHoursListProjectFiltered = specificHoursList.filter((item) => item?.client_id === dataElement?.client_id && item?.project_id === projectId);

                        let totalProjectHoursSum = { hours: 0, minutes: 0 };
                        specificHoursListProjectFiltered.forEach((specificHoursListProjectFilteredElement) => {
                            totalProjectHoursSum = sumTwoHHMM(specificHoursListProjectFilteredElement?.time, totalProjectHoursSum);
                        });

                        if (!usersTooltipDataProjectsAux[rowColumnId] || usersTooltipDataProjectsAux[rowColumnId]?.length === 0) {
                            usersTooltipDataProjectsAux[rowColumnId] = [];
                        }

                        usersTooltipDataProjectsAux[rowColumnId].push({
                            project: dataElement,
                            time: totalProjectHoursSum,
                        });
                    }

                    setUsersTooltipDataProjects(usersTooltipDataProjectsAux);
                }
            });

            const uniqueServicesIds = Array.from(new Set(specificHoursList.map((item) => `${item?.client_id}#${item?.project_id}#${item?.service_id}`)));

            uniqueServicesIds.forEach((uniqueServicesId) => {
                const splittedServiceId = uniqueServicesId?.split("#");
                retrieveServicesCalls.push(serviceService.getService(splittedServiceId[0], splittedServiceId[1], splittedServiceId[2]));
            });

            Promise.all(retrieveServicesCalls).then((data) => {
                let usersTooltipDataServicesAux = { ...usersTooltipDataServices };
                if (data && data?.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        const dataElement = data[i];

                        const specificHoursListServiceFiltered = specificHoursList.filter((item) => item?.client_id === dataElement?.client_id && item?.project_id === dataElement?.project_id && item?.service_id === dataElement?.id);

                        let totalServiceHoursSum = { hours: 0, minutes: 0 };
                        specificHoursListServiceFiltered.forEach((specificHoursListServiceFilteredElement) => {
                            totalServiceHoursSum = sumTwoHHMM(specificHoursListServiceFilteredElement?.time, totalServiceHoursSum);
                        });

                        if (!usersTooltipDataServicesAux[rowColumnId] || usersTooltipDataServicesAux[rowColumnId]?.length === 0) {
                            usersTooltipDataServicesAux[rowColumnId] = [];
                        }

                        usersTooltipDataServicesAux[rowColumnId].push({
                            service: dataElement,
                            time: totalServiceHoursSum,
                        });
                    }

                    setUsersTooltipDataServices(usersTooltipDataServicesAux);
                }
            });

            const uniqueClientsIds = Array.from(new Set(specificHoursList.map((item) => item?.client_id)));
            uniqueClientsIds.forEach((uniqueClientId) => {
                retrieveClientsCalls.push(clientService.getClient(uniqueClientId));
            });

            Promise.all(retrieveClientsCalls).then((data) => {
                let usersTooltipDataAux = { ...usersTooltipData };
                if (data && data?.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        const dataElement = data[i];

                        const specificHoursListClientFiltered = specificHoursList.filter((item) => item?.client_id === dataElement?.id);

                        let totalClientHoursSum = { hours: 0, minutes: 0 };
                        specificHoursListClientFiltered.forEach((specificHoursListClientFilteredElement) => {
                            totalClientHoursSum = sumTwoHHMM(specificHoursListClientFilteredElement?.time, totalClientHoursSum);
                        });

                        if (!usersTooltipDataAux[rowColumnId] || usersTooltipDataAux[rowColumnId]?.length === 0) {
                            usersTooltipDataAux[rowColumnId] = [];
                        }

                        usersTooltipDataAux[rowColumnId].push({
                            user_id: userId,
                            client: dataElement,
                            time: totalClientHoursSum,
                        });
                    }

                    setUsersTooltipData(usersTooltipDataAux);

                    let usersTooltipDataLoadingAux = { ...usersTooltipDataLoading };
                    usersTooltipDataLoadingAux[rowColumnId] = false;
                    setUsersTooltipDataLoading(usersTooltipDataLoadingAux);
                }
            });
        }
    };

    const fetchUserProgressBarTooltipData = (rowId, timeFrameParam, hoursList, userValue) => {
        const specificHoursList = hoursList;

        let usersProgressBarTooltipDataLoadingAux = { ...usersProgressBarTooltipDataLoading };
        usersProgressBarTooltipDataLoadingAux[rowId] = true;
        setUsersProgressBarTooltipDataLoading(usersProgressBarTooltipDataLoadingAux);

        if (specificHoursList && specificHoursList?.length > 0) {
            let retrieveClientsCalls = [];
            let retrieveServicesCalls = [];
            let retrieveProjectsCalls = [];

            const uniqueProjectsIds = Array.from(new Set(specificHoursList.map((item) => `${item?.client_id}#${item?.project_id}`)));

            uniqueProjectsIds.forEach((uniqueProjectsId) => {
                const splittedProjectId = uniqueProjectsId?.split("#");
                retrieveProjectsCalls.push(projectService.getProject(splittedProjectId[0], splittedProjectId[1]));
            });

            Promise.all(retrieveProjectsCalls).then((data) => {
                let usersProgressBarTooltipDataProjectsAux = { ...usersProgressBarTooltipDataProjects };
                if (data && data?.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        const dataElement = data[i];

                        const projectId = dataElement?.id?.split("#")[1];

                        const specificHoursListProjectFiltered = specificHoursList.filter((item) => item?.client_id === dataElement?.client_id && item?.project_id === projectId);

                        let totalProjectHoursSum = { hours: 0, minutes: 0 };
                        specificHoursListProjectFiltered.forEach((specificHoursListProjectFilteredElement) => {
                            totalProjectHoursSum = sumTwoHHMM(specificHoursListProjectFilteredElement?.time, totalProjectHoursSum);
                        });

                        if (!usersProgressBarTooltipDataProjectsAux[rowId] || usersProgressBarTooltipDataProjectsAux[rowId]?.length === 0) {
                            usersProgressBarTooltipDataProjectsAux[rowId] = [];
                        }

                        usersProgressBarTooltipDataProjectsAux[rowId].push({
                            project: dataElement,
                            time: totalProjectHoursSum,
                        });
                    }

                    setUsersProgressBarTooltipDataProjects(usersProgressBarTooltipDataProjectsAux);
                }
            });

            const uniqueServicesIds = Array.from(new Set(specificHoursList.map((item) => `${item?.client_id}#${item?.project_id}#${item?.service_id}`)));

            uniqueServicesIds.forEach((uniqueServicesId) => {
                const splittedServiceId = uniqueServicesId?.split("#");
                retrieveServicesCalls.push(serviceService.getService(splittedServiceId[0], splittedServiceId[1], splittedServiceId[2]));
            });

            Promise.all(retrieveServicesCalls).then((data) => {
                let usersProgressBarTooltipDataServicesAux = { ...usersProgressBarTooltipDataServices };
                if (data && data?.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        const dataElement = data[i];

                        const specificHoursListServiceFiltered = specificHoursList.filter((item) => item?.client_id === dataElement?.client_id && item?.project_id === dataElement?.project_id && item?.service_id === dataElement?.id);

                        let totalServiceHoursSum = { hours: 0, minutes: 0 };
                        specificHoursListServiceFiltered.forEach((specificHoursListServiceFilteredElement) => {
                            totalServiceHoursSum = sumTwoHHMM(specificHoursListServiceFilteredElement?.time, totalServiceHoursSum);
                        });

                        if (!usersProgressBarTooltipDataServicesAux[rowId] || usersProgressBarTooltipDataServicesAux[rowId]?.length === 0) {
                            usersProgressBarTooltipDataServicesAux[rowId] = [];
                        }

                        usersProgressBarTooltipDataServicesAux[rowId].push({
                            service: dataElement,
                            time: totalServiceHoursSum,
                        });
                    }

                    setUsersProgressBarTooltipDataServices(usersProgressBarTooltipDataServicesAux);
                }
            });

            const uniqueClientsIds = Array.from(new Set(specificHoursList.map((item) => item?.client_id)));
            uniqueClientsIds.forEach((uniqueClientId) => {
                retrieveClientsCalls.push(clientService.getClient(uniqueClientId));
            });

            Promise.all(retrieveClientsCalls).then((data) => {
                let usersProgressBarTooltipDataAux = { ...usersProgressBarTooltipData };
                if (data && data?.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        const dataElement = data[i];

                        const specificHoursListClientFiltered = specificHoursList.filter((item) => item?.client_id === dataElement?.id);

                        let totalClientHoursSum = { hours: 0, minutes: 0 };
                        specificHoursListClientFiltered.forEach((specificHoursListClientFilteredElement) => {
                            totalClientHoursSum = sumTwoHHMM(specificHoursListClientFilteredElement?.time, totalClientHoursSum);
                        });

                        if (!usersProgressBarTooltipDataAux[rowId] || usersProgressBarTooltipDataAux[rowId]?.length === 0) {
                            usersProgressBarTooltipDataAux[rowId] = [];
                        }

                        usersProgressBarTooltipDataAux[rowId].push({
                            user_id: userValue?.id,
                            client: dataElement,
                            time: totalClientHoursSum,
                        });
                    }

                    setUsersProgressBarTooltipData(usersProgressBarTooltipDataAux);

                    let usersProgressBarTooltipDataLoadingAux = { ...usersProgressBarTooltipDataLoading };
                    usersProgressBarTooltipDataLoadingAux[rowId] = false;
                    setUsersProgressBarTooltipDataLoading(usersProgressBarTooltipDataLoadingAux);
                }
            });
        }
    };

    const getUserCapacityPerColumn = (userItem, timeFrameOptionParam, timeFrameParam) => {
        timeFrameOptionParam = timeFrameOptionParam ? timeFrameOptionParam : timeFrameOption;
        timeFrameParam = timeFrameParam ? timeFrameParam : timeFrame;

        let timePerWeek = { hours: 40, minutes: 0 };

        if (userItem?.time_per_week) {
            timePerWeek = userItem?.time_per_week;
        }

        if (timeFrameOptionParam === "weeks") {
            const timePerDay = toHoursAndMinutes(toMinutes(timePerWeek) / 5);
            return timePerDay;
        } else if (timeFrameOptionParam === "months") {
            if (!timeFrameParam[1]) {
                const timePerDay = toHoursAndMinutes(toMinutes(timePerWeek) / 5);
                return timePerDay;
            } else {
                if (timeFrameParam[0].getMonth() === timeFrameParam[1].getMonth()) {
                    const timePerDay = toHoursAndMinutes(toMinutes(timePerWeek) / 5);
                    return timePerDay;
                } else {
                    const timePerDay = toHoursAndMinutes(toMinutes(timePerWeek) / 5);
                    const monthsBetween = getMonthsBetweenTwoDates(timeFrameParam[0].toISOString().split("T")[0], timeFrameParam[1].toISOString().split("T")[0]);

                    let monthsWeekDays = [];

                    monthsBetween.forEach((monthBetween) => {
                        monthsWeekDays = [...monthsWeekDays, ...getWeekDaysInMonth(parseInt(monthBetween.split("-")[1]) - 1, monthBetween.split("-")[0])];
                    });

                    const timePerMonth = toHoursAndMinutes(toMinutes(timePerDay) * monthsWeekDays?.length);
                    return timePerMonth;
                }
            }
        }
    };

    const getUserCapacityProgressBar = (userItem, timeFrameOptionParam, timeFrameParam) => {
        timeFrameOptionParam = timeFrameOptionParam ? timeFrameOptionParam : timeFrameOption;
        timeFrameParam = timeFrameParam ? timeFrameParam : timeFrame;

        let timePerWeek = { hours: 40, minutes: 0 };

        if (userItem?.time_per_week) {
            timePerWeek = userItem?.time_per_week;
        }

        if (timeFrameOptionParam === "weeks") {
            return timePerWeek;
        } else if (timeFrameOptionParam === "months") {
            if (!timeFrameParam[1]) {
                // return timePerWeek;
                const timePerDay = toHoursAndMinutes(toMinutes(timePerWeek) / 5);

                const monthWeekDays = getWeekDaysInMonth(timeFrameParam[0].getMonth(), timeFrameParam[0].getFullYear());
                return toHoursAndMinutes(toMinutes(timePerDay) * monthWeekDays?.length);
            } else {
                if (timeFrameParam[0].getMonth() === timeFrameParam[1].getMonth()) {
                    const timePerDay = toHoursAndMinutes(toMinutes(timePerWeek) / 5);

                    const monthWeekDays = getWeekDaysInMonth(timeFrameParam[0].getMonth(), timeFrameParam[0].getFullYear());
                    return toHoursAndMinutes(toMinutes(timePerDay) * monthWeekDays?.length);
                } else {
                    const timePerDay = toHoursAndMinutes(toMinutes(timePerWeek) / 5);
                    const monthsBetween = getMonthsBetweenTwoDates(timeFrameParam[0].toISOString().split("T")[0], timeFrameParam[1].toISOString().split("T")[0]);

                    let monthsWeekDays = [];

                    monthsBetween.forEach((monthBetween) => {
                        monthsWeekDays = [...monthsWeekDays, ...getWeekDaysInMonth(parseInt(monthBetween.split("-")[1]) - 1, monthBetween.split("-")[0])];
                    });

                    const timePerMonth = toHoursAndMinutes(toMinutes(timePerDay) * monthsWeekDays?.length);
                    return timePerMonth;
                }
            }
        }
    };

    const getHoursPercentage = (clientItemTime, hoursList, columnValue, timeFrameParam) => {
        timeFrameParam = timeFrameParam ? timeFrameParam : timeFrame;

        const specificHoursList = getEspecificDateTimeHours(hoursList, columnValue, timeFrameParam);

        let totalColumnHoursSum = { hours: 0, minutes: 0 };
        specificHoursList.forEach((specificHoursListElement) => {
            totalColumnHoursSum = sumTwoHHMM(specificHoursListElement?.time, totalColumnHoursSum);
        });

        const percentage = (toMinutes(clientItemTime) * 100) / toMinutes(totalColumnHoursSum);

        return percentage?.toFixed(0);
    };

    const getHoursPercentageProgressBar = (clientItemTime, hoursList, timeFrameParam) => {
        timeFrameParam = timeFrameParam ? timeFrameParam : timeFrame;

        // const specificHoursList = getEspecificDateTimeHours(hoursList, columnValue, timeFrameParam);
        const specificHoursList = hoursList;

        let totalColumnHoursSum = { hours: 0, minutes: 0 };
        specificHoursList.forEach((specificHoursListElement) => {
            totalColumnHoursSum = sumTwoHHMM(specificHoursListElement?.time, totalColumnHoursSum);
        });

        const percentage = (toMinutes(clientItemTime) * 100) / toMinutes(totalColumnHoursSum);

        return percentage?.toFixed(0);
    };

    const usersTableHeader = (
        <div className="grid">
            <div className="col-12 md:col-6 xl:col-2">
                <div className="flex align-content-end flex-wrap w-full h-full">
                    <div className="flex align-items-end justify-content-center w-full h-full">
                        <Dropdown
                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                            id="search-hours-time-dowpdown"
                            name="search-hours-time-dowpdown"
                            dataKey="type"
                            value={getCapacityTimeFrameObject(timeFrameOption)}
                            options={CAPACITY_TIME_FRAME_VALUES}
                            optionLabel={(item) => {
                                return t(item?.label);
                            }}
                            onChange={(e) => onTimeFrameOptionChange(e?.value?.type)}
                            className="w-full"
                        />
                    </div>
                </div>
            </div>
            <div className="col-12 md:col-6 xl:col-3">
                <div className="flex flex-row flex-wrap h-full min-w-full">
                    <div className="flex align-content-end flex-wrap min-w-full">
                        <div className="flex align-items-center justify-content-center custom-calendar-box min-w-full">
                            <div className="flex align-items-center justify-content-center h-full w-2">
                                <i className="pi pi-angle-left pl-3 pr-3 cursor-pointer" onClick={() => onTimeFrameButtonClick("previous")} />
                            </div>
                            <div className="flex align-items-center align-content-end justify-content-center w-8">
                                <div className="flex align-content-end flex-wrap w-full h-full">
                                    <div className="flex align-items-end justify-content-start w-full h-full">
                                        <Calendar
                                            value={timeFrame}
                                            onChange={(e) => onTimeFrameChange(e.value)}
                                            selectionMode="range"
                                            readOnlyInput
                                            locale={i18n?.language?.split("-")[0]}
                                            className="w-full custom-single-line-calendar"
                                            disabledDays={[0, 6]}
                                            view={timeFrameOption === "weeks" ? "date" : timeFrameOption === "months" ? "month" : "year"}
                                            formatDateTime={(item) => (timeFrameOption === "weeks" ? parseDateToMonthDay(item, i18n.language) : timeFrameOption === "months" ? parseDateToMonthName(item, i18n.language) : parseDateToYearName(item, i18n.language))}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex align-items-center justify-content-center h-full w-2">
                                <i className="pi pi-angle-right pl-3 pr-3 cursor-pointer" onClick={() => onTimeFrameButtonClick("next")} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 xl:col-7">
                <form id="search-team-form" onSubmit={formik.handleSubmit} className="min-w-full">
                    <small className="pl-2 mousee-text-small font-weight-regular">{t("label.use_upper_and_lower_case")}</small>
                    <span className="p-input-icon-right min-w-full">
                        <i className="pi pi-search" />
                        <InputText
                            value={formik.values.text}
                            className="w-full xl:min-w-full"
                            onChange={(e) => {
                                formik.setFieldValue("text", e.target.value);
                                if (e.target.value === "") {
                                    formik.handleSubmit();
                                }
                            }}
                            placeholder={t("label.search_user")}
                        />
                    </span>
                </form>
            </div>
        </div>
    );

    const clientsProjectsTableTemplate = (data) => {
        return (
            <div className="pl-5 pr-5 pt-1">
                <div className="card p-0" style={{ overflow: "hidden" }}>
                    <DataTable
                        dataKey="rowId"
                        value={data.hours_data}
                        onRowClick={(e) => {
                            window.open(`/projects/${e?.data?.client_id}/${e?.data?.project_id}/details?process_id=${e?.data?.service_id}`, "_blank", "rel=noopener noreferrer");
                        }}
                        loading={loadingSublist && activeUserId === data.id}
                        emptyMessage={t("label.no_items_to_display")}
                        rowClassName="cursor-pointer"
                    >
                        <Column
                            header={tableHeaderTemplate(null, t("label.client"))}
                            body={(item) => <label className="mousee-text font-x-small font-weight-regular">{item?.client_name}</label>}
                            style={{ width: "30%", minWidth: "20rem" }}
                            headerClassName="mousee-text font-x-small font-weight-semibold"
                        />
                        <Column
                            header={tableHeaderTemplate(null, t("label.project"))}
                            body={(item) => <label className="mousee-text font-x-small font-weight-regular">{item?.project_name}</label>}
                            style={{ width: "30%", minWidth: "20rem" }}
                            headerClassName="mousee-text font-x-small font-weight-semibold"
                        />
                        <Column
                            header={tableHeaderTemplate(null, t("label.service"))}
                            body={(item) => <label className="mousee-text font-x-small font-weight-regular">{item?.service_name}</label>}
                            style={{ width: "30%", minWidth: "20rem" }}
                            headerClassName="mousee-text font-x-small font-weight-semibold"
                        />
                        <Column
                            header={tableHeaderTemplate(null, t("label.incurred_time"))}
                            body={(item) => <label className="mousee-text font-x-small font-weight-regular">{parseTimeV2(item?.incurred_time)}</label>}
                            style={{ width: "30%", minWidth: "15rem" }}
                            headerClassName="mousee-text font-x-small font-weight-semibold"
                        />
                        <Column
                            header={tableHeaderTemplate(null, t("label.percentage"))}
                            body={(item) => <label className="mousee-text font-x-small font-weight-regular">{`${item?.percentage}%`}</label>}
                            style={{ width: "5%", minWidth: "10rem" }}
                            headerClassName="mousee-text font-x-small font-weight-semibold"
                        />
                    </DataTable>
                </div>
            </div>
        );
    };

    const getDifferentProjectServicesData = (hoursDataParam) => {
        let exclusiveHourProjectService = {};

        for (let i = 0; i < hoursDataParam?.length; i++) {
            const hoursDataParamItem = hoursDataParam[i];

            // const composeClientProjectServiceId = `${hoursDataParamItem?.client_id}#${hoursDataParamItem?.project_id}#${hoursDataParamItem?.process_id}`

            if (hoursDataParamItem?.client_id in exclusiveHourProjectService) {
                if (hoursDataParamItem?.project_id in exclusiveHourProjectService[hoursDataParamItem?.client_id]) {
                    if (!(hoursDataParamItem?.service_id in exclusiveHourProjectService[hoursDataParamItem?.client_id][hoursDataParamItem?.project_id])) {
                        exclusiveHourProjectService[hoursDataParamItem?.client_id][hoursDataParamItem?.project_id][hoursDataParamItem?.service_id] = true;
                    }
                } else {
                    let serviceMap = {};
                    serviceMap[hoursDataParamItem.service_id] = true;

                    let projectMap = {};
                    projectMap[hoursDataParamItem?.project_id] = serviceMap;

                    exclusiveHourProjectService[hoursDataParamItem?.client_id] = {
                        ...exclusiveHourProjectService[hoursDataParamItem?.client_id],
                        ...projectMap,
                    };
                }
            } else {
                let serviceMap = {};
                serviceMap[hoursDataParamItem.service_id] = true;

                let projectMap = {};
                projectMap[hoursDataParamItem?.project_id] = serviceMap;

                exclusiveHourProjectService[hoursDataParamItem?.client_id] = projectMap;
            }
        }

        return exclusiveHourProjectService;
    };

    const getUserData = async (userParam) => {
        let newUsers = users;

        const operation = async () => {
            setLoadingSublist(true);
            const hoursData = usersData[userParam?.id];

            const totalIncurredTimeSum = getTotalSumHoursList(usersData[userParam?.id]?.data);

            userParam.hours_data = [];

            if (toMinutes(totalIncurredTimeSum) > 0) {
                const exclusiveHourProjectService = getDifferentProjectServicesData(hoursData?.data);

                let userDataCalls = [];

                // For Loop for Clients
                for (const clientsMapKey in exclusiveHourProjectService) {
                    userDataCalls.push(clientService.getClient(clientsMapKey));

                    // For Loop for Projects
                    for (const projectsMapKey in exclusiveHourProjectService[clientsMapKey]) {
                        userDataCalls.push(projectService.getProject(clientsMapKey, projectsMapKey));

                        // For Loop for Services
                        for (const servicesMapKey in exclusiveHourProjectService[clientsMapKey][projectsMapKey]) {
                            userDataCalls.push(serviceService.getService(clientsMapKey, projectsMapKey, servicesMapKey));
                        }
                    }
                }

                const userData = await Promise.all(userDataCalls);

                // For Loop for Clients
                for (const clientsMapKey in exclusiveHourProjectService) {
                    const clientData = userData?.filter((item) => item?.id === clientsMapKey);
                    if (clientData?.length > 0) {
                        const clientDataObject = clientData[0];

                        // For Loop for Projects
                        for (const projectsMapKey in exclusiveHourProjectService[clientsMapKey]) {
                            const projectData = userData?.filter((item) => item?.id === `${clientsMapKey}#${projectsMapKey}`);
                            if (projectData?.length > 0) {
                                const projectDataObject = projectData[0];

                                // For Loop for Services
                                for (const servicesMapKey in exclusiveHourProjectService[clientsMapKey][projectsMapKey]) {
                                    const serviceData = userData?.filter((item) => item?.client_id === clientsMapKey && item?.project_id === projectsMapKey && item?.id === servicesMapKey);
                                    if (serviceData?.length > 0) {
                                        const serviceDataObject = serviceData[0];

                                        let totalServiceTime = { hours: 0, minutes: 0 };
                                        hoursData?.data.forEach((hoursDataItem) => {
                                            if (hoursDataItem?.client_id === clientsMapKey && hoursDataItem?.project_id === projectsMapKey && hoursDataItem?.service_id === servicesMapKey) {
                                                totalServiceTime = sumTwoHHMM(totalServiceTime, hoursDataItem?.time);
                                            }
                                        });

                                        let totalServiceTimeMinutes = toMinutes(totalServiceTime);

                                        userParam.hours_data.push({
                                            rowId: `${clientDataObject?.id}#${projectDataObject?.id}#${serviceDataObject?.id}`,
                                            client_id: clientDataObject?.id,
                                            project_id: projectDataObject?.id?.split("#")[1],
                                            service_id: serviceDataObject?.id,
                                            client_name: clientDataObject?.name,
                                            project_name: projectDataObject?.name,
                                            service_name: serviceDataObject?.name,
                                            incurred_time: totalServiceTime,
                                            percentage: Math.floor((totalServiceTimeMinutes * 100) / toMinutes(totalIncurredTimeSum)),
                                        });
                                    }
                                }
                            }
                        }
                    }
                }

                const currentIndex = newUsers.findIndex((item) => item?.id === userParam?.id);

                newUsers[currentIndex] = {
                    ...userParam,
                    retrieved: true,
                };

                setUsers(newUsers);
            }

            setLoadingSublist(false);
            // setLoading(false);
            setActiveUserId(undefined);
        };

        await operation();

        return userParam;
    };

    const expandSpecificRow = async (e) => {
        let _expandedRows = { ...expandedRows };

        if (_expandedRows[e.data.id]) {
            delete _expandedRows[e.data.id];
        } else {
            if (!e.data.retrieved) {
                // setLoading(true);
                setLoadingSublist(true);
                setActiveUserId(e.data.id);
                e.data = await getUserData(e.data);
                if (_expandedRows.length > 0) {
                    _expandedRows[e.data.id] = e.data;
                } else {
                    _expandedRows = {};
                    _expandedRows[e.data.id] = e.data;
                }
                setLoading(false);
            } else {
                if (_expandedRows.length > 0) {
                    _expandedRows[e.data.id] = e.data;
                } else {
                    _expandedRows = {};
                    _expandedRows[e.data.id] = e.data;
                }
            }
        }

        setExpandedRows(_expandedRows);
    };

    return checkComponentPermissions(
        <div className="grid page-layout">
            <Toast ref={toast} />
            <ConfirmDialog />
            {/* <div className="xl:col-offset-1"></div> */}
            <div className="col-12 xl:col-12">
                <div className="grid">
                    <div className="col-12 p-0">
                        <div className="flex justify-content-start md:justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <div className="grid p-0">
                                    <div className="col-12 p-0">
                                        <label className="mousee-text font-large font-weight-semibold">{t("label.capacity")}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="flex align-items-center justify-content-end w-full md:w-8 xl:w-5 pt-3 md:pt-0">
                                <div className="grid min-w-full">
                                    <div className="col-12 md:col-3">
                                        <div className="grid">
                                            <div className="col-12 pb-0">
                                                <label className="mousee-text font-small font-weight-regular">{t("label.incurred_time")}</label>
                                            </div>
                                            <div className="col-12 pt-0">
                                                {someUsersDataLoading(loadingUsersData) ? (
                                                    <Skeleton className="text-center" width="6rem" height="2rem"></Skeleton>
                                                ) : (
                                                    <label className="mousee-text font-medium font-weight-regular">{parseTimeV2(calculateTotalIncurredTime(usersData, timeFrameOption, timeFrame))}</label>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 md:col-3">
                                        <div className="grid">
                                            <div className="col-12 pb-0">
                                                <label className="mousee-text font-small font-weight-regular">{t("label.total_capacity")}</label>
                                            </div>
                                            <div className="col-12 pt-0">
                                                <label className="mousee-text font-medium font-weight-regular">{parseTimeV2(calculateTotalCapacity(users, timeFrameOption, timeFrame))}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 md:col-6 md:pt-5">
                                        <ProgressBar showValue={false} value={(toMinutes(calculateTotalIncurredTime(usersData)) * 100) / toMinutes(calculateTotalCapacity(users, timeFrameOption, timeFrame))} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* {dynamicColumns && users
                        ? dynamicColumns?.map((columnValue) => {
                              return users?.map((userValue, userIndex) => {
                                  return (
                                      <Tooltip
                                          position="top"
                                          target={`.${columnValue.replaceAll(" ", "").trim() + userValue?.email.replaceAll("@", "").replaceAll(".", "")}`}
                                          style={{ width: "20rem" }}
                                          onShow={() => {
                                              const rowColumnId = `${columnValue.replaceAll(" ", "").trim() + userValue?.email.replaceAll("@", "").replaceAll(".", "")}`;

                                              if (!(rowColumnId in usersTooltipDataLoading)) {
                                                  fetchUserTooltipData(rowColumnId, columnValue, timeFrame, usersData[userValue?.id]?.data, userValue?.id);
                                              }
                                          }}
                                      >
                                          {usersTooltipDataLoading[`${columnValue.replaceAll(" ", "").trim() + userValue?.email.replaceAll("@", "").replaceAll(".", "")}`] === true ? (
                                              <div className="flex align-items-center">
                                                  <i className="pi pi-spin pi-spinner ml-2" style={{ fontSize: "1rem" }}></i>
                                                  <label className="mousee-text font-x-small font-weight-regular ml-2" style={{ color: "white" }}>
                                                      {t("label.loading")}
                                                  </label>
                                              </div>
                                          ) : usersTooltipData[`${columnValue.replaceAll(" ", "").trim() + userValue?.email.replaceAll("@", "").replaceAll(".", "")}`] ? (
                                              <div className="grid pl-5 pt-3">
                                                  <div className="col-12 p-0 pb-2">
                                                      <div className="flex flex-row flex-wrap">
                                                          <div className="flex align-items-center justify-content-center">
                                                              <label className="mousee-text font-small font-weight-bold" style={{ color: "white" }}>{`${t("label.total_capacity")}: `}</label>
                                                          </div>
                                                          <div className="flex align-items-center justify-content-center pl-2">
                                                              <label className="mousee-text font-small font-weight-regular" style={{ color: "white" }}>
                                                                  {parseTimeV2(getUserCapacityPerColumn(userValue, timeFrameOption, timeFrame))}
                                                              </label>
                                                          </div>
                                                      </div>
                                                  </div>

                                                  {usersTooltipData[`${columnValue.replaceAll(" ", "").trim() + userValue?.email.replaceAll("@", "").replaceAll(".", "")}`]?.map((clientItem) => {
                                                      return (
                                                          <div className="col-12 p-0 pl-2">
                                                              <div className="grid p-0">
                                                                  <div className="col-12 p-0 pt-3">
                                                                      <div className="flex justify-content-start flex-wrap">
                                                                          <div className="flex align-items-center justify-content-center">
                                                                              <label className="mousee-text font-x-small font-weight-semibold" style={{ color: "white" }}>{`${clientItem?.client?.name}: `}</label>
                                                                          </div>
                                                                          <div className="flex align-items-center justify-content-center pl-2">
                                                                              <label className="mousee-text font-x-small font-weight-light" style={{ color: "white" }}>
                                                                                  {parseTimeV2(clientItem?.time)}
                                                                              </label>
                                                                          </div>
                                                                          <div className="flex align-items-center justify-content-center pl-2">
                                                                              <label className="mousee-text font-x-small font-weight-light" style={{ color: "white" }}>
                                                                                  {`(${getHoursPercentage(clientItem?.time, usersData[clientItem?.user_id]?.data, columnValue, timeFrame, clientItem?.user_id)}%)`}
                                                                              </label>
                                                                          </div>
                                                                      </div>
                                                                  </div>

                                                                  {usersTooltipDataProjects[`${columnValue.replaceAll(" ", "").trim() + userValue?.email.replaceAll("@", "").replaceAll(".", "")}`]?.map((projectItem) => {
                                                                      if (projectItem?.project?.client_id === clientItem.client?.id) {
                                                                          return (
                                                                              <div className="grid pl-3 pt-2">
                                                                                  <div className="col-12 p-0">
                                                                                      <div className="flex justify-content-start flex-wrap">
                                                                                          <div className="flex align-items-center justify-content-center">
                                                                                              <label className="mousee-text-small font-xx-small font-weight-light" style={{ color: "white" }}>{`${projectItem?.project?.name}`}</label>
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>

                                                                                  {usersTooltipDataServices[`${columnValue.replaceAll(" ", "").trim() + userValue?.email.replaceAll("@", "").replaceAll(".", "")}`]?.map((serviceItem) => {
                                                                                      if (serviceItem?.service?.client_id === clientItem.client?.id && serviceItem?.service?.project_id === projectItem.project?.id?.split("#")[1]) {
                                                                                          return (
                                                                                              <div className="col-12 p-0 pl-2">
                                                                                                  <div className="flex justify-content-start flex-wrap">
                                                                                                      <div className="flex align-items-center justify-content-center">
                                                                                                          <label className="mousee-text-small font-xx-small font-weight-light" style={{ color: "white" }}>{`${serviceItem?.service?.name}`}</label>
                                                                                                      </div>
                                                                                                  </div>
                                                                                              </div>
                                                                                          );
                                                                                      }
                                                                                  })}
                                                                              </div>
                                                                          );
                                                                      }
                                                                  })}
                                                              </div>
                                                          </div>
                                                      );
                                                  })}
                                              </div>
                                          ) : (
                                              <label className="mousee-text font-x-small font-weight-regular" style={{ color: "white" }}>
                                                  {t("label.no_items_to_display")}
                                              </label>
                                          )}
                                      </Tooltip>
                                  );
                              });
                          })
                        : null}

                    {users?.map((userValue) => {
                        return (
                            <Tooltip
                                position="top"
                                target={`.${userValue?.email.replaceAll("@", "").replaceAll(".", "")}`}
                                style={{ width: "20rem" }}
                                onShow={() => {
                                    const rowId = `${userValue?.email.replaceAll("@", "").replaceAll(".", "")}`;

                                    if (!(rowId in usersProgressBarTooltipDataLoading)) {
                                        fetchUserProgressBarTooltipData(rowId, timeFrame, usersData[userValue?.id]?.data, userValue);
                                    }
                                }}
                            >
                                {usersProgressBarTooltipDataLoading[`${userValue?.email.replaceAll("@", "").replaceAll(".", "")}`] === true ? (
                                    <div className="flex align-items-center">
                                        <i className="pi pi-spin pi-spinner ml-2" style={{ fontSize: "1rem" }}></i>
                                        <label className="mousee-text font-x-small font-weight-regular ml-2" style={{ color: "white" }}>
                                            {t("label.loading")}
                                        </label>
                                    </div>
                                ) : usersProgressBarTooltipData[`${userValue?.email.replaceAll("@", "").replaceAll(".", "")}`] ? (
                                    <div className="grid pl-5 pt-3">
                                        <div className="col-12 p-0 pb-2">
                                            <div className="flex flex-row flex-wrap">
                                                <div className="flex align-items-center justify-content-center">
                                                    <label className="mousee-text font-small font-weight-bold" style={{ color: "white" }}>{`${t("label.total_capacity")}: `}</label>
                                                </div>
                                                <div className="flex align-items-center justify-content-center pl-2">
                                                    <label className="mousee-text font-small font-weight-regular" style={{ color: "white" }}>
                                                        {parseTimeV2(getUserCapacityProgressBar(userValue, timeFrameOption, timeFrame))}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {usersProgressBarTooltipData[`${userValue?.email.replaceAll("@", "").replaceAll(".", "")}`]?.map((clientItem) => {
                                            return (
                                                <div className="col-12 p-0 pl-2">
                                                    <div className="grid p-0">
                                                        <div className="col-12 p-0 pt-3">
                                                            <div className="flex justify-content-start flex-wrap">
                                                                <div className="flex align-items-center justify-content-center">
                                                                    <label className="mousee-text font-x-small font-weight-semibold" style={{ color: "white" }}>{`${clientItem?.client?.name}: `}</label>
                                                                </div>
                                                                <div className="flex align-items-center justify-content-center pl-2">
                                                                    <label className="mousee-text font-x-small font-weight-light" style={{ color: "white" }}>
                                                                        {parseTimeV2(clientItem?.time)}
                                                                    </label>
                                                                </div>
                                                                <div className="flex align-items-center justify-content-center pl-2">
                                                                    <label className="mousee-text font-x-small font-weight-light" style={{ color: "white" }}>
                                                                        {`(${getHoursPercentageProgressBar(clientItem?.time, usersData[clientItem?.user_id]?.data, timeFrame, clientItem?.user_id)}%)`}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {usersProgressBarTooltipDataProjects[`${userValue?.email.replaceAll("@", "").replaceAll(".", "")}`]?.map((projectItem) => {
                                                            if (projectItem?.project?.client_id === clientItem.client?.id) {
                                                                return (
                                                                    <div className="grid pl-3 pt-2">
                                                                        <div className="col-12 p-0">
                                                                            <div className="flex justify-content-start flex-wrap">
                                                                                <div className="flex align-items-center justify-content-center">
                                                                                    <label className="mousee-text-small font-xx-small font-weight-light" style={{ color: "white" }}>{`${projectItem?.project?.name}`}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        {usersProgressBarTooltipDataServices[`${userValue?.email.replaceAll("@", "").replaceAll(".", "")}`]?.map((serviceItem) => {
                                                                            if (serviceItem?.service?.client_id === clientItem.client?.id && serviceItem?.service?.project_id === projectItem.project?.id?.split("#")[1]) {
                                                                                return (
                                                                                    <div className="col-12 p-0 pl-2">
                                                                                        <div className="flex justify-content-start flex-wrap">
                                                                                            <div className="flex align-items-center justify-content-center">
                                                                                                <label className="mousee-text-small font-xx-small font-weight-light" style={{ color: "white" }}>{`${serviceItem?.service?.name}`}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        })}
                                                                    </div>
                                                                );
                                                            }
                                                        })}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <label className="mousee-text font-x-small font-weight-regular" style={{ color: "white" }}>
                                        {t("label.no_items_to_display")}
                                    </label>
                                )}
                            </Tooltip>
                        );
                    })} */}

                    <div className="col-12 p-0 pt-5">
                        <div className="card p-0" style={{ overflow: "hidden" }}>
                            <DataTable
                                key="team-users-capacity-table"
                                selectionMode="single"
                                dataKey="id"
                                emptyMessage={t("label.no_items_to_display")}
                                loading={loadingUsers}
                                value={users?.slice(usersActiveIndex * usersRowsNumber, usersActiveIndex * usersRowsNumber + usersRowsNumber)}
                                responsiveLayout="scroll"
                                stripedRows
                                header={usersTableHeader}
                                first={usersTableFirst}
                                scrollable
                                rowHover={false}
                                onRowClick={(e) => {
                                    expandSpecificRow(e);
                                }}
                                paginator={false}
                                expandedRows={expandedRows}
                                onRowToggle={(e) => {
                                    setExpandedRows(e.data);
                                }}
                                rowExpansionTemplate={clientsProjectsTableTemplate}
                                onRowExpand={(e) => {
                                    if (!e.data.retrieved) {
                                        // setLoading(true);
                                        setLoadingSublist(true);
                                        setActiveUserId(e.data.id);
                                        e.data = getUserData(e.data);
                                    }
                                }}
                                footer={<CustomTablePaginator activeIndex={usersActiveIndex} onPageChange={onTablePageChange} first={usersTableFirst} rowsNumber={usersRowsNumber} keys={usersTableKeys} lastKey={queryUsersResponse?.last_key} />}
                            >
                                <Column frozen={isDesktop()} bodyStyle={{ background: "white" }} expander={true} style={{ width: "5rem" }} />

                                <Column frozen={isDesktop()} header={tableHeaderTemplate(null, t("label.name"))} style={{ width: "20%", minWidth: "20rem" }} body={(item) => userItemTemplate(item)} headerStyle={{}} bodyStyle={{ background: "white" }} />
                                {/* <Column frozen header={tableHeaderTemplate(null, null)} style={{ width: "5%", minWidth: "1rem" }} headerStyle={{ background: "white" }} bodyStyle={{ background: "white" }} /> */}

                                {dynamicColumns?.map((columnValue) => {
                                    return (
                                        <Column
                                            header={tableHeaderTemplate(null, columnValue)}
                                            style={{ width: "10%", minWidth: "10rem" }}
                                            alignHeader="center"
                                            body={(item, options) => {
                                                if (item?.id && loadingUsersData[item?.id]) {
                                                    return <Skeleton className="text-center" width="7rem" height="3rem"></Skeleton>;
                                                } else {
                                                    if (usersData[item?.id] && objectHasKeys(usersData[item?.id]) && usersData[item?.id]?.data?.length > 0) {
                                                        const parsedTime = parseTimeV2(getEspecificDateTime(usersData[item?.id]?.data, columnValue, timeFrame));
                                                        const columnId = `${columnValue.replaceAll(" ", "").trim() + item?.email.replaceAll("@", "").replaceAll(".", "")}`;
                                                        return (
                                                            <div className={`custom-incurred-hour-box ${parsedTime !== "0h" ? columnId : ""}`}>
                                                                <label className="mousee-text font-x-small font-weight-light">{parsedTime !== "0h" ? parsedTime : "-"}</label>
                                                            </div>
                                                        );
                                                    } else {
                                                        return (
                                                            <div className="custom-incurred-hour-box">
                                                                <label className="mousee-text font-x-small font-weight-light">-</label>
                                                            </div>
                                                        );
                                                    }
                                                }
                                            }}
                                        />
                                    );
                                })}

                                {/* <Column
                                    frozen={isDesktop()}
                                    alignFrozen="right"
                                    headerStyle={{ width: "5%", minWidth: "5rem", textAlign: "center" }}
                                    bodyStyle={{ textAlign: "center", overflow: "visible", background: "white" }}
                                    body={(item, options) => {
                                        return (
                                            <Button
                                                icon={<Icon icon="solar:eye-bold-duotone" data-pr-tooltip={t("label.see_details")} style={{ fontSize: "24px" }} />}
                                                className="p-button-text p-button-secondary"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                            />
                                        );
                                    }}
                                /> */}

                                <Column
                                    frozen={isDesktop()}
                                    alignFrozen="right"
                                    headerStyle={{}}
                                    bodyStyle={{ background: "white" }}
                                    header={tableHeaderTemplate(null, t("label.incurred_time"))}
                                    style={{ width: "20%", minWidth: "19rem" }}
                                    body={(item) => {
                                        if (item?.id && someUsersDataLoading(loadingUsersData)) {
                                            return <Skeleton height="2rem"></Skeleton>;
                                        } else {
                                            if (usersData[item?.id] && objectHasKeys(usersData[item?.id]) && usersData[item?.id]?.data?.length > 0) {
                                                const totalIncurredTimeSum = getTotalSumHoursList(usersData[item?.id]?.data);
                                                const incurredTimeProgress = getTotalIncurredTimeProgressBar(totalIncurredTimeSum);
                                                const rowId = `${item?.email.replaceAll("@", "").replaceAll(".", "")}`;
                                                return (
                                                    <div className="grid">
                                                        <div className={`${totalIncurredTimeSum?.minutes ? "col-2" : "col-2"}`}>
                                                            <label className="mousee-text font-x-small font-weight-bold">{parseTimeV2(totalIncurredTimeSum)}</label>
                                                        </div>
                                                        <div className={`${totalIncurredTimeSum?.minutes ? "col-10" : "col-10"}`}>
                                                            <ProgressBar className={`${rowId}`} showValue={false} value={incurredTimeProgress} style={{}} color={incurredTimeProgress === 100 ? "var(--success-color)" : incurredTimeProgress < 100 ? "var(--primary-color)" : "var(--danger-color)"} />
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div className="grid">
                                                        <div className="col-2">
                                                            <label className="mousee-text font-x-small font-weight-bold">{parseTimeV2({ hours: 0, minutes: 0 })}</label>
                                                        </div>
                                                        <div className="col-10">
                                                            <ProgressBar showValue={false} value={0} />
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }
                                    }}
                                />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="xl:col-offset-1"></div> */}
        </div>,
        ["view_users"],
        null,
        <Forbidden />
    );
};
export default Capacity;
