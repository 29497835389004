import { API } from "aws-amplify";

const API_NAME = "InvoicesAPI";
const BASE_PATH = "/invoice";

export default class InvoiceService {
    async queryInvoices(queryStringParameters = {}, data) {
        return API.post(API_NAME, `${BASE_PATH}/query`, { queryStringParameters: queryStringParameters, body: data });
    }

    async queryInvoicesResume() {
        return API.get(API_NAME, `${BASE_PATH}/query/resume`);
    }

    async createInvoice(data) {
        return API.post(API_NAME, BASE_PATH, { body: data });
    }

    async updateInvoice(data, invoice_id) {
        return API.put(API_NAME, `${BASE_PATH}/${invoice_id}`, { body: data });
    }

    async deleteInvoice(invoice_id) {
        return API.del(API_NAME, `${BASE_PATH}/${invoice_id}`);
    }

    async getInvoice(invoice_id) {
        return API.get(API_NAME, `${BASE_PATH}/${invoice_id}`);
    }
}
