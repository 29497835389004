import { Line, StyleSheet, Svg, Text, View } from "@react-pdf/renderer";
import React, { Fragment } from "react";

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "center",
        // minWidth: "100%"
    },

    // qty: {
    //     width: "10%",
    //     maxWidth: "10%",
    //     fontSize: 10,
    //     textAlign: "center",
    //     fontFamily: "Roboto-Regular",
    //     color: "#4B4566",
    // },
    // price: {
    //     width: "10%",
    //     maxWidth: "10%",
    //     fontSize: 10,
    //     textAlign: "center",
    //     fontFamily: "Roboto-Regular",
    //     color: "#4B4566",
    // },
    // taxes: {
    //     width: "10%",
    //     maxWidth: "10%",
    //     fontSize: 10,
    //     textAlign: "center",
    //     fontFamily: "Roboto-Regular",
    //     color: "#4B4566",
    // },
    // total: {
    //     width: "10%",
    //     maxWidth: "10%",
    //     fontSize: 10,
    //     textAlign: "right",
    //     fontFamily: "Roboto-Regular",
    //     color: "#4B4566",
    // },
    classOne: {
        width: "10%",
        maxWidth: "10%",
        fontSize: 10,
        textAlign: "center",
        fontFamily: "Roboto-Regular",
        color: "#4B4566",
    },
    description: {
        width: "60%",
        // maxWidth: "60%",
        fontSize: 10,
        fontFamily: "Roboto-Regular",
        color: "#4B4566",
    },
});

const TableRow = ({ items, header, footer }) => {
    let rows = [
        <View style={{ ...styles.row, paddingBottom: 15 }} key={header.sr.toString()}>
            <Text style={{ ...styles.description, fontFamily: "Roboto-Bold" /*, color: "#6165ff"*/ }}>{header.fieldOne}</Text>
            <Text style={{ ...styles.classOne, fontFamily: "Roboto-Bold" /*, color: "#6165ff"*/ }}>{header.fieldTwo}</Text>
            <Text style={{ ...styles.classOne, fontFamily: "Roboto-Bold" /*, color: "#6165ff"*/ }}>{header.fieldThree}</Text>
            <Text style={{ ...styles.classOne, fontFamily: "Roboto-Bold" /*, color: "#6165ff"*/ }}>{header.fieldFour}</Text>
            <Text style={{ ...styles.classOne, textAlign: "right", fontFamily: "Roboto-Bold" /*, color: "#6165ff"*/ }}>{header.fieldFive}</Text>
        </View>,
    ];

    items.forEach((item) => {
        rows.push(
            <View style={styles.row} key={item.sr.toString()}>
                <View style={{ flexDirection: "column", width: "60%" }}>
                    <Text style={{ ...styles.description, fontFamily: "Roboto-Medium", textAlign: "left", width: "100%" }}>{item.title}</Text>
                    <Text style={{ ...styles.description, width: "100%" }}>{item.description}</Text>
                </View>
                <Text style={styles.classOne}>{item.qty}</Text>
                <Text style={styles.classOne}>{item.price}</Text>
                <Text style={styles.classOne}>{item.taxes}</Text>
                <Text style={{ ...styles.classOne, textAlign: "right" }}>{item.total}</Text>
            </View>
        );

        rows.push(
            <View style={styles.row}>
                <Svg height="5" width="100%" style={{ marginBottom: 10 }}>
                    <Line x1="0" y1="5" x2="50000" y2="5" strokeWidth={1} stroke="#E0E0E0" />
                </Svg>
            </View>
        );
    });

    footer?.map(item => {
        rows.push(
            <View style={{...styles.row, marginBottom: 5}} key={item.sr.toString()}>
                <View style={{  width: "60%" }}>
                    {/* <Text style={{ ...styles.description, fontFamily: "Roboto-Medium", textAlign: "left", width: "100%" }}>{item.title}</Text> */}
                    {/* <Text style={{ ...styles.description, width: "100%" }}>{item.description}</Text> */}
                </View>
                {/* <Text style={styles.classOne}>{}</Text> */}
                <Text style={{...styles.classOne, textAlign: "right", width: "20%", maxWidth: "20%", fontFamily: item?.bold ? "Roboto-Medium" : "Roboto-Regular"}}>{item?.title}</Text>
                {/* <Text style={styles.classOne}>{}</Text> */}
                <Text style={{ ...styles.classOne, textAlign: "right", width: "20%", maxWidth: "20%", fontFamily: item?.bold ? "Roboto-Medium" : "Roboto-Regular" }}>{item?.value}</Text>
            </View>
        );
        if (item?.divider) {
            rows.push(
                <View style={styles.row}>
                    <Svg height="5" width="100%" style={{ marginBottom: 10 }}>
                        <Line x1="370" y1="5" x2="5000" y2="5" strokeWidth={item?.primaryDivider ? 2 : 1} stroke={item?.primaryDivider ? "#6165ff" : "#E0E0E0"} />
                    </Svg>
                </View>
            );
        }
    })

    

    return <Fragment>{rows}</Fragment>;
};

export default TableRow;
