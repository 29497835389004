import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import CategoriesList from "../../../../components/Templates/CategoriesList";
import TemplateCard from "../../../../components/Templates/TemplateCard";
import "./TemplatesGalleryDialog.scss";

import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { objectHasKeys } from "../../../../Utils";
import maximizeIcon from "../../../../assets/icons/maximize-icon.svg";
import minimizeIcon from "../../../../assets/icons/minimize-icon.svg";
import CategoryService from "../../../../services/CategoryService/CategoryService";
import ProcessService from "../../../../services/ProcessService/ProcessService";
import TemplateService from "../../../../services/TemplateService/TemplateService";
import TemplateDetails from "../TemplateDetails";

const TemplatesGalleryDialog = ({ visible, setVisible }) => {
    const navigate = useNavigate();

    const toast = useRef(null);

    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [creatingProcess, setCreatingProcess] = useState(false);
    const [loadingCategories, setLoadingCategories] = useState(false);
    const [loadingTemplates, setLoadingTemplates] = useState(false);

    const categoryService = new CategoryService();
    const templateService = new TemplateService();
    const processService = new ProcessService();

    const [activeTemplate, setActiveTemplate] = useState();
    const [activeCategory, setActiveCategory] = useState();

    const [categories, setCategories] = useState([]);
    const [queryCategoriesResponse, setQueryCategoriesResponse] = useState({});
    const [categoriesRowsNumber, setCategoriesRowsNumber] = useState(20);

    const [templates, setTemplates] = useState([]);
    const [queryTemplatesResponse, setQueryTemplatesResponse] = useState({});
    const [templatesRowsNumber, setTemplatesRowsNumber] = useState(25);

    const [templateVisibility, setTemplateVisibility] = useState("public");

    const formik = useFormik({
        initialValues: {
            text: "",
        },
        onSubmit: (data) => {
            refreshTemplates(templateVisibility, undefined, data.text);
            setActiveCategory(undefined);
        },
    });

    useEffect(() => {
        refreshCategories();
        refreshTemplates();
    }, []);

    const refreshCategories = (visibility) => {
        setLoadingCategories(true);
        categoryService
            .queryCategories({ limit: categoriesRowsNumber }, undefined, "template", "public")
            .then((data) => {
                setQueryCategoriesResponse(data);
                setCategories(data?.data);
                setLoadingCategories(false);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    };

    const refreshTemplates = (visibility, categoryId, name) => {
        setLoadingTemplates(true);

        let queryParameters = {
            limit: templatesRowsNumber,
        };

        if (categoryId) {
            queryParameters = {
                ...queryParameters,
                category_id: categoryId,
            };
        } else if (name && name.length > 0) {
            queryParameters = {
                ...queryParameters,
                name: name,
            };
        }

        let lastKey = undefined;

        if (queryTemplatesResponse && queryTemplatesResponse?.last_key && objectHasKeys(queryTemplatesResponse?.last_key)) {
            if (!queryTemplatesResponse.last_key.category_id && categoryId) {
                lastKey = undefined;
            } else if (!queryTemplatesResponse.last_key.name && name) {
                lastKey = undefined;
            } else {
                lastKey = queryTemplatesResponse?.last_key;
            }
        }

        templateService.queryTemplates(queryParameters, lastKey, visibility ? visibility : templateVisibility).then((data) => {
            setQueryTemplatesResponse(data);
            setTemplates(data?.data);
            setLoadingTemplates(false);
        });
    };

    const createProcessFromTemplate = (activeTemplate) => {
        setCreatingProcess(true);
        const newProcess = {
            name: activeTemplate?.name,
            description: activeTemplate?.description,
            tags: activeTemplate?.tags,
            tasks: activeTemplate?.tasks,
        };

        processService
            .createProcess(newProcess)
            .then((data) => {
                navigate(`/processes/${data.id}`);
            })
            .catch((error) => {
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data, life: 5000 });
            })
            .finally(() => {
                setCreatingProcess(false);
            });
    };

    const onCategoryChange = (newValue) => {
        setActiveTemplate(undefined);
        setActiveCategory(newValue);
        if (!newValue) {
            refreshTemplates(templateVisibility, undefined);
        } else {
            refreshTemplates(templateVisibility, newValue?.id);
        }
    };

    return (
        <Dialog
            visible={visible}
            onHide={() => setVisible(false)}
            style={{ height: "90%", width: "90%" }}
            draggable={false}
            maximizable
            className="templates-gallery-dialog"
            maximizeIcon={<img alt={"dialog-maximize-icon"} src={maximizeIcon} className="" style={{ width: "1.5rem" }}></img>}
            minimizeIcon={<img alt={"dialog-minimize-icon"} src={minimizeIcon} className="" style={{ width: "1.5rem" }}></img>}
            header={
                <div className="flex justify-content-between flex-wrap">
                    <div className="flex align-items-center justify-content-center">
                        <label className="pl-5 mousee-text font-x-large font-weight-regular">{t("processes.page.template_gallery")}</label>
                    </div>
                </div>
            }
        >
            <div className={`grid min-h-full`}>
                <Toast ref={toast} />
                <div className="col-12 xl:col-3 pt-4 pl-7 min-h-full" style={{ background: "white" }}>
                    <div className="grid pr-7">
                        <div className="col-12 pt-0">
                            <p className="mousee-text font-x-small font-weight-light">{t("processes.page.use_our_templates_created_by_experts_to_inspire_and_shape_your_services")}</p>
                        </div>

                        <div className="col-12">
                            <form id="search-process-form" onSubmit={formik.handleSubmit} className="min-w-full">
                                <small className="pl-2 mousee-text-small font-weight-regular">{t("label.use_upper_and_lower_case")}</small>
                                <span className="p-input-icon-right min-w-full">
                                    <i className="pi pi-search" />
                                    <InputText
                                        value={formik.values.text}
                                        className="w-full xl:min-w-full"
                                        onChange={(e) => {
                                            formik.setFieldValue("text", e.target.value);
                                            if (e.target.value === "") {
                                                formik.handleSubmit();
                                            }
                                        }}
                                        placeholder={t("label.search_inputtext")}
                                    />
                                </span>
                            </form>
                        </div>

                        <div className="col-12 pt-6">
                            <label className="mousee-text font-large font-weight-light">{t("label.categories")}</label>
                        </div>

                        <div className="col-12 pt-6">
                            <CategoriesList items={categories} loading={loadingCategories} setCategory={onCategoryChange} category={activeCategory} />
                        </div>
                    </div>
                </div>

                <div className={`pl-3 pr-3 col-12 xl:col-9`} style={{ background: "var(--miflow-no-white-background)" }}>
                    <div className={`grid`}>
                        {activeTemplate ? (
                            <React.Fragment>
                                <div className={`col-12 pt-4 pr-4 xl:pr-8`}>
                                    <div className="flex justify-content-between flex-wrap">
                                        <div className="flex align-items-center justify-content-center">
                                            <Button icon={"pi pi-angle-left"} className="p-button-link" label={t("processes.page.return_to_templates")} onClick={() => setActiveTemplate(undefined)} />
                                        </div>
                                        <div className="flex align-items-center justify-content-center">
                                            <Button className="p-button" label={t("processes.page.use_template")} onClick={() => createProcessFromTemplate(activeTemplate)} loading={creatingProcess} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <TemplateDetails template={activeTemplate} />
                                </div>
                            </React.Fragment>
                        ) : (
                            <div className={`col-12 pt-5`}>
                                <BlockUI blocked={loadingTemplates} containerClassName="min-h-full" template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}>
                                    {!loadingTemplates ? (
                                        <div className="flex flex-row flex-wrap justify-content-center min-h-full">
                                            {templates.map((template, index) => {
                                                return (
                                                    <div className="flex align-items-center justify-content-center max-w-20rem p-2">
                                                        <TemplateCard template={template} setActiveTemplate={() => setActiveTemplate(template)} useTemplate={() => createProcessFromTemplate(template)} creatingProcess={creatingProcess} />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : null}
                                </BlockUI>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
export default TemplatesGalleryDialog;
