import { Avatar } from "primereact/avatar";
import { v4 as uuid } from 'uuid';
import { removeItemAll } from "../../../Utils";
import { WEEK_DAYS, getTenantWeekDayOrder } from "../../../data/Tenant";

export const containsProfile = (items, searchId) => {
    if (items) {
        let i = 0;
        let flag = false;

        while (i < items.length && !flag) {
            let activeProfileId = undefined
            if (items[i] && items[i].id && items[i].id.includes("#")) {
                activeProfileId = items[i].id.split("#")[1];    
            } else {
                activeProfileId = items[i].id
            }
            

            if (activeProfileId === searchId) {
                flag = true;
            }

            i++;
        }

        return flag;
    }
    return false;
}

export const containsRole = (items, searchId) => {
    if (items) {
        let i = 0;
        let flag = false;

        while (i < items.length && !flag) {
            // let activeProfileId = undefined
            // if (items[i] && items[i].id && items[i].id.includes("#")) {
            //     activeProfileId = items[i].id.split("#")[1];    
            // } else {
            //     activeProfileId = items[i].id
            // }
            

            if (items[i].id === searchId) {
                flag = true;
            }

            i++;
        }

        return flag;
    }
    return false;
}

export const userItemTemplate = (option, avatarSize) => {
    return (
        <div className="flex align-items-center gap-2">
            <Avatar 
                image={option?.avatar ? option?.avatar : null}
                label={!option?.avatar ? `${option?.name?.split("")[0]}${option?.surnames?.split("")[0]}` : null}
                style={!option?.avatar ? {backgroundColor: "var(--surface-300)", color: "var(--secondary-color-extra-light)"} : {}}
                // size="xlarge"
                size={avatarSize ? avatarSize : "xlarge"}
                shape="circle"
            />
            <div className="flex flex-column">
                <div className="flex align-items-center justify-content-start">
                    <label className='mousee-text font-x-small font-weight-regular pl-3'>{option?.name + " " + option?.surnames}</label>  
                </div>
                <div className="flex align-items-center justify-content-start">
                    <label className='mousee-text-small font-xx-small font-weight-regular pl-3'>{option?.email}</label>  
                </div>
            </div>
        </div>
    );
};

export const handleUsersOnScroll = (e, userService, usersRowsNumber, queryUsersResponse, setQueryUsersResponse, users, setUsers, profileFilterName, setUserFilterName, clientId) => {
    const bottom = e?.target?.scrollHeight - e?.target?.clientHeight <= e?.target?.scrollTop + 50;
    if (bottom) {

        const retrieveUsers = (lastKey) => {
            lastKey = lastKey && Object.keys(lastKey).length > 0 ? lastKey : undefined
            if(lastKey){
                delete lastKey["name"]
            }

            let queryParameters = {
                limit: usersRowsNumber
            }

            if (clientId) {
                queryParameters = {
                    ...queryParameters,
                    client_id: clientId
                }
            }
            userService.queryUsers(queryParameters/*{limit: usersRowsNumber}*/, lastKey).then(data => {
                
                if(data) {
                    setQueryUsersResponse(data)
                
                    if (data.data && data.data.length > 0) {
                        var ids = new Set(users.map(d => d.id));

                        var mergedUsers = [...users, ...data.data.filter(d => !ids.has(d.id))];
                        setUsers(mergedUsers) 
                    }
                }
            })
        }

        let lastKey = queryUsersResponse?.last_key
        if (!lastKey || (lastKey && Object.keys(lastKey).length === 0)) {

            if (users && users.length > 0) {
                const lastUser = users[users.length - 1]
                lastKey = {
                    id: lastUser.id
                }

                if (profileFilterName !== undefined) {
                    if (profileFilterName && profileFilterName.length > 0) {
                        lastKey = {
                            ...lastKey,
                            name: lastUser.name
                        }
                    }
                    retrieveUsers(lastKey)
                    if (profileFilterName === "") {
                        setUserFilterName(undefined)
                    }
                }
            }
        } else {
            retrieveUsers(lastKey)
        }
    }
}

export const filterUsers = (event, options, setUserFilterName, queryUsersResponse, setQueryUsersResponse, userService, usersRowsNumber, users, setUsers, clientId) => {    
    let _filterValue = event.target.value;
    setUserFilterName(_filterValue)

    let lastKey = undefined

    if (queryUsersResponse && queryUsersResponse.last_key && Object.keys(queryUsersResponse.last_key).length > 0) {
        if (_filterValue.trim() === "" || _filterValue.length === 0) {
            if (lastKey)
                delete lastKey["name"]
        }else {
            if (lastKey && !lastKey.name) {
                if (users && users.length > 0) {
                    lastKey = {
                        id: users[users.length-1].id,
                        name: users[users.length-1].name
                    }
                } else {
                    lastKey = {
                        ...lastKey,
                        name: ""
                    }
                }
            }
        }
    }

    let queryParameters = {
        limit: usersRowsNumber
    }

    if (clientId) {
        queryParameters = {
            ...queryParameters,
            client_id: clientId
        }
    }

    if (_filterValue) {
        queryParameters = {
            ...queryParameters,
            name: _filterValue
        }
    }

    userService.queryUsers(queryParameters,  lastKey).then(data => {
        setQueryUsersResponse(data)
        
        if (data && data.data && data.data.length > 0) {
            var ids = new Set(users.map(d => d.id));

            var mergedUsers = [...users, ...data.data.filter(d => !ids.has(d.id))];
            setUsers(mergedUsers)
        }
        options?.filterOptions?.filter(event);
    })
}

export const getUserLogoUrl = (currentUser, userId, filename) => {
    var fileExt = filename.split('.').pop();
    const unique_id = uuid();

    if (currentUser && currentUser.attributes && currentUser.attributes["custom:tenant_id"]) {
        const tenantId = currentUser.attributes["custom:tenant_id"]
        const finalPictureName = `${unique_id}.${fileExt}`

        const finalPictureUrl = `/private/${tenantId}/users/${userId}/${finalPictureName}`

        return finalPictureUrl;
    }

    return
}

export const getAbsenceById = (absenceId, absences) => {
    if (absenceId) {
        const absenceFilteredList = absences?.filter((item) => item?.id === absenceId);

        if (absenceFilteredList && absenceFilteredList?.length > 0 && absenceFilteredList[0]) {
            return absenceFilteredList[0];
        }
    }

    return undefined;
};

export const getNotAllowedWeekDays = (tenantInfoParam) => {
    let notAllowedDays = WEEK_DAYS.map((item) => item?.order);
    if (tenantInfoParam && tenantInfoParam?.absences_config && tenantInfoParam?.absences_config?.business_days && tenantInfoParam?.absences_config?.business_days?.length > 0) {
        tenantInfoParam?.absences_config?.business_days.forEach((businessDay) => {
            notAllowedDays = removeItemAll(notAllowedDays, getTenantWeekDayOrder(businessDay));
        });
    }
    return notAllowedDays;
};

export const getNotAllowedSpecificDates = (tenantInfoParam) => {
    let notAllowedDays = []
    if (tenantInfoParam && tenantInfoParam?.absences_config && tenantInfoParam?.absences_config?.holidays && tenantInfoParam?.absences_config?.holidays?.length > 0) {
        tenantInfoParam?.absences_config?.holidays.forEach((holidayDay) => {
            notAllowedDays.push(holidayDay);
        });
    }
    return notAllowedDays;
};

export const containsHour = (items, searchId) => {
    if (items) {
        let i = 0;
        let flag = false;

        while (i < items.length && !flag) {
            let activeProfileId = undefined
            if (items[i] && items[i].id && items[i].id.includes("#")) {
                activeProfileId = items[i].id.split("#")[1];    
            } else {
                activeProfileId = items[i].id
            }
            

            if (activeProfileId === searchId) {
                flag = true;
            }

            i++;
        }

        return flag;
    }
    return false;
}