import { Icon } from "@iconify/react";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import React, { createRef, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { formatDateV2 } from "../../../../Utils";
import { checkPermissions } from "../../../../data/Permissions";
import ServiceService from "../../../../services/ServiceService/ServiceService";
import TaskService from "../../../../services/TaskService/TaskService";
import "./ProjectKanban.scss";

const ProjectKanban = ({ process, setProcess, project, client_id, tasks, setTasks, setActiveTask, setShowEditTaskDialog, setLoading }) => {
    const { t, i18n } = useTranslation();

    const listMenuRef = useRef(null);
    const taskService = new TaskService();
    const serviceService = new ServiceService();
    const toast = useRef(null);
    const [activeListIndex, setActiveListIndex] = useState();

    const [addingTask, setAddingTask] = useState(false);
    const [taskName, setTaskName] = useState("");

    const [addingList, setAddingList] = useState(false);
    const [listName, setListName] = useState("");
    const [loadingNewList, setLoadingNewList] = useState(false);

    // const [dropables, setDropables] = useState();

    const listNameInputRefs = useRef([]);

    const menuItems = () => {
        // if (activeListIndex >= 0) {
        let menuItems = [
            {
                label: t("label.rename"),
                icon: <Icon icon="solar:pen-bold" className="mr-3" style={{ fontSize: "20px" }} />,
                command: () => {
                    // listNameInputRef?.current?.focus()
                    listNameInputRefs?.current[activeListIndex]?.current?.focus();
                },
                //   visible: checkPermissions(["edit_services"])
            },
            {
                label: t("label.clear"),
                icon: <Icon icon="solar:eraser-bold-duotone" className="mr-3" style={{ fontSize: "20px" }} />,
                command: () => {
                    clearList(process?.lists[activeListIndex]);
                },
                //   visible: checkPermissions(["edit_services"]) && duplicateCommand ? true : false
            },
            {
                label: t("label.delete"),
                icon: <Icon icon="solar:trash-bin-trash-bold-duotone" className="mr-3" style={{ fontSize: "20px" }} />,
                command: () => {
                    removeList(process?.lists[activeListIndex])
                },
                visible: checkPermissions(["edit_services"]) ? true : false,
            },
        ];

        return menuItems;
        // }
    };

    const updateTaskStatus = (client_id, project_id, process_id, task_id, status) => {
        taskService.updateTask({ update_params: { status: status } }, client_id, project_id, process_id, task_id);

        let newTasksResume = process?.tasks_resume;
        const currentTaskIndex = newTasksResume?.findIndex((taskResume) => taskResume.task_id === task_id);

        newTasksResume[currentTaskIndex] = {
            ...newTasksResume[currentTaskIndex],
        };

        let progress = status && status === "done" ? 100 : 0;

        newTasksResume[currentTaskIndex]["progress"] = progress;
        newTasksResume[currentTaskIndex]["status"] = status;

        const serviceUpdateParams = {
            update_params: {
                tasks_resume: newTasksResume,
            },
        };

        serviceService.updateService(serviceUpdateParams, client_id, project_id, process_id).then((data) => {
            setProcess(data);
        });
    };

    const createTask = (taskNameParam, listIndex) => {
        const currentList = process?.lists?.filter((item, index) => index === listIndex)[0];

        const clientIdParam = client_id;
        const projectIdParam = project?.id?.split("#")[1];
        const processIdParam = process?.id;

        const newData = {
            title: taskNameParam,
            client_id: clientIdParam,
            project_id: projectIdParam,
            process_id: processIdParam,
            order: process?.tasks + 1,
            include_client: false,
            status: currentList?.id,
            participants: 0,
        };

        taskService
            .createTask(clientIdParam, projectIdParam, processIdParam, newData)
            .then((data) => {
                let newTasks = []
                if (tasks) {
                    newTasks = [...tasks]
                }
                newTasks.push(data)
                setTasks(newTasks)
                

                let newTasksResume = process?.tasks_resume;

                newTasksResume.push({
                    task_id: data?.id,
                    progress: 0,
                });

                const serviceUpdateParams = {
                    update_params: {
                        tasks_resume: newTasksResume,
                        tasks: process?.tasks + 1,
                    },
                };

                serviceService.updateService(serviceUpdateParams, data?.client_id, data?.project_id, data?.process_id).then((processData) => {
                    setProcess(processData);
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const addList = () => {
        const newList = {
            id: uuid(),
            name: listName,
            tasks: [],
        };

        let newLists = [];
        if (process?.lists) {
            newLists = [...process?.lists];
        }

        newLists.push(newList);

        // const newLists = [...dropables, newList];

        // setDropables(newLists);

        let listsToUpdate = [];
        newLists.forEach((item) => {
            listsToUpdate.push({ id: item?.id, name: item?.name });
        });

        const serviceUpdateParams = {
            update_params: {
                lists: listsToUpdate,
            },
        };

        serviceService.updateService(serviceUpdateParams, process?.client_id, process?.project_id, process?.id).then((data) => {
            setProcess(data);
            setLoadingNewList(false);
        });

        setAddingList(false);
        setListName("");
    };

    const onDragEnd = (result) => {
        // let newDropables = dropables;
        let newLists = parseLists(tasks, process);
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        // Sorting in same list
        /* if (source.droppableId === destination.droppableId) {
            let newDestinationList = newLists.filter((dropable) => dropable.id === destination.droppableId)[0].tasks;

            const newValue = newDestinationList[source.index];

            // Remove the element from source list
            newDestinationList = newDestinationList.filter((_, index) => index !== source.index);

            // Insert the new element in the destination list
            newDestinationList.splice(destination.index, 0, newValue);

            for (let i = 0; i < newLists.length; i++) {
                if (newLists[i].id === destination.droppableId) {
                    newLists[i].tasks = newDestinationList;
                    break;
                }
            }
            setDropables(newLists);
        }*/
        // Interlist movement
        if (source.droppableId !== destination.droppableId) {
            let newDestinationList = newLists.filter((dropable) => dropable.id === destination.droppableId)[0].tasks;
            let newSourceList = newLists.filter((dropable) => dropable.id === source.droppableId)[0].tasks;

            // Insert the new element in the destination list
            newDestinationList.splice(destination.index, 0, newSourceList[source.index]);

            // Remove the element from source list
            newSourceList = newSourceList.filter((_, index) => index !== source.index);

            for (let i = 0; i < newLists.length; i++) {
                if (newLists[i].id === source.droppableId) {
                    newLists[i].tasks = newSourceList;
                } else if (newLists[i].id === destination.droppableId) {
                    let newTasks = newDestinationList;
                    // const id = newTasks[destination.index].id
                    const client_id = newTasks[destination.index].client_id;
                    const project_id = newTasks[destination.index].project_id;
                    const process_id = newTasks[destination.index].process_id;
                    const task_id = newTasks[destination.index].id;

                    newTasks[destination.index].status = destination.droppableId;
                    newLists[i].tasks = newTasks;
                    updateTaskStatus(client_id, project_id, process_id, task_id, destination.droppableId);
                }
            }

            // setDropables(newDropables);
        }
    };

    // const [lists, setLists] = useState([
    //     {
    //         id: "todo",
    //         label: t("label.todo"),
    //         tasks: [],
    //     },
    //     {
    //         id: "inprogress",
    //         label: t("label.inprogress"),
    //         tasks: [],
    //     },
    //     {
    //         id: "blocked",
    //         label: t("label.blocked"),
    //         tasks: [],
    //     },
    //     {
    //         id: "done",
    //         label: t("label.done"),
    //         tasks: [],
    //     },
    // ]);

    const parseLists = (tasksParam, processParam) => {
        if (processParam && tasksParam) {
            if (processParam?.lists) {
                let newLists = [...processParam?.lists];
                if (tasksParam?.length > 0) {
                    for (let i = 0; i < newLists.length; i++) {
                        const listTasks = tasksParam.filter((item) => item.status === newLists[i].id);
                        newLists[i]["tasks"] = listTasks ? listTasks : [];
                    }
                }
                listNameInputRefs.current = newLists?.map((list, i) => listNameInputRefs?.current[i] ?? createRef());
                
                return newLists;
            }
        }
        return [];
    };

    const getItemStyle = (isDragging, draggableStyle) => {
        let style = {
            userSelect: "none",
            // width: '28.3rem',
            width: "100%",
            // width: "280px",
            marginBottom: "2em",
            borderRadius: "var(--border-radius-large)",
            ...draggableStyle,
        };
        if (isDragging) {
            style = {
                ...style,
                backgroundColor: "rgba(97, 101, 255, 0.1)",
                border: "1px solid #89899D",
            };
        }

        return style;
    };

    const getListStyle = (isDraggingOver) => ({
        // background: isDraggingOver ? "rgba(97, 101, 255, 0.1)" : 'transparent',
        background: "var(--miflow-no-white-v2-background)",
        // padding: grid,
        // width:"25rem"
        width: "100%",
    });

    const updateServiceListName = (newName, listIndex) => {
        let newLists = [];
        if (process?.lists) {
            newLists = [...process?.lists];
        }
        // let newLists = [...dropables];

        newLists[listIndex]["name"] = newName;

        // setDropables(newLists);

        let listsToUpdate = [];
        newLists.forEach((item) => {
            listsToUpdate.push({ id: item?.id, name: item?.name });
        });

        const serviceUpdateParams = {
            update_params: {
                lists: listsToUpdate,
            },
        };

        serviceService.updateService(serviceUpdateParams, process?.client_id, process?.project_id, process?.id).then((data) => {
            setProcess(data);
        });

        setActiveListIndex(undefined);
        setListName("");
    };

    const removeList = (listParam) => {
        confirmDialog({
            tagKey: "confirmListRemoveDialog",
            message: (
                <div>
                    <span>{t("label.are_you_sure_want_to_delete_category")}</span>
                    <br/>
                    <span className="pt-5" style={{ color: "var(--danger-color)" }}>{t("label.note_all_tasks_related_to_this_category_will_also_be_deleted")}</span>
                </div>
            ),
            closable: false,
            header: t("label.delete"),
            draggable: false,
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: t("label.delete"),
            rejectLabel: t("label.cancel"),
            rejectClassName: "p-button-outlined p-button-plain",
            accept: () => {
                const newLists = process?.lists?.filter((item) => item?.id !== listParam?.id);

                const serviceUpdateParams = {
                    update_params: {
                        lists: newLists,
                    },
                };

                serviceService.updateService(serviceUpdateParams, process?.client_id, process?.project_id, process?.id).then((data) => {
                    setProcess(data);
                });
                setProcess({ ...process, lists: newLists });

                let newTasks = [...tasks];
                taskService
                    .emptyTask(process?.client_id, process?.project_id, process?.id, listParam?.id)
                    .then((data) => {
                        console.log(data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                newTasks = newTasks.filter((item) => item.status !== listParam?.id);

                setTasks(newTasks);
                setActiveListIndex(undefined);
            },
            contentClassName: "pt-3",
        });
    };

    const clearList = (listParam) => {
        confirmDialog({
            tagKey: "confirmListEraseDialog",
            message: <span>{t("label.are_you_sure_you_want_to_delete_all_tasks_within_the_x_category", { list_name: listParam?.name })}</span>,
            closable: false,
            header: t("label.clear"),
            draggable: false,
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: t("label.clear"),
            rejectLabel: t("label.cancel"),
            rejectClassName: "p-button-outlined p-button-plain",
            accept: () => {
                const newTasks = tasks?.filter((item) => item?.status !== listParam?.id);
                setTasks(newTasks);
                setActiveListIndex(undefined);
                taskService.emptyTask(process?.client_id, process?.project_id, process?.id, listParam?.id)
                .catch((error) => {
                    console.log(error);
                });
                
            },
            contentClassName: "pt-3",
        });
    };

    return (
        <div className="col-12 overflow-auto">
            <ConfirmDialog tagKey="confirmListEraseDialog" />
            <ConfirmDialog tagKey="confirmListRemoveDialog" />
            <div className="custom-kanban-list-container">
                <Menu model={menuItems()} popup ref={listMenuRef} />
                <Toast ref={toast} />
                <DragDropContext onDragEnd={onDragEnd}>
                    {parseLists(tasks, process)?.map((list, i) => {
                        return (
                            <div className="custom-kanban-list-container-column" disabled={project?.status === "draft"}>
                                <div className="flex flex-column" style={{ maxWidth: "350px"}}>
                                    <div className="flex align-items-center justify-content-top pt-3 min-w-full pb-3">
                                        <div className="flex justify-content-between flex-wrap min-w-full">
                                            <div className="flex align-items-center justify-content-start">
                                                <InputText
                                                    className="w-full xl:min-w-full mousee-text font-medium font-weight-semibold custom-title-inputtext"
                                                    value={activeListIndex === i && listName ? listName : list?.name}
                                                    ref={listNameInputRefs?.current[i]}
                                                    onFocus={() => {
                                                        setActiveListIndex(i);
                                                        setListName(list?.name);
                                                    }}
                                                    onBlur={(e) => {
                                                        if (listName && listName !== list?.name) {
                                                            updateServiceListName(listName, i);
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        setListName(e?.target?.value);
                                                    }}
                                                />
                                            </div>
                                            <div className="flex align-items-center justify-content-end">
                                                <Button
                                                    icon={"pi pi-ellipsis-v"}
                                                    className="p-button-text p-button-secondary"
                                                    onClick={(e) => {
                                                        setActiveListIndex(i);
                                                        listMenuRef?.current?.toggle(e);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <Droppable droppableId={list?.id}>
                                        {(provided, snapshot) => (
                                            <div className="flex align-items-top justify-content-center" style={{ minHeight: "1rem" }}>
                                                <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                                    {list?.tasks?.map((item, index) => (
                                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    className="card shadow-1 p-3"
                                                                    onClick={() => {
                                                                        let allowed = false;
                                                                        if (checkPermissions(null, ["client"])) {
                                                                            allowed = item?.include_client;
                                                                        } else if (checkPermissions(null, ["employee"])) {
                                                                            allowed = checkPermissions(["edit_tasks"]);
                                                                        }

                                                                        if (allowed) {
                                                                            setActiveTask(item);
                                                                            setShowEditTaskDialog(true);
                                                                        }
                                                                    }}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                                >
                                                                    <div className="grid text-left" disabled={checkPermissions(null, ["client"]) && !item?.include_client}>
                                                                        <div className="col-12">
                                                                            <label className="mousee-text font-x-small font-weight-semibold">{item.title ? item.title : "N/A"}</label>
                                                                        </div>

                                                                        <div className="col-12">
                                                                            <div className="flex justify-content-between flex-wrap">
                                                                                <div className="flex align-items-center justify-content-center">
                                                                                    <div className="flex justify-content-start flex-wrap">
                                                                                        <div className="flex align-items-center justify-content-center">
                                                                                            <Icon icon="solar:users-group-two-rounded-bold-duotone" className="mr-2" style={{ fontSize: "20px" }} />
                                                                                            <label className="mousee-text-small font-x-small font-weight-regular">{item?.participants ? item?.participants : 0}</label>
                                                                                        </div>
                                                                                        <div className="flex align-items-center justify-content-center"></div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex align-items-center justify-content-center">
                                                                                    <Icon icon="solar:calendar-bold-duotone" className="mr-2" style={{ fontSize: "20px" }} />
                                                                                    <label className="mousee-text-small font-x-small font-weight-regular">{formatDateV2(item?.delivery_date, false, i18n.language)}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            </div>
                                        )}
                                    </Droppable>

                                    {addingTask === i ? (
                                        <div>
                                            <div className="card shadow-1 p-3">
                                                <InputText
                                                    className="w-full xl:min-w-full mousee-text font-x-small font-weight-semibold custom-title-inputtext"
                                                    value={taskName}
                                                    autoFocus
                                                    placeholder={t("label.task_name")}
                                                    onBlur={(e) => {
                                                        e.preventDefault();
                                                        if (taskName) {
                                                            createTask(taskName, i);
                                                            setAddingTask(undefined);
                                                            setTaskName("");
                                                        } else {
                                                            setAddingTask(undefined);
                                                            setTaskName("");
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        setTaskName(e?.target?.value);
                                                    }}
                                                />
                                            </div>

                                            <div className="flex align-items-center justify-content-center pt-3 min-w-full pb-3">
                                                <Button
                                                    icon={"pi pi-times"}
                                                    label={t("label.close")}
                                                    type="button"
                                                    className="p-button-text p-button-secondary min-w-full py-3 font-weight-bold font-x-small"
                                                    onMouseDown={() => {
                                                        setAddingTask(undefined);
                                                        setTaskName("");
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="flex align-items-center justify-content-center pt-3 min-w-full pb-3">
                                            <Button icon={"pi pi-plus"} label={t("label.add_task")} type="button" className="p-button-text p-button-secondary min-w-full py-3 font-weight-bold font-x-small" onClick={() => setAddingTask(i)} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </DragDropContext>
                <div className="custom-kanban-list-container-add-section-container">
                    {addingList ? (
                        <InputText
                            className="mousee-text font-medium font-weight-semibold custom-title-inputtext"
                            style={{ minWidth: "280px" }}
                            value={listName}
                            autoFocus
                            placeholder={t("label.list_name")}
                            onBlur={(e) => {
                                if (listName && listName?.trim() !== "") {
                                    addList();
                                } else {
                                    setListName("");
                                    setAddingList(false);
                                }
                            }}
                            onChange={(e) => {
                                setListName(e?.target?.value);
                            }}
                        />
                    ) : (
                        <Button className="p-button-outlined p-button-plain p-3 px-6 mousee-text font-small font-weight-bold" loading={loadingNewList} label={t("label.add_another_list")} icon="pi pi-plus" style={{ minWidth: "280px" }} onClick={() => setAddingList(true)} />
                    )}
                </div>
            </div>
            {/* <MultipleDragList project={project} process={process} setProcess={setProcess} disabled={project?.status === "draft"} client_id={client_id} dropables={parseLists(tasks)} setDropables={setLists} setActiveTask={setActiveTask} setShowEditTaskDialog={setShowEditTaskDialog} /> */}
        </div>
    );
};
export default ProjectKanban;
