import { API } from 'aws-amplify';

const API_NAME = "ServicesAPI";
const BASE_PATH = "/service"

export default class ServiceService {
    async queryServices(queryStringParameters={}, data) {
        return API.post(API_NAME, `${BASE_PATH}/query`,{queryStringParameters:queryStringParameters, body: data})
            .then((response) => response)
            .catch((error) => {
                console.log(error.response);
            });
    }

    async createService(client_id, project_id, data) {
        return API.post( API_NAME, BASE_PATH, { queryStringParameters: {client_id: client_id, project_id: project_id}, body: data } )
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }

    async getService(client_id, project_id, service_id) {
        return API.get( API_NAME, `${BASE_PATH}/${service_id}`, { queryStringParameters: {client_id: client_id, project_id: project_id} });
    }    
    
    async updateService(data, client_id, project_id, process_id) {
        return API.put( API_NAME, `${BASE_PATH}/${process_id}`, { queryStringParameters: {client_id: client_id, project_id: project_id}, body: data } )
    }
}