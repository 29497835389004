import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getFileExtensionIcon } from "../../Utils";
import { checkComponentPermissions } from "../../data/Permissions";
import FileService from "../../services/FileService/FileService";
import "./FilePreview.css";

const FilePreview = ({ file, classnames, deleteFile }) => {
    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState(false);

    const fileService = new FileService();

    const downloadFile = () => {
        // setLoading(true);

        const fileUrl = file?.url;
        const link = document.createElement("a");
        link.href = fileUrl;
        link.target = "__blank"
        link.download = file?.name; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // fetch(file?.url, {
        //     method: "GET",
        //     // headers: {
        //     //     'Content-Type': 'application/pdf',
        //     // },
        // })
        //     .then((response) => response.blob())
        //     .then((blob) => {
        //         // Create blob link to download
        //         const url = window.URL.createObjectURL(new Blob([blob]));
        //         const link = document.createElement("a");
        //         link.href = url;
        //         link.setAttribute("download", file?.name);

        //         // Append to html link element page
        //         document.body.appendChild(link);

        //         // Start download
        //         link.click();

        //         // Clean up and remove the link
        //         link.parentNode.removeChild(link);
        //         setLoading(false);
        //     });
    };

    return (
        <div className="">
            <div className={`custom-file-preview ${classnames ? classnames : ""}`}>
                <div className="custom-file-preview-container" onClick={() => downloadFile()}>
                    <div className={`custom-file-preview-container-content ${classnames}`}>
                        {loading ? (
                            <i className="custom-file-preview-container-content-icon pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
                        ) : (
                            <Icon icon={getFileExtensionIcon(file?.name)} className={`custom-file-preview-container-content-icon ${classnames ? classnames : ""}`} style={{ fontSize: "2.5rem", color: "var(--secondary-color-extra-light)" }} />
                        )}
                    </div>
                    {checkComponentPermissions(
                        <div
                            className="custom-file-preview-container-content-close-icon"
                            onClick={(e) => {
                                e.stopPropagation();
                                deleteFile && deleteFile(file?.id);
                            }}
                        >
                            <span className="pi pi-times"></span>
                        </div>,
                        ["edit_tasks"],
                        null,
                        null
                    )}

                    {/* <div className={`custom-file-preview-container-content${file ? "-hidden" : ""} ${classnames}`}>
                        <Icon className="custom-file-preview-container-content-icon" icon="solar:eye-bold-duotone" style={{ fontSize: "20px" }} />
                    </div> */}
                </div>
            </div>
        </div>
    );
};
export default FilePreview;
