import { API } from 'aws-amplify';

const API_NAME = "ProfilesAPI";
const BASE_PATH = "/profile"

export default class ProfileService {
    async queryProfiles(queryStringParameters={}, data, visibility) {
        const queryParameters = {
            ...queryStringParameters,
            visibility: visibility,
        }
        
        return API.post(API_NAME, `${BASE_PATH}/query`,{queryStringParameters:queryParameters, body: data})
            // .then((response) => response)
            // .catch((error) => {
            //     console.log(error.response);
            // });
    }

    async createProfile(data) {
        return API.post( API_NAME, BASE_PATH, { body: data } )
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }

    async asignUser(data, user_id) {
        return API.post( API_NAME, `${BASE_PATH}/asign/${user_id}`, { body: data } )
    }

    // async getUsers(profile_id) {
    //     return API.get( API_NAME, `${BASE_PATH}/${profile_id}/users`)
    //     .then((response) => response)
    //     .catch((error) => {
    //         console.log(error.response);
    //     });
    // }

    async updateProfile(data, id) {
        return API.put( API_NAME, `${BASE_PATH}/${id}`, { body: data } )
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }

    async deleteProfile(id) {
        return API.del( API_NAME, `${BASE_PATH}/${id}`)
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }

    async getProfile(id) {
        return API.get( API_NAME, `${BASE_PATH}/${id}`)
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }    
}