import React, { useEffect, useState } from "react";

import { useAuthenticator } from "@aws-amplify/ui-react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { BlockUI } from "primereact/blockui";
import { Divider } from "primereact/divider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getAuthData, getGreetingMessage, isDesktop } from "../../Utils";
import ProjectCard from "../../components/Cards/ProjectCard";
import { getAdditionalTopBarComponentsV2 } from "../../data/TabsData";
import NotificationService from "../../services/NotificationService/NotificationService";
import ProjectService from "../../services/ProjectService/ProjectService";
import UserService from "../../services/UserService/UserService";

const Panel = (props) => {
    const { t, i18n } = useTranslation();
    const { user, signOut } = useAuthenticator((context) => [context.user]);

    const navigate = useNavigate();
    const projectService = new ProjectService();
    const notificationService = new NotificationService();
    const userService = new UserService();
    const [loading, setLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState();

    const [notifications, setNotifications] = useState([]);
    const [queryNotificationsResponse, setQueryNotificationsResponse] = useState();
    const notificationsLimit = 5;

    const [projects, setProjects] = useState([]);

    useEffect(() => {
        props.setTopbarAdditionalComponents(getAdditionalTopBarComponentsV2("", navigate));
    }, []);

    useEffect(() => {
        props.setOption("panel");
    }, []);

    useEffect(() => {
        notificationService.queryNotifications({ limit: notificationsLimit, readed: "false" }).then((data) => {
            setQueryNotificationsResponse(data);
            setNotifications(data?.data);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        setLoading(true);

        projectService.queryProjects({ status: "draft", limit: 5 }).then((data) => {
            setProjects(data?.data);
        });

        if (getAuthData()?.is_tenant === "false" && user) {
            userService.getUser({}, user.attributes.sub).then((data) => {
                setCurrentUser(data);
            });
        }
    }, []);

    const emptyPage = () => {
        return (
            <div className="flex align-items-center justify-content-center overflow-hidden min-w-full">
                <img src={`/empty_images/${i18n.language}/panel_empty.svg`} alt="o" width={isDesktop() ? 550 : 300} />
            </div>
        );
    };

    return (
        <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}>
            <div className="page-layout">
                {!loading && (!projects || (projects && projects.length === 0)) ? (
                    emptyPage()
                ) : (
                    <div className="grid">
                        <div className="col-12 pb-0">
                            <div className="flex flex-column">
                                <div className="flex align-items-center justify-content-start">
                                    <label className="mousee-text font-large font-weight-regular" style={{ color: "var(--primary-color)" }}>{`${getGreetingMessage(t)}${currentUser && currentUser.name ? `, ${currentUser.name}` : ""}`}</label>
                                </div>
                                <div className="flex align-items-center justify-content-start">
                                    <label className="mousee-text font-small font-weight-regular" style={{ color: "var(--primary-color)" }}>
                                        {t("message.quickly_access_your_recent_projects_mailbox_and_workspaces")}
                                    </label>
                                </div>
                                <div className="flex align-items-center justify-content-center">
                                    <Divider className="border-300" />
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-12 pt-0">
                            <label className="mousee-text font-small font-weight-regular" style={{ color: "var(--primary-color)" }}>
                                {t("message.quickly_access_your_recent_projects_mailbox_and_workspaces")}
                            </label>
                        </div> */}

                        {/* <div className="col-12">
                            <Divider className="border-300" />
                        </div> */}

                        {projects && projects.length > 0 ? (
                            <div className="col-12">
                                <Accordion activeIndex={0}>
                                    <AccordionTab header={<label className="mousee-text font-x-small font-weight-semibold">{t("message.projects_not_activated")}</label>}>
                                        <div className="flex md:justify-content-start justify-content-center flex-wrap">
                                            {projects.map((item) => {
                                                return (
                                                    <div className="flex align-items-center justify-content-center p-2">
                                                        <ProjectCard project={item} />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </AccordionTab>
                                </Accordion>
                            </div>
                        ) : null}
                    </div>
                )}
                {/* <div className={`col-12 ${notifications && notifications.length > 0 ? "md:col-10" : "md:col-12"}`} style={{ padding: "5.5rem 2rem 2rem 4rem" }}>
                    
                </div> */}

                {/* {notifications && notifications.length > 0 ? (
                    <div className="col-12 md:col-2" style={{ paddingTop: "4.3rem", background: "rgba(97, 101, 255, 0.1)", minHeight: "70rem" }}>
                        {notifications.map((notification, index) => {
                            return (
                                <div className="grid pt-3">
                                    <div className="col-12 pr-3">
                                        <div className="card shadow-1" style={{ background: "#F4F5FE", width: "100%", marginBottom: "1em" }}>
                                            <div className="flex justify-content-between flex-wrap">
                                                <div className="flex align-items-center justify-content-center pt-2">
                                                    <label className="mousee-text font-small font-weight-bold">{notification?.type === "task_comment" ? t("label.new_comment") : notification?.type === "budget" ? t("label.new_budget") : t("label.new_notification")}</label>
                                                </div>
                                            </div>

                                            <p className="pt-3">{notification.type === "task_comment" ? notification?.data?.title : notification?.data?.content}</p>

                                            {notification?.type && notification?.type !== "system" ? (
                                                <div className="flex justify-content-end flex-wrap">
                                                    <div className="flex align-items-center justify-content-center">
                                                        <Button
                                                            className="p-button-link"
                                                            onClick={() => window.open(notification?.url, "_blank", "rel=noopener noreferrer")}
                                                            label={`${notification.type === "task_comment" ? t("label.go_to_task") : notification.type === "budget" ? t("label.go_to_budget") : notification.type === "project" ? t("label.go_to_project") : ""}`}
                                                        />
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : null} */}
            </div>
        </BlockUI>
    );
};
export default Panel;
