import { useFormik } from "formik";
import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import dropdownDown from "../../assets/icons/dropdown-down.svg";
import { getMetricPeriodObject, getMetricSelectorOptionLabel } from "../../data/Metric";
import { checkComponentPermissions } from "../../data/Permissions";
import { getAdditionalTopBarComponentsV2 } from "../../data/TabsData";
import ClientService from "../../services/ClientService/ClientService";
import MetricService from "../../services/MetricService/MetricService";
import ProjectService from "../../services/ProjectService/ProjectService";
import TaskService from "../../services/TaskService/TaskService";
import Forbidden from "../Auth/Forbidden";
import { clientOptionTemplate, filterClients, handleClientsOnScroll, selectedClientTemplate } from "../People/Clients/Utils";
import { filterProjects, filterServices, handleProjectsOnScroll, handleServicesOnScroll } from "../Projects/Utils";
import { MetricScreen } from "./MetricScreen/MetricScreen";

import { useAuthenticator } from "@aws-amplify/ui-react";
import { MultiSelect } from "primereact/multiselect";
import { useTranslation } from "react-i18next";
import { objectHasKeys } from "../../Utils";
import metricsTimeSelector from "../../assets/icons/metrics/metric-time-selector.svg";
import ServiceService from "../../services/ServiceService/ServiceService";
import TenantService from "../../services/TenantService/TenantService";
import "./Metrics.scss";
import { MetricSelectorOverlay } from "./Overlays/MetricSelectorOverlay";

const Metrics = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const toast = useRef(null);
    // const [projects, setProjects] = useState();
    const [loading, setLoading] = useState(false);
    const clientService = new ClientService();
    const projectService = new ProjectService();
    const taskService = new TaskService();
    const metricService = new MetricService();
    const serviceService = new ServiceService();
    const [dates, setDates] = useState();
    const [metricData, setMetricData] = useState();

    const [clients, setClients] = useState([]);
    const [queryClientsResponse, setQueryClientsResponse] = useState();
    const [clientsRowsNumber, setClientsRowsNumber] = useState(3);
    const [clientFilterName, setClientFilterName] = useState(undefined);

    const [selectedPeriod, setSelectedPeriod] = useState("current_year");

    const [projects, setProjects] = useState([]);
    const [queryProjectsResponse, setQueryProjectsResponse] = useState();
    const [projectsRowsNumber, setProjectsRowsNumber] = useState(5);
    const [projectFilterName, setProjectFilterName] = useState(undefined);

    const [services, setServices] = useState([]);
    const [queryServicesResponse, setQueryServicesResponse] = useState();
    const [servicesRowsNumber, setServicesRowsNumber] = useState(5);
    const [serviceFilterName, setServiceFilterName] = useState(undefined);

    const metricsTimeSelectorRef = useRef(null);

    const tenantService = new TenantService();
    const { user } = useAuthenticator((context) => [context.user]);
    const [tenantInfo, setTenantInfo] = useState();

    const [loadingDropdown, setLoadingDropdown] = useState(undefined);

    useEffect(() => {
        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            tenantService.getTenant(user.attributes["custom:tenant_id"]).then((data) => {
                if (data) {
                    setTenantInfo(data);
                }
            });
        }
    }, []);

    useEffect(() => {
        props.setTopbarAdditionalComponents(getAdditionalTopBarComponentsV2("", navigate));
    }, []);

    useEffect(() => {
        props.setOption("metrics");
    }, []);

    useEffect(() => {
        formik.resetForm();
        setLoading(true);

        // if (searchParams.get("tags")) {
        //     formik.setFieldValue("tags", searchParams.get("tags").split(","));
        // }

        getMetric(undefined, undefined, undefined, searchParams.get("tags"));

        clientService
            .queryClients()
            .then((data) => {
                setQueryClientsResponse(data);
                const clientsData = data.data;
                setClients(clientsData);

                if (searchParams.get("client_id")) {
                    const currentClients = clientsData.filter((newClient) => newClient.id === searchParams.get("client_id"));

                    if (currentClients && currentClients.length > 0) {
                        formik.setFieldValue("client", currentClients[0]);

                        projectService.queryProjects({ client_id: searchParams.get("client_id"), limit: projectsRowsNumber }).then((data) => {
                            setQueryProjectsResponse(data);
                            setProjects(data?.data);

                            if (searchParams.get("project_id")) {
                                const currentProject = data?.data?.filter((newProject) => newProject.id.split("#")[1] === searchParams.get("project_id"));

                                if (currentProject && currentProject.length > 0) {
                                    formik.setFieldValue("project", currentProject[0]);

                                    serviceService.queryServices({ client_id: searchParams.get("client_id"), project_id: searchParams.get("project_id"), limit: servicesRowsNumber }).then((data) => {
                                        setQueryServicesResponse(data);
                                        setServices(data?.data);

                                        if (searchParams.get("service_id")) {
                                            const currentService = data?.data?.filter((newService) => newService.id === searchParams.get("service_id"));

                                            if (currentService && currentService.length > 0) {
                                                formik.setFieldValue("service", currentService[0]);

                                                getMetric(searchParams.get("client_id"), searchParams.get("project_id"), searchParams.get("service_id"), searchParams.get("tags"));
                                            } else {
                                                searchParams.delete("service_id");
                                                setSearchParams(searchParams);
                                                getMetric(searchParams.get("client_id"), searchParams.get("project_id"), undefined, searchParams.get("tags"));
                                            }
                                        } else {
                                            getMetric(searchParams.get("client_id"), searchParams.get("project_id"), undefined, searchParams.get("tags"));
                                        }
                                    });
                                } else {
                                    searchParams.delete("project_id");
                                    searchParams.delete("service_id");
                                    setSearchParams(searchParams);
                                    getMetric(searchParams.get("client_id"), undefined, undefined, searchParams.get("tags"));
                                }
                            } else {
                                getMetric(searchParams.get("client_id"), undefined, undefined, searchParams.get("tags"));
                            }
                        });
                    } else {
                        searchParams.delete("client_id");
                        searchParams.delete("project_id");
                        setSearchParams(searchParams);
                        getMetric(undefined, undefined, undefined, searchParams.get("tags"));
                    }
                } else {
                    getMetric(undefined, undefined, undefined, searchParams.get("tags"));
                }
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    }, []);

    const formik = useFormik({
        initialValues: {
            text: "",
            period: getMetricPeriodObject("days"),
            client: null,
            project: null,
            service: null,
            tags: [],
            tasks: null,
        },
        onSubmit: (data) => {},
    });

    const onClientChange = (e) => {
        formik.setFieldValue("client", e.value);

        let tags = undefined;

        if (formik.values.tags) {
            tags = formik.values.tags.join(",");
        }

        if (e.value) {
            // projectService.queryProjects({ client_id: e.value.id, limit: projectsRowsNumber }).then((data) => {
            //     setQueryProjectsResponse(data);
            //     setProjects(data?.data);
            // });

            getMetric(e.value.id, undefined, undefined, tags);
            searchParams.set("client_id", e.value.id);
            setSearchParams(searchParams);
        } else {
            setProjects([])
            setServices([])
            
            formik.setFieldValue("project", undefined);
            getMetric(undefined, undefined, undefined, tags);
            searchParams.delete("client_id");
            searchParams.delete("project_id");
            setSearchParams(searchParams);
        }
    };

    const getMetric = (clientId, projectId, serviceId, tags) => {
        setLoading(true);

        metricService
            .getMetric(clientId, projectId, serviceId, tags)
            .then((data) => {
                setMetricData(data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const onProjectChange = (e) => {
        formik.setFieldValue("project", e.value);

        let tags = undefined;

        if (formik.values.tags) {
            tags = formik.values.tags.join(",");
        }

        if (e.value) {
            const clientId = e.value.id.split("#")[0];
            const projectId = e.value.id.split("#")[1];

            serviceService.queryServices({ client_id: clientId, project_id: projectId, limit: servicesRowsNumber }).then((data) => {
                setQueryServicesResponse(data);
                setServices(data?.data);
            });

            getMetric(clientId, projectId, undefined, tags);
            searchParams.set("project_id", projectId);
            setSearchParams(searchParams);
        } else {
            formik.setFieldValue("project", undefined);
            formik.setFieldValue("service", undefined);
            setServices([]);

            getMetric(formik?.values?.client?.id, undefined, undefined, tags);
            searchParams.delete("project_id");
            searchParams.delete("service_id");
            setSearchParams(searchParams);
        }
    };

    const onServiceChange = (e) => {
        formik.setFieldValue("service", e.value);

        let tags = undefined;

        if (formik.values.tags) {
            tags = formik.values.tags.join(",");
        }

        if (e.value) {
            const clientId = e.value.client_id;
            const projectId = e.value.project_id;
            const serviceId = e.value.id;

            getMetric(clientId, projectId, serviceId, tags);

            searchParams.set("service_id", serviceId);
            setSearchParams(searchParams);
        } else {
            getMetric(formik?.values?.client?.id, formik?.values?.project ? formik?.values?.project?.id?.split("#")[1] : undefined, undefined, tags);
            searchParams.delete("service_id");
            setSearchParams(searchParams);
        }
    };

    const onVerticalChange = (e) => {
        formik.setFieldValue("tags", e.value);

        if (e.value && e.value.length > 0) {
            const clientId = e.value.client_id;
            const projectId = e.value.project_id;
            const serviceId = e.value.id;

            const tags = e.value.join(",");

            getMetric(clientId, projectId, serviceId, tags);

            searchParams.set("tags", tags);
            setSearchParams(searchParams);
        } else {
            if (formik.values.client) {
                if (formik.values.project) {
                    if (formik.values.service) {
                        getMetric(formik.values.client.id, formik.values.project.id.split("#")[1], formik.values.service.id);
                    } else {
                        getMetric(formik.values.client.id, formik.values.project.id.split("#")[1]);
                        searchParams.delete("process_id");
                    }
                } else {
                    searchParams.delete("project_id");
                    searchParams.delete("process_id");
                    getMetric(formik.values.client.id);
                }
            } else {
                searchParams.delete("client_id");
                searchParams.delete("project_id");
                searchParams.delete("process_id");
            }

            searchParams.delete("tags");
            setSearchParams(searchParams);
        }
    };

    const getMetricView = (client, project) => {
        if (client) {
            if (project) {
                // Display specific project
                return <MetricScreen first_id={client.id} second_id={project.id.split("#")[1]} />;
            } else {
                // Display specific client metric
                return <MetricScreen first_id="CLIENT" second_id={client.id} />;
            }
        } else {
            // Display Global Tenant
            return <MetricScreen first_id="TENANT" />;
        }
    };

    if (loading) return <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}></BlockUI>;

    return checkComponentPermissions(
        <div style={{ textAlign: "left", verticalAlign: "middle" }} className="grid page-layout">
            <Toast ref={toast} />

            {/* <div className="xl:col-offset-1"></div> */}
            <div className="col-12 xl:col-12">
                <div className="grid">
                    <div className="col-12 p-0">
                        <div className="flex  justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <div className="grid p-0">
                                    <div className="col-12 p-0">
                                        <label className="mousee-text font-large font-weight-semibold">{t("label.metrics")}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pb-0 pt-5">
                        <div className="grid">
                            <div className="col-12 xl:col-2 lg:col-4 md:col-6">
                                <Dropdown
                                    dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                    id="clients-dowpdown"
                                    name="clients-dowpdown"
                                    dataKey="id"
                                    value={formik.values.client}
                                    options={clients}
                                    optionLabel="name"
                                    valueTemplate={selectedClientTemplate}
                                    itemTemplate={clientOptionTemplate}
                                    onChange={(e) => onClientChange(e)}
                                    placeholder={t("label.all_clients")}
                                    className={`w-full xl:min-w-full ${formik.values.client ? "clients-custom-dropdown" : ""}`}
                                    style={{ maxWidth: "25rem" }}
                                    showClear
                                    filter
                                    emptyMessage={
                                        loadingDropdown === "clients" ? (
                                            <div className="flex align-items-center">
                                                <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
                                                <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading")}</label>
                                            </div>
                                        ) : (
                                            <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>
                                        )
                                    }
                                    onShow={() => {
                                        if (!clients || clients?.length === 0) {
                                            setLoadingDropdown("clients");
                                            clientService
                                                .queryClients({ limit: clientsRowsNumber })
                                                .then((data) => {
                                                    setQueryClientsResponse(data);
                                                    setClients(data?.data);
                                                    setLoadingDropdown(undefined);
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                                });
                                        }
                                    }}
                                    emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                    onScrollCapture={(e) => handleClientsOnScroll(e, clientService, clientsRowsNumber, queryClientsResponse, setQueryClientsResponse, clients, setClients, clientFilterName, setClientFilterName)}
                                    filterTemplate={(options) => (
                                        <span className="p-input-icon-right min-w-full">
                                            <i className="pi pi-search" />
                                            <InputText autoFocus className="min-w-full" value={clientFilterName} ref={null} onChange={(e) => filterClients(e, options, setClientFilterName, queryClientsResponse, setQueryClientsResponse, clientService, clientsRowsNumber, clients, setClients)} />
                                        </span>
                                    )}
                                />
                            </div>
                            <div className="col-12 xl:col-2 lg:col-4 md:col-6">
                                <Dropdown
                                    dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                    id="projects-dowpdown"
                                    name="projects-dowpdown"
                                    dataKey="id"
                                    value={formik.values.project}
                                    disabled={!formik.values.client}
                                    options={projects}
                                    optionLabel="name"
                                    onChange={(e) => onProjectChange(e)}
                                    placeholder={t("label.all_projects")}
                                    className="w-full"
                                    filter
                                    style={{ maxWidth: "20rem" }}
                                    showClear
                                    emptyMessage={
                                        loadingDropdown === "projects" ? (
                                            <div className="flex align-items-center">
                                                <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
                                                <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading")}</label>
                                            </div>
                                        ) : (
                                            <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>
                                        )
                                    }
                                    onShow={() => {
                                        if (!projects || projects?.length === 0) {
                                            setLoadingDropdown("projects");
                                            let queryParams = {
                                                limit: projectsRowsNumber,
                                            };

                                            if (formik.values.client && objectHasKeys(formik.values.client)) {
                                                queryParams = {
                                                    ...queryParams,
                                                    client_id: formik.values?.client?.id,
                                                };
                                            }

                                            projectService.queryProjects(queryParams).then((data) => {
                                                setQueryProjectsResponse(data);
                                                setProjects(data?.data);
                                                setLoadingDropdown(undefined);
                                            });
                                        }
                                    }}
                                    emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                    // emptyMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                    onScrollCapture={(e) => {
                                        let queryParams = {
                                            limit: projectsRowsNumber,
                                        };

                                        if (formik.values.client && objectHasKeys(formik.values.client)) {
                                            queryParams = {
                                                ...queryParams,
                                                client_id: formik.values?.client?.id,
                                            };
                                        }

                                        handleProjectsOnScroll(e, projectService, queryParams, queryProjectsResponse, setQueryProjectsResponse, projects, setProjects, projectFilterName, setProjectFilterName);
                                    }}
                                    filterTemplate={(options) => (
                                        <span className="p-input-icon-right min-w-full">
                                            <i className="pi pi-search" />
                                            <InputText
                                                autoFocus
                                                className="min-w-full"
                                                value={projectFilterName}
                                                ref={null}
                                                onChange={(e) => filterProjects(e, options, setProjectFilterName, queryProjectsResponse, setQueryProjectsResponse, projectService, projectsRowsNumber, projects, setProjects)}
                                            />
                                        </span>
                                    )}
                                />
                            </div>

                            <div className="col-12 xl:col-2 lg:col-4 md:col-6">
                                <Dropdown
                                    dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                    id="services-dowpdown"
                                    name="services-dowpdown"
                                    dataKey="id"
                                    value={formik.values.service}
                                    disabled={!formik.values.project}
                                    options={services}
                                    optionLabel="name"
                                    onChange={(e) => onServiceChange(e)}
                                    placeholder={t("label.all_services")}
                                    className="w-full"
                                    filter
                                    style={{ maxWidth: "20rem" }}
                                    showClear
                                    emptyMessage={
                                        loadingDropdown === "services" ? (
                                            <div className="flex align-items-center">
                                                <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
                                                <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading")}</label>
                                            </div>
                                        ) : (
                                            <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>
                                        )
                                    }
                                    onShow={() => {
                                        if (!services || services?.length === 0) {
                                            setLoadingDropdown("services");

                                            let queryParams = {
                                                limit: servicesRowsNumber,
                                            };
                                            if (formik.values.client && objectHasKeys(formik.values.client)) {
                                                queryParams = {
                                                    ...queryParams,
                                                    client_id: formik.values?.client?.id,
                                                };
                                            }

                                            if (formik.values.project && objectHasKeys(formik.values.project)) {
                                                queryParams = {
                                                    ...queryParams,
                                                    client_id: formik.values?.project?.client_id,
                                                    project_id: formik.values?.project?.id,
                                                };
                                            }

                                            serviceService.queryServices(queryParams).then((data) => {
                                                setQueryServicesResponse(data);
                                                setServices(data?.data);
                                                setLoadingDropdown(undefined);
                                            });
                                        }
                                    }}
                                    emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                    // emptyMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                    onScrollCapture={(e) => {
                                        let queryParams = {
                                            limit: servicesRowsNumber,
                                        };

                                        if (formik.values.client && objectHasKeys(formik.values.client)) {
                                            queryParams = {
                                                ...queryParams,
                                                client_id: formik.values?.client?.id,
                                            };
                                        }

                                        if (formik.values.project && objectHasKeys(formik.values.project)) {
                                            queryParams = {
                                                ...queryParams,
                                                client_id: formik.values?.project?.client_id,
                                                project_id: formik.values?.project?.id,
                                            };
                                        }
                                        handleServicesOnScroll(e, serviceService, queryParams, queryServicesResponse, setQueryServicesResponse, services, setServices, serviceFilterName, setServiceFilterName);
                                    }}
                                    filterTemplate={(options) => (
                                        <span className="p-input-icon-right min-w-full">
                                            <i className="pi pi-search" />
                                            <InputText
                                                autoFocus
                                                className="min-w-full"
                                                value={serviceFilterName}
                                                ref={null}
                                                onChange={(e) => filterServices(e, options, setServiceFilterName, queryServicesResponse, setQueryServicesResponse, serviceService, servicesRowsNumber, services, setServices)}
                                            />
                                        </span>
                                    )}
                                />
                            </div>

                            <div className="col-12 xl:col-2 lg:col-4 md:col-6">
                                <MultiSelect
                                    value={tenantInfo?.verticals ? (formik.values.tags ? formik.values.tags : []) : []}
                                    onChange={(e) => onVerticalChange(e)}
                                    dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                    options={tenantInfo?.verticals}
                                    display="chip"
                                    closeIcon={<></>}
                                    placeholder={t("label.all_verticals")}
                                    className="min-w-full max-w-full"
                                    showSelectAll={false}
                                />
                            </div>

                            <div className="xl:col-offset-2 lg:col-offset-2"></div>
                            <div className="col-12 xl:col-2 lg:col-4">
                                <div className="flex justify-content-end flex-wrap min-w-full">
                                    <div className="flex align-items-center justify-content-end min-w-full">
                                        <Button icon={<img src={metricsTimeSelector} alt="metrics-selector-logo" className="pr-3" />} className="p-button-filled pointer-none" onClick={(e) => metricsTimeSelectorRef.current.toggle(e)}>
                                            <div className="flex flex-row flex-wrap">
                                                <div className="flex align-items-center justify-content-center">
                                                    <label className="mousee-text font-x-small font-weight-regular">{t(getMetricSelectorOptionLabel(selectedPeriod))}</label>
                                                </div>
                                                <div className="flex align-items-center justify-content-end">
                                                    <img src={dropdownDown} alt="metrics-selector-logo" className="pl-2" />
                                                </div>
                                            </div>
                                        </Button>
                                        <MetricSelectorOverlay customRef={metricsTimeSelectorRef} selectedPeriod={selectedPeriod} setSelectedPeriod={setSelectedPeriod} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">{metricData ? <MetricScreen selectedPeriod={selectedPeriod} tableData={metricData} client_name={formik?.values?.client?.name} project_name={formik?.values?.project?.name} /> : null}</div>
                </div>
            </div>
            {/* <div className="xl:col-offset-1"></div> */}
        </div>,
        ["view_metrics"],
        null,
        <Forbidden />
    );
};
export default Metrics;
