import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUpdateRemoveParams, objectHasKeys } from "../../../Utils";
import { checkComponentPermissions, checkPermissions } from "../../../data/Permissions";
import IamService from "../../../services/IamService/IamService";
import UserService from "../../../services/UserService/UserService";
import { userItemTemplate } from "../../People/Users/Utils";

const AddEditRoleDialog = ({ show, setShow, actualRole, roles, setRoles }) => {
    const toast = useRef(null);
    const msgsRef = useRef(null);
    const formRef = useRef();

    const { t } = useTranslation();

    const iamService = new IamService();
    const userService = new UserService();

    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);

    const [users, setUsers] = useState([]);
    const [queryUsersResponse, setQueryUsersResponse] = useState();
    const usersRowsNumber = 5;

    const formik = useFormik({
        initialValues: {
            name: actualRole?.name,
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            if (actualRole) {
                const updateData = getUpdateRemoveParams(actualRole, data);

                iamService
                    .updateRole(updateData, actualRole?.id)
                    .then((data) => {
                        const roleIndex = roles.findIndex((item) => item?.id === actualRole?.id);

                        if (roleIndex >= 0) {
                            let newRoles = [];
                            if (roles) {
                                newRoles = [...roles];
                            }
                            newRoles[roleIndex] = data;
                            setRoles(newRoles);
                            setShow(false);
                        }
                    })
                    .catch((error) => {
                        toast.current.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data });
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                iamService
                    .createRole(data)
                    .then((data) => {
                        let newRoles = [];
                        if (roles) {
                            newRoles = [...roles];
                        }

                        newRoles.push(data);
                        setRoles(newRoles);
                        setShow(false);
                    })
                    .catch((error) => {
                        toast.current.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data });
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }

            formik.resetForm();
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        if (actualRole) {
            if (!checkPermissions(["view_users"])) {
                setShowError(true);
            } else {
                setLoadingUsers(true);

                iamService.queryUsers({ role_id: actualRole?.id, limit: usersRowsNumber }).then((data) => {
                    if (data?.data) {
                        setQueryUsersResponse(data);
                        let retrieveUsersCalls = [];
                        data?.data.forEach((userElement) => {
                            retrieveUsersCalls.push(userService.getUser({}, userElement?.user_id));
                        });

                        Promise.all(retrieveUsersCalls).then((data) => {
                            const parsedData = data?.filter((item) => objectHasKeys(item))

                            setUsers(parsedData);
                            setLoadingUsers(false);
                        });
                    }
                });
            }
        }
    }, []);

    const loadMoreUsers = () => {
        if (queryUsersResponse?.last_key && objectHasKeys(queryUsersResponse?.last_key)) {
            setLoadingUsers(true);

            iamService.queryUsers({ role_id: actualRole?.id, limit: usersRowsNumber }, queryUsersResponse?.last_key).then((data) => {
                if (data?.data) {
                    setQueryUsersResponse(data);
                    let retrieveUsersCalls = [];
                    data?.data.forEach((userElement) => {
                        retrieveUsersCalls.push(userService.getUser({}, userElement?.user_id));
                    });

                    Promise.all(retrieveUsersCalls).then((data) => {
                        const parsedData = data?.filter((item) => objectHasKeys(item))

                        let newUsers = users.concat(parsedData);
                        setUsers(newUsers);
                        setLoadingUsers(false);
                    });
                }
            });
        }
    };

    const unassignRole = (selectedUser) => {
        const userFullname = `${selectedUser?.name} ${selectedUser?.surnames}`.trim();
        confirmDialog({
            message: <span>{t("permissions.page.are_you_sure_you_want_to_remove_the_assignment_to_user_username", { username: userFullname })}</span>,
            header: t("permissions.page.remove_assignment"),
            draggable: false,
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: t("label.yes"),
            accept: () => {
                setLoadingUsers(true);
                iamService
                    .unassignRole({ role_id: actualRole?.id, user_id: selectedUser?.id })
                    .then(() => {
                        setUsers(users?.filter((item) => item?.id !== selectedUser?.id));
                        setLoadingUsers(false);
                    })
                    .catch((error) => {
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data });
                    });
            },
            contentClassName: "pt-3",
        });
    };

    return (
        <Dialog
            visible={show}
            onHide={() => setShow(false)}
            draggable={false}
            className="custom-dialog"
            closable={false}
            dismissableMask
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "24px 24px 0px" }}>
                        <label className="mousee-text font-medium font-weight-bold">{actualRole ? t("permissions.page.edit_role") : t("permissions.page.new_role")}</label>
                    </div>
                </div>
            }
            footer={
                <div className="px-3 pt-3">
                    <Button
                        className="p-button-outlined p-button-plain"
                        label={t("label.cancel")}
                        onClick={() => {
                            setShow(false);
                        }}
                    />
                    <Button form="new-profile-form" className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={() => formik.handleSubmit()} loading={loading} />
                </div>
            }
        >
            <Toast ref={toast} />

            <div className="pt-5">
                <form id="new-profile-form" onSubmit={formik.handleSubmit} innerRef={formRef} className="p-fluid">
                    <div className="grid">
                        <div className="field col-12">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.name")}*</label>
                            <InputText
                                id="name"
                                name="name"
                                placeholder={t("label.name")}
                                value={formik.values.name}
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("name") })}
                                onChange={(e) => {
                                    formik.setFieldValue("name", e.target.value);
                                }}
                            />
                            {getFormErrorMessage("name")}
                        </div>
                    </div>
                </form>
            </div>
            {/* </div> */}

            {actualRole ? (
                <div className="grid">
                    <div className="col-12 pb-0">
                        <label className="mousee-text font-medium font-weight-semibold">{t("label.users")}</label>
                    </div>
                    {checkComponentPermissions(
                        <div className="col-12 pt-0 pl-0">
                            {loadingUsers && users?.length === 0 ? (
                                <div className="flex align-items-center">
                                    <i className="pi pi-spin pi-spinner ml-2" style={{ fontSize: "1rem" }}></i>
                                    <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading_users")}</label>
                                </div>
                            ) : users?.length === 0 ? (
                                <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.no_items_to_display")}</label>
                            ) : (
                                <div className="grid pt-3 pl-4 pr-4">
                                    {users?.map((item, index) => {
                                        return (
                                            <React.Fragment>
                                                <div className="col-12">
                                                    <div className="flex justify-content-between flex-wrap">
                                                        <div className="flex align-items-center justify-content-center">{userItemTemplate(item, "large")}</div>
                                                        <div className="flex align-items-center justify-content-center">
                                                            <Button icon={<Icon icon="solar:trash-bin-trash-bold-duotone" className="" style={{ fontSize: "20px" }} />} className="p-button-text p-button-secondary" onClick={() => unassignRole(item)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <Divider className="border-300 mt-1 mb-1" />
                                            </React.Fragment>
                                        );
                                    })}
                                    {queryUsersResponse?.last_key && objectHasKeys(queryUsersResponse?.last_key) ? (
                                        <div className="col-12">
                                            <Button className="p-button-filled" label={t("label.show_more")} loading={loadingUsers} onClick={loadMoreUsers} />
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        </div>,
                        ["view_users"],
                        null,
                        null
                    )}
                </div>
            ) : null}

            {showError ? (
                <div className="col-12 pl-0 pt-3">
                    <Message
                        severity="error"
                        text={
                            <div className="grid p-0 pl-4 pt-2 pb-2 min-w-full">
                                <div className="col-12 p-0">
                                    <label className="mousee-text font-small font-weight-bold" style={{ color: "var(--danger-color)" }}>
                                        {t("label.access_denied")}
                                    </label>
                                </div>
                                <div className="col-12 p-0">
                                    <label className="mousee-text font-small font-weight-regular" style={{ color: "var(--danger-color)" }}>
                                        {t("label.you_do_not_have_permission_to_perform_this_action")}
                                    </label>
                                </div>
                            </div>
                        }
                    />
                </div>
            ) : null}
        </Dialog>
    );
};
export default AddEditRoleDialog;
