import { Icon } from "@iconify/react";
import React from "react";
import "./InvoiceHeaderItem.scss";

const InvoiceHeaderItem = ({ headerLabel, subheaderLabel, footerLabel, icon, level, ptg }) => {
    const getStrokeColor = (level, ptg) => {
        if (level === "primary") {
            return `rgba(97, 101, 255, ${ptg})`;
        } else if (level === "success") {
            return `rgba(60, 196, 145, ${ptg})`;
        } else if (level === "warning") {
            return `rgba(252, 183, 52, ${ptg})`;
        } else if (level === "danger") {
            return `rgba(250, 78, 87, ${ptg})`;
        } else if (level === "draft") {
            return `rgba(99, 115, 129, ${ptg})`;
        }
    };

    const getIconColor = (level) => {
        if (level === "primary") {
            return "var(--primary-color)";
        } else if (level === "success") {
            return "var(--success-color)";
        } else if (level === "warning") {
            return "var(--warning-color)";
        } else if (level === "danger") {
            return "var(--danger-color)";
        } else if (level === "draft") {
            return `rgba(99, 115, 129, 1)`;
        }
    };

    const getStrokeDashArray = (ptg) => {
        return `calc(${ptg ? (ptg / 100 === 1 ? 2 : ptg / 100) : 0} * calc(6.28319 * 139)) calc(6.28319 * 139)`;
    };

    return (
        <div className="flex flex-wrap align-items-center justify-content-left w-2" style={{ minWidth: "15rem" }}>
            <div class={`flex align-items-center justify-content-left`}>
                <div class="icon-container">
                    <div className={`flex align-items-center justify-content-center absolute`}>
                        <Icon icon={icon} style={{ fontSize: "30px", color: getIconColor(level) }} />
                    </div>
                    <svg x="0px" y="0px" viewBox="0 0 397.6 435.3" enableBackground="new 0 0 397.6 435.3" xmlSpace="preserve" class="icon-border">
                        <circle class="circle-bg" cx="198.3" cy="217.3" r="139.2" transform="rotate(270 198.3 217.3)" stroke={getStrokeColor(level, 0.3)} />

                        <circle strokeDasharray={getStrokeDashArray(ptg)} class="circle" cx="198.3" cy="217.3" r="139.2" transform="rotate(270 198.3 217.3)" stroke={getStrokeColor(level, 1)} />
                    </svg>
                </div>
            </div>

            <div class="flex align-items-center justify-content-center pl-3">
                <div class="flex flex-column">
                    <div class="flex align-items-center justify-content-left">
                        <label className="mousee-text font-small font-weight-bold">{headerLabel}</label>
                    </div>
                    <div class="flex align-items-center justify-content-left pt-2">
                        <label className="mousee-text-small font-x-small font-weight-regular">{subheaderLabel}</label>
                    </div>
                    <div class="flex align-items-center justify-content-left pt-2">
                        <label className="mousee-text font-x-small font-weight-semibold">{footerLabel}</label>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default InvoiceHeaderItem;
