import { useAuthenticator } from "@aws-amplify/ui-react";
import { Buffer } from "buffer";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { getUpdateRemoveParams, objectHasKeys } from "../../../Utils";
import dropdownDown from "../../../assets/icons/dropdown-down.svg";
import VerticalTabMenu from "../../../components/VerticalTabMenu/VerticalTabMenu";
import { USER_STATUSES, getUserStatusLabel } from "../../../data/User";
import ProfileService from "../../../services/ProfileService/ProfileService";
import UserService from "../../../services/UserService/UserService";
import AddLinkOverlay from "./AddLinkOverlay";
import { containsProfile, containsRole, getUserLogoUrl } from "./Utils";

import getSymbolFromCurrency from "currency-symbol-map";
import { Chip } from "primereact/chip";
import { MultiSelect } from "primereact/multiselect";
import { useTranslation } from "react-i18next";
import maximizeIcon from "../../../assets/icons/maximize-icon.svg";
import minimizeIcon from "../../../assets/icons/minimize-icon.svg";
import AvatarUpload from "../../../components/AvatarUpload/AvatarUpload";
import { checkPermissions } from "../../../data/Permissions";
import CategoryService from "../../../services/CategoryService/CategoryService";
import IamService from "../../../services/IamService/IamService";
import TenantService from "../../../services/TenantService/TenantService";
import { filterRoles, handleRolesOnScroll } from "../../Configuration/Permissions/Utils";
import { filterCategories, handleCategoriesOnScroll } from "../../Products/Templates/Utils";
import { filterProfiles, handleProfilesOnScroll } from "../Profiles/Utils";

const EditUserDialog = (props) => {
    const toast = useRef(null);

    const { t, i18n } = useTranslation();

    const userService = new UserService();
    const profileService = new ProfileService();
    const categoryService = new CategoryService();
    const iamService = new IamService();

    const [loading, setLoading] = useState(false);
    const [sendingInvitation, setSendingInvitation] = useState(false);

    const [profilesToDelete, setProfilesToDelete] = useState([]);
    const [profilesToAdd, setProfilesToAdd] = useState([]);
    const [currentFile, setCurrentFile] = useState();
    const fileUploadRef = useRef(null);

    const addEditLinkRef = useRef(null);
    const [activeLinkIndex, setActiveLinkIndex] = useState(undefined);

    const { user } = useAuthenticator((context) => [context.user]);

    const [roles, setRoles] = useState([]);
    const [queryRolesResponse, setQueryRolesResponse] = useState();
    const [rolesRowsNumber, setRolesRowsNumber] = useState(5);
    const [roleFilterName, setRoleFilterName] = useState(undefined);
    const [loadingRoles, setLoadingRoles] = useState(false);
    const [selectedRoles, setSelectedRoles] = useState();

    const [profiles, setProfiles] = useState([]);
    const [queryProfilesResponse, setQueryProfilesResponse] = useState();
    const [profilesRowsNumber, setProfilesRowsNumber] = useState(100);
    const [profileFilterName, setProfileFilterName] = useState(undefined);
    const [loadingProfiles, setLoadingProfiles] = useState(false);

    const [profileVisibility, setProfileVisibility] = useState("public");

    const [categoryVisibility, setCategoryVisibility] = useState("public");
    const [categories, setCategories] = useState();
    const [categoriesRowsNumber, setCategoriesRowsNumber] = useState(10);
    const [queryCategoriesResponse, setQueryCategoriesResponse] = useState();
    const [categoryFilterName, setCategoryFilterName] = useState(undefined);

    const [selectedCategory, setSelectedCategory] = useState();

    const [selectedProfiles, setSelectedProfiles] = useState([]);

    const tenantService = new TenantService();
    const [tenantInfo, setTenantInfo] = useState();

    const [rolesToAdd, setRolesToAdd] = useState([]);
    const [rolesToDelete, setRolesToDelete] = useState([]);
    const [currentRoles, setCurrentRoles] = useState([]);

    useEffect(() => {
        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            tenantService.getTenant(user.attributes["custom:tenant_id"]).then((data) => {
                if (data) {
                    setTenantInfo(data);
                }
            });
        }
    }, []);

    useEffect(() => {
        setLoadingRoles(true);

        iamService.queryRoles({ limit: rolesRowsNumber }).then((data) => {
            setQueryRolesResponse(data);
            setRoles(data?.data);
            iamService.queryRoles({ limit: 50, user_id: props?.user?.id }).then((data) => {
                setSelectedRoles(data?.data);
                setCurrentRoles(data?.data);
                setLoadingRoles(false);
            });
        });
    }, []);

    useEffect(() => {
        setLoading(true);

        userService
            .getUser({}, props.user.id)
            .then((data) => {
                // profileService.queryProfiles({limit: 50, user_id: props.user.id}).then(data => {
                profileService.queryProfiles({ limit: 50, user_id: props.user.id }, undefined, "private").then((data) => {
                    if (data) {
                        // formik.setFieldValue("profiles", data?.data)
                        setSelectedProfiles(data?.data);
                    }
                });
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        refreshCategories();
    }, []);

    const refreshCategories = (visibility) => {
        setLoading(true);
        categoryService.queryCategories({ limit: categoriesRowsNumber }, undefined, "profile", visibility ? visibility : categoryVisibility)
        .then((data) => {
            setQueryCategoriesResponse(data);
            setCategories(data?.data);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
            toast?.current?.show({severity: 'error', summary: t("label.error"), detail: error?.response?.data?.message, life: 5000});
        })
    };

    const formik = useFormik({
        initialValues: {
            name: props?.user?.name,
            surnames: props?.user?.surnames,
            email: props?.user?.email,
            phone: props?.user?.phone,
            birthday: props?.user?.birthday,
            type: props?.user?.type,
            status: props?.user?.status,
            links: props?.user?.links,
            hour_cost: props?.user?.hour_cost,
            avatar: props?.user?.avatar,
            external: props?.user?.external,

            // image: undefined,
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = t("label.field_required");
            }

            if (!data.surnames) {
                errors.surnames = t("label.field_required");
            }

            if (!data.email) {
                errors.email = t("label.field_required");
            }

            if (!data.type) {
                errors.type = t("label.field_required");
            }

            if (checkPermissions(["view_hour_cost"])) {
                if (!data.hour_cost) {
                    errors.hour_cost = t("label.field_required");
                }
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            let newData = {
                ...props.user,
                ...data,
            };

            if (currentFile) {
                const newPicture = getUserLogoUrl(user, props.user.id, currentFile.name);
                newData["avatar"] = newPicture;
            }

            const updateData = getUpdateRemoveParams(props.user, newData);

            userService
                .updateUser({}, updateData, props.user.id)
                .then((data) => {
                    if (rolesToAdd.length > 0 || rolesToDelete.length > 0) {
                        let assignmentRoleCalls = [];

                        const parsedRolesToAdd = rolesToAdd.filter((item) => !containsRole(currentRoles, item?.id));
                        if (parsedRolesToAdd.length > 0) {
                            parsedRolesToAdd.forEach((roleToAdd) => {
                                if (props?.user?.id && roleToAdd?.id) {
                                    assignmentRoleCalls.push(iamService.assignRole({ user_id: props?.user?.id, role_id: roleToAdd?.id }));
                                }
                            });
                        }
                        const parsedRolesToDelete = rolesToDelete.filter((item) => containsRole(currentRoles, item?.id));
                        if (parsedRolesToDelete.length > 0) {
                            parsedRolesToDelete.forEach((roleToDelete) => {
                                if (props?.user?.id && roleToDelete?.id) {
                                    assignmentRoleCalls.push(iamService.unassignRole({ user_id: props?.user?.id, role_id: roleToDelete?.id }));
                                }
                            });
                        }
                        Promise.all(assignmentRoleCalls);
                    }

                    if (profilesToAdd.length > 0 || profilesToDelete.length > 0) {
                        let asignUserData = {};

                        if (profilesToAdd.length > 0) {
                            asignUserData = {
                                add: profilesToAdd.map((newProfile) => `${newProfile.visibility}#${newProfile.id}`),
                            };
                        }

                        if (profilesToDelete.length > 0) {
                            asignUserData = {
                                ...asignUserData,
                                remove: profilesToDelete.map((newProfile) => `${newProfile.visibility}#${newProfile.id}`),
                            };
                        }

                        profileService.asignUser(asignUserData, props.user.id);
                        userService.asignProfiles(asignUserData, props.user.id);
                    }
                    if (props.user && data.avatar && data.avatar !== props.user.avatar) {
                        convertToBase64(currentFile).then((convertedFile) => {
                            fetch(data.avatar, {
                                method: "PUT",
                                body: new Buffer.from(convertedFile.replace(/^data:image\/\w+;base64,/, ""), "base64"),
                                headers: {
                                    "Content-type": currentFile.type,
                                },
                            })
                                .then((response) => {
                                    console.log(response);
                                    updateUsers(props.user.id, data);
                                    setLoading(false);
                                    props.onHide(false);
                                })
                                .catch((error) => {
                                    console.error("Error:", error);
                                    formik.resetForm();
                                    setLoading(false);
                                    props.onHide(false);
                                });
                        });
                    } else {
                        updateUsers(props.user.id, data);
                        setLoading(false);
                        props.onHide(false);
                    }
                    formik.resetForm();
                    props.setUser(undefined);
                })
                .catch((error) => {
                    setLoading(false);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data, life: 5000 });
                });
        },
    });

    const updateUsers = (currentUserId, newData) => {
        let newUsers = props.users;
        const currentUserIndex = newUsers.findIndex((item) => item.id === currentUserId);
        newUsers[currentUserIndex] = newData;
        props.setUsers(newUsers);
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
        });
    };

    const onProfileChange = (checked, profile) => {
        setLoading(true);
        if (checked) {
            // Remove from profilesToDelete if it exists there
            let newProfilesToDelete = profilesToDelete;
            if (containsProfile(newProfilesToDelete, profile.id)) {
                newProfilesToDelete = newProfilesToDelete.filter((profileToDelete) => profileToDelete.id !== profile.id);
                setProfilesToDelete(newProfilesToDelete);
            }
            // Add to profilesToAdd if it not exists there
            if (!containsProfile(profilesToAdd, profile.id)) {
                setProfilesToAdd([...profilesToAdd, profile]);
            }
            setSelectedProfiles([...selectedProfiles, profile]);
        } else {
            // Remove from profilesToAdd if it exists there
            let newProfilesToAdd = profilesToAdd;
            if (containsProfile(newProfilesToAdd, profile.id)) {
                newProfilesToAdd = newProfilesToAdd.filter((profileToAdd) => profileToAdd.id !== profile.id);
                setProfilesToAdd(newProfilesToAdd);
            }

            // Add to profilesToDelete if it not exists there
            if (!containsProfile(profilesToDelete, profile.id)) {
                setProfilesToDelete([...profilesToDelete, profile]);
            }
            setSelectedProfiles([...selectedProfiles, profile]);
        }
        setLoading(false);
    };

    const onRoleChange = (checked, role) => {
        setLoading(true);
        if (checked) {
            // Remove from rolesToDelete if it exists there
            let newRolesToDelete = rolesToDelete;
            if (containsRole(newRolesToDelete, role.id)) {
                newRolesToDelete = newRolesToDelete.filter((roleToDelete) => roleToDelete.id !== role.id);
                setRolesToDelete(newRolesToDelete);
            }
            // Add to rolesToAdd if it not exists there
            if (!containsRole(rolesToAdd, role.id)) {
                setRolesToAdd([...rolesToAdd, role]);
            }
            setSelectedRoles([...selectedRoles, role]);
        } else {
            // Remove from rolesToAdd if it exists there
            let newRolesToAdd = rolesToAdd;
            if (containsRole(newRolesToAdd, role.id)) {
                newRolesToAdd = newRolesToAdd.filter((roleToAdd) => roleToAdd.id !== role.id);
                setRolesToAdd(newRolesToAdd);
            }

            // Add to rolesToDelete if it not exists there
            if (!containsRole(rolesToDelete, role.id)) {
                setRolesToDelete([...rolesToDelete, role]);
            }
            setSelectedRoles(selectedRoles.filter((item) => item.id !== role?.id));
        }
        setLoading(false);
    };

    const refreshProfiles = (visibility, categoryId, name) => {
        setLoading(true);

        let queryParameters = {
            limit: profilesRowsNumber,
        };

        if (categoryId) {
            queryParameters = {
                ...queryParameters,
                category_id: categoryId,
            };
        } else if (name && name.length > 0) {
            queryParameters = {
                ...queryParameters,
                name: name,
            };
        } else if (selectedCategory) {
            queryParameters = {
                ...queryParameters,
                category_id: selectedCategory?.id,
            };
        }

        let lastKey = undefined;

        if (visibility === profileVisibility && queryProfilesResponse && queryProfilesResponse?.last_key && objectHasKeys(queryProfilesResponse?.last_key)) {
            lastKey = queryProfilesResponse?.last_key;
        }

        // profileService.queryProfiles(queryParameters, lastKey, visibility ? visibility : profileVisibility)
        profileService.queryProfiles(queryParameters, lastKey, "private").then((data) => {
            setQueryProfilesResponse(data);
            setProfiles(data?.data);
            setLoading(false);
        });
    };

    const personalDataContent = () => {
        return (
            <React.Fragment>
                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-3">
                        <AvatarUpload inputFileRef={fileUploadRef} file={currentFile ? currentFile : formik.values.avatar} setFile={setCurrentFile} />
                    </div>

                    <div className="col-12 md:col-12 lg:col-9">
                        <div className="grid">
                            <div className="col-12">
                                <div className="field">
                                    <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2">
                                        {t("label.name")}*
                                    </label>
                                    <InputText
                                        id="name"
                                        name="name"
                                        placeholder={t("label.name")}
                                        value={formik.values.name}
                                        className={classNames("w-full", { "p-invalid": formik.errors.name })}
                                        onChange={(e) => {
                                            formik.setFieldValue("name", e.target.value);
                                        }}
                                    />
                                    {getFormErrorMessage("name")}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="field">
                                    <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2">
                                        {t("label.surnames")}*
                                    </label>
                                    <InputText
                                        id="surnames"
                                        name="surnames"
                                        placeholder={t("label.surnames")}
                                        value={formik.values.surnames}
                                        className={classNames("w-full", { "p-invalid": formik.errors.surnames })}
                                        onChange={(e) => {
                                            formik.setFieldValue("surnames", e.target.value);
                                        }}
                                    />
                                    {getFormErrorMessage("surnames")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="grid pt-0 md:pt-0 lg:pt-3">
                        <div className="col-12 md:col-12 lg:col-4">
                            <div className="field">
                                <label htmlFor="form-name" className="block mousee-text font-x-small font-weight-semibold pl-2">
                                    {t("label.email")}*
                                </label>
                                <InputText
                                    id="email"
                                    name="email"
                                    placeholder={t("label.email")}
                                    value={formik.values.email}
                                    disabled={true}
                                    className={classNames("w-full", { "p-invalid": formik.errors.email })}
                                    onChange={(e) => {
                                        formik.setFieldValue("email", e.target.value);
                                    }}
                                />
                                {getFormErrorMessage("email")}
                            </div>
                        </div>

                        <div className="col-12 md:col-12 lg:col-4">
                            <div className="field">
                                <label htmlFor="form-name" className="block mousee-text font-x-small font-weight-semibold pl-2">
                                    {t("label.phone_number")}
                                </label>
                                <InputText
                                    id="phone"
                                    name="phone"
                                    placeholder={t("label.phone_number")}
                                    value={formik.values.phone}
                                    className="w-full"
                                    onChange={(e) => {
                                        formik.setFieldValue("phone", e.target.value);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-12 md:col-12 lg:col-4">
                            <div className="field">
                                <label htmlFor="form-name" className="block mousee-text font-x-small font-weight-semibold pl-2">
                                    {t("users.page.birthday")}
                                </label>
                                <Calendar className="min-w-full" iconPos="left" showIcon maxDate={new Date()} value={formik.values.birthday} onChange={(e) => formik.setFieldValue("birthday", e.value)} locale={i18n.language.split("-")[0]} />
                            </div>
                        </div>
                    </div>
                </div>

                {formik.values.links && formik.values.links.length > 0 ? (
                    <React.Fragment>
                        <label className="block mousee-text font-small font-weight-semibold pl-2">{t("users.page.links")}</label>

                        {formik.values.links.map((link, index) => {
                            return (
                                <div className="col-12">
                                    <div className="flex flex-row flex-wrap">
                                        <div className="flex align-items-center justify-content-center pr-4">
                                            <a href={link?.url} rel="noopener noreferrer" target="_blank">
                                                {link?.name}
                                            </a>
                                        </div>
                                        <div className="flex align-items-center justify-content-center">
                                            <i
                                                className="pi pi-times-circle"
                                                onClick={() =>
                                                    formik.setFieldValue(
                                                        "links",
                                                        formik.values.links.filter((_, itemIndex) => itemIndex !== index)
                                                    )
                                                }
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </React.Fragment>
                ) : null}

                <div className="col-12">
                    <Button
                        className="p-button-filled"
                        icon="pi pi-plus-circle"
                        label={t("users.page.add_link")}
                        disabled={formik.values.links && formik.values.links.length > 0 && formik.values.links[formik.values.links.length - 1].name.trim() === ""}
                        onClick={(e) => {
                            addEditLinkRef?.current?.toggle(e);
                        }}
                    />
                </div>
            </React.Fragment>
        );
    };

    const onCategoryChange = (newValue) => {
        setSelectedCategory(newValue);
        refreshProfiles(profileVisibility, newValue?.id, undefined);
    };

    const profileDataContent = () => {
        return (
            <React.Fragment>
                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-6">
                        <div className="flex flex-column card-container">
                            <div className="flex align-items-left justify-content-left">
                                <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold block pl-2">
                                    {t("label.roles")}
                                </label>
                            </div>
                            <MultiSelect
                                dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                value={selectedRoles && roles && selectedRoles.filter((item) => containsRole(roles, item.id))}
                                onChange={(e) => {
                                    const role = e?.selectedOption;
                                    let checked = !containsRole(selectedRoles, role?.id);

                                    onRoleChange(checked, role);

                                    if (checked) {
                                        setSelectedRoles([...selectedRoles, role]);
                                    } else {
                                        setSelectedRoles(selectedRoles.filter((item) => item.id !== role?.id));
                                    }
                                }}
                                options={roles}
                                optionLabel="name"
                                display="chip"
                                placeholder={t("label.dropdown_select")}
                                maxSelectedLabels={3}
                                className="w-full"
                                showSelectAll={false}
                                filter
                                closeIcon={<></>}
                                emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                onScrollCapture={(e) => handleRolesOnScroll(e, iamService, rolesRowsNumber, queryRolesResponse, setQueryRolesResponse, roles, setRoles, roleFilterName, setRoleFilterName)}
                                filterTemplate={(options) => (
                                    <div className="flex flex-column min-w-full">
                                        <div className="flex align-items-center justify-content-center">
                                            <span className="p-input-icon-right w-full mr-2">
                                                <i className="pi pi-search" />
                                                <InputText autoFocus className="min-w-full" value={roleFilterName} ref={null} onChange={(e) => filterRoles(e, options, setRoleFilterName, queryRolesResponse, setQueryRolesResponse, iamService, rolesRowsNumber, roles, setRoles)} />
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                    </div>

                    {selectedRoles ? (
                        <div className="col-12 pt-0">
                            <div className="flex flex-row flex-wrap ">
                                {selectedRoles.map((item, index) => {
                                    return (
                                        <div className="flex align-items-center justify-content-center ml-2 mt-2">
                                            <Chip key={`chip-item-${item?.id}`} label={item?.name} style={{ borderRadius: "var(--border-radius-small)" }} removable onRemove={() => onRoleChange(false, item)} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : null}

                    <React.Fragment>
                        <div className="col-12">
                            <div className="flex align-items-center pl-2">
                                <Checkbox inputId="line-divisor" name="line-divisor" onChange={() => formik.setFieldValue("external", !formik.values.external)} checked={formik.values.external} />
                                <label htmlFor="line-divisor" className="mousee-text font-x-small font-weight-regular ml-2">
                                    {t("label.freelance")}
                                </label>
                            </div>
                        </div>
                        <div className="col-12">
                            <Divider className="border-300" />
                        </div>
                        <div className="col-12">
                            <label className="mousee-text font-medium font-weight-semibold">{t("label.skills")}</label>
                        </div>

                        <div className="col-12 xl:col-6">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.category")}*</label>
                            <Dropdown
                                dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                id="profile-categories-dowpdown"
                                name="profile-categories-dowpdown"
                                key="profile-categories-dowpdown"
                                value={selectedCategory}
                                options={categories}
                                optionLabel="name"
                                filter
                                emptyMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                onChange={(e) => {
                                    onCategoryChange(e.value);
                                }}
                                placeholder={t("label.dropdown_select")}
                                className="w-full"
                                onScrollCapture={(e) => handleCategoriesOnScroll(e, categoryService, categoriesRowsNumber, queryCategoriesResponse, setQueryCategoriesResponse, categories, setCategories, categoryFilterName, setCategoryFilterName, "profile", categoryVisibility)}
                                filterTemplate={(options) => (
                                    <span className="p-input-icon-right min-w-full">
                                        <i className="pi pi-search" />
                                        <InputText
                                            autoFocus
                                            className="min-w-full"
                                            value={categoryFilterName}
                                            ref={null}
                                            onChange={(e) => filterCategories(e, options, setCategoryFilterName, queryCategoriesResponse, setQueryCategoriesResponse, categoryService, categoriesRowsNumber, categories, setCategories, "profile", categoryVisibility)}
                                        />
                                    </span>
                                )}
                            />
                        </div>

                        <div className="col-12 xl:col-6">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.skills")}*</label>
                            <MultiSelect
                                dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                value={selectedProfiles && profiles && selectedProfiles.filter((item) => containsProfile(profiles, item.id))}
                                onChange={(e) => {
                                    const profile = e?.selectedOption;
                                    let checked = !containsProfile(selectedProfiles, profile?.id);

                                    onProfileChange(checked, profile);

                                    // if (checked) {
                                    //     setSelectedProfiles([...selectedProfiles, profile]);
                                    // } else {
                                    //     setSelectedProfiles(selectedProfiles.filter((item) => item.id !== profile?.id));
                                    // }
                                }}
                                disabled={!selectedCategory}
                                options={profiles}
                                optionLabel="name"
                                useOptionAsValue={false}
                                showSelectAll={false}
                                display="chip"
                                placeholder={t("label.dropdown_select")}
                                filter
                                itemTemplate={(item) => {
                                    return item?.name;
                                }}
                                className={classNames("w-full", { "p-invalid": formik.errors.profiles })}
                                closeIcon={<></>}
                                emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                onScrollCapture={(e) => handleProfilesOnScroll(e, profileService, profilesRowsNumber, queryProfilesResponse, setQueryProfilesResponse, profiles, setProfiles, profileFilterName, setProfileFilterName, profileVisibility)}
                                filterTemplate={(options) => (
                                    <div className="flex flex-column min-w-full">
                                        <div className="flex align-items-center justify-content-center">
                                            <span className="p-input-icon-right w-full mr-2">
                                                <i className="pi pi-search" />
                                                <InputText
                                                    autoFocus
                                                    className="min-w-full"
                                                    value={profileFilterName}
                                                    ref={null}
                                                    onChange={(e) => filterProfiles(e, options, setProfileFilterName, queryProfilesResponse, setQueryProfilesResponse, profileService, profilesRowsNumber, profiles, setProfiles, profileVisibility)}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>

                        {selectedProfiles ? (
                            <div className="col-12">
                                <div className="flex flex-row flex-wrap ">
                                    {selectedProfiles.map((item, index) => {
                                        return (
                                            <div className="flex align-items-center justify-content-center ml-2 mt-2">
                                                <Chip key={`chip-item-${item?.id}`} label={item?.name} style={{ borderRadius: "var(--border-radius-small)" }} removable onRemove={() => onProfileChange(false, item)} />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : null}
                    </React.Fragment>
                </div>
            </React.Fragment>
        );
    };

    const activityDataContent = () => {
        return (
            <div className="grid">
                <div className="col-12">
                    <label className="mousee-text font-medium font-weight-semibold">{t("users.page.company_cost")}</label>
                </div>
                <div className="col-12 md:col-12 lg:col-3">
                    <label className="block pl-2 mousee-text font-x-small font-weight-semibold pl-2">{t("label.hour_cost")}*</label>
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi">
                                <label className="mousee-text-small font-medium font-weight-bold">{getSymbolFromCurrency(tenantInfo?.currency)}</label>
                            </i>
                        </span>
                        <InputNumber
                            value={formik.values.hour_cost}
                            min={0}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            locale={i18n.language.split("-")[0]}
                            onValueChange={(e) => formik.setFieldValue("hour_cost", e.value)}
                            className={classNames("w-full", { "p-invalid": isFormFieldValid("hour_cost") })}
                        />
                    </div>

                    <small className="pl-2 mousee-text-small font-weight-regular">{t("label.enter_the_cost_per_hour")}</small>
                    <br />
                    {getFormErrorMessage("hour_cost")}
                </div>
            </div>
        );
    };

    const editItems = [
        {
            id: "personal_data",
            label: t("label.general_information"),
            content: personalDataContent(),
            show: true,
        },
        {
            id: "profile",
            label: t("label.skill"),
            content: profileDataContent(),
            show: true,
        },
        {
            id: "activity",
            label: t("label.salary_details"),
            content: activityDataContent(),
            show: checkPermissions(["view_hour_cost"]),
        },
    ];

    const userStatuses = () => {
        let userStatuses = USER_STATUSES;

        for (let i = 0; i < userStatuses.length; i++) {
            const currentStatus = userStatuses[i];
            userStatuses[i] = {
                ...userStatuses[i],
                label: t(userStatuses[i]?.label),
                command: () => formik.setFieldValue("status", currentStatus.type),
            };
        }

        return userStatuses;
    };

    const resendPassword = () => {
        setLoading(true);
        userService
            .executeUserAction({ action: "RESEND" }, props.user.id)
            .then((data) => {
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const sendInvitation = (userId) => {
        setSendingInvitation(true);

        const updateParams = {
            status: "active",
        };

        userService.updateUser({}, { update_params: updateParams }, userId).then((data) => {
            if (data) {
                updateUsers(userId, data);
                // formik.setValues(data)

                formik.setValues({
                    name: data?.name,
                    surnames: data?.surnames,
                    email: data?.email,
                    phone: data?.phone,
                    birthday: data?.birthday,
                    type: data?.type,
                    status: data?.status,
                    links: data?.links,
                    hour_cost: data?.hour_cost,
                    avatar: data?.avatar,
                    external: data?.external,
                });
            }

            setSendingInvitation(false);
        });
    };

    return (
        <Dialog
            visible={props.show}
            onHide={() => {
                props.onHide(false);
                props.setUser(null);
            }}
            style={{ width: "65vw", minHeight: "20vw" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            draggable={false}
            // maximizable
            contentClassName="pb-0"
            maximizeIcon={<img alt={"dialog-maximize-icon"} src={maximizeIcon} className="" style={{ width: "1.5rem" }}></img>}
            minimizeIcon={<img alt={"dialog-minimize-icon"} src={minimizeIcon} className="" style={{ width: "1.5rem" }}></img>}
            header={
                <div>
                    <div className="flex justify-content-between flex-wrap card-container">
                        <div className="flex align-items-center justify-content-center">
                            <span className="mousee-text font-medium font-weight-bold">{t("label.edit_user")}</span>
                        </div>
                        <div className="flex align-items-center justify-content-center pr-3">
                            {formik.values.status === "draft" ? (
                                <Button type="button" icon="pi pi-send" className="p-button-filled p-button-secondary" label={t("label.send_invitation")} loading={sendingInvitation} onClick={(e) => sendInvitation(props.user.id)} />
                            ) : (
                                <React.Fragment>
                                    <Button className="pr-4" label={t("label.restore_password")} type="button" link onClick={() => resendPassword()} />
                                    <SplitButton label={t(getUserStatusLabel(formik.values.status))} icon="pi pi-bookmark" dropdownIcon={"pi pi-caret-down"} className={`p-button-${formik.values.status === "active" ? "success" : "warning"}`} model={userStatuses()} />
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            }
            footer={
                <div>
                    <Button form="edit-user-form" className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={() => formik.handleSubmit()} loading={loading} />
                </div>
            }
        >
            <Toast ref={toast} />
            <AddLinkOverlay addEditLinkRef={addEditLinkRef} linkIndex={activeLinkIndex} links={formik.values.links} setLinks={(newLinks) => formik.setFieldValue("links", newLinks)} />
            <VerticalTabMenu items={editItems} style={{ minHeight: "20vw" }} />
        </Dialog>
    );
};
export default EditUserDialog;
