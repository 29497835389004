import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomTablePaginator from "../../../components/CustomTablePaginator";
import { checkComponentPermissions, checkPermissions } from "../../../data/Permissions";
import { getAdditionalTopBarComponentsV2 } from "../../../data/TabsData";
import ProfileService from "../../../services/ProfileService/ProfileService";
import { FormatCurrency, formatDateV2, objectHasKeys, tableHeaderTemplate } from "../../../Utils";
import Forbidden from "../../Auth/Forbidden";
import AddProfileDialog from "./AddProfileDialog";
import EditProfileDialog from "./EditProfileDialog";

import { useAuthenticator } from "@aws-amplify/ui-react";
import { Icon } from "@iconify/react";
import { Dropdown } from "primereact/dropdown";
import { Menu } from "primereact/menu";
import { useTranslation } from "react-i18next";
import dropdownDown from "../../../assets/icons/dropdown-down.svg";
import CategoryService from "../../../services/CategoryService/CategoryService";
import TenantService from "../../../services/TenantService/TenantService";
import { filterCategories, handleCategoriesOnScroll } from "../../Products/Templates/Utils";

const Profiles = (props) => {
    const { t, i18n } = useTranslation();
    const [showNewProfileDialog, setShowNewProfileDialog] = useState(false);
    const [showEditProfileDialog, setShowEditProfileDialog] = useState(false);
    const [profileVisibility, setProfileVisibility] = useState("private");

    const profileService = new ProfileService();
    const categoryService = new CategoryService();
    const menuRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [activeProfile, setActiveProfile] = useState();

    const [profiles, setProfiles] = useState([]);
    const [queryProfilesResponse, setQueryProfilesResponse] = useState();
    const [tableFirst, setTableFirst] = useState(0);
    const [tableKeys, setTableKeys] = useState([0]);
    const [rowsNumber, setRowsNumber] = useState(10);
    const [activeIndex, setActiveIndex] = useState(0);

    const [categoryVisibility, setCategoryVisibility] = useState("public");
    const [categories, setCategories] = useState();
    const [categoriesRowsNumber, setCategoriesRowsNumber] = useState(10);
    const [queryCategoriesResponse, setQueryCategoriesResponse] = useState();
    const [categoryFilterName, setCategoryFilterName] = useState(undefined);

    const tenantService = new TenantService();
    const { user } = useAuthenticator((context) => [context.user]);
    const [tenantInfo, setTenantInfo] = useState();

    useEffect(() => {
        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            tenantService.getTenant(user.attributes["custom:tenant_id"]).then((data) => {
                if (data) {
                    setTenantInfo(data);
                }
            });
        }
    }, []);

    useEffect(() => {
        props.setTopbarAdditionalComponents(getAdditionalTopBarComponentsV2("profiles", navigate));
    }, []);

    useEffect(() => {
        props.setOption("people");
    }, []);

    useEffect(() => {
        formik.resetForm();
        refreshProfiles(profileVisibility, undefined, undefined);
        refreshCategories();
    }, []);

    const formik = useFormik({
        initialValues: {
            text: "",
            category: undefined,
        },
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);
            let queryParameter = {
                limit: rowsNumber,
            };
            if (data.text && data.text.length > 0) {
                queryParameter = {
                    ...queryParameter,
                    name: data.text,
                };
            }

            formik.setFieldValue("category", undefined);
            profileService.queryProfiles(queryParameter, undefined, "private").then((data) => {
                setTableFirst(0);
                setTableKeys([0]);
                setActiveIndex(0);
                setQueryProfilesResponse(data);
                setProfiles(data.data);
                setLoading(false);
            });
        },
    });

    const refreshCategories = (visibility) => {
        setLoading(true);
        categoryService.queryCategories({ limit: categoriesRowsNumber }, undefined, "profile", visibility ? visibility : categoryVisibility)
        .then((data) => {
            setQueryCategoriesResponse(data);
            setCategories(data?.data);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
        })
    };

    const refreshProfiles = (visibility, categoryId, name) => {
        setLoading(true);

        let queryParameters = {
            limit: rowsNumber,
        };

        if (categoryId) {
            queryParameters = {
                ...queryParameters,
                category_id: categoryId,
            };
        } else if (name && name.length > 0) {
            queryParameters = {
                ...queryParameters,
                name: name,
            };
        } else if (formik.values.category) {
            queryParameters = {
                ...queryParameters,
                category_id: formik.values.category?.id,
            };
        }

        let lastKey = undefined;

        if (visibility === profileVisibility && queryProfilesResponse && queryProfilesResponse?.last_key && objectHasKeys(queryProfilesResponse?.last_key)) {
            lastKey = queryProfilesResponse?.last_key;
        }

        profileService.queryProfiles(queryParameters, lastKey, "private").then((data) => {
            setQueryProfilesResponse(data);
            setProfiles(data?.data);
            setLoading(false);
        });
    };

    const deleteProfile = (item) => {
        confirmDialog({
            message: <span>{t("skills.page.are_you_sure_you_want_to_delete_the_skill", { skill_name: item?.name })}</span>,
            header: t("skills.page.delete_skill"),
            draggable: false,
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: t("label.yes"),
            rejectLabel: t("label.cancel"),
            rejectClassName: "p-button-outlined p-button-plain",
            accept: () => {
                profileService.deleteProfile(item.id);
                const newProfiles = profiles.filter((newProfile) => newProfile.id !== item.id);
                setProfiles(newProfiles);
            },
            contentClassName: "pt-3",
        });
    };

    const onTablePageChange = (key) => {
        if (key === tableKeys.length) {
            setLoading(true);

            let queryParameters = {
                limit: rowsNumber,
            };

            if (formik.values.text) {
                queryParameters = {
                    ...queryParameters,
                    name: formik.values.text,
                };
            } else if (formik.values.category) {
                queryParameters = {
                    ...queryParameters,
                    category_id: formik.values.category?.id,
                };
            }

            profileService.queryProfiles(queryParameters, queryProfilesResponse.last_key, "private").then((data) => {
                setQueryProfilesResponse(data);

                if (data.data && data.data.length > 0) {
                    const newProfiles = [...profiles, ...data.data];
                    setProfiles(newProfiles);

                    setTableKeys(tableKeys.concat([tableKeys.length]));
                    setActiveIndex(tableKeys.length);

                    if (tableKeys.length > 0) {
                        setTableFirst(tableKeys.length * rowsNumber);
                    } else {
                        setTableFirst(0);
                    }
                }

                setLoading(false);
            });
        } else {
            const newIndex = tableKeys.findIndex((item) => item === key);
            setActiveIndex(newIndex);

            if (newIndex > 0) {
                setTableFirst(newIndex * rowsNumber);
            } else {
                setTableFirst(0);
            }
        }
    };

    const filterByParams = (e, type) => {
        setLoading(true);

        formik.setFieldValue(type, e.value);

        let flag = false;

        if (type === "category") {
            if (e.value) {
                flag = true;

                profileService.queryProfiles({ limit: rowsNumber, category_id: e?.value?.id }, undefined, "private").then((data) => {
                    setTableFirst(0);
                    setTableKeys([0]);
                    setActiveIndex(0);
                    setQueryProfilesResponse(data);
                    setProfiles(data?.data);
                    setLoading(false);
                });
            }
        }

        if (!flag) {
            profileService.queryProfiles({ limit: rowsNumber }, undefined, "private").then((data) => {
                setTableFirst(0);
                setTableKeys([0]);
                setActiveIndex(0);
                setQueryProfilesResponse(data);
                setProfiles(data?.data);
                setLoading(false);
            });
        }
    };

    const getMenuItems = (item) => {
        let items = [
            {
                label: t("label.edit"),
                icon: <Icon icon="solar:pen-bold" className="mr-3" style={{ fontSize: "20px" }} />,
                command: () => setShowEditProfileDialog(true),
            },
            {
                label: t("label.delete"),
                icon: <Icon icon="solar:trash-bin-trash-bold-duotone" className="mr-3" style={{ fontSize: "20px" }} />,
                command: () => deleteProfile(item),
            },
        ];

        return items;
    };

    const profilesTableHeader = (
        <div className="grid">
            <div className="col-12 md:col-4">
                <div className="flex align-content-end flex-wrap w-full h-full">
                    <div className="flex align-items-end justify-content-center w-full h-full">
                        <Dropdown
                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                            id="profile-categories-dowpdown"
                            name="profile-categories-dowpdown"
                            key="profile-categories-dowpdown"
                            value={formik.values.category}
                            options={categories}
                            optionLabel="name"
                            filter
                            showClear
                            emptyMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                            emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                            onChange={(e) => filterByParams(e, "category")}
                            placeholder={t("label.all_categories")}
                            className="w-full"
                            onScrollCapture={(e) => handleCategoriesOnScroll(e, categoryService, categoriesRowsNumber, queryCategoriesResponse, setQueryCategoriesResponse, categories, setCategories, categoryFilterName, setCategoryFilterName, "profile", categoryVisibility)}
                            filterTemplate={(options) => (
                                <span className="p-input-icon-right min-w-full">
                                    <i className="pi pi-search" />
                                    <InputText
                                        autoFocus
                                        className="min-w-full"
                                        value={categoryFilterName}
                                        ref={null}
                                        onChange={(e) => filterCategories(e, options, setCategoryFilterName, queryCategoriesResponse, setQueryCategoriesResponse, categoryService, categoriesRowsNumber, categories, setCategories, "profile", categoryVisibility)}
                                    />
                                </span>
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12 md:col-8">
                <form id="search-team-form" onSubmit={formik.handleSubmit} className="min-w-full">
                    <small className="pl-2 mousee-text-small font-weight-regular">{t("label.use_upper_and_lower_case")}</small>
                    <span className="p-input-icon-right min-w-full">
                        <i className="pi pi-search" />
                        <InputText
                            value={formik.values.text}
                            className="w-full xl:min-w-full"
                            onChange={(e) => {
                                formik.setFieldValue("text", e.target.value);
                            }}
                            placeholder={t("label.search_skill")}
                        />
                    </span>
                </form>
            </div>
        </div>
    );

    return checkComponentPermissions(
        <div className="grid page-layout">
            <ConfirmDialog />
            {showNewProfileDialog ? <AddProfileDialog show={showNewProfileDialog} setShow={setShowNewProfileDialog} profiles={profiles} setProfiles={setProfiles} reload={setLoading} /> : null}
            {showEditProfileDialog ? <EditProfileDialog show={showEditProfileDialog} setShow={setShowEditProfileDialog} profile={activeProfile} setProfile={setActiveProfile} profiles={profiles} setProfiles={setProfiles} /> : null}
            <Menu model={getMenuItems(activeProfile)} popup ref={menuRef} />

            {/* <div className="xl:col-offset-1"></div> */}
            <div className="col-12 xl:col-12">
                <div className="grid">
                    <div className="col-12 p-0">
                        <div className="flex  justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <div className="grid p-0">
                                    <div className="col-12 p-0">
                                        <label className="mousee-text font-large font-weight-semibold">{t("label.skills")}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <div className="flex flex-row flex-wrap">
                                    {checkComponentPermissions(
                                        <div className="flex align-items-center justify-content-center">
                                            <Button icon={"pi pi-plus-circle"} label={t("skills.page.add_skill")} type="button" className="p-button xl:ml-2 w-full" onClick={() => setShowNewProfileDialog(true)} />
                                        </div>,
                                        ["add_profiles"],
                                        null,
                                        null
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 p-0 pt-5">
                        <div className="card p-0" style={{ overflow: "hidden" }}>
                            <DataTable
                                key="team-profiles-table"
                                dataKey="id"
                                emptyMessage={t("label.no_items_to_display")}
                                loading={loading}
                                rowClassName="cursor-pointer"
                                value={profiles.slice(activeIndex * rowsNumber, activeIndex * rowsNumber + rowsNumber)}
                                responsiveLayout="scroll"
                                stripedRows
                                onRowClick={(e) => {
                                    if (checkPermissions(["edit_profiles"])) {
                                        setActiveProfile(e?.data);
                                        setShowEditProfileDialog(true);
                                    }
                                }}
                                sortIcon={(item) => {
                                    if (item?.sorted) {
                                        if (item?.sortOrder === 1) {
                                            return <Icon icon="solar:sort-from-bottom-to-top-bold-duotone" style={{ fontSize: "20px" }} />;
                                        } else if (item?.sortOrder === -1) {
                                            return <Icon icon="solar:sort-from-top-to-bottom-bold-duotone" style={{ fontSize: "20px" }} />;
                                        }
                                    }
                                }}
                                header={profilesTableHeader}
                                first={tableFirst}
                                footer={<CustomTablePaginator activeIndex={activeIndex} onPageChange={onTablePageChange} first={tableFirst} rowsNumber={rowsNumber} keys={tableKeys} lastKey={queryProfilesResponse?.last_key} />}
                            >
                                <Column sortable field="name" header={tableHeaderTemplate(null, t("label.name"))} style={{ width: "55%", minWidth: "30rem" }} />
                                <Column sortable field="hour_cost" header={tableHeaderTemplate(null, t("label.hour_cost"))} body={(item) => FormatCurrency(item.hour_cost, 2, tenantInfo?.currency, i18n.language)} style={{ width: "20%", minWidth: "15rem" }} />
                                <Column sortable field="created_at" header={tableHeaderTemplate(null, t("label.creation_date"))} body={(item) => formatDateV2(item.created_at, true, i18n.language)} style={{ width: "15%", minWidth: "14rem" }} />
                                <Column sortable field="updated_at" header={tableHeaderTemplate(null, t("label.update_date"))} body={(item) => formatDateV2(item.updated_at, true, i18n.language)} style={{ width: "15%", minWidth: "14rem" }} />

                                {checkComponentPermissions(
                                    checkPermissions("delete_profiles") && checkPermissions("edit_profiles") ? (
                                        <Column
                                            headerStyle={{ width: "5rem", textAlign: "center" }}
                                            bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                            body={(item, options) => {
                                                return (
                                                    <Button
                                                        icon={"pi pi-ellipsis-v"}
                                                        className="p-button-text p-button-secondary"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setActiveProfile(item);
                                                            menuRef.current.toggle(e);
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    ) : checkPermissions("delete_profiles") ? (
                                        <Column
                                            headerStyle={{ width: "5rem", textAlign: "center" }}
                                            bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                            body={(item, options) => {
                                                return (
                                                    <Button
                                                        icon={<Icon icon="solar:trash-bin-trash-bold-duotone" className="" style={{ fontSize: "20px" }} />}
                                                        className="p-button-text p-button-secondary"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            deleteProfile(item);
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    ) : checkPermissions("edit_profiles") ? (
                                        <Column
                                            headerStyle={{ width: "5rem", textAlign: "center" }}
                                            bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                            body={(item, options) => {
                                                return (
                                                    <Button
                                                        icon={<Icon icon="solar:pen-bold" className="" style={{ fontSize: "20px" }} />}
                                                        className="p-button-text p-button-secondary"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setActiveProfile(item);
                                                            setShowEditProfileDialog(true);
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    ) : null,
                                    ["delete_profiles", "edit_profiles"],
                                    null,
                                    null
                                )}
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="xl:col-offset-1"></div> */}
        </div>,
        ["view_profiles"],
        null,
        <Forbidden />
    );
};
export default Profiles;
