import { API } from 'aws-amplify';

const API_NAME = "ParticipantsAPI";
const BASE_PATH = "/participant"

export default class ParticipantService {
    async queryParticipants(queryStringParameters={}, data) {
        return API.post(API_NAME, `${BASE_PATH}/query`,{queryStringParameters:queryStringParameters, body: data})
    }

    async createParticipant(client_id, project_id, process_id, task_id, data) {
        return API.post( API_NAME, BASE_PATH, { queryStringParameters: {client_id: client_id, project_id: project_id, process_id: process_id, task_id: task_id}, body: data } );
    }

    async updateParticipant(data, client_id, project_id, process_id, task_id, participant_id) {
        return API.put( API_NAME, `${BASE_PATH}/${participant_id}`, { queryStringParameters: {client_id: client_id, project_id: project_id, process_id: process_id, task_id: task_id}, body: data } );
    }

    async deleteParticipant(client_id, project_id, process_id, task_id, participant_id) {
        return API.del( API_NAME, `${BASE_PATH}/${participant_id}`, { queryStringParameters: {client_id: client_id, project_id: project_id, process_id: process_id, task_id: task_id}})
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }

    async emptyParticipants(client_id, project_id, process_id, task_id) {
        return API.del( API_NAME, `${BASE_PATH}/empty`, { queryStringParameters: {client_id: client_id, project_id: project_id, process_id: process_id, task_id: task_id}})
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }
}