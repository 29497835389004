import { AddressElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import PaymentService from "../../../services/PaymentService/PaymentService";
import "./PaymentPreferencesDialogs.css";

const EditPaymentMethodDialog = ({ show, onHide, paymentMethods, setPaymentMethods, activePaymentMethod, setDefaultPaymentMethod, setActivePaymentMethod }) => {
    const toast = useRef(null);

    const [loading, setLoading] = useState(false);

    const { t, i18n } = useTranslation();
    const paymentService = new PaymentService();

    const stripe = useStripe();
    const elements = useElements();

    const billingAddressSection = () => {
        return (
            <AddressElement
                style={{ minHeight: "20vw" }}
                id="address-element"
                options={{
                    mode: "billing",
                    display: { name: "organization" },
                    fields: { phone: "always" },
                    defaultValues: {
                        name: activePaymentMethod?.billing_details?.name,
                        address: activePaymentMethod?.billing_details?.address,
                        phone: activePaymentMethod?.billing_details?.phone,
                    },
                }}
            />
        );
    };

    const menuItems = [
        {
            id: "billing_address",
            label: t("label.billing_address"),
            content: billingAddressSection(),
            show: true,
        },
    ];

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        // event.preventDefault();

        setLoading(true);

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        var addressElement = elements.getElement("address");

        const addressInfo = await addressElement.getValue();

        paymentService
            .updatePaymentMethod(activePaymentMethod?.id, addressInfo?.value)
            .then((data) => {
                if (data) {
                    const currentIndex = paymentMethods?.findIndex((item) => item?.id === activePaymentMethod?.id);
                    let currentPaymentMethods = [...paymentMethods];
                    currentPaymentMethods[currentIndex] = data;
                    setPaymentMethods(currentPaymentMethods);

                    setActivePaymentMethod(data);
                }
                setLoading(false);
                onHide(false);
            })
            .catch((response) => {
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: response?.error });
                setLoading(false);
            });
    };

    return (
        <Dialog
            visible={show}
            onHide={() => onHide(false)}
            // style={{ width: '55vw', minHeight: '20vw' }}
            // breakpoints={{ '960px': '75vw', '641px': '100vw' }}
            // draggable={false}
            // contentStyle={{backgroundColor: "#f4f5ff", paddingBottom: 0}}
            draggable={false}
            className="custom-dialog"
            closable={false}
            dismissableMask
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "24px 24px 0px" }}>
                        <label className="mousee-text font-medium font-weight-bold">{t("payment_preferences.page.edit_payment_method")}</label>
                    </div>
                </div>
            }
            footer={
                <div className="px-3 pt-3">
                    {/* <Button className="p-button-link" label={t("label.cancel")} onClick={() => {onHide(false);}}/> */}
                    <Button
                        className="p-button-outlined p-button-plain"
                        label={t("label.cancel")}
                        onClick={() => {
                            onHide(false);
                        }}
                    />
                    <Button className="p-button" type="submit" label={t("label.save_changes")} autoFocus loading={loading} onClick={handleSubmit} />
                </div>
            }
        >
            <Toast ref={toast} />

            <div className="grid pt-5">
                <div className="col-12">
                    <label className="mousee-text font-small font-weight-bold">{t("label.billing_address")}</label>
                </div>
                <div className="col-12">{billingAddressSection()}</div>
                {/* <VerticalTabMenu items={menuItems} style={{ minHeight: '20vw' }} /> */}
            </div>
        </Dialog>
    );
};
export default EditPaymentMethodDialog;
