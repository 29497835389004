import { Divider } from "primereact/divider";
import React, { useEffect, useState } from "react";
import { FormatCurrency, isDesktop, tableHeaderTemplate } from "../../../Utils";

import { useAuthenticator } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import templateDeliveryTimeIcon from "../../../assets/icons/templates/delivery_time.svg";
import templateExecutionTimeIcon from "../../../assets/icons/templates/execution_time.svg";
import templateMedianCostIcon from "../../../assets/icons/templates/median_cost.svg";
import templateParticipantsIcon from "../../../assets/icons/templates/participants_number.svg";
import templateTasksIcon from "../../../assets/icons/templates/tasks_icon.svg";
import TenantService from "../../../services/TenantService/TenantService";
import { getProcessResume } from "../Processes/Utils";
import "./TemplateDetails.scss";

const TemplateDetails = ({template}) => {

    const [templateResume, setTemplateResume] = useState();
    const { t, i18n } = useTranslation();

    const tenantService = new TenantService();
    const { user } = useAuthenticator((context) => [context.user]);
    const [tenantInfo, setTenantInfo] = useState();

    useEffect(() => {
        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            tenantService.getTenant(user.attributes["custom:tenant_id"]).then((data) => {
                if (data) {
                    setTenantInfo(data)
                }
            })
        }
    }, [])

    useEffect(() => {
        setTemplateResume(getProcessResume(template)[0])
    }, []);

    return (
        <div className="grid p-4 xl:pl-8 xl:pr-8 xl:pb-8">

            <div className="col-12 template-details-image">
                <img
                    alt="template-logo"
                    src={`${template?.image}`}
                    style={{height: 200, left: 0, right: 0}}
                    className="min-w-full border-round"
                />
            </div>

            <div className="col-12 xl:col-6">
                <div className="grid">
                    <div className="col-12">
                        <label className="mousee-text font-x-large font-weight-bold">{template?.name}</label>
                    </div>
                    <div className="col-12">
                        <div 
                            style={{background: "var(--miflow-no-white-background)", backgroundColor: "var(--miflow-no-white-background)"}}
                            className="col-12 min-w-full mousee-text font-small font-weight-light" 
                            dangerouslySetInnerHTML={{__html: template?.description.replaceAll("rgb(255, 255, 255)", "transparent").replace(/(<? *script)/gi, 'illegalscript')}}
                        />
                    </div>
                </div>
            </div>
            
            <Divider className='border-300' layout={`${isDesktop()? "vertical" : "horizontal" }`}/>
            
            <div className="col-12 xl:col-5">
                <div className="grid">
                    {/* Tasks */}
                    <div className="col-12">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                {tableHeaderTemplate(templateTasksIcon, t("label.tasks"))}
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text font-x-small font-weight-light">{template?.count}</label>
                            </div>
                        </div>
                    </div>
                    
                    {/* T. Entrega */}
                    <div className="col-12">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                {tableHeaderTemplate(templateDeliveryTimeIcon, t("processes.page.delivery_time"))}
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text font-x-small font-weight-light">{`${templateResume?.totalTime} ${t("label.days")}`}</label>
                            </div>
                        </div>
                    </div>

                    {/* T. Ejecución */}
                    <div className="col-12">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                {tableHeaderTemplate(templateExecutionTimeIcon, t("processes.page.execution_time"))}
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text font-x-small font-weight-light">{`${templateResume?.totalRealTime} ${t("label.hrs")}`}</label>
                            </div>
                        </div>
                    </div>

                    {/* Participantes */}
                    <div className="col-12">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                {tableHeaderTemplate(templateParticipantsIcon, t("label.participants"))}
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text font-x-small font-weight-light">{templateResume?.participantsNumber}</label>
                            </div>
                        </div>
                    </div>

                    {/* Coste medio */}
                    <div className="col-12">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                {tableHeaderTemplate(templateMedianCostIcon, t("label.cost"))}
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text font-x-small font-weight-light">{FormatCurrency(templateResume?.totalCost ? templateResume?.totalCost : 0, 2, tenantInfo?.currency, i18n.language)}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TemplateDetails;