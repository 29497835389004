import { Icon } from '@iconify/react';
import { Card } from 'primereact/card';
import React from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../Utils';
import { getProjectStatusChip } from '../../data/Project';

const ProjectCard = (props) => {

    const {t, i18n} = useTranslation()

    const navigate = useNavigate();
    const client_id = props.project && props.project.id && props.project.id.split("#")[0]
    const project_id = props.project && props.project.id && props.project.id.split("#")[1]

    return (
        <Card className="shadow-2" style={{ width: '20rem', marginBottom: '2em' }} onClick={() => navigate(`/projects/${client_id}/${project_id}/details`)} >
            <div className="flex justify-content-between flex-wrap">
                <div className="flex align-items-center justify-content-center">
                    <span>{formatDate(props?.project?.creation, i18n.language)}</span>
                </div>
            </div>

            <div className="pt-1">
                <label className='mousee-text font-medium font-weight-bold'>{props?.project?.name}</label>
            </div>

            <div className='pt-1'>
                <div className="flex align-items-center justify-content-left">
                    {/* <i className="pi pi-briefcase font-x-small font-weight-extralight"></i> */}
                    <Icon icon="solar:mask-happly-bold-duotone" color="inherit" className="mousee-text font-medium" />
                    <label className='mousee-text font-x-small font-weight-regular pl-2'>{props?.project?.client_name}</label>
                </div>
            </div>

            <div className="grid pt-3">
                {getProjectStatusChip(props?.project?.status, t)}
            </div>  
        </Card>
  )
}
export default ProjectCard;