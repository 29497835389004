import { useAuthenticator } from "@aws-amplify/ui-react";
import getSymbolFromCurrency from "currency-symbol-map";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUpdateRemoveParams } from "../../../Utils";
import dropdownDown from "../../../assets/icons/dropdown-down.svg";
import CategoryService from "../../../services/CategoryService/CategoryService";
import ProfileService from "../../../services/ProfileService/ProfileService";
import TenantService from "../../../services/TenantService/TenantService";
import { filterCategories, handleCategoriesOnScroll } from "../../Products/Templates/Utils";

const EditProfileDialog = ({show, setShow, profile, setProfile, profiles, setProfiles}) => {
    const { t, i18n } = useTranslation();

    const toast = useRef(null);
    const profileService = new ProfileService();
    const formRef = useRef();
    const [loading, setLoading] = useState(false);

    const categoryService = new CategoryService();

    const [categoryVisibility, setCategoryVisibility] = useState("public");
    const [categories, setCategories] = useState();
    const [categoriesRowsNumber, setCategoriesRowsNumber] = useState(50);
    const [queryCategoriesResponse, setQueryCategoriesResponse] = useState();
    const [categoryFilterName, setCategoryFilterName] = useState(undefined);

    const tenantService = new TenantService();
    const { user } = useAuthenticator((context) => [context.user]);
    const [tenantInfo, setTenantInfo] = useState();

    useEffect(() => {
        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            tenantService.getTenant(user.attributes["custom:tenant_id"]).then((data) => {
                if (data) {
                    setTenantInfo(data);
                }
            });
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            name: profile ? profile.name : "",
            hour_cost: profile ? profile.hour_cost : 0,
            category_id: profile ? profile.category_id : undefined,
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = t("label.field_required");
            }

            if (!data.category_id) {
                errors.category_id = t("label.field_required");
            }

            if (!data.hour_cost) {
                errors.hour_cost = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true)
            const updateData = getUpdateRemoveParams(profile, data);

            profileService.updateProfile(updateData, profile.id).then((data) => {
                updateProfiles(profile.id, data);
                setShow(false);
                setProfile(undefined);
                setLoading(false)
            });
        },
    });

    useEffect(() => {
        refreshCategories();
    }, []);

    const refreshCategories = (visibility) => {
        setLoading(true);
        categoryService.queryCategories({ limit: categoriesRowsNumber }, undefined, "profile", visibility ? visibility : categoryVisibility)
        .then((data) => {
            setQueryCategoriesResponse(data);
            setCategories(data?.data);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
            toast?.current?.show({severity: 'error', summary: t("label.error"), detail: error?.response?.data?.message, life: 5000});
        })
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const saveChanges = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
            setShow(false);
        }
    };

    const updateProfiles = (currentProfileId, newData) => {
        let newProfiles = profiles;
        const currentProfileIndex = newProfiles.findIndex((item) => item.id === currentProfileId);
        newProfiles[currentProfileIndex] = newData;
        setProfiles(newProfiles);
    };

    return (
        <Dialog
            visible={show}
            onHide={() => setShow(false)}
            // style={{ minWidth: "35vw" }}
            // breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            // draggable={false}


            draggable={false}
            className="custom-dialog"
            closable={false}
            dismissableMask

            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "24px 24px 0px" }}>
                        <label className="mousee-text font-medium font-weight-bold">{t("skills.page.edit_skill")}</label>
                    </div>
                </div>
            }
            footer={
                <div className="px-3 pt-3">
                    <Button
                        className="p-button-outlined p-button-plain"
                        label={t("label.cancel")}
                        onClick={() => {
                            setShow(false);
                        }}
                    />
                    <Button form="new-profile-form" className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={saveChanges} loading={loading} />
                </div>
                // <div>
                //     <Button form="new-profile-form" className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={saveChanges} loading={loading} />
                // </div>
            }
        >
            <Toast ref={toast} />
                <div className="pt-5">
                    <form id="new-profile-form" onSubmit={formik.handleSubmit} innerRef={formRef} className="p-fluid">
                        <div className="grid">
                            <div className="col-12">
                                <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.name")}*</label>
                                <InputText
                                    id="name"
                                    name="name"
                                    placeholder={t("label.name")}
                                    value={formik.values.name}
                                    className={classNames("block w-full", { "p-invalid": isFormFieldValid("name") })}
                                    onChange={(e) => {
                                        formik.setFieldValue("name", e.target.value);
                                    }}
                                />
                                {getFormErrorMessage("name")}
                            </div>

                            <div className="col-12">
                                <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.category")}*</label>
                                <Dropdown
                                    dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                    id="profile-categories-dowpdown"
                                    name="profile-categories-dowpdown"
                                    key="profile-categories-dowpdown"
                                    value={categories && categories.filter((item) => item.id === formik.values.category_id)[0]}
                                    options={categories}
                                    optionLabel="name"
                                    filter
                                    emptyMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                    emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                    onChange={(e) => {
                                        formik.setFieldValue("category_id", e?.value?.id);
                                    }}
                                    placeholder={t("label.dropdown_select")}
                                    className={classNames("w-full", { "p-invalid": formik.errors.category_id })}
                                    onScrollCapture={(e) => handleCategoriesOnScroll(e, categoryService, categoriesRowsNumber, queryCategoriesResponse, setQueryCategoriesResponse, categories, setCategories, categoryFilterName, setCategoryFilterName, "profile", categoryVisibility)}
                                    filterTemplate={(options) => (
                                        <span className="p-input-icon-right min-w-full">
                                            <i className="pi pi-search" />
                                            <InputText
                                                autoFocus
                                                className="min-w-full"
                                                value={categoryFilterName}
                                                ref={null}
                                                onChange={(e) => filterCategories(e, options, setCategoryFilterName, queryCategoriesResponse, setQueryCategoriesResponse, categoryService, categoriesRowsNumber, categories, setCategories, "profile", categoryVisibility)}
                                            />
                                        </span>
                                    )}
                                />
                                {getFormErrorMessage("category_id")}
                            </div>

                            <div className="col-12">
                                <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.hour_cost")}*</label>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi">
                                            <label className="mousee-text-small font-medium font-weight-bold">{getSymbolFromCurrency(tenantInfo?.currency)}</label>
                                        </i>
                                    </span>
                                    <InputNumber
                                        value={formik.values.hour_cost}
                                        min={0}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        locale={i18n.language.split("-")[0]}
                                        onValueChange={(e) => formik.setFieldValue("hour_cost", e.value)}
                                        className={classNames("w-full", { "p-invalid": isFormFieldValid("hour_cost") })}
                                    />
                                </div>

                                <small className="pl-2 mousee-text-small font-weight-regular">{t("label.enter_the_cost_per_hour")}</small>
                                <br />
                                {getFormErrorMessage("hour_cost")}
                            </div>
                        </div>
                    </form>
                </div>
        </Dialog>
    );
};
export default EditProfileDialog;
