import { Icon } from "@iconify/react";
import { Button } from "primereact/button";
import React from "react";
import { useTranslation } from "react-i18next";
import { capitalizeWord, formatDateV2 } from "../../../../Utils";

const ApprovedAbsenceComponent = ({ notification, deleteNotification, markAsRead }) => {
    const { t, i18n } = useTranslation();
    return (
        <div className="card grid p-2" style={{ borderRadius: "var(--border-radius)" }}>
            <div className="col-1">
                <div className="border-round h-5rem w-5rem" style={{ border: "1px solid #9e9e9e", backgroundColor: "var(--success-color-light)" }}>
                    <div class="flex flex-column ">
                        <div class="flex align-items-center align-content-center justify-content-center pt-1">
                            <label className="mousee-text font-large font-weight-semibold">{notification?.data?.day}</label>
                        </div>
                        <div class="flex align-items-center align-content-end justify-content-center mt-1" style={{ borderTop: "1px solid #9e9e9e" }}>
                            <label className="mousee-text font-small font-weight-regular">{capitalizeWord(new Date(new Date().getFullYear(), parseInt(notification?.data?.month), 1).toLocaleString(i18n?.language, { month: "short" }))}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-10">
                <div className="grid">
                    <div className="col-12 pb-0">
                        <div className="grid pb-0">
                            <div className="col-12 pb-0 pt-2">
                                <label className="mousee-text font-x-small font-weight-semibold">{notification?.data?.absence_name}</label>
                            </div>
                            <div className="col-12 pb-0 pt-0">
                                <label className="mousee-text font-x-small font-weight-semibold">{notification?.data?.all_day ? t("label.all_day") : t("label.half_day")}</label>
                            </div>
                            <div className="col-12 pb-0 pt-1">
                                <label className="mousee-text font-x-small font-weight-regular">{t("label.your_absence_request_has_been_approved")}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-1">
                <label className="mousee-text-small font-xx-small font-weight-regular">{formatDateV2(notification?.created_at, false, i18n.language)}</label>
            </div>

            <div className="col-12">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex align-items-start justify-content-left">
                        <Button
                            label={t("label.go_to_absences")}
                            className="p-button-link pl-0"
                            onClick={(e) => {
                                window.open(notification?.url, "_blank", "noopener,noreferrer");
                            }}
                        />
                    </div>
                    <div class="flex align-items-center justify-content-center">
                        <div className="flex justify-content-end align-content-center flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                {notification?.readed === "false" ? <Button label={t("label.single_mark_as_read")} className="p-button-link" onClick={markAsRead} /> : <label className="mousee-text-small font-x-small font-weight-regular pr-3">{t("label.readed")}</label>}
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <Button icon={<Icon icon="solar:trash-bin-trash-bold-duotone" className="" style={{ fontSize: "20px" }} />} className="p-button-text p-button-secondary" onClick={deleteNotification} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ApprovedAbsenceComponent;
