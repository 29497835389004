import { useAuthenticator } from "@aws-amplify/ui-react";
import { loadStripe } from "@stripe/stripe-js";
import { Buffer } from "buffer";
import { useFormik } from "formik";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { capitalizeWord, convertToBase64, getUpdateRemoveParams } from "../../../../Utils";
import dropdownDown from "../../../../assets/icons/dropdown-down.svg";
import AvatarUpload from "../../../../components/AvatarUpload/AvatarUpload";
import { countryOptionTemplate, getCountriesTranslated, getCountryByCode, selectedCountryTemplate } from "../../../../data/General";
import { checkComponentPermissions } from "../../../../data/Permissions";
import IamService from "../../../../services/IamService/IamService";
import UserService from "../../../../services/UserService/UserService";
import { filterRoles, handleRolesOnScroll } from "../../../Configuration/Permissions/Utils";
import { getUserLogoUrl } from "../Utils";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AddUser = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const fileUploadRef = useRef(null);
    const [currentFile, setCurrentFile] = useState();
    const { user } = useAuthenticator((context) => [context.user]);
    const toast = useRef(null);

    const userService = new UserService();
    const iamService = new IamService();

    const [roles, setRoles] = useState([]);
    const [queryRolesResponse, setQueryRolesResponse] = useState();
    const [rolesRowsNumber, setRolesRowsNumber] = useState(5);
    const [roleFilterName, setRoleFilterName] = useState(undefined);
    const [loadingRoles, setLoadingRoles] = useState(false);
    const [selectedRoles, setSelectedRoles] = useState([]);

    useEffect(() => {
        setLoading(true);
        let calls = [];
        calls.push(
            iamService.queryRoles({ limit: rolesRowsNumber }).then((data) => {
                setQueryRolesResponse(data);
                setRoles(data?.data);
                setLoadingRoles(false);
            })
        );

        // calls.push(
        //     tenantService.getTenant(user.attributes["custom:tenant_id"]).then((data) => {
        //         if (data) {
        //             setTenantInfo(data);
        //         }
        //         return data;
        //     })
        // )

        Promise.all(calls).then(() => {
            setLoading(false);
        });
    }, []);

    const formik = useFormik({
        initialValues: {
            name: "",
            surnames: "",
            type: "employee",
            hour_cost: 0,
            email: "",
            external: false,
            status: "draft",
            locale: i18n?.language,
            address: {
                city: undefined,
                country: undefined,
                line1: undefined,
                line2: undefined,
                postal_code: undefined,
                state: undefined,
            },

            // Non original
            roles: [],
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = t("label.field_required");
            }

            if (!data.surnames) {
                errors.surnames = t("label.field_required");
            }

            if (!data.email) {
                errors.email = t("label.field_required");
            }

            // if (!data.hour_cost) {
            //     errors.hour_cost = t("label.field_required");
            // }

            if (!data.roles || data?.roles?.length === 0) {
                errors.roles = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            delete data["roles"];

            userService
                .createUser({}, data)
                .then((data) => {
                    if (data && data.id) {
                        let calls = [];

                        if (selectedRoles?.length > 0) {
                            selectedRoles.forEach((roleToAdd) => {
                                if (roleToAdd?.id) {
                                    calls.push(iamService.assignRole({ user_id: data?.id, role_id: roleToAdd?.id }));
                                }
                            });
                        }

                        if (currentFile) {
                            const newData = {
                                ...data,
                                avatar: getUserLogoUrl(user, data.id, currentFile.name),
                            };
                            const updateParams = getUpdateRemoveParams(data, newData);
                            calls.push(
                                userService.updateUser({}, updateParams, data.id).then((data) => {
                                    convertToBase64(currentFile).then((convertedFile) => {
                                        fetch(data.avatar, {
                                            method: "PUT",
                                            body: new Buffer.from(convertedFile.replace(/^data:image\/\w+;base64,/, ""), "base64"),
                                            headers: {
                                                "Content-type": currentFile.type,
                                            },
                                        }).catch((error) => {
                                            console.error("Error:", error);
                                            setLoading(false);
                                        });
                                    });
                                })
                            );
                        }

                        Promise.all(calls).then(() => {
                            setLoading(false);
                            navigate("/people");
                        });
                    } else {
                        navigate("/people");
                    }
                })
                .catch((error) => {
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data, life: 5000 });
                })
                .finally(() => {
                    setLoading(false);
                });
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const clientData = () => {
        return (
            <div className="grid card">
                <div className="col-12 md:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.name")}*</label>
                    <InputText
                        id="name"
                        name="name"
                        placeholder={t("label.name")}
                        value={formik.values.name}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("name") })}
                        onChange={(e) => {
                            formik.setFieldValue("name", e.target.value);
                        }}
                    />
                    {getFormErrorMessage("name")}
                </div>

                <div className="col-12 md:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.surnames")}*</label>
                    <InputText
                        id="surnames"
                        name="surnames"
                        placeholder={t("label.surnames")}
                        value={formik.values.surnames}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("surnames") })}
                        onChange={(e) => {
                            formik.setFieldValue("surnames", e.target.value);
                        }}
                    />
                    {getFormErrorMessage("surnames")}
                </div>

                <div className="col-12 md:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.email")}*</label>
                    <InputText
                        id="email"
                        name="email"
                        keyfilter="email"
                        placeholder={t("label.email")}
                        value={formik.values.email}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("email") })}
                        onChange={(e) => {
                            formik.setFieldValue("email", e.target.value);
                        }}
                    />
                    {getFormErrorMessage("email")}
                </div>

                <div className="col-12 md:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2 p-invalid">{t("label.roles")}*</label>
                    <MultiSelect
                        value={formik.values.roles ? formik.values.roles : loading === true && selectedRoles ? selectedRoles : []}
                        onChange={(e) => {formik.setFieldValue("roles", e.value); setSelectedRoles(e.value)}}
                        options={roles}
                        optionLabel="name"
                        display="chip"
                        placeholder={t("label.dropdown_select")}
                        maxSelectedLabels={3}
                        dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                        className={classNames("w-full", { "p-invalid": isFormFieldValid("roles") })}
                        showSelectAll={false}
                        filter
                        closeIcon={<></>}
                        emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                        onScrollCapture={(e) => handleRolesOnScroll(e, iamService, rolesRowsNumber, queryRolesResponse, setQueryRolesResponse, roles, setRoles, roleFilterName, setRoleFilterName)}
                        filterTemplate={(options) => (
                            <div className="flex flex-column min-w-full">
                                <div className="flex align-items-center justify-content-center">
                                    <span className="p-input-icon-right w-full mr-2">
                                        <i className="pi pi-search" />
                                        <InputText autoFocus className="min-w-full" value={roleFilterName} ref={null} onChange={(e) => filterRoles(e, options, setRoleFilterName, queryRolesResponse, setQueryRolesResponse, iamService, rolesRowsNumber, roles, setRoles)} />
                                    </span>
                                </div>
                            </div>
                        )}
                    />
                    {getFormErrorMessage("roles")}
                </div>
                <div className="col-12">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.country_or_region")}</label>
                    <Dropdown
                        dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                        value={getCountryByCode(formik?.values?.address?.country, t)}
                        onChange={(e) => formik.setFieldValue("address.country", e?.value?.isoCode)}
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                        filter
                        optionLabel="name"
                        showClear
                        placeholder={t("label.country_or_region")}
                        options={getCountriesTranslated(t)}
                        className="w-full"
                        emptyMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                        emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                    />
                </div>

                <div className="col-12 lg:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.address_line1")}</label>
                    <InputText
                        id="address.line1"
                        name="address.line1"
                        placeholder={t("label.address.address_line1_placeholder")}
                        value={formik?.values?.address?.line1}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.line1") })}
                        onChange={(e) => {
                            formik.setFieldValue("address.line1", e.target.value);
                        }}
                    />
                </div>

                <div className="col-12 lg:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.address_line2")}</label>
                    <InputText
                        id="address.line2"
                        name="address.line2"
                        placeholder={t("label.address.address_line2_placeholder")}
                        value={formik?.values?.address?.line2}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.line2") })}
                        onChange={(e) => {
                            formik.setFieldValue("address.line2", e.target.value);
                        }}
                    />
                </div>

                <div className="col-12 md:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.city")}</label>
                    <InputText
                        id="address.city"
                        name="address.city"
                        placeholder={t("label.address.city_placeholder")}
                        value={formik?.values?.address?.city}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.city") })}
                        onChange={(e) => {
                            formik.setFieldValue("address.city", e.target.value);
                        }}
                    />
                </div>

                <div className="col-12 md:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.state")}</label>
                    <InputText
                        id="address.state"
                        name="address.state"
                        placeholder={t("label.address.state_placeholder")}
                        value={formik?.values?.address?.state}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.state") })}
                        onChange={(e) => {
                            formik.setFieldValue("address.state", e.target.value);
                        }}
                    />
                </div>

                <div className="col-12 md:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.postal_code")}</label>
                    <InputText
                        keyfilter="int"
                        id="address.postal_code"
                        name="address.postal_code"
                        placeholder={t("label.address.postal_code_placeholder")}
                        value={formik?.values?.address?.postal_code}
                        inputClassName="w-full"
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.postal_code") })}
                        onChange={(e) => {
                            formik.setFieldValue("address.postal_code", e.target.value);
                        }}
                    />
                </div>

                <div className="col-12 md:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.phone_number")}</label>
                    <InputText
                        keyfilter="int"
                        id="phone_number"
                        name="phone_number"
                        placeholder={t("label.phone_number")}
                        value={formik?.values?.phone}
                        inputClassName="w-full"
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("phone") })}
                        onChange={(e) => {
                            formik.setFieldValue("phone", e.target.value);
                        }}
                    />
                </div>
            </div>
        );
    };

    return checkComponentPermissions(
        <div className="grid page-layout">
            <Toast ref={toast} />
            {/* <div className="xl:col-offset-1"></div> */}
            <div className="col-12 xl:col-12">
                <div className="grid">
                    <div className="col-12 p-0">
                        <div className="flex  justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <div className="grid p-0">
                                    <div className="col-12 p-0">
                                        <BreadCrumb
                                            home={{
                                                icon: <label className="mousee-text font-x-small font-weight-regular cursor-pointer">{capitalizeWord(t("label.users"))}</label>,
                                                command: () => {
                                                    navigate("/people");
                                                },
                                            }}
                                            separatorIcon={<div className="pl-2 pr-2">/</div>}
                                            model={[{ label: t("label.create_a_new_user"), disabled: true }]}
                                        />
                                    </div>

                                    <div className="col-12 px-0">
                                        <label className="mousee-text font-large font-weight-regular">{t("label.create_a_new_user")}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <Button label={t("label.save_changes")} loading={loading} type="button" className="p-button" onClick={formik.handleSubmit} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pt-5">
                        <div className="grid">
                            <div className="col-12 xl:col-5">
                                <div className="grid card xl:mr-1">
                                    <div className="col-12">
                                        <AvatarUpload
                                            inputFileRef={fileUploadRef}
                                            file={currentFile ? currentFile : formik.values.avatar}
                                            setFile={(newValue) => {
                                                setCurrentFile(newValue);
                                                formik.setTouched({}, false);
                                            }}
                                            classnames={classNames({ "p-invalid": isFormFieldValid("image") })}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex align-items-center justify-content-center">
                                                <div class="flex flex-column">
                                                    <div class="flex align-items-center justify-content-left">
                                                        <label className="mousee-text font-x-small font-weight-semibold block">{t("label.status")}</label>
                                                    </div>
                                                    <div class="flex align-items-center justify-content-left">
                                                        <label className="mousee-text-small font-x-small font-weight-regular block">{t("label.disabling_this_will_not_send_the_invitation_to_the_user")}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex align-items-center justify-content-center">
                                                <InputSwitch checked={formik.values?.status === "active"} onChange={(e) => formik.setFieldValue("status", formik.values?.status === "active" ? "draft" : "active")} />
                                            </div>
                                        </div>
                                        {/* <div>
                                            <label className="mousee-text font-x-small font-weight-semibold block">{t("label.status")}</label>
                                        </div>
                                        <div className="flex justify-content-left pt-3">
                                            <div className="flex flex-column gap-3">
                                                {CLIENT_TYPES.map((clientType) => {
                                                    return (
                                                        <div key={clientType?.type} className="flex align-items-center">
                                                            <RadioButton inputId={clientType?.type} name="type" value={clientType?.type} onChange={(e) => onTypeChange(e?.value)} checked={formik?.values?.type === clientType?.type} />
                                                            <label htmlFor={clientType?.type} className="ml-2">
                                                                {t(clientType?.label)}
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 xl:col-7">{clientData()}</div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="xl:col-offset-1"></div> */}
        </div>,
        ["add_users"],
        null,
        null
    );
};
export default AddUser;
