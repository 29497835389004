import { Icon } from "@iconify/react";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import { Editor } from "primereact/editor";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { blockBackgroundScroll, isDesktop, parseTime, tableHeaderTemplate } from "../../../../Utils";
import taskProfileIcon from "../../../../assets/icons/process/process-profile.svg";
import "../../../../components/Task/TaskOverviewSidebar/TaskOverviewSidebar.scss";
import { checkComponentPermissions } from "../../../../data/Permissions";
import AddChecklistDialog from "../../../Projects/dialogs/AddChecklistDialog/AddChecklistDialog";
import AddEditBuilderParticipantDialog from "./Dialogs/AddEditBuilderParticipantDialog";

// const TaskBuilderSidebar = ({ show, setShow, task, process, tasks, setTasks, setActiveTask, project_name, setProcess }) => {
const TaskBuilderSidebar = ({ show, setShow, tasks, task, saveTasks, removeTask, title }) => {
    const { t, i18n } = useTranslation();

    const [currentTaskDialog, setCurrentTaskDialog] = useState();
    const [showTaskDialog, setShowTaskDialog] = useState();
    const [activeParticipant, setActiveParticipant] = useState();

    const deleteTask = () => {
        confirmDialog({
            tagKey: "confirmTaskDeletionDialog",
            message: <span>{t("label.are_you_sure_you_want_to_delete_the_task_task_title", { task_title: task?.title })}</span>,
            closable: false,
            header: t("label.delete_task"),
            draggable: false,
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: t("label.delete_task"),
            rejectLabel: t("label.cancel"),
            rejectClassName: "p-button-outlined p-button-plain",
            accept: () => {
                const findedIndex = tasks?.findIndex((item) => item?.id === task?.id);
                removeTask(findedIndex);

                close();
            },
            contentClassName: "pt-3",
        });
    };

    const updateTaskField = (fieldName, fieldValue) => {
        let newData = task;
        newData[fieldName] = fieldValue;

        saveTasks("update", newData);
    };

    const descriptionSection = () => {
        return (
            <div className="col-12 pt-4">
                <div className="grid">
                    <div className="col-12 md:col-2">
                        <div className="flex align-content-center flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text-small font-x-small font-weight-semibold">{t("label.description")}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-10 custom-description-editor">
                        <Editor
                            value={task?.description}
                            className="w-12 border-transparent mousee-text text-x-small font-weight-regular"
                            placeholder={t("label.add_a_description")}
                            style={{ maxHeight: "400px", overflow: "auto" /*, maxWidth: "400px"*/ }}
                            headerTemplate={
                                <span className="ql-formats">
                                    <button className="ql-bold" aria-label="Bold"></button>
                                    <button className="ql-italic" aria-label="Italic"></button>
                                    <button className="ql-underline" aria-label="Underline"></button>
                                    <button className="ql-list" value={"ordered"} aria-label="List"></button>
                                    <button className="ql-list" value={"bullet"} aria-label="List"></button>
                                    <button className="ql-align"></button>
                                </span>
                            }
                            onTextChange={(e) => {
                                if (!e.htmlValue) {
                                    updateTaskField("description", "");
                                } else {
                                    updateTaskField("description", e.htmlValue);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const deleteChecklist = () => {
        confirmDialog({
            tagKey: "confirmChecklistDeletionDialog",
            message: <span>{t("label.are_you_sure_you_want_to_delete_subtasks")}</span>,
            header: t("label.delete_subtasks"),
            draggable: false,
            acceptLabel: t("label.yes"),
            rejectLabel: t("label.no"),
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept: () => {
                updateTaskField("checklist", null);
            },
            contentClassName: "pt-3",
        });
    };

    const subtasksSection = () => {
        return task?.checklist?.options?.length > 0 ? (
            <div className="col-12 pt-4">
                <div className="grid">
                    <div className="col-12 md:col-2">
                        <div className="flex align-content-center flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text-small font-x-small font-weight-semibold">{t("label.subtasks")}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-10">
                        <div className="grid">
                            {task?.checklist?.title ? (
                                <div className="col-12 pt-1">
                                    <div className="flex justify-content-between flex-wrap">
                                        <div className="flex align-items-center justify-content-center">
                                            <label className="mousee-text font-small font-weight-semibold">{task?.checklist?.title}</label>
                                        </div>
                                        {checkComponentPermissions(
                                            <div className="flex align-items-center justify-content-center">
                                                <div className="flex justify-content-end flex-wrap">
                                                    <div className="flex align-items-center justify-content-center">
                                                        <Button
                                                            icon={<Icon icon="solar:pen-bold" data-pr-tooltip={t("label.edit_subtasks")} style={{ fontSize: "20px" }} />}
                                                            className="p-button-text p-button-secondary"
                                                            tooltip={t("label.edit_subtasks")}
                                                            tooltipOptions={{ position: "left" }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setCurrentTaskDialog("add_checklist");
                                                                setShowTaskDialog(true);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center">
                                                        <Button
                                                            icon={<Icon icon="solar:trash-bin-trash-bold-duotone" data-pr-tooltip={t("label.delete_subtasks")} style={{ fontSize: "20px" }} />}
                                                            className="p-button-text p-button-secondary"
                                                            tooltip={t("label.delete_subtasks")}
                                                            tooltipOptions={{ position: "left" }}
                                                            onClick={() => deleteChecklist()}
                                                        />
                                                    </div>
                                                </div>
                                            </div>,
                                            ["edit_tasks"],
                                            null,
                                            null
                                        )}
                                    </div>
                                </div>
                            ) : null}
                            {task?.checklist?.options.map((option, index) => {
                                return (
                                    <div className={`col-12 pt-1`}>
                                        <div className="flex align-items-center pl-2">
                                            <Checkbox inputId={`${option}-${index}`} name={`${option}-${index}`} />
                                            <label htmlFor={`${option}-${index}`} className="mousee-text font-x-small font-weight-light ml-2">
                                                {task?.checklist?.checked?.includes(option) ? <s>{option}</s> : option}
                                            </label>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        ) : null;
    };

    const clientSection = () => {
        return (
            <div className="col-12 pt-4">
                <div className="grid">
                    <div className="col-4 md:col-3">
                        <div className="flex align-content-center flex-wrap h-full">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text-small font-x-small font-weight-semibold">{t("label.client")}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-8 md:col-9">
                        <div className="flex align-items-center">
                            <InputSwitch
                                checked={task?.include_client}
                                onChange={(e) => {
                                    updateTaskField("include_client", e.value);
                                }}
                            />
                            <label className="ml-2 mousee-text font-x-small font-weight-light">{t("label.visible_to_the_client")}</label>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const timeToDoItSection = () => {
        return (
            <div className="col-12 pt-4">
                <div className="grid">
                    <div className="col-4 md:col-3">
                        <div className="flex align-content-center flex-wrap h-full">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text-small font-x-small font-weight-semibold">{t("label.time_to_do_it_days")}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-8 md:col-9 input-time-custom">
                        <InputNumber
                            // style={{ minWidth: "8rem" }}
                            // className="w-full"
                            inputId="horizontal-buttons"
                            value={task?.duration_in_days ? task?.duration_in_days : 0}
                            min={1}
                            onValueChange={(e) => updateTaskField("duration_in_days", e.value)}
                            incrementButtonClassName="p-button-text"
                            decrementButtonClassName="p-button-text"
                            showButtons
                            buttonLayout="horizontal"
                            step={1}
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                        />
                    </div>
                </div>
            </div>
        );
    };

    const updateTime = (currentParticipant, quantity, operation, type, participantIndex) => {
        let time = currentParticipant.time;

        if (type === "manual") {
            quantity = quantity.split(":");
            time = {
                hours: parseInt(quantity[0]),
                minutes: parseInt(quantity[1]) > 59 ? 0 : parseInt(quantity[1]),
            };
        } else {
            if (operation === "add") {
                if (time.minutes + 15 >= 60) {
                    const minutesToAnHour = 60 - time.minutes;
                    const quantityToAdd = 15 - minutesToAnHour;

                    time = {
                        hours: time.hours + 1,
                        minutes: quantityToAdd,
                    };
                } else {
                    time = {
                        ...time,
                        minutes: time.minutes + 15,
                    };
                }
            } else {
                if (time.minutes - 15 >= 0) {
                    time = {
                        ...time,
                        minutes: time.minutes - quantity,
                    };
                } else {
                    let auxMinutes = time.minutes - 15;

                    time = {
                        hours: time.hours === 0 ? 0 : time.hours - 1,
                        minutes: time.hours === 0 ? 0 : 60 - Math.abs(auxMinutes),
                    };
                }
            }
        }

        const newParticipant = {
            ...currentParticipant,
            time: time,
        };

        // updateParticipant(newParticipant, participantIndex);

        let newParticipants = task?.participants;

        newParticipants[participantIndex] = newParticipant;

        updateTaskField("participants", newParticipants);
    };

    const participantsSection = (participantsParam) => {
        return (
            <div className="col-12 pt-4">
                <div className="grid">
                    <div className="col-3 md:col-2">
                        <div className="flex align-content-center flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text-small font-x-small font-weight-semibold">{t("label.participants")}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-9 md:col-10">
                        <DataTable 
                            emptyMessage={t("label.no_items_to_display")} 
                            value={participantsParam} 
                            stripedRows 
                            responsiveLayout="scroll" 
                            rowHover={false} 
                            rowClassName="pb-0" 
                            scrollable 
                            scrollHeight="225px"
                            footer={
                                <div class="flex justify-content-end flex-wrap">
                                    <div class="flex align-items-center justify-content-center">
                                        <Button
                                            icon="pi pi-plus-circle"
                                            label={t("label.add_participant")}
                                            className="p-button-filled min-w-full"
                                            onClick={(e) => {
                                                e?.stopPropagation();
                                                setCurrentTaskDialog("add_edit_participant");
                                                setShowTaskDialog(true);
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                        >
                            <Column field="profile.name" header={tableHeaderTemplate(taskProfileIcon, t("label.skill"))} style={{ width: "15%", minWidth: "15rem" }} headerClassName="hidden" className="mousee-text font-x-small font-weight-semibold" />

                            <Column
                                body={(item, options) => {
                                    return (
                                        <div className="p-inputgroup custom-time-picker">
                                            <Button
                                                icon="pi pi-minus"
                                                className="p-button-text"
                                                onClick={(e) => {
                                                    updateTime(item, 15, "subtract", "", options.rowIndex);
                                                }}
                                            />
                                            <InputMask
                                                value={item.time ? parseTime(item.time) : "00:00"}
                                                placeholder="00:00"
                                                mask="99:99"
                                                className="w-full"
                                                onChange={(e) => {
                                                    if (!e.value.includes("_")) {
                                                        updateTime(item, e.value, "", "manual", options.rowIndex);
                                                    }
                                                }}
                                            />

                                            <Button
                                                icon="pi pi-plus"
                                                className="p-button-text"
                                                onClick={(e) => {
                                                    updateTime(item, 15, "add", "", options.rowIndex);
                                                }}
                                            />
                                        </div>
                                    );
                                }}
                                style={{ width: "10%", minWidth: "10rem" }}
                                header={tableHeaderTemplate(null, t("label.role"))}
                                headerClassName="hidden"
                            />

                            <Column
                                bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                headerClassName="hidden"
                                style={{ width: "3%", minWidth: "3rem" }}
                                body={(item, options) => {
                                    return (
                                        <Button
                                            icon={<Icon icon="solar:trash-bin-trash-bold-duotone" style={{ fontSize: "20px" }} />}
                                            className="p-button-text p-button-secondary"
                                            onClick={() => {
                                                const newParticipants = task?.participants.filter((_, item_index) => item_index !== options.rowIndex);
                                                updateTaskField("participants", newParticipants);
                                            }}
                                        />
                                    );
                                }}
                            />
                        </DataTable>
                    </div>
                </div>
            </div>
        );
        // return (
        //     <div className="col-12 pt-4">
        //         <div className="grid">
        //             <div className="col-3 md:col-2">
        //                 <div className="flex align-content-center flex-wrap h-full">
        //                     <div className="flex align-items-center justify-content-center">
        //                         <label className="mousee-text-small font-x-small font-weight-semibold">{t("label.participants")}</label>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div className="col-9 md:col-10">
        //                 <div className="flex flex-row flex-wrap align-content-center h-full">
        //                     {participantsParam?.map((item, index) => {
        //                         return (
        //                             <div className={`flex align-items-center justify-content-center pr-2 ${index > 4 ? "pt-2" : ""}`}>
        //                                 <CustomAvatar
        //                                     tooltipId={`participant-${index}`}
        //                                     tooltip={`custom-target-`}
        //                                     label={item?.profile?.name}
        //                                     hoverIcon="solar:pen-bold"
        //                                     hoverable={true}
        //                                     hoverAction={() => {
        //                                         setActiveParticipant(item);
        //                                         setCurrentTaskDialog("add_edit_participant");
        //                                         setShowTaskDialog(true);
        //                                     }}
        //                                 />
        //                             </div>
        //                         );
        //                     })}

        //                     <div className={`flex align-items-center justify-content-center`}>
        //                         <Button
        //                             icon={"pi pi-plus"}
        //                             className="p-button-text p-button-secondary add-participant-button"
        //                             tooltip={t("label.add_participant")}
        //                             tooltipOptions={{ position: "left" }}
        //                             onClick={(e) => {
        //                                 e.stopPropagation();
        //                                 setCurrentTaskDialog("add_edit_participant");
        //                                 setShowTaskDialog(true);
        //                             }}
        //                         />
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // );
    };

    const close = () => {
        blockBackgroundScroll(false);
        setShow(false);
    };

    const getTaskDialog = (dialog) => {
        if (dialog === "add_edit_participant") {
            return (
                <AddEditBuilderParticipantDialog
                    show={showTaskDialog}
                    setShow={(newValue) => {
                        setShowTaskDialog(newValue);
                        setActiveParticipant(undefined);
                    }}
                    actualParticipant={activeParticipant}
                    setParticipants={(newValue) => updateTaskField("participants", newValue)}
                    participants={task?.participants}
                />
            );
        } else if (dialog === "add_checklist") {
            return <AddChecklistDialog show={showTaskDialog} onHide={setShowTaskDialog} checklist={task?.checklist} setTask={(newCheckList) => updateTaskField("checklist", newCheckList)} taskType="process_task" />;
        }
    };

    return (
        <Sidebar showCloseIcon={false} position="right" visible={show} onHide={close} className="custom-sidebar" header={false} blockScroll style={{ overflowY: "hidden" }} onShow={() => blockBackgroundScroll(true)}>
            {showTaskDialog ? getTaskDialog(currentTaskDialog) : null}
            <ConfirmDialog tagKey="confirmChecklistDeletionDialog" />
            <ConfirmDialog tagKey="confirmTaskDeletionDialog" />

            <div className="flex flex-column justify-content-between flex-wrap min-h-full static">
                <div className="flex align-items-center justify-content-center">
                    <div className="grid min-w-full">
                        <div className="col-12 p-0 h-5rem min-w-full top-0 sticky" style={{ zIndex: 1200, backgroundColor: "white" }}>
                            <div className="grid">
                                <div className="col-12 pt-4 pl-4 pr-4 pb-0">
                                    <div className="flex justify-content-between flex-wrap">
                                        <div className="flex align-items-center justify-content-center">
                                            <div className="flex justify-content-start flex-wrap">
                                                {!isDesktop() ? (
                                                    <div className="flex align-items-center justify-content-center">
                                                        <Button
                                                            icon={"pi pi-angle-left"}
                                                            className="p-button-text p-button-secondary"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                close();
                                                            }}
                                                        />
                                                    </div>
                                                ) : null}
                                                <div className="flex align-items-center justify-content-center pl-2">
                                                    <label className="mousee-text font-medium font-weight-bold">{title}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex align-items-center justify-content-center">
                                            <div className="flex justify-content-start flex-wrap">
                                                <div className="flex align-items-center justify-content-center">
                                                    <Button
                                                        icon={<Icon icon="solar:checklist-minimalistic-bold-duotone" data-pr-tooltip={t("label.subtasks")} style={{ fontSize: "20px" }} />}
                                                        className="p-button-text p-button-secondary"
                                                        tooltip={t("label.subtasks")}
                                                        tooltipOptions={{ position: "left" }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setCurrentTaskDialog("add_checklist");
                                                            setShowTaskDialog(true);
                                                        }}
                                                    />
                                                </div>
                                                <div className="flex align-items-center justify-content-center">
                                                    <Button
                                                        icon={<Icon icon="solar:trash-bin-trash-bold-duotone" data-pr-tooltip={t("label.delete_task")} style={{ fontSize: "20px" }} />}
                                                        className="p-button-text p-button-secondary"
                                                        tooltip={t("label.delete_task")}
                                                        tooltipOptions={{ position: "left" }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            deleteTask();
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 p-0">
                                    <Divider className="border-300 mb-0" />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 pl-4 pr-4">
                            <div className="grid">
                                <div className="col-12 pt-4">
                                    <InputText
                                        className="w-full xl:min-w-full mousee-text font-medium font-weight-semibold custom-title-inputtext"
                                        value={task?.title}
                                        placeholder={t("label.add_a_task_name")}
                                        onChange={(e) => {
                                            updateTaskField("title", e.target.value);
                                        }}
                                    />
                                </div>

                                {clientSection()}

                                {timeToDoItSection()}

                                {/* {participantsSection(participants)} */}

                                {descriptionSection()}

                                {participantsSection(task?.participants)}

                                {subtasksSection()}

                                {/* {attachmentsSection(files)} */}
                            </div>
                        </div>
                        {/* <div className="col-12">{commentsSection(comments)}</div> */}
                    </div>
                </div>

                {/* <div className="flex align-items-center justify-content-center h-11rem min-w-full custom-sidebar-footer bottom-0 sticky" style={{ zIndex: 1200, backgroundColor: "white" }}>
                    {commentSection()}
                </div> */}
            </div>
        </Sidebar>
    );
};
export default TaskBuilderSidebar;
