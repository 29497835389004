// Form.js
import { Auth } from "aws-amplify";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { setAuthData } from "../../Utils";
import { ConfirmSignUp } from "./ConfirmSignUp";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordSubmit from "./ForgotPasswordSubmit";
import RequiredNewPassword from "./RequiredNewPassword";
import { SignIn } from "./SignIn";
import { SignUp } from "./SignUp";

// import SignUp from './SignUp'
// import ConfirmSignUp from './ConfirmSignUp'
// import ForgotPassword from './ForgotPassword'
// import ForgotPasswordSubmit from './ForgotPasswordSubmit'

const initialFormState = {
    username: "",
    password: "",
    email: "",
    confirmationCode: "",
    company: "",
};

export function Form(props) {
    const { t } = useTranslation();

    const [formType, updateFormType] = useState("signIn");
    const [formState, updateFormState] = useState(initialFormState);

    const msgRef = useRef(null);
    const [loading, setLoading] = useState(false);

    function updateForm(event) {
        const newFormState = {
            ...formState,
            [event.target.name]: event.target.value,
        };
        updateFormState(newFormState);
    }

    async function signUp({ username, password, email, company }, updateFormType) {
        try {
            await Auth.signUp({
                username,
                password,
                attributes: {
                    email: email,
                    "custom:tenant_id": uuid(),
                    locale: navigator.language,
                    name: company
                },
            });
            console.log("sign up success!");
            updateFormType("confirmSignUp");
        } catch (err) {
            console.log("error signing up..", err);

            if (err && err.code && err.code === "UsernameExistsException") {
                msgRef?.current?.clear();
                msgRef?.current?.show([{ sticky: true, severity: "error", summary: "", detail: t("auth.an_account_with_the_given_email_already_exists"), closable: true }]);
            } else {
                msgRef?.current?.clear();
                msgRef?.current?.show([{ sticky: true, severity: "error", summary: "", detail: err.message, closable: true }]);
            }
        }

        setLoading(false);
    }

    async function confirmSignUp({ username, confirmationCode }, updateFormType) {
        try {
            await Auth.confirmSignUp(username, confirmationCode);
            updateFormType("signIn");
        } catch (err) {
            console.log("error confirming sign up..", err);

            if (err && err.code && err.code === "CodeMismatchException") {
                msgRef?.current?.clear();
                msgRef?.current?.show([{ sticky: true, severity: "error", summary: "", detail: t("auth.invalid_verification_code_try_again"), closable: true }]);
            } else {
                msgRef?.current?.clear();
                msgRef?.current?.show([{ sticky: true, severity: "error", summary: "", detail: err.message, closable: true }]);
            }
        }
        setLoading(false);
    }

    async function resendConfirmationCode({ username }) {
        try {
            await Auth.resendSignUp(username);
            console.log("code resent successfully");
        } catch (err) {
            msgRef?.current?.clear();
            msgRef?.current?.show([{ sticky: true, severity: "error", summary: "", detail: err?.message, closable: true }]);
        }
        setLoading(false);
    }

    async function signIn({ username, password }) {
        try {
            const user = await Auth.signIn(username, password);
            // const userInfo = { username: user.username, ...user.attributes }

            // const userInfo = await Auth.currentSession();

            if (user && user.challengeName && user.challengeName === "NEW_PASSWORD_REQUIRED") {
                updateFormState({
                    username: username,
                    oldPassword: password,
                });
                updateFormType("requiredNewPassword");
            } else {
                // const userPermissions = user?.signInUserSession?.idToken?.payload?.permissions;
                // const userType = user?.signInUserSession?.idToken?.payload?.user_type;
                // setUserPermissions(userPermissions, userType);
                setAuthData(user?.signInUserSession?.idToken?.payload)
            }

            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log("error signing in..", err);
            if (err && err.code && err.code === "PasswordResetRequiredException") {
                msgRef?.current?.clear();
                msgRef?.current?.show([
                    {
                        sticky: true,
                        severity: "error",
                        summary: "",
                        detail: (
                            <span>
                                {t("auth.the_password_has_expired_Generate_a_new_one_by_clicking")}
                                <i>{`"${t("auth.forgot_password")}"`}</i>
                            </span>
                        ),
                        closable: true,
                    },
                ]);
            } else if (err && err.code && err.code === "UserNotConfirmedException") {
                resendConfirmationCode({ username });
                updateFormType("confirmSignUp");
            } else if (err && err.code && err.code === "NotAuthorizedException") {
                msgRef?.current?.clear();
                msgRef?.current?.show([{ sticky: true, severity: "error", summary: "", detail: t("auth.the_account_does_not_exist_or_the_password_is_incorrect"), closable: true }]);
            } else {
                msgRef?.current?.clear();
                msgRef?.current?.show([{ sticky: true, severity: "error", summary: "", detail: err.message, closable: true }]);
            }
        }
    }

    async function forgotPassword({ username }, updateFormType) {
        try {
            await Auth.forgotPassword(username);
            updateFormType("forgotPasswordSubmit");

            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log("error submitting username to reset password...", err);
            msgRef?.current?.clear();
            msgRef?.current?.show([{ sticky: true, severity: "error", summary: "", detail: err.message, closable: true }]);
        }
    }

    async function forgotPasswordSubmit({ username, confirmationCode, password }, updateFormType) {
        try {
            await Auth.forgotPasswordSubmit(username, confirmationCode, password);
            updateFormType("signIn");
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log("error updating password... :", err);

            if (err && err.code && err.code === "CodeMismatchException") {
                msgRef?.current?.clear();
                msgRef?.current?.show([{ sticky: true, severity: "error", summary: "", detail: t("auth.invalid_verification_code_try_again"), closable: true }]);
            } else {
                msgRef?.current?.clear();
                msgRef?.current?.show([{ sticky: true, severity: "error", summary: "", detail: err.message, closable: true }]);
            }
        }
    }

    async function resetPassword({ username, oldPassword, password }, updateFormType) {
        try {
            const user = await Auth.signIn(username, oldPassword);
            // const userInfo = { email: username, ...user.attributes };

            if (user && user.challengeName && user.challengeName === "NEW_PASSWORD_REQUIRED") {
                user.completeNewPasswordChallenge(
                    password,
                    {},
                    {
                        onSuccess: (result) => {
                            Auth.signIn(username, password).then((data) => {
                                // const userPermissions = data?.signInUserSession?.idToken?.payload?.permissions;
                                // const userType = data?.signInUserSession?.idToken?.payload?.user_type;
                                // setUserPermissions(userPermissions, userType);

                                setAuthData(user?.signInUserSession?.idToken?.payload)

                                setLoading(false);
                            });
                        },
                        onFailure: (result) => {
                            console.log(result);
                            setLoading(false);
                        },
                    }
                );
            }
        } catch (err) {
            setLoading(false);
            console.log("error updating password... :", err);
            msgRef?.current?.clear();
            msgRef?.current?.show([{ sticky: true, severity: "error", summary: "", detail: err.message, closable: true }]);
        }
    }

    function renderForm() {
        switch (formType) {
            case "signUp":
                // return "signUp"
                return <SignUp signUp={() => signUp(formState, updateFormType)} updateFormState={(e) => updateForm(e)} updateFormType={updateFormType} loading={loading} setLoading={setLoading} msgRef={msgRef} />;
            case "confirmSignUp":
                // return "confirmSignUp"
                // return (
                //   <ConfirmSignUp
                //     confirmSignUp={() => confirmSignUp(formState, updateFormType)}
                //     updateFormState={e => updateForm(e)}
                //   />
                // )

                return (
                    <ConfirmSignUp
                        username={formState?.username}
                        resendConfirmationCode={() => resendConfirmationCode(formState)}
                        confirmSignUp={() => confirmSignUp(formState, updateFormType)}
                        updateFormState={(e) => updateForm(e)}
                        updateFormType={updateFormType}
                        loading={loading}
                        setLoading={setLoading}
                        msgRef={msgRef}
                    />
                );
            case "signIn":
                return <SignIn signIn={() => signIn(formState)} updateFormState={(e) => updateForm(e)} updateFormType={updateFormType} loading={loading} setLoading={setLoading} msgRef={msgRef} />;
            case "requiredNewPassword":
                return <RequiredNewPassword resetPassword={() => resetPassword(formState, updateFormType)} updateFormState={(e) => updateForm(e)} updateFormType={updateFormType} loading={loading} setLoading={setLoading} msgRef={msgRef} />;
            case "forgotPassword":
                // return "forgotPassword"
                return <ForgotPassword forgotPassword={() => forgotPassword(formState, updateFormType)} updateFormState={(e) => updateForm(e)} updateFormType={updateFormType} loading={loading} setLoading={setLoading} msgRef={msgRef} />;
            case "forgotPasswordSubmit":
                // return "forgotPasswordSubmit"
                return <ForgotPasswordSubmit forgotPasswordSubmit={() => forgotPasswordSubmit(formState, updateFormType)} updateFormState={(e) => updateForm(e)} loading={loading} setLoading={setLoading} msgRef={msgRef} updateFormType={updateFormType} />;
            default:
                return null;
        }
    }

    return (
        <div>
            {renderForm()}
            {/* {
        formType === 'signUp' && (
          <p>
            Already have an account? <span
            onClick={() => updateFormType('signIn')}
            >Sign In</span>
          </p>
        )
      }
      {
        formType === 'signIn' && (
          <>
            <p>
              Need an account? <span
              onClick={() => updateFormType('signUp')}
              >Sign Up</span>
            </p>
            <p>
              Forget your password? <span
                onClick={() => updateFormType('forgotPassword')}
              >Reset Password</span>
            </p>
          </>
        )
      } */}
        </div>
    );
}
