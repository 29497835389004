import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dropdownDown from '../../../assets/icons/dropdown-down.svg';
import BudgetService from '../../../services/BudgetService/BudgetService';
import ClientService from '../../../services/ClientService/ClientService';
import { clientOptionTemplate, filterClients, handleClientsOnScroll, selectedClientTemplate } from '../../People/Clients/Utils';


const AddBudgetDialog = ({show, setShow, budgets, setBudgets, reload}) => {
    const {t, i18n} = useTranslation()
    
    const toast = useRef(null);

    const navigate = useNavigate();

    const budgetService = new BudgetService()
    const clientService = new ClientService()
    const formRef = useRef()
    const [loading, setLoading] = useState(false);
    
    const [clients, setClients] = useState([]);
    const [queryClientsResponse, setQueryClientsResponse] = useState();
    const [clientsRowsNumber, setClientsRowsNumber] = useState(3)
    const [clientFilterName,setClientFilterName] = useState(undefined)

    const [currentClient, setCurrentClient] = useState();

    useEffect(() => {
        setLoading(true)
        clientService.queryClients({limit: clientsRowsNumber}).then(data => {
            setQueryClientsResponse(data)
            setClients(data.data)
        })
        .catch((error) => {
            console.log(error);
            toast?.current?.show({severity: 'error', summary: t("label.error"), detail: error?.response?.data?.message, life: 5000});
        })
        .finally(() => {
            setLoading(false)
        })
    }, []);


    const formik = useFormik({
        initialValues: {
            concept: '',
        },
        validate: (data) => {
            let errors = {};

            if (!currentClient) {
                errors.client = t("label.field_required");
            }

            if (!data.concept) {
                errors.concept = t("label.field_required");
            }
            
            return errors;
        },
        onSubmit: (data) => {
            setLoading(true)

            const newData = {
                concept: data.concept, 
                status: "draft",
                inuse: false,
                date: new Date().toISOString(),
                client: {
                    id: currentClient?.id,
                    name: currentClient?.name,
                    picture: currentClient?.picture,
                    tax_id: currentClient?.tax_id,
                    address: currentClient?.address
                }
            }

            budgetService.createBudget(currentClient.id, newData)
                .then(data => {
                    if (data) {
                        updateBudgets(data)
                        setShow(false);
                        navigate(`/budgets/${data?.id.split("#")[0]}/${data?.id.split("#")[1]}/details`)
                    }
                })
                .catch((error) => {
                    toast?.current?.show({severity: 'error', summary: t("label.error"), detail: error?.response?.data?.message, life: 5000});
                })
                .finally(() => {
                    setLoading(false)
                });
            
            formik.resetForm();
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };


    const updateBudgets = (newData) => {
        let newBudgets = budgets
        newBudgets.unshift(newData)
        setBudgets(newBudgets)
        reload(false)
    }

    return (
        <Dialog 
            visible={show} 
            onHide={() => setShow(false)} 
            // style={{ minWidth: '35vw' }} 
            // breakpoints={{ '960px': '75vw', '641px': '100vw' }}
            // draggable={false}
            draggable={false}
            className="custom-dialog"
            closable={false}
            dismissableMask
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "24px 24px 0px" }}>
                        <label className="mousee-text font-medium font-weight-bold">{t("label.new_budget")}</label>
                    </div>
                </div>
                // <div>
                //     <span className='mousee-text font-medium font-weight-bold'>{t("label.new_budget")}</span>
                // </div>
                
            }
            footer={
                <div className="px-3 pt-3">
                    <Button
                        className="p-button-outlined p-button-plain"
                        label={t("label.cancel")}
                        onClick={() => {
                            setShow(false);
                        }}
                    />
                    <Button form="new-profile-form" className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={formik.handleSubmit} loading={loading} />
                </div>
                // <div>
                //     <Button form='new-profile-form' className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={() => formik.handleSubmit()} loading={loading}/>
                // </div>
            }
        >
            <Toast ref={toast}/>
            <div className='pt-3'>
                {/* <span className='mousee-text font-small font-weight-semibold'>Indica los datos básicos del nuevo presupuesto</span> */}

                <div className='pt-2'>
                    <form id="new-profile-form" onSubmit={formik.handleSubmit} innerRef={formRef} className="p-fluid">
                        <div className='grid'>
                            <div className='field col-12'>
                                <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.project")}*</label>
                                <InputText 
                                    id="concept"
                                    name='concept'
                                    placeholder={t("label.project")}
                                    value={formik.values.concept}
                                    className={classNames('block w-full', {'p-invalid': isFormFieldValid('concept') })}
                                    onChange={(e) => {
                                        formik.setFieldValue('concept', e.target.value);
                                    }}
                                />
                                {getFormErrorMessage('concept')}
                            </div>
                            
                            <div className='field col-12'>
                                <div className="flex flex-column card-container">
                                    <div className="flex align-items-left justify-content-left">
                                        <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.client")}*</label>
                                    </div>
                                    <div className="flex align-items-center justify-content-center pt-2">
                                        <Dropdown
                                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt='up'></img>}
                                            id="budget-clients-dowpdown"
                                            name='budget-clients-dowpdown'
                                            key="budget-clients-dowpdown"
                                            value={currentClient}
                                            options={clients}
                                            optionLabel='name'
                                            onChange={(e) => setCurrentClient(e.value)} 
                                            placeholder={t("label.dropdown_select")}
                                            valueTemplate={selectedClientTemplate} 
                                            itemTemplate={clientOptionTemplate}
                                            filter
                                            emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                            className={classNames('w-full', { 'p-invalid': formik.errors.client })}
                                            onScrollCapture={(e) => handleClientsOnScroll(e, clientService, clientsRowsNumber, queryClientsResponse, setQueryClientsResponse, clients, setClients, clientFilterName, setClientFilterName)}
                                            filterTemplate={ (options) => 
                                                <span className="p-input-icon-right min-w-full">
                                                    <i className="pi pi-search" />
                                                    <InputText autoFocus className='min-w-full' value={clientFilterName} ref={null} onChange={(e) => filterClients(e, options, setClientFilterName, queryClientsResponse, setQueryClientsResponse, clientService, clientsRowsNumber, clients, setClients)} />
                                                </span>
                                            }
                                        />
                                    </div>
                                    {getFormErrorMessage('client')}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>


        </Dialog>
    );
}
export default AddBudgetDialog;