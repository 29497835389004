import { API } from 'aws-amplify';

const API_NAME = "UsersAPI";
const BASE_PATH = "/user"

export default class UserService {
    async getUsers(queryStringParameters={}) {
        return API.get(API_NAME, `${BASE_PATH}`,{queryStringParameters:queryStringParameters})
            .then((response) => response)
            .catch((error) => {
                console.log(error.response);
            });
    }

    async queryUsers(queryStringParameters={}, data) {
        return API.post(API_NAME, `${BASE_PATH}/query`,{queryStringParameters:queryStringParameters, body: data})
            .then((response) => response)
            .catch((error) => {
                console.log(error.response);
            });
    }

    async asignProfiles(data, user_id) {
        return API.post( API_NAME, `${BASE_PATH}/asign/${user_id}`, { body: data } )
    }

    async createUser(queryStringParameters={}, data) {
        return API.post( API_NAME, BASE_PATH, { queryStringParameters:queryStringParameters, body: data } )
        // .then((response) => response)
        // .catch((error) => {
        //     console.log(error.response);
        // });
    }

    async executeUserAction(data, id) {
        return API.post( API_NAME, `${BASE_PATH}/${id}`, { body: data } )
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }

    async updateUser(queryStringParameters={}, data, id) {
        return API.put( API_NAME, `${BASE_PATH}/${id}`, { queryStringParameters:queryStringParameters, body: data } )
        // .then((response) => response)
        // .catch((error) => {
        //     console.log(error.response);
        // });
    }

    async deleteUser(queryStringParameters={}, id) {
        return API.del( API_NAME, `${BASE_PATH}/${id}`, { queryStringParameters:queryStringParameters})
        // .then((response) => response)
        // .catch((error) => {
        //     console.log(error.response);
        // });
    }

    async getUser(queryStringParameters={}, id) {
        return API.get( API_NAME, `${BASE_PATH}/${id}`, { queryStringParameters:queryStringParameters})
        // .then((response) => response)
        // .catch((error) => {
        //     console.log(error.response);
        // });
    }

    // async addProjectToUsers(data) {
    //     return API.put( API_NAME, `/add_project_to_users`, { body: data } )
    //     .then((response) => response)
    //     .catch((error) => {
    //         console.log(error.response);
    //     });
    // }
}