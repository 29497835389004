
import { ListBox } from 'primereact/listbox';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProfileService from '../../../services/ProfileService/ProfileService';


const ShowProfilesOverlay = ({customRef, userId, onHide, visible}) => {

    const {t, i18n} = useTranslation();
    
    const profileService = new ProfileService();
    const [loading, setLoading] = useState(false);

    const [profiles, setProfiles] = useState([]);
    const [queryProfilesResponse, setQueryProfilesResponse] = useState();
    const [profilesRowsNumber, setProfilesRowsNumber] = useState(10)
    const [profileFilterName,setProfileFilterName] = useState(undefined)

    useEffect(() => {
        setLoading(true)
        
        
        // profileService.queryProfiles({user_id: userId, limit: profilesRowsNumber}).then(data => {
        profileService.queryProfiles({user_id: userId, limit: profilesRowsNumber}, undefined, "private").then(data => {
            setQueryProfilesResponse(data)
            setProfiles(data?.data)
            setLoading(false)
        })

    }, []);

    return(
        <OverlayPanel 
            ref={customRef}
            // visible={false}
            onHide={(e) => {
                onHide(false);
                customRef?.current?.hide(e)
            }}
            // onHide={(e) => customRef?.current?.toggle(e)}
            style={{overflow: "hidden", padding: profiles && profiles.length === 0 ? "1.25rem" : 0}}
        >
            {
                profiles && profiles.length === 0 ?
                    loading ?
                        <div className="flex align-items-center">
                            <i className="pi pi-spin pi-spinner" style={{ fontSize: '1rem' }}></i>
                            <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading")}</label>
                        </div>
                    :
                        <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>
                 : 
                    <div className='grid'>
                        <div className='col-12 p-0 pt-3'>
                            <ListBox 
                                // value={selectedUser}
                                options={profiles} 
                                optionLabel="name" 
                                className="min-w-full border-none"
                                // itemTemplate={taskParticipantUserOptionTemplate}
                            />
                        </div>
                    </div>
            }
        </OverlayPanel>
    )
}
export default ShowProfilesOverlay;