import { Icon } from "@iconify/react";
import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { objectHasKeys, tableHeaderTemplate } from "../../Utils";
import dropdownDown from "../../assets/icons/dropdown-down.svg";
import CustomTablePaginator from "../../components/CustomTablePaginator";
import { checkComponentPermissions, checkPermissions } from "../../data/Permissions";
import { TENANT_HOURS_TIMING, WEEK_DAYS, getOriginalTenantWeekDayList, getParsedTenantWeekDayList, getTenantHolidaysDateObject, getTenantHolidaysISOString, getTenantHoursTimingObject } from "../../data/Tenant";
import AbsenceService from "../../services/AbsenceService/AbsenceService";
import AddEditAbsenceDialog from "./Dialogs/AddEditAbsenceDialog";
import "./Dialogs/AddEditAbsenceDialog.scss";

const TenantAbsences = ({ absences, setAbsences, absencesActiveIndex, setAbsencesActiveIndex, absencesTableFirst, setAbsencesTableFirst, absencesTableKeys, setAbsencesTableKeys, queryAbsencesResponse, setQueryAbsencesResponse, absencesRowsNumber, setAbsencesRowsNumber, tenantInfo, updateTenantField }) => {
    const { t, i18n } = useTranslation();

    const absenceService = new AbsenceService();

    const toast = useRef(null);
    const [loading, setLoading] = useState(false);

    const [showAddEditAbsenceDialog, setShowAddEditAbsenceDialog] = useState(false);
    const [actualAbsence, setActualAbsence] = useState(undefined);

    useEffect(() => {
        if (!absences || absences?.length === 0) {
            setLoading(true);
            absenceService.queryAbsences({ limit: absencesRowsNumber }).then((data) => {
                setQueryAbsencesResponse(data);
                setAbsences(data?.data);

                setLoading(false);
            });
        }
    }, []);

    const onTablePageChange = (key) => {
        if (key === absencesTableKeys.length) {
            setLoading(true);

            let queryParameters = {
                limit: absencesRowsNumber,
            };

            absenceService.queryAbsences(queryParameters, queryAbsencesResponse.last_key).then((data) => {
                setQueryAbsencesResponse(data);

                if (data.data && data.data.length > 0) {
                    const newAbsences = [...absences, ...data.data];
                    setAbsences(newAbsences);

                    setAbsencesTableKeys(absencesTableKeys.concat([absencesTableKeys.length]));
                    setAbsencesActiveIndex(absencesTableKeys.length);

                    if (absencesTableKeys.length > 0) {
                        setAbsencesTableFirst(absencesTableKeys.length * absencesRowsNumber);
                    } else {
                        setAbsencesTableFirst(0);
                    }
                }

                setLoading(false);
            });
        } else {
            const newIndex = absencesTableKeys.findIndex((item) => item === key);
            setAbsencesActiveIndex(newIndex);

            if (newIndex > 0) {
                setAbsencesTableFirst(newIndex * absencesRowsNumber);
            } else {
                setAbsencesTableFirst(0);
            }
        }
    };

    const deleteAbsence = (item) => {
        confirmDialog({
            tagKey: "confirmAbsenceDeletionDialog",
            message: <span>{t("absences.page.are_you_sure_you_want_to_delete_the_absence_x", { absence_name: item?.name })}</span>,
            header: t("label.delete_absence"),
            draggable: false,
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: t("label.delete_absence"),
            rejectLabel: t("label.cancel"),
            rejectClassName: "p-button-outlined p-button-plain",
            accept: () => {
                setLoading(true);
                absenceService
                    .deleteAbsence(item?.id)
                    .then(() => {
                        const newAbsences = absences?.filter((newAbsence) => newAbsence.id !== item?.id);
                        setAbsences(newAbsences);
                    })
                    .catch((error) => {
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data });
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            },
            contentClassName: "pt-3",
        });
    };

    return (
        <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}>
            <Toast ref={toast} />
            <ConfirmDialog tagKey="confirmAbsenceDeletionDialog" />
            {showAddEditAbsenceDialog ? (
                <AddEditAbsenceDialog
                    show={showAddEditAbsenceDialog}
                    setShow={(value) => {
                        setShowAddEditAbsenceDialog(value);
                        setActualAbsence(undefined);
                    }}
                    actualAbsence={actualAbsence}
                    absences={absences}
                    setAbsences={setAbsences}
                />
            ) : null}

            <div className="grid">
                <div className="col-12 xl:col-6">
                    <div className="grid card xl:mr-2">
                        <div className="col-12">
                            <label className="mousee-text font-medium font-weight-bold">{t("label.absences_configuration")}</label>
                        </div>
                        <div className="col-12 xl:col-7">
                            <label className="pl-2  mousee-text font-x-small font-weight-semibold">{t("label.users_can_request")}</label>
                            <Dropdown
                                dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                id="tenant-hours-timing-dowpdown"
                                name="tenant-hours-timing-dowpdown"
                                key="tenant-hours-timing-dowpdown"
                                value={getTenantHoursTimingObject(tenantInfo?.absences_config?.hours_timing)}
                                options={TENANT_HOURS_TIMING}
                                optionLabel={(item) => {
                                    return t(item?.label);
                                }}
                                onChange={(e) => objectHasKeys(tenantInfo?.absences_config) ? updateTenantField("absences_config.hours_timing", e?.value?.code) : updateTenantField("absences_config", {hours_timing: e?.value?.code})}
                                placeholder={t("label.dropdown_select")}
                                className="min-w-full"
                            />
                        </div>
                        <div className="col-12 xl:col-5">
                            <label className="pl-2  mousee-text font-x-small font-weight-semibold">{t("label.absences_by_default")}</label>
                            <InputNumber
                                value={tenantInfo?.absences_config?.default_absence_days}
                                min={0}
                                onValueChange={(e) => {
                                    objectHasKeys(tenantInfo?.absences_config) ? updateTenantField("absences_config.default_absence_days", e?.value) : updateTenantField("absences_config", {default_absence_days: e?.value})
                                }}
                                // inputClassName="max-w-5rem"
                                className="min-w-full"
                            />
                        </div>
                        <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.business_days")}</label>
                            <MultiSelect
                                value={getParsedTenantWeekDayList(tenantInfo?.absences_config?.business_days)}
                                onChange={(e) => objectHasKeys(tenantInfo?.absences_config) ? updateTenantField("absences_config.business_days", getOriginalTenantWeekDayList(e.value)) : updateTenantField("absences_config", {business_days: getOriginalTenantWeekDayList(e.value)})} 
                                options={WEEK_DAYS}
                                optionLabel={(item) => t(item?.label)}
                                placeholder={t("label.dropdown_select")}
                                maxSelectedLabels={6} 
                                className="min-w-full"
                                display="chip"
                                showSelectAll={false}
                            />
                            <small className="pl-2 mousee-text-small font-weight-regular">{t("label.select_the_days_of_the_week_where_absences_can_be_requested")}</small>
                        </div>
                        <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.holidays")}</label>
                            <Calendar
                                value={getTenantHolidaysDateObject(tenantInfo?.absences_config?.holidays)}
                                onChange={(e) => objectHasKeys(tenantInfo?.absences_config) ? updateTenantField("absences_config.holidays", getTenantHolidaysISOString(e.value)) : updateTenantField("absences_config", {holidays: getTenantHolidaysISOString(e.value)})} 
                                selectionMode="multiple" 
                                readOnlyInput
                                className="min-w-full"
                                disabledDays={[0, 6]}
                                locale={i18n?.language?.split("-")[0]}
                            />
                            <small className="pl-2 mousee-text-small font-weight-regular">{t("label.add_or_remove_country_and_region_holidays")}</small>
                        </div>
                        <div className="col-12 xl:col-6"></div>
                    </div>
                </div>
                <div className="col-12 xl:col-6">
                    <div className="grid card">
                        <div className="col-12">
                            <div className="flex justify-content-between flex-wrap">
                                <div className="flex align-items-center justify-content-center pl-2">
                                    <label className="mousee-text font-medium font-weight-bold">{t("label.types_of_absences")}</label>
                                </div>
                                {checkComponentPermissions(
                                    <div className="flex align-items-center justify-content-center pr-2">
                                        <Button className="p-button-filled" label={t("label.add_new")} icon="pi pi-plus" onClick={() => setShowAddEditAbsenceDialog(true)} />
                                    </div>,
                                    ["edit_tenant"],
                                    null,
                                    null
                                )}
                            </div>
                        </div>
                        <div className="col-12">
                            <DataTable
                                key="absences-table"
                                selectionMode="single"
                                style={{ borderRadius: "var(--border-radius)" }}
                                dataKey="id"
                                emptyMessage={t("label.no_items_to_display")}
                                // loading={loadingAbsences}
                                value={absences.slice(absencesActiveIndex * absencesRowsNumber, absencesActiveIndex * absencesRowsNumber + absencesRowsNumber)}
                                responsiveLayout="scroll"
                                stripedRows
                                onRowClick={(e) => {
                                    if (checkPermissions(["edit_tenant"])) {
                                        if (e && e.data && e.data.id) {
                                            setActualAbsence(e.data);
                                            setShowAddEditAbsenceDialog(true);
                                        }
                                    }
                                }}
                                paginator={false}
                                first={absencesTableFirst}
                                footer={<CustomTablePaginator first={absencesTableFirst} activeIndex={absencesActiveIndex} onPageChange={onTablePageChange} rowsNumber={absencesRowsNumber} keys={absencesTableKeys} lastKey={queryAbsencesResponse?.last_key} />}
                            >
                                <Column
                                    header={tableHeaderTemplate(null, t("label.name"))}
                                    style={{ width: "90%", minWidth: "20rem" }}
                                    body={(item, options) => {
                                        return (
                                            <div class="flex flex-row flex-wrap">
                                                <div class="flex align-items-center justify-content-center">
                                                    <Checkbox
                                                        inputId={`color-item-${options.rowIndex}`}
                                                        name={`color-item-${options.rowIndex}`}
                                                        checked={false}
                                                        className={`absence-checkbox ${item?.color?.replace("#", "").match(/^\d/) ? `a${item?.color?.replace("#", "")}` : item?.color?.replace("#", "")}`}
                                                    />
                                                </div>
                                                <div class="flex align-items-center justify-content-center pl-3">
                                                    <label className="mousee-text font-x-small font-weight-regular">{item?.name}</label>
                                                </div>
                                            </div>
                                        );
                                    }}
                                />

                                {checkComponentPermissions(
                                    <Column
                                        headerStyle={{ width: "5rem", textAlign: "center" }}
                                        bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                        body={(item, options) => {
                                            return (
                                                <Button
                                                    icon={<Icon icon="solar:trash-bin-trash-bold-duotone" className="" style={{ fontSize: "20px" }} />}
                                                    className="p-button-text p-button-secondary"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        deleteAbsence(item);
                                                    }}
                                                />
                                            );
                                        }}
                                    />,
                                    ["edit_tenant"],
                                    null,
                                    null
                                )}
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </BlockUI>
    );
};
export default TenantAbsences;
