import { API } from 'aws-amplify';

const API_NAME = "TenantsAPI";
const BASE_PATH = "/tenant"

export default class TenantService {
    // async getUsers(queryStringParameters={}) {
    //     return API.get(API_NAME, `${BASE_PATH}`,{queryStringParameters:queryStringParameters})
    //         .then((response) => response)
    //         .catch((error) => {
    //             console.log(error.response);
    //         });
    // }

    // async createUser(data) {
    //     return API.post( API_NAME, BASE_PATH, { body: data } )
    //     .then((response) => response)
    //     .catch((error) => {
    //         console.log(error.response);
    //     });
    // }

    async updateTenant(data, id) {
        return API.put( API_NAME, `${BASE_PATH}/${id}`, { body: data } )
        // .then((response) => response)
        // .catch((error) => {
        //     console.log(error.response);
        // });
    }

    // async deleteUser(data, id) {
    //     return API.del( API_NAME, `${BASE_PATH}/${id}`, {body: data})
    //     .then((response) => response)
    //     .catch((error) => {
    //         console.log(error.response);
    //     });
    // }

    async getTenant(id) {
        return API.get( API_NAME, `${BASE_PATH}/${id}`)
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }    
}