export const handleRolesOnScroll = (e, iamService, rolesRowsNumber, queryRolesResponse, setQueryRolesResponse, roles, setRoles, roleFilterName, setRoleFilterName) => {
    const bottom = e?.target?.scrollHeight - e?.target?.clientHeight <= e?.target?.scrollTop + 50;
    if (bottom) {
        const retrieveRoles = (lastKey) => {
            lastKey = lastKey && Object.keys(lastKey).length > 0 ? lastKey : undefined;
            if (lastKey) delete lastKey["name"];
            iamService.queryRoles({ limit: rolesRowsNumber }, lastKey).then((data) => {
                if (data) {
                    setQueryRolesResponse(data);

                    if (data.data && data.data.length > 0) {
                        var ids = new Set(roles.map((d) => d.id));

                        var mergedRoles = [...roles, ...data.data.filter((d) => !ids.has(d.id))];
                        setRoles(mergedRoles);
                    }
                }
            });
        };

        let lastKey = queryRolesResponse?.last_key;
        if (!lastKey || (lastKey && Object.keys(lastKey).length === 0)) {
            // if (roles && roles.length > 0) {
            //     const lastRole = roles[roles.length - 1]
            //     // lastKey = {
            //     //     sk: lastRole.id
            //     // }
            //     if (roleFilterName !== undefined) {
            //         if (roleFilterName && roleFilterName.length > 0) {
            //             lastKey = {
            //                 ...lastKey,
            //                 name: lastRole.name
            //             }
            //         }
            //         retrieveRoles(lastKey)
            //         if (roleFilterName === "") {
            //             setRoleFilterName(undefined)
            //         }
            //     }
            // }
        } else {
            retrieveRoles(lastKey);
        }
    }
};

export const filterRoles = (event, options, setRoleFilterName, queryRolesResponse, setQueryRolesResponse, iamService, rolesRowsNumber, roles, setRoles) => {
    let _filterValue = event.target.value;
    setRoleFilterName(_filterValue);

    let lastKey = undefined;

    if (queryRolesResponse && queryRolesResponse.last_key && Object.keys(queryRolesResponse.last_key).length > 0) {
        if (_filterValue.trim() === "" || _filterValue.length === 0) {
            if (lastKey) delete lastKey["name"];
        } else {
            if (lastKey && !lastKey.name) {
                if (roles && roles.length > 0) {
                    lastKey = {
                        id: roles[roles.length - 1].id,
                        name: roles[roles.length - 1].name,
                    };
                } else {
                    lastKey = {
                        ...lastKey,
                        name: "",
                    };
                }
            }
        }
    }

    iamService.queryRoles({ name: _filterValue, limit: rolesRowsNumber }, lastKey).then((data) => {
        setQueryRolesResponse(data);

        if (data && data.data && data.data.length > 0) {
            var ids = new Set(roles.map((d) => d.id));

            var mergedRoles = [...roles, ...data.data.filter((d) => !ids.has(d.id))];
            setRoles(mergedRoles);
        }
        options?.filterOptions?.filter(event);
    });
};
