import { useAuthenticator } from "@aws-amplify/ui-react";
import { Icon } from "@iconify/react";
import { Buffer } from "buffer";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { convertToBase64 } from "../../Utils";
import { getTaskFileUrl } from "../../pages/Task/Utils";
import FileService from "../../services/FileService/FileService";
import "./FileUpload.css";

const FileUpload = ({ inputFileRef, classnames, files, setFiles, task, project_name }) => {
    const { t, i18n } = useTranslation();

    const toast = useRef();
    const [loading, setLoading] = useState(false);
    const fileService = new FileService();

    const { user } = useAuthenticator((context) => [context.user]);

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const actualFile = e.target.files[0];

            setLoading(true);

            const newFileUrl = getTaskFileUrl(user, task?.id, actualFile.name);

            const newFile = {
                name: actualFile.name,
                url: newFileUrl,
                client_id: task?.client_id,
                project_id: task?.project_id,
                process_id: task?.process_id,
                task_id: task?.id,
                project_name: project_name,
                task_name: task?.title,
            };

            fileService
                .createFile(task?.client_id, task?.project_id, task?.process_id, task?.id, newFile)
                .then((data) => {
                    convertToBase64(actualFile).then((convertedFile) => {
                        fetch(data?.url, {
                            method: "PUT",
                            body: new Buffer.from(convertedFile.substr(convertedFile.indexOf(",") + 1), "base64"),
                            headers: {
                                "Content-type": actualFile.type,
                            },
                        })
                            .then((response) => {
                                let newFiles = [];

                                if (files && files.length > 0) {
                                    newFiles = files;
                                }

                                newFiles.push(data);

                                setFiles(newFiles);
                                setLoading(false);
                            })
                            .catch((error) => {
                                console.error("Error:", error);
                                setLoading(false);
                            });
                    });
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }
    };

    return (
        <div className="">
            <Toast ref={toast} />
            <div className={`custom-upload ${classnames}`}>
                <div className="custom-upload-container" onClick={() => inputFileRef?.current?.click()}>
                    <input accept="*" type="file" tabIndex="-1" style={{ display: "none" }} ref={inputFileRef} onChange={handleFileChange} />

                    <div className={`custom-upload-container-content ${classnames}`}>
                        {loading ? <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i> : <Icon icon="solar:cloud-upload-bold-duotone" className={`custom-upload-container-content-icon ${classnames}`} style={{ fontSize: "2.5rem", color: "var(--secondary-color-extra-light)" }} />}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default FileUpload;
