export const TENANT_CURRENCIES = [
    {
        label: "currency.eur",
        code: "EUR",
    },
    {
        label: "currency.usd",
        code: "USD",
    },
    {
        label: "currency.mxn",
        code: "MXN",
    },
    {
        label: "currency.gbp",
        code: "GBP",
    },
    {
        label: "currency.cad",
        code: "CAD",
    },
    {
        label: "currency.jpy",
        code: "JPY",
    },
    {
        label: "currency.aud",
        code: "AUD",
    },
    {
        label: "currency.chf",
        code: "CHF",
    },
    {
        label: "currency.cny",
        code: "CNY",
    },
    {
        label: "currency.hkd",
        code: "HKD",
    },
    {
        label: "currency.nzd",
        code: "NZD",
    },
    {
        label: "currency.nok",
        code: "NOK",
    },
    {
        label: "currency.sek",
        code: "SEK",
    },
];

export const getTenantCurrencyLabel = (code) => {
    const filteredList = TENANT_CURRENCIES.filter((item) => item.code === code)[0];

    return filteredList.label;
};

export const getTenantCurrencyObject = (code) => {
    const filteredItem = TENANT_CURRENCIES.filter((item) => item.code === code)[0];

    return filteredItem;
};

export const WEEK_DAYS = [
    {
        order: 0,
        label: "label.sunday",
        code: "sunday",
    },
    {
        order: 1,
        label: "label.monday",
        code: "monday",
    },
    {
        order: 2,
        label: "label.tuesday",
        code: "tuesday",
    },
    {
        order: 3,
        label: "label.wednesday",
        code: "wednesday",
    },
    {
        order: 4,
        label: "label.thursday",
        code: "thursday",
    },
    {
        order: 5,
        label: "label.friday",
        code: "friday",
    },
    {
        order: 6,
        label: "label.saturday",
        code: "saturday",
    },
];

export const getTenantWeekDayLabel = (code) => {
    const filteredItem = WEEK_DAYS.filter((item) => item.code === code);

    if (filteredItem && filteredItem.length > 0) {
        return filteredItem[0].label;
    }
};

export const getTenantWeekDayOrder = (code) => {
    const filteredItem = WEEK_DAYS.filter((item) => item.code === code);

    if (filteredItem && filteredItem.length > 0) {
        return filteredItem[0].order;
    }
};

export const getTenantWeekDayObject = (code) => {
    const filteredItem = WEEK_DAYS.filter((item) => item.code === code);

    if (filteredItem && filteredItem.length > 0) {
        return filteredItem[0];
    }
};

export const getParsedTenantWeekDayList = (currentTenantWeekDaysList) => {
    if (currentTenantWeekDaysList && currentTenantWeekDaysList?.length > 0) {
        let parsedList = [];
        for (let i = 0; i < currentTenantWeekDaysList.length; i++) {
            const currentTenantWeekDaysListElement = currentTenantWeekDaysList[i];

            parsedList.push(getTenantWeekDayObject(currentTenantWeekDaysListElement));
        }

        return parsedList;
    }

    return [];
};

export const getOriginalTenantWeekDayList = (originalWeekDaysList) => {
    if (originalWeekDaysList && originalWeekDaysList?.length > 0) {
        let parsedList = [];
        for (let i = 0; i < originalWeekDaysList.length; i++) {
            const originalWeekDaysListElement = originalWeekDaysList[i];

            if (originalWeekDaysListElement?.code) {
                parsedList.push(originalWeekDaysListElement?.code);
            }
        }

        return parsedList;
    }

    return [];
};

export const getTenantHolidaysISOString = (listOfDates) => {
    if (listOfDates && listOfDates?.length > 0) {
        let parsedList = [];

        for (let i = 0; i < listOfDates.length; i++) {
            const listOfDatesElement = listOfDates[i];

            parsedList.push(listOfDatesElement?.toISOString());
        }

        return parsedList;
    }

    return [];
};

export const getTenantHolidaysDateObject = (listOfDates) => {
    if (listOfDates && listOfDates?.length > 0) {
        let parsedList = [];

        for (let i = 0; i < listOfDates.length; i++) {
            const listOfDatesElement = listOfDates[i];

            parsedList.push(new Date(listOfDatesElement));
        }

        return parsedList;
    }

    return [];
};

export const TENANT_HOURS_TIMING = [
    {
        label: "label.all_days",
        code: "all_day"
    },
    {
        label: "label.all_and_half_day",
        code: "all_and_half_day"
    },
]

export const getTenantHoursTimingLabel = (code) => {
    const filteredItem = TENANT_HOURS_TIMING.filter((item) => item.code === code);

    if (filteredItem && filteredItem.length > 0) {
        return filteredItem[0].label;
    }
};

export const getTenantHoursTimingObject = (code) => {
    const filteredItem = TENANT_HOURS_TIMING.filter((item) => item.code === code);

    if (filteredItem && filteredItem.length > 0) {
        return filteredItem[0];
    }
};