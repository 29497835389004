import { useAuthenticator } from "@aws-amplify/ui-react";
import { loadStripe } from "@stripe/stripe-js";
import { Buffer } from "buffer";
import { useFormik } from "formik";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Editor } from "primereact/editor";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { capitalizeWord, convertToBase64, getUpdateRemoveParams, objectHasKeys } from "../../../../Utils";
import dropdownDown from "../../../../assets/icons/dropdown-down.svg";
import AvatarUpload from "../../../../components/AvatarUpload/AvatarUpload";
import { CLIENT_TYPES } from "../../../../data/Client";
import { countryOptionTemplate, getCountriesTranslated, getCountryByCode, selectedCountryTemplate } from "../../../../data/General";
import { checkComponentPermissions } from "../../../../data/Permissions";
import ClientService from "../../../../services/ClientService/ClientService";
import { getClientLogoUrl } from "../Utils";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AddClient = ({ setOption }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const fileUploadRef = useRef(null);
    const [currentFile, setCurrentFile] = useState();
    const { user } = useAuthenticator((context) => [context.user]);
    const toast = useRef(null);

    const clientService = new ClientService();

    useEffect(() => {
        setOption("clients");
    }, []);

    const formik = useFormik({
        initialValues: {
            type: "company",
            status: "active",
            email: "",
            position: "",
            phone: "",

            notes: "",
            // name: "",
            // cif: "",
            // social_reason: "",
            // country: "",
            // province: "",
            // postal_code: "",
            // city: "",
            // address: "",
            address: {
                city: undefined,
                country: undefined,
                line1: undefined,
                line2: undefined,
                postal_code: undefined,
                state: undefined,
            },
            picture: "",
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = t("label.field_required");
            }

            // if (!currentFile && !data?.picture) {
            //     errors.image = t("label.field_required");
            // }

            if (data.type === "individual") {
                if (!data.email) {
                    errors.email = t("label.field_required");
                }
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            // let newData = {
            //     name: data.name,
            //     type: data?.type ? data?.type : "company",
            //     address: data?.address
            // };
            // if (data?.type === "individual") {
            //     newData = {
            //         ...newData,
            //         email: data?.email,
            //         position: data?.position,
            //         phone: data?.phone,
            //     };
            // }

            // if (data.cif || data.social_reason || data.country || data.province || data.postal_code || data.city || data.address) {
            //     newData["fiscal_data"] = {};

            //     if (data.cif) {
            //         newData["fiscal_data"]["cif"] = data.cif;
            //     }
            //     if (data.social_reason) {
            //         newData["fiscal_data"]["social_reason"] = data.social_reason;
            //     }
            //     if (data.country) {
            //         newData["fiscal_data"]["country"] = data.country;
            //     }
            //     if (data.province) {
            //         newData["fiscal_data"]["province"] = data.province;
            //     }
            //     if (data.postal_code) {
            //         newData["fiscal_data"]["postal_code"] = data.postal_code;
            //     }
            //     if (data.city) {
            //         newData["fiscal_data"]["city"] = data.city;
            //     }
            //     if (data.address) {
            //         newData["fiscal_data"]["address"] = data.address;
            //     }
            // }

            clientService
                .createClient(data)
                .then((data) => {
                    if (data && data.id && currentFile) {
                        const newPicture = getClientLogoUrl(user, data.id, currentFile?.name);
                        const newData = {
                            ...data,
                            picture: newPicture,
                        };

                        const updateParams = getUpdateRemoveParams(data, newData);
                        clientService.updateClient(updateParams, data.id).then((data) => {
                            convertToBase64(currentFile).then((convertedFile) => {
                                fetch(data.picture, {
                                    method: "PUT",
                                    body: new Buffer.from(convertedFile.replace(/^data:image\/\w+;base64,/, ""), "base64"),
                                    headers: {
                                        "Content-type": currentFile.type,
                                    },
                                })
                                    .then((response) => {
                                        setLoading(false);
                                        navigate("/clients");
                                    })
                                    .catch((error) => {
                                        console.error("Error:", error);
                                        formik.resetForm();
                                        setLoading(false);
                                    });
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                        })
                    } else {
                        navigate("/clients");
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                })
                .finally(() => {
                    setLoading(false);
                });

            formik.resetForm();
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const clientData = (typeParam) => {
        return (
            <div className="grid card">
                <div className="col-12 xl:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.fullname")}*</label>
                    <InputText
                        id="name"
                        name="name"
                        placeholder={t("label.fullname")}
                        value={formik.values.name}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("name") })}
                        onChange={(e) => {
                            formik.setFieldValue("name", e.target.value);
                        }}
                    />
                    {getFormErrorMessage("name")}
                </div>

                <div className="col-12 xl:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.tax_id")}</label>
                    <InputText
                        id="tax_id"
                        name="tax_id"
                        placeholder={t("label.tax_id")}
                        value={formik.values.tax_id}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("tax_id") })}
                        onChange={(e) => {
                            formik.setFieldValue("tax_id", e.target.value);
                        }}
                    />
                </div>
                {typeParam === "individual" ? (
                    <>
                        {/* <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.fullname")}*</label>
                            <InputText
                                id="name"
                                name="name"
                                placeholder={t("label.fullname")}
                                value={formik.values.name}
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("name") })}
                                onChange={(e) => {
                                    formik.setFieldValue("name", e.target.value);
                                }}
                            />
                            {getFormErrorMessage("name")}
                        </div> */}

                        <div className="col-12 xl:col-6">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.email")}*</label>
                            <InputText
                                id="email"
                                name="email"
                                placeholder={t("label.email")}
                                value={formik.values.email}
                                keyfilter="email"
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("email") })}
                                onChange={(e) => {
                                    formik.setFieldValue("email", e.target.value);
                                }}
                            />
                            {getFormErrorMessage("email")}
                        </div>

                        <div className="col-12 xl:col-6">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.job_title")}</label>
                            <InputText
                                id="position"
                                name="position"
                                placeholder={t("label.job_title")}
                                value={formik.values.position}
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("position") })}
                                onChange={(e) => {
                                    formik.setFieldValue("position", e.target.value);
                                }}
                            />
                        </div>
                    </>
                ) : null}

                <div className="col-12">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.country_or_region")}</label>
                    <Dropdown
                        dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                        value={getCountryByCode(formik?.values?.address?.country, t)}
                        onChange={(e) => formik.setFieldValue("address.country", e?.value?.isoCode)}
                        valueTemplate={selectedCountryTemplate} 
                        itemTemplate={countryOptionTemplate}
                        filter
                        optionLabel="name"
                        showClear
                        placeholder={t("label.country_or_region")}
                        options={getCountriesTranslated(t)}
                        className="w-full"
                        emptyMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                        emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                    />
                </div>

                <div className="col-12 lg:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.address_line1")}</label>
                    <InputText
                        id="address.line1"
                        name="address.line1"
                        placeholder={t("label.address.address_line1_placeholder")}
                        value={formik?.values?.address?.line1}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.line1") })}
                        onChange={(e) => {
                            formik.setFieldValue("address.line1", e.target.value);
                        }}
                    />
                </div>

                <div className="col-12 lg:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.address_line2")}</label>
                    <InputText
                        id="address.line2"
                        name="address.line2"
                        placeholder={t("label.address.address_line2_placeholder")}
                        value={formik?.values?.address?.line2}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.line2") })}
                        onChange={(e) => {
                            formik.setFieldValue("address.line2", e.target.value);
                        }}
                    />
                </div>

                <div className="col-12 md:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.city")}</label>
                    <InputText
                        id="address.city"
                        name="address.city"
                        placeholder={t("label.address.city_placeholder")}
                        value={formik?.values?.address?.city}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.city") })}
                        onChange={(e) => {
                            formik.setFieldValue("address.city", e.target.value);
                        }}
                    />
                </div>

                <div className="col-12 md:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.state")}</label>
                    <InputText
                        id="address.state"
                        name="address.state"
                        placeholder={t("label.address.state_placeholder")}
                        value={formik?.values?.address?.state}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.state") })}
                        onChange={(e) => {
                            formik.setFieldValue("address.state", e.target.value);
                        }}
                    />
                    {/* <Dropdown
                        dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                        value={State.getStateByCodeAndCountry(formik?.values?.address?.state, formik.values?.address?.country)}
                        onChange={(e) => formik.setFieldValue("address.state", e.value.isoCode)}
                        optionLabel="name"
                        filter
                        disabled={!formik.values?.address?.country}
                        placeholder={t("label.address.state_placeholder")}
                        options={formik.values?.address?.country ? State.getStatesOfCountry(formik.values?.address?.country) : null}
                        className="w-full"
                        emptyMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                        emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                    /> */}
                </div>

                <div className="col-12 md:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.postal_code")}</label>
                    <InputText
                        keyfilter="int"
                        id="address.postal_code"
                        name="address.postal_code"
                        placeholder={t("label.address.postal_code_placeholder")}
                        value={formik?.values?.address?.postal_code}
                        inputClassName="w-full"
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.postal_code") })}
                        onChange={(e) => {
                            formik.setFieldValue("address.postal_code", e.target.value);
                        }}
                    />
                </div>

                <div className="col-12 md:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.phone_number")}</label>
                    <InputText
                        keyfilter="int"
                        id="phone_number"
                        name="phone_number"
                        placeholder={t("label.phone_number")}
                        value={formik?.values?.phone}
                        inputClassName="w-full"
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("phone") })}
                        onChange={(e) => {
                            formik.setFieldValue("phone", e.target.value);
                        }}
                    />
                </div>
            </div>
        );
    };

    const onTypeChange = (newType) => {
        formik.resetForm();
        formik.setFieldValue("type", newType);
    };

    return checkComponentPermissions(
        <div className="grid page-layout">
            {/* <div className="xl:col-offset-1"></div> */}
            <div className="col-12 xl:col-12">
                <div className="grid">
                    <div className="col-12 p-0">
                        <div className="flex  justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <div className="grid p-0">
                                    <div className="col-12 p-0">
                                        <BreadCrumb
                                            home={{
                                                icon: <label className="mousee-text font-x-small font-weight-regular cursor-pointer">{capitalizeWord(t("label.clients"))}</label>,
                                                command: () => {
                                                    navigate("/clients");
                                                },
                                            }}
                                            separatorIcon={<div className="pl-2 pr-2">/</div>}
                                            model={[{ label: t("label.create_a_new_client"), disabled: true }]}
                                        />
                                    </div>

                                    <div className="col-12 px-0">
                                        <label className="mousee-text font-large font-weight-regular">{t("label.create_a_new_client")}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <Button label={t("label.save_changes")} loading={loading} disabled={objectHasKeys(formik.errors)} type="button" className="p-button" onClick={formik.handleSubmit} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pt-5">
                        <div className="grid">
                            <div className="col-12 xl:col-5">
                                <div className="grid card xl:mr-1">
                                    <div className="col-12">
                                        <AvatarUpload
                                            inputFileRef={fileUploadRef}
                                            file={currentFile ? currentFile : formik.values.picture}
                                            setFile={(newValue) => {
                                                setCurrentFile(newValue);
                                                formik.setTouched({}, false);
                                            }}
                                            classnames={classNames({ "p-invalid": isFormFieldValid("image") })}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <div>
                                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.client_type")}*</label>
                                        </div>
                                        <div className="flex justify-content-left pt-3">
                                            <div className="flex flex-column gap-3">
                                                {CLIENT_TYPES.map((clientType) => {
                                                    return (
                                                        <div key={clientType?.type} className="flex align-items-center">
                                                            <RadioButton inputId={clientType?.type} name="type" value={clientType?.type} onChange={(e) => onTypeChange(e?.value)} checked={formik?.values?.type === clientType?.type} />
                                                            <label htmlFor={clientType?.type} className="ml-2">
                                                                {t(clientType?.label)}
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div>
                                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.notes")}</label>
                                        </div>
                                        <div className="pt-1">
                                            <Editor
                                                value={formik.values.notes}
                                                className="min-w-full border-transparent mousee-text text-x-small font-weight-regular"
                                                headerTemplate={
                                                    <span className="ql-formats">
                                                        <button className="ql-bold" aria-label="Bold"></button>
                                                        <button className="ql-italic" aria-label="Italic"></button>
                                                        <button className="ql-underline" aria-label="Underline"></button>

                                                        <button className="ql-list" value={"ordered"} aria-label="List"></button>
                                                        <button className="ql-list" value={"bullet"} aria-label="List"></button>

                                                        <button className="ql-align"></button>
                                                    </span>
                                                }
                                                onTextChange={(e) => {
                                                    formik.setFieldValue("notes", e.htmlValue);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 xl:col-7">{clientData(formik?.values?.type)}</div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="xl:col-offset-1"></div> */}
        </div>,
        ["add_clients"],
        null,
        null
    );
};
export default AddClient;
