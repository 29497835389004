import isEqual from "lodash.isequal";
import moment from "moment";
import { v4 as uuid } from "uuid";

export const isDesktop = () => {
    return window.innerWidth >= 1024;
};

export const formatDate = (timestamp, withHoursMinutes, lang) => {
    if (withHoursMinutes) {
        return new Intl.DateTimeFormat(lang?.split("-")[0], { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" }).format(timestamp);
    }

    return new Intl.DateTimeFormat(lang?.split("-")[0], { year: "numeric", month: "2-digit", day: "2-digit" }).format(timestamp);
};

export const formatDateV2 = (dateStr, withHoursMinutes, lang) => {
    lang = lang ? lang : navigator.language;
    if (dateStr) {
        const timestamp = new Date(dateStr).getTime();
        if (withHoursMinutes) {
            return new Intl.DateTimeFormat(lang?.split("-")[0], { year: "numeric", month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" }).format(timestamp);
        }

        return new Intl.DateTimeFormat(lang?.split("-")[0], { year: "numeric", month: "short", day: "2-digit" }).format(timestamp);
    } else {
        return "N/A";
    }
};

export const formatDateV2FromTimestamp = (timestamp, withHoursMinutes, onlyCurrentYear, lang) => {
    lang = lang ? lang : navigator.language;
    if (timestamp) {
        if (withHoursMinutes) {
            if (onlyCurrentYear) {
                const date = new Date(timestamp);
                if (date.getFullYear() === new Date().getFullYear()) {
                    return new Intl.DateTimeFormat(lang?.split("-")[0], { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" }).format(timestamp);
                } else {
                    return new Intl.DateTimeFormat(lang?.split("-")[0], { year: "numeric", month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" }).format(timestamp);
                }
            } else {
                return new Intl.DateTimeFormat(lang?.split("-")[0], { year: "numeric", month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" }).format(timestamp);
            }
        } else {
            if (onlyCurrentYear) {
                const date = new Date(timestamp);
                if (date.getFullYear() === new Date().getFullYear()) {
                    return new Intl.DateTimeFormat(lang?.split("-")[0], { month: "short", day: "2-digit" }).format(timestamp);
                } else {
                    return new Intl.DateTimeFormat(lang?.split("-")[0], { year: "numeric", month: "short", day: "2-digit" }).format(timestamp);
                }
            } else {
                return new Intl.DateTimeFormat(lang?.split("-")[0], { year: "numeric", month: "short", day: "2-digit" }).format(timestamp);
            }
        }
    } else {
        return "N/A";
    }
};

export const getWeekFirstLastDay = (dateStr, lang) => {
    lang = lang ? lang : navigator.language;
    if (typeof dateStr === "string" || dateStr instanceof String) {
        var year = dateStr.split("-")[0];
        var week = dateStr.split("-")[1];
        var d = new Date("Jan 01, " + year + " 01:00:00");
        var w = d.getTime() + 604800000 * (week - 1);
        var n1 = new Date(w);
        var n2 = new Date(w + 518400000);

        const firstDay = n1.getDate();
        const firstDayMonthName = n1.toLocaleString(lang, { month: "short" });

        const secondDay = n2.getDate();
        const secondDayMonthName = n2.toLocaleString(lang, { month: "short" });

        if (firstDayMonthName === secondDayMonthName) {
            return `${firstDayMonthName} (${firstDay}-${secondDay})`;
        } else {
            return `${firstDayMonthName} ${firstDay} - ${secondDayMonthName} ${secondDay}`;
        }
    }
};

export const getMonthsBetweenTwoDates = (startDate, endDate) => {
    if (typeof startDate === "string" || startDate instanceof String) {
        var start = startDate.split("-");
        var end = endDate.split("-");
        var startYear = parseInt(start[0]);
        var endYear = parseInt(end[0]);
        var dates = [];

        for (var i = startYear; i <= endYear; i++) {
            var endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
            var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
            for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
                var month = j + 1;
                var displayMonth = month < 10 ? "0" + month : month;
                dates.push([i, displayMonth, "01"].join("-"));
            }
        }
        return dates;
    }
};

export const parseDateToMonthDay = (newDate, lang) => {
    lang = lang ? lang : navigator.language;
    return `${capitalizeWord(newDate.toLocaleString(lang, { month: "short" }))} ${newDate.toLocaleString(lang, { day: "2-digit" })}`;
};

export const datesAreOnSameDay = (first, second) => {
    return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();
};

export const parseDateToMonthName = (newDate, lang) => {
    return capitalizeWord(newDate.toLocaleString(lang, { month: "long" }));
};

export const parseDateToYearName = (newDate, lang) => {
    return newDate.toLocaleString(lang, { year: "numeric" });
};

export const getWeekFirstLastDayObject = (dateObject) => {
    // ✅ Get the first day of the current week (Sunday)
    const firstDay = new Date(dateObject.setDate(dateObject.getDate() - dateObject.getDay()));

    // ✅ Get the last day of the current week (Saturday)
    const lastDay = new Date(dateObject.setDate(dateObject.getDate() - dateObject.getDay() + 6));

    return [firstDay, lastDay];
};

export const getMonthName = (monthDateStr, lang) => {
    const year = parseInt(monthDateStr.split("-")[0]);
    const month = parseInt(monthDateStr.split("-")[1]);

    const currentDate = new Date(year, month, 1, 0, 0, 0, 0);

    const monthName = currentDate.toLocaleString(lang, { month: "long" });

    // const monthNameCapitalized = monthName.charAt(0).toUpperCase() + monthName.slice(1)
    const monthNameCapitalized = capitalizeWord(monthName);

    return monthNameCapitalized;
};

export const capitalizeWord = (str) => {
    if (str) {
        let strCapitalized = str.charAt(0).toUpperCase();
        if (strCapitalized.length >= 1) {
            strCapitalized = strCapitalized + str.slice(1);
        }

        return strCapitalized;
    }

    return undefined;
};

export const getQuarterName = (quarterDateStr) => {
    const year = parseInt(quarterDateStr.split("-")[0]);
    const quarter = parseInt(quarterDateStr.split("-")[1]);

    return `${year} - T${quarter}`;
};

export const formatMinutesToMMHH = (minutes) => {
    const mm = minutes % 60;
    const hh = (minutes - mm) / 60;

    return (hh < 10 ? "0" : "") + hh.toString() + ":" + (mm < 10 ? "0" : "") + mm.toString();
};

export const getDatesBetweenTwoDates = (startDate, stopDate) => {
    var dateArray = [];
    var currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(new Date(currentDate));
        currentDate = addDaysToDate(currentDate, 1);
    }
    return dateArray;
};

export const getWeekDayDatesBetweenTwoDates = (startDate, stopDate) => {
    var dateArray = [];
    var currentDate = startDate;
    while (currentDate <= stopDate) {
        if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) dateArray.push(new Date(currentDate));
        currentDate = addDaysToDate(currentDate, 1);
    }
    return dateArray;
};

export const addDaysToDate = (date, days) => {
    date.setDate(date.getDate() + days);
    return date;
};

export const getDaysInMonth = (month, year) => {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }
    return days;
};

export const getWeekDaysInMonth = (month, year) => {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
        if (date.getDay() !== 0 && date.getDay() !== 6) {
            days.push(new Date(date));
        }
        date.setDate(date.getDate() + 1);
    }
    return days;
};

export const getSplittedDateMap = (dates, type) => {
    let dayTypeMap = {};
    let typeList = [];

    if (dates && type) {
        const list = dates.reduce((acc, date) => {
            // create a composed key: 'year-month'
            let yearType = "";
            if (type === "weeks") {
                yearType = `${moment(date).year()}-${moment(date).week()}`;
            } else if (type === "months") {
                yearType = `${moment(date).year()}-${moment(date).month()}`;
            } else if (type === "years") {
                yearType = `${moment(date).year()}`;
            } else if (type === "quarters") {
                yearType = `${moment(date).year()}-${moment(date).quarter()}`;
            }
    
            // add this key as a property to the result object
            if (!acc[yearType]) {
                acc[yearType] = [];
            }
    
            // push the current date that belongs to the year-month calculated befor
            acc[yearType].push(date);
    
            return acc;
        }, {});
    
        
    
        for (const key in list) {
            list[key].forEach((date) => {
                dayTypeMap[date] = key;
            });
            typeList.push(key);
        }
    }
    
    return { dayTypeMap, typeList };
    
};

export const FormatCurrency = (value, fraction, currency, lang) => {
    lang = lang ? lang : navigator.language;
    value = value ? value : 0;
    if (lang) {
        if (!fraction) {
            if (currency) {
                return value.toLocaleString(lang, { style: "currency", currency: currency, maximumFractionDigits: "0" });
            }
            return value.toLocaleString(lang, { style: "currency", currency: "EUR", maximumFractionDigits: "0" });
        } else {
            if (currency) {
                return value.toLocaleString(lang, { style: "currency", currency: currency });
            }
            return value.toLocaleString(lang, { style: "currency", currency: "EUR" });
        }
    }

    return undefined;
};

export const parseTime = (time) => {
    const hoursString = `${time.hours}`.padStart(2, "0");
    const minutesString = time.minutes > 59 ? "00" : `${time.minutes}`.padStart(2, "0");

    return `${hoursString}:${minutesString}`;
};

export const parseTimeV2 = (time) => {
    const hoursString = time?.hours ? time.hours : "0";
    const minutesString = time?.minutes ? time.minutes : "0";

    if (time?.minutes > 0) {
        if (time?.hours > 0) {
            return `${hoursString}h ${minutesString}m`;
        } else {
            return `${minutesString}m`;
        }
    } else {
        return `${hoursString}h`;
    }
};

export const toHoursAndMinutes = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return { hours, minutes };
};

// export const setUserPermissions = (permissions, userType) => {
//     if (permissions || userType) {
//         let permissionsList = [];
//         if (permissions) {
//             permissionsList = permissions.split(",");
//         }
//         setAuthData(permissionsList, userType)
//     }
// };

export const setUserData = (userData) => {
    if (userData) {
        localStorage.setItem("__cognitoUser", JSON.stringify({ ...userData }));
    }
};
export const getUserData = () => {
    const userData = localStorage.getItem("__cognitoUser");
    if (userData) {
        return JSON.parse(userData);
    }

    return undefined;
};

export const setAuthData = (payload) => {
    localStorage.setItem("__authUser", JSON.stringify(payload));
};

export const getAuthData = () => {
    const authData = localStorage.getItem("__authUser");
    if (authData) {
        return JSON.parse(authData);
    }

    return undefined;
};

export const toMinutes = (hoursMinutesObject) => {
    let totalMinutes = 0;

    if (hoursMinutesObject) {
        if (hoursMinutesObject.hours) {
            totalMinutes += hoursMinutesObject.hours * 60;
        }
        if (hoursMinutesObject.minutes) {
            totalMinutes += hoursMinutesObject.minutes;
        }
    }

    return totalMinutes;
};

export const getPercentage = (total, currentValue) => {
    return (currentValue * 100) / total;
};

export const extractContentFromHtml = (s) => {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
};

export const tableHeaderTemplate = (icon, label) => {
    return (
        <div className="flex flex-row flex-wrap">
            {icon ? (
                <div className="flex align-items-center justify-content-center">
                    <img alt={"table-header-icon"} src={icon} className="mr-3" style={{ width: "1.5rem" }}></img>
                </div>
            ) : null}
            <div className="flex align-items-center justify-content-center">
                <label className="mousee-text-small font-x-small font-weight-semibold">{label}</label>
            </div>
        </div>
    );
};

export const iconPlusLabel = (icon, label) => {
    return (
        <div className="flex flex-row flex-wrap">
            {icon ? (
                <div className="flex align-items-center justify-content-center">
                    <img alt={"table-header-icon"} src={icon} className="mr-3" style={{ width: "1.5rem" }}></img>
                </div>
            ) : null}
            <div className="flex align-items-center justify-content-center">
                <label className="mousee-text font-x-small font-weight-regular">{label}</label>
            </div>
        </div>
    );
};

export const getTasksTree = (tasks) => {
    let tree = [];

    let tasksMap = {};

    for (let i = 0; i < tasks.length; i++) {
        tasksMap[tasks[i].id] = {
            ...tasks[i],
            origin_index: i,
        };
    }

    for (const k in tasksMap) {
        if (tasksMap[k].depends_on) {
            if (tasksMap[tasksMap[k]["depends_on"]] && tasksMap[tasksMap[k]["depends_on"]].children) {
                tasksMap[tasksMap[k]["depends_on"]]["children"].push(tasksMap[k]);
            } else {
                if (tasksMap[tasksMap[k]["depends_on"]]) {
                    tasksMap[tasksMap[k]["depends_on"]]["children"] = [tasksMap[k]];
                } else {
                    tree.push(tasksMap[k]);
                }
            }
        } else {
            tree.push(tasksMap[k]);
        }
    }

    return tree;
};

export const parseMonthAndDay = (timestamp, lang) => {
    return new Intl.DateTimeFormat(lang?.split("-")[0], { month: "long", day: "2-digit" }).format(timestamp);
};

export const getOrderedTasks = (tasks, paddingLeft) => {
    let newTasks = [];

    for (let i = 0; i < tasks.length; i++) {
        const task = tasks[i];

        if (task) {
            newTasks.push(getTaskObject(task, paddingLeft, i));

            const children = task.children;
            delete task["children"];
            if (children && children.length > 0) {
                const newPaddingLeft = paddingLeft + 2;
                newTasks = newTasks.concat([...getOrderedTasks(children, newPaddingLeft, "complex")]);
            }
        }
    }

    return newTasks;
};

export const getTaskObject = (task, paddingLeft, index) => {
    return {
        ...task,
        paddingLeft: paddingLeft,
    };
};

export const filterTasks = (tasks, currentTaskId) => {
    // Filter principal tasks

    let newTasks = [...tasks];

    // Getting tasks tree
    const tasksTree = getTasksTree(tasks);

    let currentTaskWithChildren = undefined;

    for (let i = 0; i < tasksTree.length && !currentTaskWithChildren; i++) {
        const task = tasksTree[i];

        if (task.id === currentTaskId) {
            currentTaskWithChildren = task;
        }
    }

    if (currentTaskWithChildren) {
        const notAllowedTaskIds = getAllTaskChildren(currentTaskWithChildren);

        newTasks = newTasks.filter((task) => !notAllowedTaskIds.includes(task.id) && task.id !== currentTaskId);
    }

    newTasks = newTasks.filter((task) => task.id !== currentTaskId);

    return newTasks;
};

export const getAllTaskChildren = (task) => {
    let newTasks = [];

    const operation = (tasks) => {
        let operationTasks = [];
        for (let index = 0; index < tasks.length; index++) {
            const task = tasks[index];

            const children = task.children;

            if (children) {
                delete task["children"];
                operationTasks.push(task);

                const taskChildren = operation(children);
                if (taskChildren) {
                    operationTasks = operationTasks.concat(taskChildren);
                }
            } else {
                operationTasks.push(task);
            }
        }

        return operationTasks;
    };

    if (task.children) {
        newTasks = operation(task.children);
        newTasks = newTasks.map((task) => task.id);
    }

    return newTasks;
};

export const getUpdateRemoveParams = (originalValues, newValues) => {
    let updateParams = {};
    let removeParams = [];

    if (newValues) {
        for (const k in newValues) {
            if (k in newValues) {
                if (!isEqual(newValues[k], originalValues[k])) {
                    updateParams[k] = newValues[k];
                }
            } else {
                removeParams.push(k);
            }
        }
    }

    return { update_params: updateParams, remove_params: removeParams };
};

export const delay = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
};

export const getFirstLastDayOfTheMonth = (newDate) => {
    var date = newDate,
        y = date.getFullYear(),
        m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);

    return { firstDay, lastDay };
};

export const getDayName = (newDate, lang) => {
    return capitalizeWord(newDate.toLocaleDateString(lang?.split("-")[0], { weekday: "long" }));
};

export const blockBackgroundScroll = (active) => {
    document.body.style.overflow = active ? "hidden" : "unset";
};

export const convertToBase64 = (file) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        };
    });
};

export const calculateTime = (newTime, quantity, operation, type) => {
    let time = newTime;

    if (type === "manual") {
        quantity = quantity.split(":");
        time = {
            hours: parseInt(quantity[0]),
            minutes: parseInt(quantity[1]) > 59 ? 0 : parseInt(quantity[1]),
        };
    } else {
        if (operation === "add") {
            if (time.minutes + 15 >= 60) {
                const minutesToAnHour = 60 - time.minutes;
                const quantityToAdd = 15 - minutesToAnHour;

                time = {
                    hours: time.hours + 1,
                    minutes: quantityToAdd,
                };
            } else {
                time = {
                    ...time,
                    minutes: time.minutes + 15,
                };
            }
        } else {
            if (time.minutes - 15 >= 0) {
                time = {
                    ...time,
                    minutes: time.minutes - quantity,
                };
            } else {
                let auxMinutes = time.minutes - 15;

                time = {
                    hours: time.hours === 0 ? 0 : time.hours - 1,
                    minutes: time.hours === 0 ? 0 : 60 - Math.abs(auxMinutes),
                };
            }
        }
    }

    return time;
};

export const objectHasKeys = (object) => {
    if (object) {
        return Object.keys(object).length > 0;
    } else {
        return false;
    }
};

export const substractTwoHHMM = (hhmm_1, hhmm_2) => {
    let result = 0;

    if (hhmm_1 !== undefined && hhmm_2 !== undefined) {
        result = toMinutes(hhmm_1) - toMinutes(hhmm_2);
    }

    return result;
};

export const getGreetingMessage = (t) => {
    let timeNow = new Date().getHours();
    let greeting = timeNow >= 5 && timeNow < 12 ? t("message.good_morning") : timeNow >= 12 && timeNow < 18 ? t("message.good_afternoon") : t("message.good_evening");
    return greeting;
};

export const getTenantCommercialNameAcronym = (commercialName) => {
    if (commercialName) {
        const commercialNameSplitted = commercialName?.split(" ");
        if (commercialNameSplitted && commercialNameSplitted?.length > 1) {
            return `${capitalizeWord(commercialNameSplitted[0]?.split("")[0])}${capitalizeWord(commercialNameSplitted[1]?.split("")[0])}`;
        } else {
            return capitalizeWord(commercialNameSplitted[0]?.split("")[0]);
        }
    }

    return undefined;
};

export const listContainsStringWith = (list, str) => {
    let flag = false;

    if (list && str) {
        for (let i = 0; i < list.length && !flag; i++) {
            flag = str.startsWith(list[i]);
        }
    }

    return flag;
};

export const getFileExtensionIcon = (filename) => {
    const extension = filename.split(".").pop();

    let icon = "";

    if (extension === "xlsx" || extension === "xlsm" || extension === "xlsb" || extension === "xltx" || extension === "xltm" || extension === "xls" || extension === "xlt" || extension === "xlam" || extension === "xla" || extension === "xlw" || extension === "xlr") {
        icon = "file-icons:microsoft-excel";
    } else if (extension === "txt") {
        icon = "bxs:file-txt";
    } else if (extension === "mmp") {
        icon = "file-icons:microsoft-project";
    } else if (extension === "msg") {
        icon = "dashicons:email-alt2";
    } else if (extension === "ppt" || extension === "pptx") {
        icon = "file-icons:microsoft-powerpoint";
    } else if (extension === "one") {
        icon = "file-icons:microsoft-onenote";
    } else if (extension === "jpg" || extension === "png" || extension === "jpeg") {
        icon = "bxs:image";
    } else if (extension === "pdf") {
        icon = "bxs:file-pdf";
    } else if (extension === "zip" || extension === "7z") {
        icon = "teenyicons:zip-solid";
    } else if (extension === "csv") {
        icon = "eos-icons:csv-file";
    } /*  else if (extension === "") {
        icon = ""
    } else if (extension === "") {
        icon = ""
    } else if (extension === "") {
        icon = ""
    } else if (extension === "") {
        icon = ""
    }*/

    return icon;
};

export const getDescendantProp = (obj, desc) => {
    var arr = desc.split(".");
    while (arr.length && (obj = obj[arr.shift()]));
    return obj;
};

export const updateObjectProperty = (obj, key, value) => {
    const path = key?.split(".");
    while (path.length > 1) {
        obj = obj[path.shift()];
    }
    obj[path.shift()] = value;
};

export const getTenantLogoUrl = (tenantId, filename) => {
    var fileExt = filename.split(".").pop();
    const unique_id = uuid();

    if (tenantId && filename) {
        const finalPictureName = `${unique_id}.${fileExt}`;

        const finalPictureUrl = `/private/${tenantId}/${finalPictureName}`;

        return finalPictureUrl;
    }

    return;
};

export const getTenantAvatarUrl = (tenantId, filename) => {
    var fileExt = filename.split(".").pop();
    const unique_id = uuid();

    if (tenantId && filename) {
        const finalPictureName = `${unique_id}.${fileExt}`;

        const finalPictureUrl = `/private/${tenantId}/${finalPictureName}`;

        return finalPictureUrl;
    }

    return;
};

export const removeItemAll = (arr, value) => {
    var i = 0;
    while (i < arr?.length) {
        if (arr[i] === value) {
            arr?.splice(i, 1);
        } else {
            ++i;
        }
    }
    return arr;
};
