// import { Icon } from "@iconify/react";
// import { useFormik } from "formik";
// import { Button } from "primereact/button";
// import { Calendar } from "primereact/calendar";
// import { Dialog } from "primereact/dialog";
// import { classNames } from "primereact/utils";
// import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
// import { getUpdateRemoveParams } from "../../../../Utils";
// import TaskService from "../../../../services/TaskService/TaskService";

import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const EditServiceDeliveryDateDialog = ({ show, setShow, service, updateProcessField}) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            delivery_date: service?.delivery_date,
        },
        validate: (data) => {
            let errors = {};

            if (!data.delivery_date) {
                errors.delivery_date = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            updateProcessField("delivery_date", data?.delivery_date)

            setShow(false)

            // let updateParams = getUpdateRemoveParams(task, data);

            // taskService
            //     .updateTask(updateParams, task?.client_id, task?.project_id, task?.process_id, task?.id)
            //     .then((data) => {
            //         setOriginalTask(JSON.parse(JSON.stringify(data)));
            //         setActiveTask(data)
            //         updateTasks(data)
            //         setLoading(false);
            //         setShow(false)
            //     })
            //     .catch((error) => {
            //         console.log(error);

            //         setLoading(false);
            //     });
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <Dialog
            visible={show}
            onHide={() => setShow(false)}
            draggable={false}
            className="custom-dialog"
            closable={false}
            dismissableMask
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "24px 24px 0px" }}>
                        <label className="mousee-text font-medium font-weight-bold">{t("label.edit_dates")}</label>
                    </div>
                </div>
            }
            footer={
                <div>
                    <Button className="p-button-outlined p-button-plain" label={t("label.cancel")} onClick={() => setShow(false)} />
                    <Button className="p-button" loading={loading} type="submit" label={t("label.save_changes")} autoFocus onClick={formik.handleSubmit} />
                </div>
            }
        >
            <div className="grid pt-3">
                <div className="col-12">
                    <div className="flex-auto min-w-full">
                        <label className="pl-2 block mousee-text font-x-small font-weight-semibold">{`${t("label.delivery_date")}*`}</label>
                        <Calendar
                            icon={<Icon icon="solar:calendar-bold-duotone" style={{ fontSize: "20px" }} />}
                            iconPos="left"
                            disabledDays={[0, 6]}
                            hourFormat="24"
                            showIcon
                            readOnlyInput
                            value={formik.values.delivery_date ? new Date(formik.values.delivery_date) : null}
                            className={classNames("min-w-full", { "p-invalid": formik.errors.delivery_date })}
                            onChange={(e) => formik.setFieldValue("delivery_date", e.value ? e?.value?.toISOString() : null)}
                            locale={i18n.language.split("-")[0]}
                        />
                        {getFormErrorMessage("delivery_date")}
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
export default EditServiceDeliveryDateDialog;
