
export const getTaskProgress = (task) => {
    // let totalEstimateMinutes = 0;
    // let totalIncurredMinutes = 0;

    let result = 0;

    if (task && task.status) {
        if (task.status !== "done") {
            if (task.checklist) {
                result = calculateCheckListProgress(task.checklist)
            }
        } else {
            result = 100;
        }
    }

    return result;


    // if (task.participants) {
    //     for (let i = 0; i < task.participants.length; i++) {
    //         const participant = task.participants[i];
            
    //         totalEstimateMinutes += toMinutes(participant.estimate_time)

    //         totalIncurredMinutes += toMinutes(getParticipantIncurredTime(participant))
    //     }
    // }

    // let percentage = 0;

    // if (totalIncurredMinutes > 0 && totalEstimateMinutes > 0) {
    //     percentage = totalIncurredMinutes ? parseInt(((totalIncurredMinutes * 100)/totalEstimateMinutes).toFixed(0)) : 0
    // }

    // return percentage
}

export const getTaskProgressLabel = (task) => {
    const percentage = getTaskProgress(task)
                        
    const color = percentage < 1 ? "var(--gray-500)" : percentage <= 100 ? "var(--success-color)" : "var(--danger-color)"

    return <label className='mousee-text font-small font-weight-semibold' style={{color: color}}>{`${percentage}%`}</label>
}

export const getTaskProgressLabelV2 = (progress) => {
                    
    // const color = progress === 0 ? "var(--gray-500)" : progress <= 100 ? "var(--success-color)" : "var(--danger-color)"

    return <label className='mousee-text font-x-small font-weight-semibold' /*style={{color: color}}*/>{`${progress}%`}</label>
}

export const getProgressLabel = (percentage) => {
    percentage = parseInt(percentage)
    // const color = percentage < 1 ? "var(--gray-500)" : percentage <= 100 ? "var(--success-color)" : "var(--danger-color)"

    return <label className='mousee-text font-x-small font-weight-semibold' /*style={{color: color}}*/>{`${percentage}%`}</label>
}

export const getProgressColor = (percentage) => {
    const color = percentage < 1 ? "var(--gray-500)" : percentage <= 100 ? "var(--success-color)" : "var(--danger-color)"

    return color;
}

export const handleProjectsOnScroll = (e, projectService, queryParams, queryProjectsResponse, setQueryProjectsResponse, projects, setProjects, projectFilterName, setProjectFilterName) => {
    const bottom = e?.target?.scrollHeight - e?.target?.clientHeight <= e?.target?.scrollTop + 50;
    if (bottom) {

        const retrieveProjects = (lastKey) => {
            lastKey = lastKey && Object.keys(lastKey).length > 0 ? lastKey : undefined
            if (lastKey)
                delete lastKey["name"]
            projectService.queryProjects(queryParams, lastKey).then(data => {
                
                if(data) {
                    setQueryProjectsResponse(data)
                
                    if (data.data && data.data.length > 0) {
                        var ids = new Set(projects.map(d => d.id));

                        var mergedProjects = [...projects, ...data.data.filter(d => !ids.has(d.id))];
                        setProjects(mergedProjects)
                        // const newProjects = [...projects, ...data.data]

                        // setProjects(newProjects)   
                    }
                }
            })
        }

        let lastKey = queryProjectsResponse?.last_key
        if (!lastKey || (lastKey && Object.keys(lastKey).length === 0)) {
            // lastKey = projects && projects.length > 0 ? projects[projects.length - 1].id : ""

            if (projects && projects.length > 0) {
                const lastProject = projects[projects.length - 1]
                lastKey = {
                    id: lastProject.id
                }

                if (projectFilterName !== undefined) {
                    if (projectFilterName && projectFilterName.length > 0) {
                        lastKey = {
                            ...lastKey,
                            name: lastProject.name
                        }
                    }
                    retrieveProjects(lastKey)
                    if (projectFilterName === "") {
                        setProjectFilterName(undefined)
                    }
                }
            }

            
        } else {
            retrieveProjects(lastKey)
        }
    }
}

export const handleServicesOnScroll = (e, serviceService, queryParams, queryServicesResponse, setQueryServicesResponse, services, setServices, serviceFilterName, setServiceFilterName) => {
    const bottom = e?.target?.scrollHeight - e?.target?.clientHeight <= e?.target?.scrollTop + 50;
    if (bottom) {
        const retrieveServices = (lastKey) => {
            lastKey = lastKey && Object.keys(lastKey).length > 0 ? lastKey : undefined
            if (lastKey) {
                delete lastKey["name"]
            }

            serviceService.queryProjects(queryParams, lastKey).then(data => {
                
                if(data) {
                    setQueryServicesResponse(data)
                
                    if (data.data && data.data.length > 0) {
                        var ids = new Set(services.map(d => d.id));

                        var mergedServices = [...services, ...data.data.filter(d => !ids.has(d.id))];
                        setServices(mergedServices)
                    }
                }
            })
        }

        let lastKey = queryServicesResponse?.last_key
        if (!lastKey || (lastKey && Object.keys(lastKey).length === 0)) {
            // lastKey = projects && projects.length > 0 ? projects[projects.length - 1].id : ""

            if (services && services.length > 0) {
                const lastService = services[services.length - 1]
                lastKey = {
                    id: lastService.id
                }

                if (serviceFilterName !== undefined) {
                    if (serviceFilterName && serviceFilterName.length > 0) {
                        lastKey = {
                            ...lastKey,
                            name: lastService.name
                        }
                    }
                    retrieveServices(lastKey)
                    if (serviceFilterName === "") {
                        setServiceFilterName(undefined)
                    }
                }
            }

            
        } else {
            retrieveServices(lastKey)
        }
    }
}

export const filterProjects = (event, options, setProjectFilterName, queryProjectsResponse, setQueryProjectsResponse, projectService, projectsRowsNumber, projects, setProjects) => {    
    let _filterValue = event.target.value;
    setProjectFilterName(_filterValue)

    let lastKey = undefined

    if (queryProjectsResponse && queryProjectsResponse.last_key && Object.keys(queryProjectsResponse.last_key).length > 0) {
        if (_filterValue.trim() === "" || _filterValue.length === 0) {
            if (lastKey)
                delete lastKey["name"]
        }else {
            if (lastKey && !lastKey.name) {
                if (projects && projects.length > 0) {
                    lastKey = {
                        id: projects[projects.length-1].id,
                        name: projects[projects.length-1].name
                    }
                } else {
                    lastKey = {
                        ...lastKey,
                        name: ""
                    }
                }
            }
        }
    }

    projectService.queryProjects({name: _filterValue, limit: projectsRowsNumber},  lastKey).then(data => {
        setQueryProjectsResponse(data)
        
        if (data && data.data && data.data.length > 0) {
            var ids = new Set(projects.map(d => d.id));

            var mergedProjects = [...projects, ...data.data.filter(d => !ids.has(d.id))];
            setProjects(mergedProjects)
        }
        options?.filterOptions?.filter(event);
    })
}

export const filterServices = (event, options, setServiceFilterName, queryServicesResponse, setQueryServicesResponse, serviceService, servicesRowsNumber, services, setServices) => {    
    let _filterValue = event.target.value;
    setServiceFilterName(_filterValue)

    let lastKey = undefined

    if (queryServicesResponse && queryServicesResponse.last_key && Object.keys(queryServicesResponse.last_key).length > 0) {
        if (_filterValue.trim() === "" || _filterValue.length === 0) {
            if (lastKey)
                delete lastKey["name"]
        }else {
            if (lastKey && !lastKey.name) {
                if (services && services.length > 0) {
                    lastKey = {
                        id: services[services.length-1].id,
                        name: services[services.length-1].name
                    }
                } else {
                    lastKey = {
                        ...lastKey,
                        name: ""
                    }
                }
            }
        }
    }

    serviceService.queryServices({name: _filterValue, limit: servicesRowsNumber},  lastKey).then(data => {
        setQueryServicesResponse(data)
        
        if (data && data.data && data.data.length > 0) {
            var ids = new Set(services.map(d => d.id));

            var mergedServices = [...services, ...data.data.filter(d => !ids.has(d.id))];
            setServices(mergedServices)
        }
        options?.filterOptions?.filter(event);
    })
}

export const handleParticipantsOnScroll = (e, participantService, params, queryParticipantsResponse, setQueryParticipantsResponse, participants, setParticipants) => {
    const bottom = e?.target?.scrollHeight - e?.target?.clientHeight <= e?.target?.scrollTop + 50;
    if (bottom) {

        const retrieveParticipants = (lastKey) => {
            lastKey = lastKey && Object.keys(lastKey).length > 0 ? lastKey : undefined
            
            participantService.queryParticipants(params, lastKey).then(data => {
                
                if(data) {
                    setQueryParticipantsResponse(data)
                
                    if (data.data && data.data.length > 0) {
                        var ids = new Set(participants.map(d => d.id));

                        var mergedParticipants = [...participants, ...data.data.filter(d => !ids.has(d.id))];
                        setParticipants(mergedParticipants)
                    }
                }
            })
        }

        let lastKey = queryParticipantsResponse?.last_key
        if (!lastKey || (lastKey && Object.keys(lastKey).length === 0)) {
            
            
            if (participants && participants.length > 0) {
                const lastParticipant = participants[participants.length - 1]
                lastKey = {
                    id: lastParticipant.id
                }
            }

            
        } else {
            retrieveParticipants(lastKey)
        }
    }
}

export const calculateCheckListProgress = (checklist) => {
    let progress = 0;
    if (checklist) {
        if (checklist.options  && checklist.options.length > 0) {
            const total = checklist.options.length;
            const checked = checklist.checked && checklist.checked.length > 0 ? checklist.checked.length : 0

            progress = (checked * 100) / total
        }
    }



    return progress
}