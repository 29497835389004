import { useAuthenticator } from "@aws-amplify/ui-react";
import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Divider } from "primereact/divider";
import { Editor } from "primereact/editor";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { Sidebar } from "primereact/sidebar";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { blockBackgroundScroll, formatDateV2, getAuthData, getTenantCommercialNameAcronym, isDesktop } from "../../../Utils";
import { checkComponentPermissions, checkPermissions } from "../../../data/Permissions";
import { calculateCheckListProgress } from "../../../pages/Projects/Utils";
import AddChecklistDialog from "../../../pages/Projects/dialogs/AddChecklistDialog/AddChecklistDialog";
import CommentService from "../../../services/CommentService/CommentService";
import FileService from "../../../services/FileService/FileService";
import NotificationService from "../../../services/NotificationService/NotificationService";
import ParticipantService from "../../../services/ParticipantService/ParticipantService";
import ServiceService from "../../../services/ServiceService/ServiceService";
import TaskService from "../../../services/TaskService/TaskService";
import TenantService from "../../../services/TenantService/TenantService";
import UserService from "../../../services/UserService/UserService";
import CustomAvatar from "../../CustomAvatar/CustomAvatar";
import FilePreview from "../../FilePreview/FilePreview";
import FileUpload from "../../FileUpload/FileUpload";
import AddParticipantDialog from "./Dialogs/AddParticipantDialog";
import EditDatesDialog from "./Dialogs/EditDatesDialog";
import EditParticipantDialog from "./Dialogs/EditParticipantDialog";
import IncurredTimeDialogV3 from "./Dialogs/IncurredTimeDialogV3";
import "./TaskOverviewSidebar.scss";

const TaskOverviewSidebar = ({ show, setShow, task, process, tasks, setTasks, setActiveTask, project_name, setProcess }) => {
    const { t, i18n } = useTranslation();

    const toast = useRef();
    const menuRef = useRef();
    const { user } = useAuthenticator((context) => [context.user]);
    const fileLogoUploadRef = useRef(null);

    const serviceService = new ServiceService();
    const userService = new UserService();
    const tenantService = new TenantService();
    const taskService = new TaskService();
    const participantService = new ParticipantService();
    const commentService = new CommentService();
    const fileService = new FileService();
    const notificationService = new NotificationService();

    const [dates, setDates] = useState([new Date(task?.start_date), new Date(task?.delivery_date)]);

    const [newComment, setNewComment] = useState();
    const [loadingComment, setLoadingComment] = useState(false);
    const commentBoldButtonRef = useRef();
    const commentItalicButtonRef = useRef();
    const commentUnderlineButtonRef = useRef();

    const [originalTask, setOriginalTask] = useState();
    const [tenantInfo, setTenantInfo] = useState();
    const [userInfo, setUserInfo] = useState();
    const [activeParticipant, setActiveParticipant] = useState();
    const [currentTaskDialog, setCurrentTaskDialog] = useState();
    const [showTaskDialog, setShowTaskDialog] = useState();

    // Participants
    const [participants, setParticipants] = useState([]);
    const [queryParticipantsResponse, setQueryParticipantsResponse] = useState();
    const [participantsRowsNumber, setParticipantsRowsNumber] = useState(50);

    // Comments
    const [comments, setComments] = useState([]);
    const [queryCommentsResponse, setQueryCommentsResponse] = useState();
    const [commentsRowsNumber, setCommentsRowsNumber] = useState(50);
    // Files
    const [files, setFiles] = useState([]);
    const [queryFilesResponse, setQueryFilesResponse] = useState();
    const [filesRowsNumber, setFilesRowsNumber] = useState(50);

    useEffect(() => {
        if (user && user.attributes) {
            if (getAuthData()?.is_tenant === "false") {
                userService.getUser({}, user.attributes.sub).then((data) => {
                    setUserInfo(data);
                });
            }

            tenantService.getTenant(user.attributes["custom:tenant_id"]).then((data) => {
                setTenantInfo(data);
            });
        }
        setOriginalTask(JSON.parse(JSON.stringify(task)));
    }, []);

    useEffect(() => {
        if (checkPermissions(["view_participants"])) {
            participantService.queryParticipants({ client_id: task?.client_id, project_id: task?.project_id, process_id: task?.process_id, task_id: task?.id, limit: participantsRowsNumber }).then((data) => {
                setQueryParticipantsResponse(data);
                // getParticipantsIncurredTime(data?.data);
                setParticipants(data?.data);
            });
        }

        commentService
            .queryComments({ client_id: task?.client_id, project_id: task?.project_id, process_id: task?.process_id, task_id: task?.id, limit: commentsRowsNumber })
            .then((data) => {
                setQueryCommentsResponse(data);
                setComments(data?.data);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });

        fileService
            .queryFiles({ client_id: task?.client_id, project_id: task?.project_id, process_id: task?.process_id, task_id: task?.id, limit: filesRowsNumber })
            .then((data) => {
                setQueryFilesResponse(data);
                setFiles(data?.data);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    }, []);

    const formik = useFormik({
        initialValues: {
            id: task?.id,
            title: task?.title,
            description: task?.description,
            duration_in_days: task?.duration_in_days,
            extra_cost: task?.extra_cost,
            include_client: task?.include_client,
            status: task?.status,
            start_date: task?.start_date,
            delivery_date: task?.delivery_date,
            project_id: task?.project_id,
            process_id: task?.process_id,
            client_id: task?.client_id,
            checklist: task?.checklist,
            participants: task?.participants,
            participant_resume: task?.participant_resume,
        },
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {},
    });

    const statusMenuItems = (processParam) => {
        let items = [];

        for (let i = 0; i < processParam?.lists?.length; i++) {
            const taskStatus = processParam?.lists[i];

            items.push({
                label: taskStatus?.name,
                command: () => {
                    formik.setFieldValue("status", taskStatus?.id);
                    updateTaskField("status", taskStatus?.id);
                },
                disabled: taskStatus === task?.status,
            });
        }

        return items;
    };

    const deleteTask = () => {
        let newTasks = [...tasks];
        confirmDialog({
            tagKey: "confirmTaskDeletionDialog",
            message: <span>{t("label.are_you_sure_you_want_to_delete_the_task_task_title", { task_title: task?.title })}</span>,
            closable: false,
            header: t("label.delete_task"),
            draggable: false,
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: t("label.delete_task"),
            rejectLabel: t("label.cancel"),
            rejectClassName: "p-button-outlined p-button-plain",
            accept: () => {
                taskService.deleteTask(task?.client_id, task?.project_id, task?.process_id, task?.id).then((e) => {
                    // Remove the task from process tasks_resume for tracking progress
                    let newTasksResume = process?.tasks_resume;

                    newTasksResume = newTasksResume.filter((item) => item.task_id !== task?.id);

                    const serviceUpdateParams = {
                        update_params: {
                            tasks_resume: newTasksResume,
                            tasks: process?.tasks - 1,
                            participants: process?.participants - task?.participants,
                        },
                    };

                    serviceService.updateService(serviceUpdateParams, task?.client_id, task?.project_id, task?.process_id).then((processData) => {
                        setProcess(processData);
                    });
                });

                commentService.emptyComments(task?.client_id, task?.project_id, task?.process_id, task?.id);
                participantService.emptyParticipants(task?.client_id, task?.project_id, task?.process_id, task?.id);
                fileService.emptyFiles(task?.client_id, task?.project_id, task?.process_id, task?.id);

                newTasks = newTasks.filter((item) => item.id !== task.id);
                setTasks(newTasks);
                close();
            },
            contentClassName: "pt-3",
        });
    };

    const updateTasks = (newTask) => {
        const currentTaskIndex = tasks?.findIndex((item) => item?.id === newTask?.id);

        let newTasks = tasks;

        newTasks[currentTaskIndex] = newTask;

        setTasks(newTasks);
    };

    const updateTaskField = (fieldName, fieldValue) => {
        let newData = formik.values;
        newData[fieldName] = fieldValue;

        let updateParams = {};

        if (fieldValue === null || fieldValue === undefined) {
            updateParams["remove_params"] = [fieldName];
        } else {
            updateParams["update_params"] = {};
            updateParams["update_params"][fieldName] = fieldValue;
        }

        // Update Process progress when task progress change
        if (fieldName === "status" || fieldName === "participant_resume") {
            let newTasksResume = process?.tasks_resume;
            const currentTaskIndex = newTasksResume?.findIndex((taskResume) => taskResume.task_id === formik.values.id);

            newTasksResume[currentTaskIndex] = {
                ...newTasksResume[currentTaskIndex],
            };

            if (fieldName === "status") {
                let progress = fieldValue && fieldValue === "done" ? 100 : 0;
                if (fieldValue !== "done" && formik.values.checklist) {
                    progress = parseInt(calculateCheckListProgress(formik.values.checklist));
                }
                newTasksResume[currentTaskIndex]["progress"] = progress;
                newTasksResume[currentTaskIndex]["status"] = fieldValue;
            } else if (fieldName === "participant_resume") {
                let actualCost = 0;

                for (const key in fieldValue) {
                    if (fieldValue && fieldValue[key].actual_cost) {
                        actualCost += fieldValue[key].actual_cost;
                    }
                }

                newTasksResume[currentTaskIndex]["actual_cost"] = actualCost;
            }

            const serviceUpdateParams = {
                update_params: {
                    tasks_resume: newTasksResume,
                },
            };

            serviceService.updateService(serviceUpdateParams, formik.values.client_id, formik.values.project_id, formik.values.process_id);
        }

        if (newData[fieldName] !== originalTask[fieldName]) {
            taskService.updateTask(updateParams, formik.values.client_id, formik.values.project_id, formik.values.process_id, formik.values.id).catch((error) => {
                console.log(error);
            });
            let newTask = {
                ...formik.values,
            };
            newTask[fieldName] = fieldValue;
            setActiveTask(newTask);
            updateTasks(newTask);
        }
    };

    const descriptionSection = () => {
        return (
            <div className="col-12 pt-4">
                <div className="grid">
                    <div className="col-12 md:col-2">
                        <div className="flex align-content-center flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text-small font-x-small font-weight-semibold">{t("label.description")}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-10 custom-description-editor">
                        <Editor
                            value={formik.values.description}
                            className="w-12 border-transparent mousee-text text-x-small font-weight-regular"
                            placeholder={t("label.add_a_description")}
                            style={{ maxHeight: "400px", overflow: "auto" }}
                            headerTemplate={
                                <span className="ql-formats min-w-full">
                                    <div className="flex justify-content-between flex-wrap">
                                        <div className="flex align-items-center justify-content-center">
                                            <button className="ql-bold" aria-label="Bold"></button>
                                            <button className="ql-italic" aria-label="Italic"></button>
                                            <button className="ql-underline" aria-label="Underline"></button>
                                            <button className="ql-list" value={"ordered"} aria-label="List"></button>
                                            <button className="ql-list" value={"bullet"} aria-label="List"></button>
                                            <button className="ql-align"></button>
                                        </div>
                                        <div className="flex align-items-end justify-content-end" disabled={formik?.values?.description === task?.description}>
                                            <Icon icon="solar:diskette-bold-duotone" style={{ fontSize: "30px" }} onClick={() => updateTaskField("description", formik.values.description)} />
                                        </div>
                                    </div>
                                </span>
                            }
                            onTextChange={(e) => {
                                if (!e.htmlValue) {
                                    formik.setFieldValue("description", "");
                                } else {
                                    formik.setFieldValue("description", e.htmlValue);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const addComment = () => {
        if (newComment && newComment.trim().length > 0) {
            setLoadingComment(true);
            const newData = {
                author_name: userInfo.name,
                author_surnames: userInfo.surnames,
                avatar: userInfo.avatar,
                content: newComment,
                project_name: project_name,
                client_id: formik.values.client_id,
                project_id: formik.values.project_id,
                process_id: formik.values.process_id,
                task_id: formik.values.id,
            };

            commentService
                .createComment(formik.values.client_id, formik.values.project_id, formik.values.process_id, formik.values.id, newData)
                .then((data) => {
                    if (data) {
                        let newComments = [];
                        if (comments) {
                            newComments = [...comments];
                        }

                        newComments.unshift(data);
                        setComments(newComments);

                        const newNotification = {
                            avatar: data.avatar,
                            url: `/projects/${data.client_id}/${data.project_id}/details?process_id=${data.process_id}&view=list&task_id=${data.task_id}`,
                            readed: "false",
                            author: `${data.author_name} ${data.author_surnames}`.trim(),
                            type: "task_comment",
                            key: data.task_id,
                            data: {
                                task_name: formik.values.title,
                                title: t("label.a_comment_has_been_posted_to_task_task_name_in_project_project_name", { task_name: formik.values.title, project_name: project_name }),
                                project_name: project_name,
                                content: data.content,
                            },
                        };

                        notificationService.notify(data.client_id, data.project_id, data.process_id, data.task_id, formik.values.include_client ? "true" : "false", newNotification);
                    }
                    setNewComment("");
                    setLoadingComment(false);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }
    };

    const deleteChecklist = () => {
        confirmDialog({
            tagKey: "confirmChecklistDeletionDialog",
            message: <span>{t("label.are_you_sure_you_want_to_delete_subtasks")}</span>,
            header: t("label.delete_subtasks"),
            draggable: false,
            acceptLabel: t("label.yes"),
            rejectLabel: t("label.no"),
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept: () => {
                updateTaskField("checklist", undefined);
                formik.setFieldValue("checklist", undefined);
            },
            contentClassName: "pt-3",
        });
    };

    const subtasksSection = () => {
        return formik.values?.checklist?.options?.length > 0 ? (
            <div className="col-12 pt-4">
                <div className="grid">
                    <div className="col-12 md:col-2">
                        <div className="flex align-content-center flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text-small font-x-small font-weight-semibold">{t("label.subtasks")}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-10">
                        <div className="grid">
                            {formik.values?.checklist?.title ? (
                                <div className="col-12 pt-1">
                                    <div className="flex justify-content-between flex-wrap">
                                        <div className="flex align-items-center justify-content-center">
                                            <label className="mousee-text font-small font-weight-semibold">{formik.values?.checklist?.title}</label>
                                        </div>
                                        {checkComponentPermissions(
                                            <div className="flex align-items-center justify-content-center">
                                                <div className="flex justify-content-end flex-wrap">
                                                    <div className="flex align-items-center justify-content-center">
                                                        <Button
                                                            icon={<Icon icon="solar:pen-bold" data-pr-tooltip={t("label.edit_subtasks")} style={{ fontSize: "20px" }} />}
                                                            className="p-button-text p-button-secondary"
                                                            tooltip={t("label.edit_subtasks")}
                                                            tooltipOptions={{ position: "left" }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setCurrentTaskDialog("add_checklist");
                                                                setShowTaskDialog(true);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center">
                                                        <Button
                                                            icon={<Icon icon="solar:trash-bin-trash-bold-duotone" data-pr-tooltip={t("label.delete_subtasks")} style={{ fontSize: "20px" }} />}
                                                            className="p-button-text p-button-secondary"
                                                            tooltip={t("label.delete_subtasks")}
                                                            tooltipOptions={{ position: "left" }}
                                                            onClick={() => deleteChecklist()}
                                                        />
                                                    </div>
                                                </div>
                                            </div>,
                                            ["edit_tasks"],
                                            null,
                                            null
                                        )}
                                    </div>
                                </div>
                            ) : null}
                            {formik.values?.checklist?.options.map((option, index) => {
                                return (
                                    <div className={`col-12 pt-1`}>
                                        <div className="flex align-items-center pl-2">
                                            <Checkbox
                                                inputId={`${option}-${index}`}
                                                name={`${option}-${index}`}
                                                disabled={!checkPermissions(["edit_tasks"], null)}
                                                checked={formik.values?.checklist?.checked?.includes(option)}
                                                onChange={(e) => {
                                                    let newChecklist = formik.values.checklist;
                                                    if (newChecklist?.checked?.includes(option)) {
                                                        newChecklist.checked = newChecklist?.checked?.filter((newOption) => newOption !== option);
                                                    } else {
                                                        if (!newChecklist?.checked) {
                                                            newChecklist.checked = [];
                                                        }

                                                        newChecklist.checked.push(option);
                                                    }
                                                    formik.setFieldValue("checklist", newChecklist);
                                                    updateTaskField("checklist", newChecklist);
                                                }}
                                            />
                                            <label htmlFor={`${option}-${index}`} className="mousee-text font-x-small font-weight-light ml-2">
                                                {formik.values?.checklist?.checked?.includes(option) ? <s>{option}</s> : option}
                                            </label>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        ) : null;
    };

    const attachmentsSection = (filesParam) => {
        return (
            <div className="col-12 pt-4">
                <div className="grid">
                    <div className="col-3 md:col-2">
                        <div className="flex align-content-center flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text-small font-x-small font-weight-semibold">{t("label.attachments")}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-9 md:col-10">
                        <div className="flex flex-row flex-wrap align-content-center h-full">
                            {filesParam?.map((item, index) => {
                                return (
                                    <div className={`flex align-items-center justify-content-center pr-2 ${index > 3 ? "pt-2" : ""}`}>
                                        <FilePreview file={item} files={filesParam} setFiles={setFiles} deleteFile={deleteFile} />
                                    </div>
                                );
                            })}

                            <div className={`flex align-items-center justify-content-center ${filesParam?.length > 3 ? "pt-2" : ""}`}>
                                <FileUpload inputFileRef={fileLogoUploadRef} files={filesParam} setFiles={setFiles} task={task} project_name={project_name} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const participantsSection = (participantsParam) => {
        return checkPermissions(["view_participants"], null) ? (
            <div className="col-12 pt-4">
                <div className="grid">
                    <div className="col-3 md:col-2">
                        <div className="flex align-content-center flex-wrap h-full">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text-small font-x-small font-weight-semibold">{t("label.participants")}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-9 md:col-10">
                        <div className="flex flex-row flex-wrap align-content-center h-full">
                            {participantsParam?.map((item, index) => {
                                if (item?.user) {
                                    return (
                                        <div className={`flex align-items-center justify-content-center pr-2 ${index > 4 ? "pt-2" : ""}`}>
                                            <CustomAvatar
                                                image={item?.user?.avatar}
                                                // label={item?.user ? `${item?.user?.name?.split("")[0]}${item?.user?.surnames?.split("")[0]}` : item?.profile?.name ? getTenantCommercialNameAcronym(item?.profile?.name) : null}
                                                hoverIcon="solar:pen-bold"
                                                tooltipId={`participant-${index}`}
                                                tooltip={`custom-target-`}
                                                label={`${item?.user?.name} ${item?.user?.surnames ? item?.user?.surnames : ""}`.trim()}
                                                hoverable={true}
                                                hoverAction={() => {
                                                    setActiveParticipant(item);
                                                    setCurrentTaskDialog("edit_participant");
                                                    setShowTaskDialog(true);
                                                }}
                                            />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div className={`flex align-items-center justify-content-center pr-2 ${index > 4 ? "pt-2" : ""}`}>
                                            <CustomAvatar
                                                // label={item?.profile?.name ? getTenantCommercialNameAcronym(item?.profile?.name) : null}
                                                hoverIcon="solar:user-plus-rounded-bold-duotone"
                                                tooltipId={`participant-${index}`}
                                                tooltip={`custom-target-`}
                                                label={item?.profile?.name}
                                                hoverable={true}
                                                hoverAction={() => {
                                                    setActiveParticipant(item);
                                                    setCurrentTaskDialog("edit_participant");
                                                    setShowTaskDialog(true);
                                                }}
                                            />
                                        </div>
                                    );
                                }
                            })}

                            <div className={`flex align-items-center justify-content-center`}>
                                <Button
                                    icon={"pi pi-plus"}
                                    className="p-button-text p-button-secondary add-participant-button"
                                    tooltip={t("label.add_participants")}
                                    tooltipOptions={{ position: "right" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setCurrentTaskDialog("add_participant");
                                        setShowTaskDialog(true);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null;
    };

    const clientSection = () => {
        return (
            <div className="col-12 pt-4">
                <div className="grid">
                    <div className="col-3 md:col-2">
                        <div className="flex align-content-center flex-wrap h-full">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text-small font-x-small font-weight-semibold">{t("label.client")}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-9 md:col-10">
                        <div className="flex align-items-center">
                            <InputSwitch
                                checked={formik.values.include_client}
                                onChange={(e) => {
                                    formik.setFieldValue("include_client", e.value);
                                    updateTaskField("include_client", e.value);
                                }}
                            />
                            <label className="ml-2 mousee-text font-x-small font-weight-light">{t("label.visible_to_the_client")}</label>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const datesSection = () => {
        return (
            <div className="col-12 pt-4">
                <div className="grid">
                    <div className="col-3 md:col-2">
                        <div className="flex align-content-center flex-wrap h-full">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text-small font-x-small font-weight-semibold">{t("label.dates")}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-9 md:col-10">
                        <Button
                            className="p-button-text p-button-secondary pl-1 pr-1"
                            onClick={() => {
                                setCurrentTaskDialog("edit_dates");
                                setShowTaskDialog(true);
                            }}
                            label={`${formatDateV2(formik.values.start_date, false, i18n.language)} - ${formatDateV2(formik.values.delivery_date, false, i18n.language)}`}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const commentSection = () => {
        return (
            <div className="flex justify-content-between flex-wrap min-h-full min-w-full" style={{ padding: "20px 20px" }}>
                <div className="flex align-items-start justify-content-left w-2 md:w-1">
                    {user?.signInUserSession?.idToken?.payload?.is_tenant === "true" ? (
                        <Avatar
                            image={tenantInfo?.avatar_path ? tenantInfo?.avatar_path : null}
                            label={!tenantInfo?.avatar_path ? getTenantCommercialNameAcronym(tenantInfo?.commercial_name) : null}
                            style={!tenantInfo?.avatar_path ? { backgroundColor: "var(--surface-300)", color: "var(--secondary-color-extra-light)" } : null}
                            className=""
                            size="large"
                            shape="circle"
                        />
                    ) : (
                        <Avatar
                            image={userInfo?.avatar ? userInfo?.avatar : null}
                            label={!userInfo?.avatar && userInfo?.name ? `${userInfo?.name?.split("")[0]}${userInfo?.surnames?.split("")[0]}` : null}
                            style={!userInfo?.avatar ? { backgroundColor: "var(--surface-300)", color: "var(--secondary-color-extra-light)" } : null}
                            className=""
                            size="large"
                            shape="circle"
                        />
                    )}
                </div>
                <div
                    className="flex align-items-center justify-content-start custom-comment-box w-10 md:w-11"
                    style={
                        {
                            /*padding: "8px"*/
                        }
                    }
                >
                    <div className="grid min-w-full min-h-full m-0">
                        <div className="col-12 custom-comment-editor ">
                            <Editor
                                value={newComment}
                                onTextChange={(e) => setNewComment(e.htmlValue)}
                                placeholder={t("label.type_a_comment")}
                                headerTemplate={
                                    <span className="ql-formats min-w-full" style={{ display: "none" }}>
                                        <button className="ql-bold" ref={commentBoldButtonRef} aria-label="Bold"></button>
                                        <button className="ql-italic" ref={commentItalicButtonRef} aria-label="Italic"></button>
                                        <button className="ql-underline" ref={commentUnderlineButtonRef} aria-label="Underline"></button>
                                    </span>
                                }
                            />
                        </div>
                        <div className="col-12 ">
                            <div className="flex justify-content-between align-content-end flex-wrap h-full">
                                <div className="flex align-items-center justify-content-center">
                                    <div className="flex justify-content-start flex-wrap">
                                        <div className="flex align-items-center justify-content-center">
                                            <Button
                                                icon={<Icon className="" icon="solar:text-bold-bold-duotone" data-pr-tooltip={t("label.subtasks")} style={{ fontSize: "16px" }} />}
                                                className="p-button-text p-button-secondary ql-bold"
                                                aria-label="Bold"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    commentBoldButtonRef?.current?.click();
                                                }}
                                            />
                                        </div>
                                        <div className="flex align-items-center justify-content-center">
                                            <Button
                                                icon={<Icon className="" icon="solar:text-italic-bold-duotone" data-pr-tooltip={t("label.subtasks")} style={{ fontSize: "16px" }} />}
                                                className="p-button-text p-button-secondary"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    commentItalicButtonRef?.current?.click();
                                                }}
                                            />
                                        </div>
                                        <div className="flex align-items-center justify-content-center">
                                            <Button
                                                icon={<Icon className="" icon="solar:text-underline-bold-duotone" data-pr-tooltip={t("label.subtasks")} style={{ fontSize: "16px" }} />}
                                                className="p-button-text p-button-secondary"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    commentUnderlineButtonRef?.current?.click();
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex align-items-center justify-content-center">
                                    {/* <Button disabled={newComment === ""} label={t("label.add_comment")} loading={loadingComment} className="p-button" onClick={() => addComment()} /> */}
                                    <Button icon={<Icon className="" icon="solar:play-bold-duotone" style={{ fontSize: "24px", color: "var(--primary-color)" }} />} className="p-button-text p-button-secondary" disabled={newComment === ""} onClick={() => addComment()} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const commentsSection = (commentsParam) => {
        return commentsParam?.length > 0 ? (
            <div className="custom-comments-box" style={{ backgroundColor: "var(--miflow-no-white-v2-background)" }}>
                {commentsParam?.map((item, index) => {
                    return (
                        <div className={`flex flex-row flex-wrap min-h-full min-w-full ${index > 0 ? "" : ""}pt-3`} style={{ padding: "12px 20px" }}>
                            <div className="flex align-items-start justify-content-start w-2 lg:w-1">
                                {user?.signInUserSession?.idToken?.payload?.is_tenant === "true" ? (
                                    <Avatar
                                        image={tenantInfo?.avatar_path ? tenantInfo?.avatar_path : null}
                                        label={!tenantInfo?.avatar_path ? getTenantCommercialNameAcronym(tenantInfo?.commercial_name) : null}
                                        style={!tenantInfo?.avatar_path ? { backgroundColor: "var(--surface-300)", color: "var(--secondary-color-extra-light)" } : null}
                                        className=""
                                        size="large"
                                        shape="circle"
                                    />
                                ) : (
                                    <Avatar
                                        image={userInfo?.avatar ? userInfo?.avatar : null}
                                        label={!userInfo?.avatar && userInfo?.name ? `${userInfo?.name?.split("")[0]}${userInfo?.surnames?.split("")[0]}` : null}
                                        style={!userInfo?.avatar ? { backgroundColor: "var(--surface-300)", color: "var(--secondary-color-extra-light)" } : null}
                                        className=""
                                        size="large"
                                        shape="circle"
                                    />
                                )}
                            </div>
                            <div className="flex align-items-center justify-content-start w-10 lg:w-11 align-content-start" style={{}}>
                                <div className="flex flex-column min-w-full min-h-full">
                                    <div className="flex align-items-center min-w-full">
                                        <div className="flex justify-content-between flex-wrap align-content-start  min-w-full">
                                            <div className="flex align-items-start justify-content-start">
                                                <label className="mousee-text font-x-small font-weight-semibold">{`${item?.author_name} ${item?.author_surnames}`.trim()}</label>
                                            </div>
                                            <div className="flex align-items-center justify-content-end">
                                                <label className="mousee-text-small font-xx-small font-weight-regular">{formatDateV2(item.created_at, true, i18n.language)}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex align-items-center justify-content-start min-w-full pt-3">
                                        <div className="grid">
                                            <div className="col-12">
                                                <div className="w-full" dangerouslySetInnerHTML={{ __html: item.content.replace(/(<? *script)/gi, "illegalscript") }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        ) : null;
    };

    const updateParticipantResume = (newParticipantResume, participantId, value, taskId) => {
        const newParticipantResumeItem = {
            ...value,
        };

        if (!newParticipantResume) {
            newParticipantResume = {};
        }

        newParticipantResume[participantId] = newParticipantResumeItem;

        updateTaskField("participant_resume", newParticipantResume);
    };

    const close = () => {
        blockBackgroundScroll(false);
        setShow(false);
    };

    const getTaskDialog = (dialog) => {
        if (dialog === "incurred_time") {
            return (
                <IncurredTimeDialogV3
                    show={showTaskDialog}
                    onHide={(value) => {
                        setShowTaskDialog(value);
                        setActiveParticipant(undefined);
                    }}
                    participant={checkPermissions(null, ["employee"]) ? (activeParticipant ? activeParticipant : null) : null}
                    client_id={formik.values.client_id}
                    project_id={formik.values.project_id}
                    process_id={formik.values.process_id}
                    task_id={formik.values.id}
                    taskTitle={formik.values.title}
                    participants={participants}
                    setParticipants={setParticipants}
                    participant_resume={formik.values.participant_resume}
                    updateParticipantResume={updateParticipantResume}
                />
            );
        } else if (dialog === "add_participant") {
            return <AddParticipantDialog show={showTaskDialog} setShow={setShowTaskDialog} participants={participants} setParticipants={setParticipants} process={process} updateTaskField={updateTaskField} task={task} />;
        } else if (dialog === "edit_dates") {
            return (
                <EditDatesDialog
                    show={showTaskDialog}
                    setShow={setShowTaskDialog}
                    task={task}
                    setActiveTask={(newValue) => {
                        setActiveTask(newValue);
                        formik.setValues(newValue);
                    }}
                    updateTasks={updateTasks}
                    setOriginalTask={setOriginalTask}
                />
            );
        } else if (dialog === "edit_participant") {
            return <EditParticipantDialog process={process} show={showTaskDialog} setShow={setShowTaskDialog} participant={activeParticipant} participants={participants} setParticipants={setParticipants} />;
        } /* else if (dialog === "extra_cost") {
            return (
                <ExtraCostDialog
                    show={showTaskDialog}
                    extraCostRef={extraCostRef}
                    onHide={setShowTaskDialog}
                    extra_cost={formik.values.extra_cost}
                    client_id={formik.values.client_id}
                    project_id={formik.values.project_id}
                    process_id={formik.values.process_id}
                    task_id={formik.values.id}
                    setTask={formik.setValues}
                />
            );
        } else if (dialog === "edit_estimate_time") {
            return (
                <EditEstimateTimeOverlay
                    show={showTaskDialog}
                    onHide={setShowTaskDialog}
                    participant={activeParticipant}
                    client_id={formik.values.client_id}
                    project_id={formik.values.project_id}
                    process_id={formik.values.process_id}
                    task_id={formik.values.id}
                    participants={participants}
                    setParticipants={setParticipants}
                />
            );
        } else if (dialog === "add_attachment") {
            return (
                <AddAttachmentDialog
                    show={showTaskDialog}
                    onHide={setShowTaskDialog}
                    attachmentRef={attachmentRef}
                    client_id={formik.values.client_id}
                    project_id={formik.values.project_id}
                    process_id={formik.values.process_id}
                    task_id={formik.values.id}
                    task_name={formik.values.title}
                    project_name={props?.project_name}
                    files={files}
                    setFiles={setFiles}
                />
            );
        }*/ else if (dialog === "add_checklist") {
            return (
                <AddChecklistDialog
                    show={showTaskDialog}
                    onHide={setShowTaskDialog}
                    checklist={formik.values.checklist}
                    client_id={formik.values.client_id}
                    project_id={formik.values.project_id}
                    process_id={formik.values.process_id}
                    task_id={formik.values.id}
                    setTask={formik.setValues}
                    taskType="project_task"
                />
            );
        }
    };

    const deleteFile = (id) => {
        let newFiles = files;

        if (newFiles && newFiles.length > 0) {
            const currentIndex = newFiles.findIndex((item) => item.id === id);

            const file = newFiles[currentIndex];
            confirmDialog({
                tagKey: "confirmFileDeletionDialog",
                message: <span>{t("label.are_you_sure_you_want_to_delete_the_file_filename", { filename: file?.name })}</span>,
                header: t("label.delete_file"),
                icon: "pi pi-info-circle",
                contentClassName: "pt-3",
                acceptClassName: "p-button-danger",
                rejectLabel: t("label.no"),
                acceptLabel: t("label.yes_delete_file"),
                dismissableMask: true,
                closable: false,
                accept: () => {
                    fileService.deleteFile(task?.client_id, task?.project_id, task?.process_id, task?.id, file?.id).catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    });

                    newFiles = newFiles.filter((_, newIndex) => newIndex !== currentIndex);
                    setFiles([...newFiles]);
                },
            });
        }
    };

    return (
        <Sidebar showCloseIcon={false} position="right" visible={show} onHide={close} className="custom-sidebar" header={false} blockScroll style={{ overflowY: "hidden" }} onShow={() => blockBackgroundScroll(true)}>
            <Toast ref={toast} />
            <Menu model={statusMenuItems(process)} popup ref={menuRef} />
            {showTaskDialog ? getTaskDialog(currentTaskDialog) : null}
            <ConfirmDialog tagKey="confirmFileDeletionDialog" />
            <ConfirmDialog tagKey="confirmChecklistDeletionDialog" />
            <ConfirmDialog tagKey="confirmTaskDeletionDialog" />

            <div className="flex flex-column justify-content-between flex-wrap min-h-full static">
                <div className="flex align-items-center justify-content-center">
                    <div className="grid min-w-full">
                        <div className="col-12 p-0 h-5rem min-w-full top-0 sticky" style={{ zIndex: 1200, backgroundColor: "white" }}>
                            <div className="grid">
                                <div className="col-12 pt-4 pl-4 pr-4 pb-0">
                                    <div className="flex justify-content-between flex-wrap">
                                        <div className="flex align-items-center justify-content-center">
                                            <div className="flex justify-content-start flex-wrap">
                                                {!isDesktop() ? (
                                                    <div className="flex align-items-center justify-content-center">
                                                        <Button
                                                            icon={"pi pi-angle-left"}
                                                            className="p-button-text p-button-secondary"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                close();
                                                            }}
                                                        />
                                                    </div>
                                                ) : null}
                                                <div className="flex align-items-center justify-content-center">
                                                    <Button
                                                        iconPos="right"
                                                        // label={<label className="mousee-text font-x-small font-weight-bold">{t(getTaskStatusLabel(task?.status))}</label>}
                                                        label={<label className="mousee-text font-x-small font-weight-bold">{process?.lists?.filter((item) => item?.id === task?.status)[0]?.name}</label>}
                                                        icon={"pi pi-angle-down"}
                                                        className="p-button-text p-button-secondary pl-1 pr-1"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            menuRef?.current?.toggle(e);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex align-items-center justify-content-center">
                                            <div className="flex justify-content-start flex-wrap">
                                                {checkComponentPermissions(
                                                    <div className="flex align-items-center justify-content-center">
                                                        <Button
                                                            icon={<Icon icon="solar:checklist-minimalistic-bold-duotone" data-pr-tooltip={t("label.subtasks")} style={{ fontSize: "20px" }} />}
                                                            className="p-button-text p-button-secondary"
                                                            tooltip={t("label.subtasks")}
                                                            tooltipOptions={{ position: "left" }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setCurrentTaskDialog("add_checklist");
                                                                setShowTaskDialog(true);
                                                            }}
                                                        />
                                                    </div>,
                                                    ["edit_tasks"],
                                                    null,
                                                    null
                                                )}
                                                <div className="flex align-items-center justify-content-center">
                                                    <Button
                                                        icon={<Icon icon="solar:alarm-add-bold-duotone" data-pr-tooltip={t("label.allocate_hours")} style={{ fontSize: "20px" }} />}
                                                        className="p-button-text p-button-secondary"
                                                        tooltip={t("label.allocate_hours")}
                                                        tooltipOptions={{ position: "left" }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setActiveParticipant(participants && participants.length > 0 && participants.filter((item) => item.user).length > 0 ? participants.filter((item) => item.user)[0] : undefined);
                                                            setCurrentTaskDialog("incurred_time");
                                                            setShowTaskDialog(true);
                                                        }}
                                                    />
                                                </div>
                                                <div className="flex align-items-center justify-content-center">
                                                    <Button
                                                        icon={<Icon icon="solar:trash-bin-trash-bold-duotone" data-pr-tooltip={t("label.delete_task")} style={{ fontSize: "20px" }} />}
                                                        className="p-button-text p-button-secondary"
                                                        tooltip={t("label.delete_task")}
                                                        tooltipOptions={{ position: "left" }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            deleteTask();
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 p-0">
                                    <Divider className="border-300 mb-0" />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 pl-4 pr-4">
                            <div className="grid">
                                <div className="col-12 pt-4">
                                    <InputText
                                        className="w-full xl:min-w-full mousee-text font-medium font-weight-semibold custom-title-inputtext"
                                        value={formik.values.title}
                                        onBlur={(e) => {
                                            updateTaskField("title", formik.values.title);
                                        }}
                                        onChange={(e) => {
                                            formik.setFieldValue("title", e.target.value);
                                        }}
                                    />
                                </div>

                                {clientSection()}

                                {datesSection()}

                                {participantsSection(participants)}

                                {descriptionSection()}

                                {subtasksSection()}

                                {attachmentsSection(files)}
                            </div>
                        </div>
                        <div className="col-12">{commentsSection(comments)}</div>
                    </div>
                </div>

                <div className="flex align-items-center justify-content-center h-11rem min-w-full custom-sidebar-footer bottom-0 sticky" style={{ zIndex: 1200, backgroundColor: "white" }}>
                    {commentSection()}
                </div>
            </div>
        </Sidebar>
    );
};
export default TaskOverviewSidebar;
