import { useFormik } from "formik";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getAdditionalTopBarComponentsV2 } from "../../../data/TabsData";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { formatDateV2, getAuthData, isDesktop, tableHeaderTemplate } from "../../../Utils";
import { checkComponentPermissions, checkPermissions } from "../../../data/Permissions";
import { PROJECT_STATUSES, getProjectStatusChip } from "../../../data/Project";
import ClientService from "../../../services/ClientService/ClientService";
import ProjectService from "../../../services/ProjectService/ProjectService";
import AddProjectDialog from "./AddProjectDialog";

import dropdownDown from "../../../assets/icons/dropdown-down.svg";
// import projectClientIcon from "../../../assets/icons/project/project-client.svg";
// import projectNameIcon from "../../../assets/icons/project/project-icon.svg";
// import projectProcessesIcon from "../../../assets/icons/project/project-processes.svg";
// import projectDateIcon from "../../../assets/icons/project/project-progress.svg";
// import projectStatusIcon from "../../../assets/icons/project/project-status.svg";
import CustomTablePaginator from "../../../components/CustomTablePaginator";
import ServiceService from "../../../services/ServiceService/ServiceService";

// Projects Icons

// Services Icons

import { useAuthenticator } from "@aws-amplify/ui-react";
import { Icon } from "@iconify/react";
import { ProgressBar } from "primereact/progressbar";
import { useTranslation } from "react-i18next";
import TaskService from "../../../services/TaskService/TaskService";
import Forbidden from "../../Auth/Forbidden";
import { clientOptionTemplate, filterClients, handleClientsOnScroll, selectedClientTemplate } from "../../People/Clients/Utils";
import { getProgressLabel } from "../Utils";
import { getProcessTasksResumePercentage } from "./Utils";

const Projects = (props) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [expandedRows, setExpandedRows] = useState({});
    const projectService = new ProjectService();
    const serviceService = new ServiceService();
    const taskService = new TaskService();

    const [loadingProject, setLoadingProject] = useState(false);
    const [activeProjectId, setActiveProjectId] = useState(false);

    const toast = useRef(null);
    const navigate = useNavigate();
    const clientService = new ClientService();
    const [showNewProjectDialog, setShowNewProjectDialog] = useState(false);

    const [clients, setClients] = useState([]);
    const [queryClientsResponse, setQueryClientsResponse] = useState();
    const [clientsRowsNumber, setClientsRowsNumber] = useState(3);
    const [clientFilterName, setClientFilterName] = useState(undefined);

    const [projects, setProjects] = useState([]);
    const [queryProjectsResponse, setQueryProjectsResponse] = useState();

    const [tableFirst, setTableFirst] = useState(0);
    const [tableKeys, setTableKeys] = useState([0]);
    const [rowsNumber, setRowsNumber] = useState(10);
    const [activeIndex, setActiveIndex] = useState(0);

    const { user } = useAuthenticator((context) => [context.user]);

    useEffect(() => {
        props.setTopbarAdditionalComponents(getAdditionalTopBarComponentsV2("projects", navigate));
    }, []);

    useEffect(() => {
        props.setOption("projects");
    }, []);

    useEffect(() => {
        formik.resetForm();
        setLoading(true);

        if (checkPermissions("view_clients", null)) {
            clientService
                .queryClients({ limit: clientsRowsNumber })
                .then((data) => {
                    setQueryClientsResponse(data);
                    setClients(data?.data);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }

        // if (user && user.attributes && user.attributes["custom:role"]) {
        if (getAuthData()?.user_type) {
            // if (user.attributes["custom:role"] === "client" || user.attributes["custom:role"] === "client_read") {
            if (getAuthData()?.user_type === "client") {
                // projectService.queryProjects({ client_id: user.attributes["custom:admin"], limit: rowsNumber }).then((data) => {
                projectService.queryProjects({ client_id: getAuthData()?.client_id, limit: rowsNumber }).then((data) => {
                    setQueryProjectsResponse(data);
                    setProjects(data?.data);
                    setLoading(false);
                });
            } else {
                projectService.queryProjects({ limit: rowsNumber }).then((data) => {
                    setQueryProjectsResponse(data);
                    setProjects(data?.data);
                    setLoading(false);
                });
            }
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            text: "",
            projects: [],
            client: null,
            status: null,
        },
        onSubmit: (data) => {
            setLoading(true);
            let queryParameter = {
                limit: rowsNumber,
            };
            if (data.text.length > 0) {
                queryParameter = {
                    ...queryParameter,
                    name: data.text,
                };
            }

            projectService.queryProjects(queryParameter).then((data) => {
                setTableFirst(0);
                setTableKeys([0]);
                setActiveIndex(0);
                setQueryProjectsResponse(data);
                setProjects(data?.data);
                setLoading(false);
            });
        },
    });

    const processesTableTemplate = (data) => {
        return (
            <div className="pl-5 pr-5 pt-1">
                <div className="card p-0" style={{ overflow: "hidden" }}>
                    <DataTable
                        value={data.processes_data}
                        onRowClick={(e) => navigate(`/projects/${data.id.split("#")[0]}/${data.id.split("#")[1]}/details?process_id=${e.data.id}`)}
                        loading={loadingProject && activeProjectId === data.id}
                        emptyMessage={t("label.no_items_to_display")}
                        rowClassName="cursor-pointer"
                    >
                        <Column header={tableHeaderTemplate(null, t("label.service"))} body={(item) => <label className="mousee-text font-x-small font-weight-semibold">{item?.name}</label>} style={{ width: "50%", minWidth: "25rem" }} headerClassName="mousee-text font-x-small font-weight-semibold" />
                        <Column field="tasks" style={{ width: "10%", minWidth: "10rem" }} header={tableHeaderTemplate(null, t("label.tasks"))} headerClassName="mousee-text font-x-small font-weight-semibold" />
                        <Column field="participants" style={{ width: "12%", minWidth: "12rem" }} header={tableHeaderTemplate(null, t("label.participants"))} headerClassName="mousee-text font-x-small font-weight-semibold" />
                        <Column
                            field="delivery_date"
                            style={{ width: "15%", minWidth: "13rem" }}
                            header={tableHeaderTemplate(null, t("label.delivery_date"))}
                            body={(item) => (item.delivery_date ? formatDateV2(item?.delivery_date, false, i18n.language) : "N/A")}
                            headerClassName="mousee-text font-x-small font-weight-semibold"
                        />
                        <Column
                            header={tableHeaderTemplate(null, t("label.progress"))}
                            style={{ width: "20%", minWidth: "20rem" }}
                            body={(item) => {
                                const percentage = getProcessTasksResumePercentage(item?.tasks_resume);
                                return (
                                    <div className="grid">
                                        <div className="col-10">
                                            <ProgressBar value={percentage} color="var(--success-color)" showValue={false}></ProgressBar>
                                        </div>
                                        <div className="col-2">
                                            <label className="mousee-text font-small font-weight-semibold">{getProgressLabel(percentage)}</label>
                                        </div>
                                    </div>
                                );
                            }}
                            headerClassName="mousee-text font-x-small font-weight-semibold"
                        />
                    </DataTable>
                </div>
            </div>
        );
    };

    const getProjectData = async (project) => {
        let newProjects = projects;

        const operation = async () => {
            const client_id = project.id.split("#")[0];
            const project_id = project.id.split("#")[1];

            await serviceService.queryServices({ client_id: client_id, project_id: project_id, limit: 5 }).then((data) => {
                project.processes_data = data?.data;
            });

            const currentIndex = newProjects.findIndex((newProject) => newProject.id === project.id);

            newProjects[currentIndex] = {
                ...project,
                retrieved: true,
            };

            setProjects(newProjects);

            setLoadingProject(false);
            setLoading(false);
            setActiveProjectId(undefined);
        };

        await operation();

        return project;
    };

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error pl-2">{formik.errors[name]}</small> : null;
    };

    const filterByParams = (e, type) => {
        setLoading(true);

        formik.setFieldValue(type, e.value);

        let queryParameters = {};

        if (type === "client") {
            if (e.value) {
                queryParameters = {
                    client_id: e.value.id,
                };
            }
            if (formik.values.status) {
                // queryParameters["status"] = formik.values.status.type
                formik.setFieldValue("status", undefined);
            }
        } else if (type === "status") {
            if (e.value) {
                queryParameters = {
                    status: e.value.type,
                };
            }

            if (formik.values.client) {
                // queryParameters["client_id"] = formik.values.client.id
                formik.setFieldValue("client", undefined);
            }
        }

        projectService.queryProjects({ ...queryParameters, limit: rowsNumber }).then((data) => {
            setTableFirst(0);
            setTableKeys([0]);
            setActiveIndex(0);
            setQueryProjectsResponse(data);
            setProjects(data?.data);
            setLoading(false);
        });
    };

    const expandSpecificRow = async (e) => {
        let _expandedRows = { ...expandedRows };

        if (_expandedRows[e.data.id]) {
            delete _expandedRows[e.data.id];
        } else {
            if (!e.data.retrieved) {
                setLoading(true);
                setLoadingProject(true);
                setActiveProjectId(e.data.id);
                e.data = await getProjectData(e.data);
                if (_expandedRows.length > 0) {
                    _expandedRows[e.data.id] = e.data;
                } else {
                    _expandedRows = {};
                    _expandedRows[e.data.id] = e.data;
                }
                setLoading(false);
            } else {
                if (_expandedRows.length > 0) {
                    _expandedRows[e.data.id] = e.data;
                } else {
                    _expandedRows = {};
                    _expandedRows[e.data.id] = e.data;
                }
            }
        }

        setExpandedRows(_expandedRows);
    };

    const deleteProject = (item) => {
        confirmDialog({
            message: (
                <div>
                    <span>{t("label.if_you_delete_the_project_all_services_and_tasks_associated_with_it_will_be_deleted")}</span>
                    <br />
                    <span>{t("label.are_you_sure_you_want_to_delete_the_project_name_project", { project_name: item?.name })}</span>
                </div>
            ),
            header: t("label.delete_project"),
            icon: "pi pi-info-circle",
            draggable: false,
            acceptClassName: "p-button-danger",
            acceptLabel: t("label.delete_project"),
            rejectLabel: t("label.no"),
            accept: () => {
                setLoading(true);

                taskService.emptyTask(item.id.split("#")[0], item.id.split("#")[1]);

                projectService
                    .deleteProject(item.id.split("#")[0], item.id.split("#")[1])
                    .then((data) => {
                        setLoading(false);

                        const newProjects = projects.filter((newProject) => newProject.id !== item.id);
                        setProjects(newProjects);
                    })
                    .catch((error) => {
                        setLoading(false);
                        console.log(error);
                    });
            },
            contentClassName: "pt-3",
        });
    };

    const onTablePageChange = (key) => {
        if (key === tableKeys.length) {
            setLoading(true);

            let queryParameters = {
                limit: rowsNumber,
            };

            if (formik.values.status) {
                queryParameters = {
                    ...queryParameters,
                    status: formik.values.status.type,
                };
            } else if (formik.values.text) {
                queryParameters = {
                    ...queryParameters,
                    name: formik.values.text,
                };
            }

            projectService.queryProjects(queryParameters, queryProjectsResponse?.last_key).then((data) => {
                setQueryProjectsResponse(data);

                if (data?.data && data?.data?.length > 0) {
                    const newProjects = [...projects, ...data.data];
                    setProjects(newProjects);

                    setTableKeys(tableKeys.concat([tableKeys.length]));
                    setActiveIndex(tableKeys.length);

                    if (tableKeys.length > 0) {
                        setTableFirst(tableKeys.length * rowsNumber);
                    } else {
                        setTableFirst(0);
                    }
                }

                setLoading(false);
            });
        } else {
            const newIndex = tableKeys.findIndex((item) => item === key);
            setActiveIndex(newIndex);

            if (newIndex > 0) {
                setTableFirst(newIndex * rowsNumber);
            } else {
                setTableFirst(0);
            }
        }
    };

    const emptyPage = () => {
        return (
            <div className="flex align-items-center justify-content-center overflow-hidden min-w-full">
                <img
                    src={checkPermissions(["view_projects"], null) ? `/empty_images/${i18n.language}/admin_projects_empty.svg` : checkPermissions(null, ["client"]) ? `/empty_images/${i18n.language}/admin_projects_empty.svg` : `/empty_images/${i18n.language}/admin_projects_empty.svg`}
                    alt=""
                    width={isDesktop() ? 400 : 250}
                    className="cursor-pointer"
                    onClick={() => setShowNewProjectDialog(true)}
                />
            </div>
        );
    };

    const projectsTableHeader = checkComponentPermissions(
        <div className="grid">
            {checkComponentPermissions(
                <div className="col-12 xl:col-3 md:col-6">
                    <div className="flex align-content-end flex-wrap w-full h-full">
                        <div className="flex align-items-end justify-content-center w-full h-full">
                            <Dropdown
                                dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                id="search-clients-dowpdown"
                                name="search-clients-dowpdown"
                                dataKey="id"
                                value={formik.values.client}
                                options={clients}
                                valueTemplate={selectedClientTemplate}
                                itemTemplate={clientOptionTemplate}
                                optionLabel="name"
                                onChange={(e) => filterByParams(e, "client")}
                                placeholder={t("label.select_client_dropdown")}
                                filter
                                className={`min-w-full ${formik.values.client ? "clients-custom-dropdown" : ""}`}
                                showClear
                                emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{"label.no_items_to_display"}</label>}
                                onScrollCapture={(e) => handleClientsOnScroll(e, clientService, clientsRowsNumber, queryClientsResponse, setQueryClientsResponse, clients, setClients, clientFilterName, setClientFilterName)}
                                filterTemplate={(options) => (
                                    <span className="p-input-icon-right min-w-full">
                                        <i className="pi pi-search" />
                                        <InputText autoFocus className="min-w-full" value={clientFilterName} ref={null} onChange={(e) => filterClients(e, options, setClientFilterName, queryClientsResponse, setQueryClientsResponse, clientService, clientsRowsNumber, clients, setClients)} />
                                    </span>
                                )}
                            />
                        </div>
                    </div>
                </div>,
                ["view_clients"],
                null,
                null
            )}
            <div className={`col-12 ${checkPermissions(["view_clients"]) ? "xl:col-2 md:col-6" : "xl:col-4 md:col-12"}`}>
                <div className="flex align-content-end flex-wrap w-full h-full">
                    <div className="flex align-items-end justify-content-center w-full h-full">
                        <Dropdown
                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                            id="search-status-dowpdown"
                            name="search-status-dowpdown"
                            dataKey="type"
                            value={formik.values.status}
                            options={PROJECT_STATUSES}
                            optionLabel={(item) => {
                                return t(item?.label);
                            }}
                            onChange={(e) => filterByParams(e, "status")}
                            placeholder={t("label.select_status_dropdown")}
                            className="min-w-full"
                            showClear
                        />
                    </div>
                </div>
            </div>
            <div className="col-12 xl:col-7">
                <form id="search-project-form" onSubmit={formik.handleSubmit} className="min-w-full">
                    <small className="pl-2 mousee-text-small font-weight-regular">{t("label.use_upper_and_lower_case")}</small>
                    <span className="p-input-icon-right min-w-full">
                        <i className="pi pi-search" />
                        <InputText
                            value={formik.values.text}
                            className="min-w-full"
                            onChange={(e) => {
                                formik.setFieldValue("text", e.target.value);
                                if (e.target.value === "") {
                                    formik.handleSubmit();
                                }
                            }}
                            placeholder={t("label.search_inputtext")}
                        />
                    </span>
                </form>
            </div>
        </div>,
        null,
        ["employee"],
        null
    );

    return checkComponentPermissions(
        <div style={{ textAlign: "left", verticalAlign: "middle" }} className="grid page-layout">
            {showNewProjectDialog ? <AddProjectDialog /*reload={setLoading}*/ show={showNewProjectDialog} setShow={setShowNewProjectDialog} /*projects={projects} setProjects={setProjects}*/ /> : null}

            <Toast ref={toast} />
            <ConfirmDialog />
            {/* {!formik.values.status && !formik.values.client && formik.values.text === "" && !loading && (!projects || (projects && projects.length === 0)) ? (
                emptyPage()
            ) : (
                <>
                    
                </>
            )} */}
            {/* <div className="xl:col-offset-1"></div> */}
            <div className="col-12 xl:col-12">
                <div className="grid">
                    <div className="col-12 p-0">
                        <div className="flex  justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <div className="grid p-0">
                                    <div className="col-12 p-0">
                                        <label className="mousee-text font-large font-weight-semibold">{t("label.projects")}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <div className="flex flex-row flex-wrap">
                                    {checkComponentPermissions(
                                        <div className="flex align-items-center justify-content-center">
                                            <Button icon={"pi pi-plus"} label={t("label.activate_project")} type="button" className="p-button min-w-full" onClick={() => setShowNewProjectDialog(true)} />
                                        </div>,
                                        ["edit_projects"],
                                        null,
                                        null
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 p-0 pt-5">
                        <div className="card p-0" style={{ overflow: "hidden" }}>
                            <DataTable
                                key="projects-table"
                                selectionMode="single"
                                dataKey="id"
                                // emptyMessage={t("label.no_items_to_display")}
                                emptyMessage={!formik.values.status && !formik.values.client && formik.values.text === "" && !loading && (!projects || (projects && projects.length === 0)) ? emptyPage() : t("label.no_items_to_display")}
                                loading={loading}
                                value={projects && projects.slice(activeIndex * rowsNumber, activeIndex * rowsNumber + rowsNumber)}
                                responsiveLayout="scroll"
                                onRowClick={(e) => {
                                    expandSpecificRow(e);
                                }}
                                stripedRows
                                paginator={false}
                                expandedRows={expandedRows}
                                onRowToggle={(e) => {
                                    setExpandedRows(e.data);
                                }}
                                rowExpansionTemplate={processesTableTemplate}
                                onRowExpand={(e) => {
                                    if (!e.data.retrieved) {
                                        setLoading(true);
                                        setLoadingProject(true);
                                        setActiveProjectId(e.data.id);
                                        e.data = getProjectData(e.data);
                                    }
                                }}
                                header={projectsTableHeader}
                                first={tableFirst}
                                footer={<CustomTablePaginator activeIndex={activeIndex} onPageChange={onTablePageChange} first={tableFirst} rowsNumber={rowsNumber} keys={tableKeys} lastKey={queryProjectsResponse?.last_key} />}
                            >
                                <Column expander={true} style={{ width: "5rem" }} />

                                <Column header={tableHeaderTemplate(null, t("label.name"))} style={{ width: "25%", minWidth: "15rem" }} body={(item) => <label className="mousee-text font-x-small font-weight-semibold">{item.name}</label>} />

                                <Column header={tableHeaderTemplate(null, t("label.client"))} field="client_name" style={{ width: "30%", minWidth: "20rem" }} />

                                <Column header={tableHeaderTemplate(null, t("label.status"))} style={{ width: "15%", minWidth: "10rem" }} field="status" body={(item) => getProjectStatusChip(item?.status, t)} />

                                <Column header={tableHeaderTemplate(null, t("label.services"))} style={{ width: "10%", minWidth: "10rem" }} field="processes" />

                                {/* <Column header={tableHeaderTemplate(null, t("label.creation_date"))} style={{ width: "15%", minWidth: "14rem" }} body={(item) => formatDateV2(item.created_at, true, i18n.language)} /> */}

                                <Column header={tableHeaderTemplate(null, t("label.update_date"))} style={{ width: "15%", minWidth: "14rem" }} body={(item) => formatDateV2(item.updated_at, true, i18n.language)} />

                                {checkComponentPermissions(
                                    <Column
                                        headerStyle={{ width: "5rem", textAlign: "center" }}
                                        bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                        body={(item, options) => {
                                            return (
                                                <Button
                                                    icon={<Icon icon="solar:trash-bin-trash-bold-duotone" className="" style={{ fontSize: "20px" }} />}
                                                    className="p-button-text p-button-secondary"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        deleteProject(item);
                                                    }}
                                                />
                                            );
                                        }}
                                    />,
                                    ["edit_projects"],
                                    null,
                                    null
                                )}
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="xl:col-offset-1"></div> */}
        </div>,
        ["view_projects"],
        ["client"],
        <Forbidden />
    );
};

export default Projects;
