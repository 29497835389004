import { Avatar } from "primereact/avatar";
import { v4 as uuid } from "uuid";
import { getTenantCommercialNameAcronym } from "../../../Utils";

export const selectedClientTemplate = (option, props) => {
    if (option) {
        return (
            <div className="flex align-items-center">
                <Avatar image={option.picture} label={!option?.picture ? getTenantCommercialNameAcronym(option?.name) : null} style={!option?.picture ? { backgroundColor: "var(--surface-300)", color: "var(--secondary-color-extra-light)" } : {}} shape="circle" />
                <div className="ml-3">{option.name}</div>
            </div>
        );
    }

    return <span>{props.placeholder}</span>;
};

export const clientOptionTemplate = (option) => {
    return (
        <div className="flex align-items-center">
            <Avatar image={option.picture} label={!option?.picture ? getTenantCommercialNameAcronym(option?.name) : null} style={!option?.picture ? { backgroundColor: "var(--surface-300)", color: "var(--secondary-color-extra-light)" } : {}} size="large" shape="circle" />
            <div className="ml-3">{option.name}</div>
        </div>
    );
};

export const getClientLogoUrl = (currentUser, clientId, filename) => {
    var fileExt = filename.split(".").pop();
    const unique_id = uuid();

    if (currentUser && currentUser.attributes && currentUser.attributes["custom:tenant_id"]) {
        const tenantId = currentUser.attributes["custom:tenant_id"];
        const finalPictureName = `${unique_id}.${fileExt}`;

        const finalPictureUrl = `/private/${tenantId}/clients/${clientId}/${finalPictureName}`;

        return finalPictureUrl;
    }

    return;
};

export const handleClientsOnScroll = (e, clientService, clientsRowsNumber, queryClientsResponse, setQueryClientsResponse, clients, setClients, clientFilterName, setClientFilterName) => {
    const bottom = e?.target?.scrollHeight - e?.target?.clientHeight <= e?.target?.scrollTop + 50;
    if (bottom) {
        const retrieveClients = (lastKey) => {
            lastKey = lastKey && Object.keys(lastKey).length > 0 ? lastKey : undefined;
            if (lastKey) delete lastKey["name"];
            clientService
                .queryClients({ limit: clientsRowsNumber }, lastKey)
                .then((data) => {
                    if (data) {
                        setQueryClientsResponse(data);

                        if (data.data && data.data.length > 0) {
                            var ids = new Set(clients.map((d) => d.id));

                            var mergedClients = [...clients, ...data.data.filter((d) => !ids.has(d.id))];
                            setClients(mergedClients);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        };

        let lastKey = queryClientsResponse?.last_key;
        if (!lastKey || (lastKey && Object.keys(lastKey).length === 0)) {
            // lastKey = clients && clients.length > 0 ? clients[clients.length - 1].id : ""

            if (clients && clients.length > 0) {
                const lastClient = clients[clients.length - 1];
                lastKey = {
                    id: lastClient.id,
                };

                if (clientFilterName !== undefined) {
                    if (clientFilterName && clientFilterName.length > 0) {
                        lastKey = {
                            ...lastKey,
                            name: lastClient.name,
                        };
                    }
                    retrieveClients(lastKey);
                    if (clientFilterName === "") {
                        setClientFilterName(undefined);
                    }
                }
            }
        } else {
            retrieveClients(lastKey);
        }
    }
};

export const filterClients = (event, options, setClientFilterName, queryClientsResponse, setQueryClientsResponse, clientService, clientsRowsNumber, clients, setClients) => {
    let _filterValue = event.target.value;
    setClientFilterName(_filterValue);

    let lastKey = undefined;

    if (queryClientsResponse && queryClientsResponse.last_key && Object.keys(queryClientsResponse.last_key).length > 0) {
        if (_filterValue.trim() === "" || _filterValue.length === 0) {
            if (lastKey) delete lastKey["name"];
        } else {
            if (lastKey && !lastKey.name) {
                if (clients && clients.length > 0) {
                    lastKey = {
                        id: clients[clients.length - 1].id,
                        name: clients[clients.length - 1].name,
                    };
                } else {
                    lastKey = {
                        ...lastKey,
                        name: "",
                    };
                }
            }
        }
    }

    clientService
        .queryClients({ name: _filterValue, limit: clientsRowsNumber }, lastKey)
        .then((data) => {
            setQueryClientsResponse(data);

            if (data && data.data && data.data.length > 0) {
                var ids = new Set(clients.map((d) => d.id));

                var mergedClients = [...clients, ...data.data.filter((d) => !ids.has(d.id))];
                setClients(mergedClients);
            }
            options?.filterOptions?.filter(event);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const clientItemTemplate = (option, avatarSize) => {
    return (
        <div className="flex align-items-center gap-2">
            <Avatar
                image={option.picture && option.picture}
                label={!option?.picture ? getTenantCommercialNameAcronym(option?.name) : null}
                style={!option?.picture ? { backgroundColor: "var(--surface-300)", color: "var(--secondary-color-extra-light)" } : {}}
                size={avatarSize ? avatarSize : "xlarge"}
                shape="circle"
            />
            <span className="pl-3">{option.name}</span>
        </div>
    );
};

export const clientIndividualItemTemplate = (option, avatarSize) => {
    return (
        <div className="flex align-items-center gap-2">
            <Avatar
                image={option?.picture && option.picture}
                label={!option?.picture ? getTenantCommercialNameAcronym(option?.name) : null}
                style={!option?.picture ? { backgroundColor: "var(--surface-300)", color: "var(--secondary-color-extra-light)" } : {}}
                size={avatarSize ? avatarSize : "xlarge"}
                shape="circle"
            />
            <div className="flex flex-column">
                <div className="flex align-items-center justify-content-start">
                    <label className="mousee-text font-x-small font-weight-regular pl-3">{option?.name}</label>
                </div>
                <div className="flex align-items-center justify-content-start">
                    <label className="mousee-text-small font-xx-small font-weight-regular pl-3">{option?.email}</label>
                </div>
            </div>
        </div>
    );
};
