import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { Editor } from "primereact/editor";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { blockBackgroundScroll, objectHasKeys, parseTime, tableHeaderTemplate } from "../../../Utils";
import dropdownDown from "../../../assets/icons/dropdown-down.svg";
import taskParticipantsIcon from "../../../assets/icons/process/process-participants.svg";
import taskProfileIcon from "../../../assets/icons/process/process-profile.svg";
import taskRoleIcon from "../../../assets/icons/process/process-role.svg";
import { checkPermissions } from "../../../data/Permissions";
import { TASK_PERMISSIONS, getTaskPermissionObject } from "../../../data/Task";
import { filterProfiles, handleProfilesOnScroll } from "../../../pages/People/Profiles/Utils";
import AddChecklistDialog from "../../../pages/Projects/dialogs/AddChecklistDialog/AddChecklistDialog";
import { taskParticipantPermissionTemplate } from "../../../pages/Task/Utils";
import ProfileService from "../../../services/ProfileService/ProfileService";
import "./AddEditTaskComponent.scss";

const AddEditTaskComponent = (props) => {
    const { t, i18n } = useTranslation();

    const [profile, setProfile] = useState();
    const profileService = new ProfileService();
    const [changesDetected, setChangesDetected] = useState(false);

    const [profiles, setProfiles] = useState([]);
    const [queryProfilesResponse, setQueryProfilesResponse] = useState();
    const [profilesRowsNumber, setProfilesRowsNumber] = useState(6);
    const [profileFilterName, setProfileFilterName] = useState(undefined);
    const [edittingTitle, setEdittingTitle] = useState(true);
    const [showAddChecklistDialog, setShowAddChecklistDialog] = useState(false);

    const [profileVisibility, setProfileVisibility] = useState("public");

    useEffect(() => {
        refreshProfiles(profileVisibility, undefined);
    }, []);

    const formik = useFormik({
        initialValues: {
            id: props.task && props.task.id ? props.task.id : uuid(),
            title: props.task && props.task.title ? props.task.title : "",
            description: props.task && props.task.description ? props.task.description : "",
            participants: props.task && props.task.participants ? props.task.participants : [],
            checklist: props.task && props.task.checklist ? props.task.checklist : undefined,
            duration_in_days: props.task && props.task.duration_in_days ? props.task.duration_in_days : 1,
            depends_on: props.task && props.task.depends_on ? props.task.depends_on : null,
            include_client: props.task && props.task.include_client ? props.task.include_client : false,
        },
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {
            if (props.task) {
                props.saveChanges("update", data);
            } else {
                props.saveChanges("add", data);
            }
            blockBackgroundScroll(false);
            formik.resetForm();
            props.close();
        },
    });

    const refreshProfiles = (visibility, name) => {
        let queryParameters = {
            limit: profilesRowsNumber,
        };

        if (name && name.length > 0) {
            queryParameters = {
                ...queryParameters,
                name: name,
            };
        }

        let lastKey = undefined;

        if (visibility === profileVisibility && queryProfilesResponse && queryProfilesResponse?.last_key && objectHasKeys(queryProfilesResponse?.last_key)) {
            lastKey = queryProfilesResponse?.last_key;
        }

        profileService.queryProfiles(queryParameters, lastKey, "private").then((data) => {
            setQueryProfilesResponse(data);
            setProfiles(data?.data);
        });
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const addParticipant = (profile) => {
        setChangesDetected(true);
        const newParticipant = {
            id: uuid(),
            profile: {
                id: profile.id,
                name: profile.name,
                hour_cost: profile.hour_cost,
                visibility: profile.visibility,
            },
            role: "viewer",
            time: {
                hours: 0,
                minutes: 0,
            },
        };

        const newParticipants = [...formik.values.participants, ...[newParticipant]];

        formik.setFieldValue("participants", newParticipants);
    };

    const updateParticipant = (newValue, index) => {
        setChangesDetected(true);
        let newParticipants = formik.values.participants;

        newParticipants[index] = newValue;

        formik.setFieldValue("participants", newParticipants);
    };

    const getAvailableProfiles = (profiles) => {
        let newProfiles = profiles;
        let participants = formik.values.participants;

        if (participants && newProfiles) {
            const activeProfiles = participants.map((participant) => participant.profile && participant.profile.id);

            newProfiles = newProfiles.filter((newProfile) => !activeProfiles.includes(newProfile.id));
        }

        if (newProfiles && newProfiles.length < profilesRowsNumber && queryProfilesResponse?.last_key) {
            profileService.queryProfiles({ limit: profilesRowsNumber }, undefined, "private").then((data) => {
                setQueryProfilesResponse(data);
                if (data?.data) {
                    const newRetrievedProfiles = [...newProfiles, ...data?.data];
                    setProfiles(newRetrievedProfiles);

                    newProfiles = [...newRetrievedProfiles];
                }
            });
        }

        return newProfiles;
    };

    const updateTime = (currentParticipant, quantity, operation, type, participantIndex) => {
        setChangesDetected(true);

        let time = currentParticipant.time;

        if (type === "manual") {
            quantity = quantity.split(":");
            time = {
                hours: parseInt(quantity[0]),
                minutes: parseInt(quantity[1]) > 59 ? 0 : parseInt(quantity[1]),
            };
        } else {
            if (operation === "add") {
                if (time.minutes + 15 >= 60) {
                    const minutesToAnHour = 60 - time.minutes;
                    const quantityToAdd = 15 - minutesToAnHour;

                    time = {
                        hours: time.hours + 1,
                        minutes: quantityToAdd,
                    };
                } else {
                    time = {
                        ...time,
                        minutes: time.minutes + 15,
                    };
                }
            } else {
                if (time.minutes - 15 >= 0) {
                    time = {
                        ...time,
                        minutes: time.minutes - quantity,
                    };
                } else {
                    let auxMinutes = time.minutes - 15;

                    time = {
                        hours: time.hours === 0 ? 0 : time.hours - 1,
                        minutes: time.hours === 0 ? 0 : 60 - Math.abs(auxMinutes),
                    };
                }
            }
        }

        const newParticipant = {
            ...currentParticipant,
            time: time,
        };

        updateParticipant(newParticipant, participantIndex);
    };

    const participantsTemplateV2 = (participants) => {
        return (
            <DataTable emptyMessage={t("label.no_items_to_display")} value={participants} responsiveLayout="scroll" stripedRows rowHover={false} rowClassName="pb-0" scrollable scrollHeight="225px">
                <Column field="profile.name" header={tableHeaderTemplate(taskProfileIcon, t("label.skill"))} style={{ width: "15%", minWidth: "15rem" }} headerClassName="hidden" className="mousee-text font-x-small font-weight-semibold" />

                <Column
                    body={(item, options) => {
                        return (
                            <Dropdown
                                dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                id={`participant-roles-dowpdown-${item.id}`}
                                name={`participant-roles-dowpdown-${item.id}`}
                                dataKey="type"
                                value={getTaskPermissionObject(item.role ? item.role : "viewer")}
                                options={TASK_PERMISSIONS}
                                onChange={(e) => updateParticipant({ ...item, role: e.value.type }, options.rowIndex)}
                                placeholder={t("label.dropdown_select")}
                                className="min-w-full w-12rem"
                                valueTemplate={(item) => taskParticipantPermissionTemplate(item, t)}
                                itemTemplate={(item) => taskParticipantPermissionTemplate(item, t)}
                            />
                        );
                    }}
                    style={{ width: "20%" }}
                    header={tableHeaderTemplate(taskRoleIcon, t("label.role"))}
                    headerClassName="hidden"
                />

                <Column
                    body={(item, options) => {
                        return (
                            <div className="p-inputgroup custom-time-picker">
                                <Button
                                    icon="pi pi-minus"
                                    className="p-button-text"
                                    onClick={(e) => {
                                        updateTime(item, 15, "subtract", "", options.rowIndex);
                                    }}
                                />
                                <InputMask
                                    value={item.time ? parseTime(item.time) : "00:00"}
                                    placeholder="00:00"
                                    mask="99:99"
                                    className="w-full"
                                    onChange={(e) => {
                                        if (!e.value.includes("_")) {
                                            updateTime(item, e.value, "", "manual", options.rowIndex);
                                        }
                                    }}
                                />

                                <Button
                                    icon="pi pi-plus"
                                    className="p-button-text"
                                    onClick={(e) => {
                                        updateTime(item, 15, "add", "", options.rowIndex);
                                    }}
                                />
                            </div>
                        );
                    }}
                    style={{ width: "10%", minWidth: "10rem" }}
                    header={tableHeaderTemplate(taskRoleIcon, t("label.role"))}
                    headerClassName="hidden"
                />

                <Column
                    bodyStyle={{ textAlign: "center", overflow: "visible" }}
                    headerClassName="hidden"
                    style={{ width: "3%", minWidth: "3rem" }}
                    body={(item, options) => {
                        return (
                            <i
                                className={`pi pi-trash mousee-text font-small`}
                                style={{ fontSize: "1.5rem" }}
                                onClick={() => {
                                    const newParticipants = formik.values.participants.filter((_, item_index) => item_index !== options.rowIndex);
                                    formik.setFieldValue("participants", newParticipants);
                                }}
                            ></i>
                        );
                    }}
                />
            </DataTable>
        );
    };

    const cancel = () => {
        const operation = () => {
            formik.resetForm();
            props.close();
        };

        if (changesDetected) {
            confirmDialog({
                message: t("message.if_you_cancel_you_will_lose_the_changes_made_do_you_wish_to_continue"),
                header: t("label.confirmation"),
                draggable: false,
                icon: "pi pi-exclamation-triangle",
                contentClassName: "pt-3",
                acceptLabel: t("label.yes"),
                rejectLabel: t("label.no"),
                accept: operation,
            });
        } else {
            operation();
        }
    };

    const handleKeyDown = (event) => {
        if (document?.activeElement?.className !== "ql-editor" && !showAddChecklistDialog) {
            if (event.key === "Enter") {
                formik.handleSubmit();
            } else if (event.key === "Escape") {
                cancel();
            }
        }
    };

    return (
        <Dialog
            key={"import-process-dialog"}
            visible={props.show}
            onShow={() => blockBackgroundScroll(true)}
            onHide={() => {
                blockBackgroundScroll(false);
                props.onHide(false);
            }}
            style={{ width: "90%" }}
            // maximizable
            draggable={false}
            // maximizeIcon={<img alt={"dialog-maximize-icon"} src={maximizeIcon} className="" style={{ width: "1.5rem" }}></img>}
            // minimizeIcon={<img alt={"dialog-minimize-icon"} src={minimizeIcon} className="" style={{ width: "1.5rem" }}></img>}

            // className="custom-dialog"
            closable={false}
            dismissableMask
            header={
                <div className="flex flex-row flex-wrap pr-2">
                    <div className="flex align-items-center justify-content-start min-w-full">
                        {checkPermissions(["edit_services"]) ? (
                            edittingTitle || !formik.values.title || (formik.values.title && !formik.values.title.length === 0) ? (
                                <InputText
                                    autoFocus
                                    value={formik.values.title}
                                    style={{ border: "none" }}
                                    onChange={(e) => {
                                        formik.setFieldValue("title", e.target.value);
                                    }}
                                    content
                                    className="mousee-text font-large font-weight-light min-w-full"
                                    placeholder={t("label.what_should_be_done")}
                                />
                            ) : (
                                <label className="mousee-text font-large font-weight-light" onClick={() => (checkPermissions(["edit_services"]) ? setEdittingTitle(true) : null)}>
                                    {formik.values.title}
                                </label>
                            )
                        ) : (
                            <label className="mousee-text font-large font-weight-light">{formik.values.title}</label>
                        )}
                    </div>
                </div>
            }
            // footer={<Button disabled={!checkPermissions(["edit_services"])} form="import-process-data-form" type="button" label={t("label.save_changes")} className="p-button" onClick={formik.handleSubmit} />}
            footer={
                <div className="">
                    <Button
                        className="p-button-outlined p-button-plain"
                        label={t("label.cancel")}
                        onClick={() => {
                            blockBackgroundScroll(false);
                            props.onHide(false);
                        }}
                    />
                    <Button form="new-profile-form" className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={formik.handleSubmit} />
                </div>
            }
            contentClassName="p-4"
            contentStyle={{ background: "var(--miflow-no-white-background)" }}
        >
            <div className="grid" key={props.key} onKeyUp={handleKeyDown} disabled={!checkPermissions(["edit_services"])}>
                {showAddChecklistDialog ? <AddChecklistDialog show={showAddChecklistDialog} onHide={setShowAddChecklistDialog} checklist={formik.values.checklist} setTask={(newCheckList) => formik.setFieldValue("checklist", newCheckList)} taskType="process_task" /> : null}

                <div className="col-12 xl:col-8 sm:col-12">
                    <div className="card p-2" style={{ borderRadius: "var(--border-radius)" }}>
                        <div className="grid">
                            <div className={`col-12 pt-3 pl-0 pr-0`}>
                                <div className="col-12 p-0 pl-3">
                                    <i className="pi pi-align-left mousee-text font-small font-weight-semibold pr-3"></i>
                                    {/* <Icon icon="solar:list-bold-duotone" style={{ fontSize: "20px" }} className="mr-3 min-h-full" /> */}
                                    <label className="mousee-text font-small font-weight-semibold ">{t("label.description")}</label>
                                </div>
                                <div className="col-12 p-0">
                                    <Divider className="border-300 mt-2 mb-0" />
                                </div>

                                <div className="col-12 p-0 custom-task-editor">
                                    <Editor
                                        value={formik.values.description}
                                        className="w-12 border-transparent mousee-text text-x-small font-weight-regular"
                                        placeholder={t("label.add_a_description")}
                                        headerTemplate={
                                            <span className="ql-formats">
                                                <button className="ql-bold" aria-label="Bold"></button>
                                                <button className="ql-italic" aria-label="Italic"></button>
                                                <button className="ql-underline" aria-label="Underline"></button>

                                                <button className="ql-list" value={"ordered"} aria-label="List"></button>
                                                <button className="ql-list" value={"bullet"} aria-label="List"></button>

                                                <button className="ql-align"></button>
                                            </span>
                                        }
                                        onTextChange={(e) => {
                                            setChangesDetected(true);
                                            formik.setFieldValue("description", e.htmlValue);
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Subtareas */}
                            {formik.values.checklist ? (
                                <div className="col-12 p-0">
                                    <Divider className="border-300 mt-1" />
                                    <div className="col-12 p-0 pl-3">
                                        <i className="pi pi-check-square mousee-text font-small font-weight-semibold pr-3"></i>
                                        <label className="mousee-text font-small font-weight-semibold">{t("label.subtasks")}</label>
                                    </div>
                                    <div className="col-12 p-0">
                                        <Divider className="border-300 mt-2 mb-0" />
                                    </div>

                                    <div className="col-12">
                                        <div className="flex justify-content-between flex-wrap pl-2">
                                            <div className="flex align-items-center justify-content-center">
                                                <div className="flex justify-content-start flex-wrap">
                                                    <div className="flex align-items-center justify-content-center">
                                                        <label className="mousee-text font-medium font-weight-bold">{formik.values?.checklist?.title}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex align-items-center justify-content-center">
                                                <div className="flex justify-content-end flex-wrap">
                                                    <div className="flex align-items-center justify-content-center">
                                                        <Button icon={"pi pi-pencil"} className="p-button-text p-button-secondary" onClick={(e) => setShowAddChecklistDialog(true)} />
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center">
                                                        <Button icon={"pi pi-trash"} className="p-button-text p-button-secondary" onClick={(e) => formik.setFieldValue("checklist", undefined)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {formik.values?.checklist?.options.map((option, index) => {
                                        return (
                                            <div className="col-12 pt-3">
                                                <div className="flex align-items-center pl-2">
                                                    <Checkbox inputId={`${option}-${index}`} name={`${option}-${index}`} disabled={true} />
                                                    <label htmlFor={`${option}-${index}`} className="mousee-text font-x-small font-weight-light ml-2">
                                                        {option}
                                                    </label>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : null}

                            {/* Participants */}
                            <div className="col-12 p-0">
                                {formik.values.participants && formik.values.participants.length > 0 ? (
                                    <React.Fragment>
                                        <Divider className="border-300 mt-1" />
                                        <div className={`col-12 pt-0 pl-0 pr-0`}>
                                            <div className="col-12 p-0 pl-3">
                                                <div className="flex align-content-center flex-wrap">
                                                    <div className="flex align-items-center justify-content-center">
                                                        <img alt="participants-logo" src={taskParticipantsIcon} className="pr-3" />
                                                        <label className="mousee-text font-small font-weight-semibold">{t("label.participants")}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 p-0 pt-2">{participantsTemplateV2(formik.values.participants)}</div>
                                    </React.Fragment>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 xl:col-4 sm:col-12">
                    <div className="card">
                        <div className="grid">
                            <div className="col-12 input-time-custom">
                                <label className="block mousee-text font-x-small font-weight-regular">{t("label.time_to_do_it_days")}</label>

                                <div className="pt-2">
                                    <InputNumber
                                        style={{ minWidth: "19rem" }}
                                        className="w-full"
                                        inputId="horizontal-buttons"
                                        value={formik.values.duration_in_days}
                                        min={1}
                                        onValueChange={(e) => formik.setFieldValue("duration_in_days", e.value)}
                                        incrementButtonClassName="p-button-text"
                                        decrementButtonClassName="p-button-text"
                                        showButtons
                                        buttonLayout="horizontal"
                                        step={1}
                                        incrementButtonIcon="pi pi-plus"
                                        decrementButtonIcon="pi pi-minus"
                                    />
                                </div>
                            </div>

                            <div className="col-12">
                                <label className="block mousee-text font-x-small font-weight-regular">{t("label.skills")}</label>
                                <div className="pt-2">
                                    <Dropdown
                                        dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                        id="user-profiles-dowpdown"
                                        name="user-profiles-dowpdown"
                                        dataKey="id"
                                        value={profile}
                                        options={profiles}
                                        optionLabel="name"
                                        onChange={(e) => {
                                            addParticipant(e.value);
                                        }}
                                        placeholder={t("label.select_skill_dropdown")}
                                        filter
                                        className="min-w-full"
                                        showClear
                                        emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                        onScrollCapture={(e) => handleProfilesOnScroll(e, profileService, profilesRowsNumber, queryProfilesResponse, setQueryProfilesResponse, profiles, setProfiles, profileFilterName, setProfileFilterName, profileVisibility)}
                                        filterTemplate={(options) => (
                                            <div className="flex flex-column min-w-full">
                                                <div className="flex align-items-center justify-content-center">
                                                    <span className="p-input-icon-right min-w-full">
                                                        <i className="pi pi-search" />
                                                        <InputText
                                                            autoFocus
                                                            className="min-w-full"
                                                            value={profileFilterName}
                                                            ref={null}
                                                            onChange={(e) => filterProfiles(e, options, setProfileFilterName, queryProfilesResponse, setQueryProfilesResponse, profileService, profilesRowsNumber, profiles, setProfiles, profileVisibility)}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="flex align-items-center">
                                    <InputSwitch checked={formik.values.include_client} onChange={(e) => formik.setFieldValue("include_client", e.value)} />
                                    <label className="ml-2 mousee-text font-x-small font-weight-light">{t("label.visible_to_the_client")}</label>
                                </div>
                            </div>

                            <div className="col-12">
                                <Button
                                    label={t("label.subtasks")}
                                    icon="pi pi-check-square pr-2"
                                    className="p-button-filled min-w-full text-left"
                                    onClick={(e) => {
                                        setShowAddChecklistDialog(true);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
export default AddEditTaskComponent;
